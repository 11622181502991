import React, { useMemo, useCallback, useEffect, useState } from 'react';
import { useOutletContext, useNavigate, useLocation, useParams } from 'react-router-dom';
import { format } from 'date-fns';

import Button from 'components/Button';
import DonorList from 'components/DonorList';
import { HazardItem, keyExtractor as hazardKeyExtractor } from 'components/Filters/FilterHazards';
import HTMLParser from 'components/HTMLParser';
import Map, { MapProps } from 'components/Map';
import { OrganizationItem } from 'components/OrganizationList';
import ProjectFormModal from 'components/ProjectFormModal';
import ProjectDocuments from 'components/ProjectDocuments';
import ProjectReportsDropdown from 'components/ProjectReportsDropdown';
import StatusItemSelect from 'components/StatusItem';
import Tabs, { Tab } from 'components/Tabs';
import List from '@ra/components/List';

import Api from 'services/api';
import cs from '@ra/cs';
import Toast from 'services/toast';
import { _, Localize, objTranslator } from 'services/i18n';
import { formatNumber } from 'utils/formatter';
import { getErrorMessage } from '@ra/utils/error';

import { useAppSelector } from 'hooks/store';
import usePromise from '@ra/hooks/usePromise';
import useAdministrativeDivisions from 'hooks/useAdministrativeDivisions';

import type { Project, ProjectDocument } from 'services/types';
import type { ExpandedProject, ExpandedOutput } from 'services/report';

import OutputDetails from './OutputDetails';

import styles from './styles.scss';

interface ProjectDetailsLocationState {
    project?: Project;
}

interface DetailsItemProps extends MapProps {
    icon?: string;
    title: string;
    content?: string;
    isMap?: boolean;
    renderContent?: () => React.ReactNode;
}

const DetailsItem = (props: DetailsItemProps) => {
    const { title, icon, content, isMap, renderContent, ...mapProps } = props;

    const navigate = useNavigate();

    const handleMapLinkClick = useCallback(() => {
        navigate('/map');
    }, [navigate]);

    return (
        <div className={cs(styles.detailsInfoItem, { [styles.detailsInfoItemMap]: isMap })}>
            {Boolean(icon) && (
                <div className={styles.infoItemIcon}>
                    <span className="material-symbols-rounded">{icon}</span>
                </div>
            )}
            <div className={styles.infoItemContentContainer}>
                <div className={styles.infoItemHeader}>
                    <p
                        className={cs(styles.infoItemHeaderTitle, {
                            [styles.infoItemHeaderTitleMap]: isMap
                        })}
                    >
                        {title}
                    </p>
                    {isMap && (
                        <div className={styles.viewMapLink} onClick={handleMapLinkClick}>
                            <span
                                className={cs(styles.viewMapLinkIcon, 'material-symbols-rounded')}
                            >
                                map
                            </span>
                            <span className={styles.viewMapLinkText}>
                                <Localize>View interactive map</Localize>
                            </span>
                        </div>
                    )}
                </div>
                {isMap ? (
                    <Map style={{ width: '100%', height: 300 }} hideLegend {...mapProps} />
                ) : (
                    <div className={styles.infoItemContent}>
                        {renderContent?.() || content || '-'}
                    </div>
                )}
            </div>
        </div>
    );
};

const ProjectControlButtons: React.FC<{
    onEdit: () => void;
    onReportAdded: () => void;
    isVisible: boolean;
    documents: ProjectDocument[];
}> = ({ onEdit, isVisible, onReportAdded, documents }) => {
    if (!isVisible) {
        return null;
    }
    return (
        <div className={styles.buttons}>
            <ProjectReportsDropdown
                documents={documents}
                onReportAdded={onReportAdded}
                buttonClassName={styles.addReportButton}
            />
            <Button className={styles.editButton} small leftIcon="edit_document" onClick={onEdit}>
                <Localize>Edit</Localize>
            </Button>
        </div>
    );
};

const ProjectDetails: React.FC = () => {
    const { navBarHeight }: { navBarHeight?: number } = useOutletContext();

    const { regions } = useAppSelector((state) => state.region);
    const { organizations } = useAppSelector((state) => state.organization);
    const { priorityIndicators } = useAppSelector((state) => state.priorityIndicator);
    const { hazards } = useAppSelector((state) => state.hazard);
    const { user } = useAppSelector((state) => state.auth);

    const { projectId } = useParams();

    const location = useLocation();
    const project = (location.state as ProjectDetailsLocationState)?.project;

    const statusData: { [key: string]: string } = useMemo(
        () => ({
            pipeline: _('Pipeline'),
            active: _('Active'),
            completed: _('Completed'),
            dropped: _('Dropped')
        }),
        []
    );

    const [{ result }, getProject] = usePromise(Api.getProject);
    const getProjectData = useCallback(async () => {
        try {
            await getProject(projectId, { expand: 'outputs.activities.targets.reports,donor' });
        } catch (error) {
            console.log(error);
        }
    }, [projectId, getProject]);
    useEffect(() => {
        getProjectData();
    }, [getProjectData]);

    const activeProject = useMemo(() => {
        if (result) {
            return result;
        }
        return project;
    }, [project, result]);

    const projectDuration = useMemo(() => {
        if (activeProject) {
            const startDate = new Date(activeProject.dateFrom);
            const endDate = new Date(activeProject.dateTo);
            return `${format(startDate, 'MMM dd, yyyy')} - ${format(endDate, 'MMM dd, yyyy')}`;
        }
        return '-';
    }, [activeProject]);

    const fundingTypeValue = useMemo(() => {
        const fundingType = activeProject?.projectType?.replace(/_/g, ' ') || '';
        return fundingType.charAt(0).toUpperCase() + fundingType.slice(1);
    }, [activeProject]);

    const focusedRegion = useMemo(() => {
        if (regions.length && activeProject?.location) {
            return regions.find((reg) => reg.id === activeProject.location);
        }
        return null;
    }, [regions, activeProject]);

    const focusedRegionTitle = useMemo(() => {
        if (focusedRegion?.level === 0 || (focusedRegion?.level && focusedRegion.level < 4)) {
            return focusedRegion?.title || '-';
        }
        if (focusedRegion?.level === 4) {
            const parentRegion = regions.find((reg) => reg.id === focusedRegion.parent);
            if (parentRegion) {
                return (
                    objTranslator(parentRegion, 'title') +
                    ' ' +
                    _('ward') +
                    ' ' +
                    focusedRegion.title
                );
            }
            return objTranslator(focusedRegion, 'title');
        }
        return '-';
    }, [focusedRegion, regions]);

    const projectOrganization = useMemo(
        () => organizations.find((org) => org.id === activeProject?.organization),
        [organizations, activeProject]
    );

    const divisionLevels = useAdministrativeDivisions();
    const activeDivisionLevel = useMemo(() => {
        if (focusedRegion) {
            return divisionLevels[focusedRegion.level];
        }
        return divisionLevels[1];
    }, [divisionLevels, focusedRegion]);

    const tabScrollHeaderStyle = useMemo(() => {
        return { top: `${navBarHeight}px` };
    }, [navBarHeight]);

    const tabStyle = useMemo(() => {
        return { scrollMargin: `calc(${navBarHeight}px + 20rem)` };
    }, [navBarHeight]);

    const budgetValue = useMemo(() => {
        if (activeProject?.budget) {
            return formatNumber(activeProject.budget);
        }
        return '';
    }, [activeProject]);

    const renderBudgetValue = useCallback(() => {
        if (!budgetValue) {
            return null;
        }
        return (
            <div>
                <p className={styles.detailValue}>
                    {activeProject?.budgetCurrency + ' ' + budgetValue}
                </p>
                {activeProject?.budgetCurrency !== 'NPR' && (
                    <p className={styles.exchangeRate}>
                        {`1 ${activeProject?.budgetCurrency} = ${activeProject?.exchangeRate} NPR`}
                    </p>
                )}
            </div>
        );
    }, [activeProject, budgetValue]);

    const [{ result: documentsResult }, getDocuments] = usePromise(Api.getProjectDocuments);

    useEffect(() => {
        getDocuments({ project: activeProject?.id });
    }, [activeProject, getDocuments]);

    const handleStatusChange = useCallback(
        async (statusValue: string, changedCallback?: () => void) => {
            try {
                await Api.patchProject(activeProject?.id, { status: statusValue });
                changedCallback?.();
            } catch (err) {
                console.log(err);
                Toast.show(getErrorMessage(err), Toast.DANGER);
            }
        },
        [activeProject, getProjectData]
    );

    const renderFundingType = useCallback(() => {
        return <p className={styles.detailValue}>{fundingTypeValue}</p>;
    }, [fundingTypeValue]);

    const renderStatus = useCallback(() => {
        return (
            <StatusItemSelect
                activeProject={activeProject}
                statusData={statusData}
                onChange={handleStatusChange}
                disabled={user.organization !== activeProject?.organization}
            />
        );
    }, [activeProject, statusData, handleStatusChange, user]);

    const [showEditProjectModal, setShowEditProjectModal] = useState<boolean>(false);
    const handleEditClick = useCallback(() => {
        setShowEditProjectModal(true);
    }, []);
    const handleCloseEditModal = useCallback(() => {
        setShowEditProjectModal(false);
    }, []);

    const handleEditComplete = useCallback(() => {
        getProjectData();
        getDocuments({ project: activeProject?.id });
    }, [getProjectData, getDocuments, activeProject]);

    return (
        <div className={styles.container}>
            <header className={styles.header}>
                <div className={styles.projectTitleContainer}>
                    <h1 className={styles.projectTitle}>
                        <Localize dataKey="title">{activeProject || {}}</Localize>
                    </h1>
                </div>
                <div className={styles.tabs}>
                    <Tabs
                        headerContainerClassName={styles.tabHeaderContainer}
                        headerClassName={styles.tabHeader}
                        tabItemClassName={styles.tabItem}
                        activeTabItemClassName={styles.tabItemActive}
                        tertiary
                        PostHeaderComponent={
                            <ProjectControlButtons
                                onReportAdded={handleEditComplete}
                                onEdit={handleEditClick}
                                isVisible={Boolean(
                                    regions.length > 0 &&
                                        organizations.length > 0 &&
                                        priorityIndicators.length > 0 &&
                                        hazards.length > 0 &&
                                        user.organization &&
                                        user.organization === activeProject?.organization
                                )}
                                documents={documentsResult?.results || []}
                            />
                        }
                    >
                        <Tab label="summary" title={_('Summary')}>
                            <Tabs
                                primary
                                headerClassName={styles.tabScrollHeader}
                                headerStyle={tabScrollHeaderStyle}
                                contentContainerClassName={styles.tabScrollContentContainer}
                                mode="scroll"
                                defaultActiveTab="details"
                            >
                                <Tab style={tabStyle} label="details" title={_('Key details')}>
                                    <div className={styles.tabScrollContent}>
                                        <h2 className={styles.tabScrollContentHeader}>
                                            <Localize>Key details</Localize>
                                        </h2>
                                        <div className={styles.tabScrollContentBody}>
                                            <div className={styles.description}>
                                                <HTMLParser
                                                    content={objTranslator(
                                                        activeProject || {},
                                                        'description'
                                                    )}
                                                />
                                            </div>
                                            <div className={styles.detailsInfoContainer}>
                                                <h3 className={styles.detailsInfoHeader}>
                                                    <Localize>STATUS AND DURATION</Localize>
                                                </h3>
                                                <div className={styles.detailsInfo}>
                                                    <DetailsItem
                                                        icon="numbers"
                                                        title={_('Project ID')}
                                                        content={
                                                            formatNumber(activeProject?.id, {
                                                                minimumIntegerDigits: 6
                                                            })?.replace(/,/g, '') || '-'
                                                        }
                                                    />
                                                    <DetailsItem
                                                        icon="monitor_heart"
                                                        title={_('Status')}
                                                        renderContent={renderStatus}
                                                    />
                                                    <DetailsItem
                                                        icon="calendar_month"
                                                        title={_('Duration')}
                                                        content={projectDuration}
                                                    />
                                                </div>
                                            </div>
                                            {projectOrganization && (
                                                <div className={styles.detailsInfoContainer}>
                                                    <h3 className={styles.detailsInfoHeader}>
                                                        <Localize>
                                                            IMPLEMENTING ORGANIZATION
                                                        </Localize>
                                                    </h3>
                                                    <div className={styles.organizations}>
                                                        <OrganizationItem
                                                            index={0}
                                                            className={styles.organizationItem}
                                                            item={projectOrganization}
                                                        />
                                                    </div>
                                                </div>
                                            )}
                                            <div className={styles.detailsInfoContainer}>
                                                <h3 className={styles.detailsInfoHeader}>
                                                    <Localize>BUDGET DETAILS</Localize>
                                                </h3>
                                                <div className={styles.detailsInfo}>
                                                    <DetailsItem
                                                        title={_('Total budget')}
                                                        renderContent={renderBudgetValue}
                                                    />
                                                    <DetailsItem
                                                        title={_('Administrative budget')}
                                                        content={`NPR ${
                                                            formatNumber(
                                                                activeProject?.administrativeBudget
                                                            ) || ''
                                                        }`}
                                                    />
                                                    <DetailsItem
                                                        title={_('Funding type')}
                                                        renderContent={renderFundingType}
                                                    />
                                                </div>
                                            </div>
                                            {activeProject?.hazards?.length > 0 && (
                                                <div className={styles.detailsInfoContainer}>
                                                    <h3 className={styles.detailsInfoHeader}>
                                                        <Localize>HAZARDS</Localize>
                                                    </h3>
                                                    <List
                                                        className={styles.hazards}
                                                        data={
                                                            activeProject?.hazards
                                                                ?.map((pHz: string | number) =>
                                                                    hazards.find(
                                                                        (hz) =>
                                                                            hz.id === Number(pHz)
                                                                    )
                                                                )
                                                                .filter((hz: any) => Boolean(hz)) ||
                                                            []
                                                        }
                                                        renderItem={HazardItem}
                                                        keyExtractor={hazardKeyExtractor}
                                                    />
                                                </div>
                                            )}
                                            <div className={styles.detailsInfoContainer}>
                                                <h3 className={styles.detailsInfoHeader}>
                                                    <Localize>LOCATION</Localize>
                                                </h3>
                                                <div className={styles.detailsInfo}>
                                                    <DetailsItem
                                                        title={focusedRegionTitle}
                                                        isMap
                                                        activeDivisionLevel={activeDivisionLevel}
                                                        focusedRegion={focusedRegion}
                                                        showUnfocused
                                                        focusedRegionPadding={{
                                                            top: 30,
                                                            bottom: 30,
                                                            left: 20,
                                                            right: 20
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Tab>
                                <Tab style={tabStyle} label="donors" title={_('Donors')}>
                                    <div className={styles.tabScrollContent}>
                                        <h2 className={styles.tabScrollContentHeader}>
                                            <Localize>Donors</Localize>
                                        </h2>
                                        <DonorList
                                            className={styles.tabScrollContentBody}
                                            donorNode={activeProject?.donor}
                                        />
                                    </div>
                                </Tab>
                                <Tab style={tabStyle} label="contact" title={_('Contact')}>
                                    <div className={styles.tabScrollContent}>
                                        <h2 className={styles.tabScrollContentHeader}>
                                            <Localize>Contact</Localize>
                                        </h2>
                                        <div className={styles.tabScrollContentBody}>
                                            <div className={styles.detailsInfo}>
                                                <DetailsItem
                                                    icon="perm_identity"
                                                    title={_('Contact name')}
                                                    content={activeProject?.contactName}
                                                />
                                                <DetailsItem
                                                    icon="mail"
                                                    title={_('Email')}
                                                    content={activeProject?.contactEmail}
                                                />
                                                <DetailsItem
                                                    icon="phone"
                                                    title={_('Phone')}
                                                    content={activeProject?.contactPhone}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </Tab>
                            </Tabs>
                        </Tab>
                        {activeProject?.outputs?.length > 0 && (
                            <Tab label="output" title={_('Output')}>
                                <Tabs
                                    primary
                                    headerClassName={styles.tabScrollHeader}
                                    headerStyle={tabScrollHeaderStyle}
                                    contentContainerClassName={styles.tabScrollContentContainer}
                                    defaultActiveTab="output-1"
                                >
                                    {activeProject.outputs.map(
                                        (output: ExpandedOutput, outputIdx: number) => (
                                            <Tab
                                                style={tabStyle}
                                                label={`output-${outputIdx + 1}`}
                                                title={`Output ${outputIdx + 1}`}
                                            >
                                                <OutputDetails
                                                    output={output}
                                                    onReportAdded={getProjectData}
                                                    project={activeProject}
                                                />
                                            </Tab>
                                        )
                                    )}
                                </Tabs>
                            </Tab>
                        )}
                        <Tab label="documents" title={_('Documents')}>
                            <Tabs
                                primary
                                headerClassName={styles.tabScrollHeader}
                                headerStyle={tabScrollHeaderStyle}
                                contentContainerClassName={styles.tabScrollContentContainer}
                                mode="scroll"
                                defaultActiveTab="details"
                            >
                                <Tab
                                    style={tabStyle}
                                    label="agreements"
                                    title={_('Project Agreements')}
                                >
                                    <ProjectDocuments
                                        type="agreements"
                                        documents={documentsResult?.results ?? []}
                                        headerClassName={styles.tabScrollContentHeader}
                                        headerTitle={_('Project Agreements')}
                                        className={styles.tabScrollContent}
                                        contentContainerClassName={styles.tabScrollContentBody}
                                        onEditComplete={handleEditComplete}
                                    />
                                </Tab>
                                <Tab style={tabStyle} label="reports" title={_('Project Reports')}>
                                    <ProjectDocuments
                                        type="reports"
                                        documents={documentsResult?.results ?? []}
                                        headerClassName={styles.tabScrollContentHeader}
                                        headerTitle={_('Project Reports')}
                                        className={styles.tabScrollContent}
                                        contentContainerClassName={styles.tabScrollContentBody}
                                        onEditComplete={handleEditComplete}
                                    />
                                </Tab>
                            </Tabs>
                        </Tab>
                    </Tabs>
                </div>
            </header>
            <ProjectFormModal
                editMode
                documents={documentsResult?.results ?? []}
                isVisible={showEditProjectModal}
                activeProjectType={{
                    code: 'implementor',
                    title: _('Implementor'),
                    description: _('Select this if you are the implementor of this project'),
                    iconName: 'manage_accounts'
                }}
                onClose={handleCloseEditModal}
                onComplete={handleEditComplete}
                project={activeProject as ExpandedProject}
            />
        </div>
    );
};

export default ProjectDetails;
