import React, { useCallback, useMemo, useState } from 'react';

import Form, { type FormSubmitCallback } from '@ra/components/Form/Advanced';
import Label from '@ra/components/Form/Label';
import AuthInput from 'components/Input/AuthInput';
import CurrencyInputGroup, {
    CurrencyInput as FormCurrencyInput
} from 'components/Input/CurrencyInputGroup';
import TextareaInput from 'components/Input/TextareaInput';
import { OrganizationSelectInput, OptionsType } from 'components/SelectInput';
import Tabs, { Tab } from 'components/Tabs';

import cs from '@ra/cs';
import { _, Localize } from 'services/i18n';
import Toast from 'services/toast';
import { useAppSelector } from 'hooks/store';
import { agreementDocumentTypeTitles, currencies } from 'utils/form';

import type { Organization } from 'services/types';
import type { SelectInputChangeCallback } from '@ra/components/Form/SelectInput';
import type { TabChangeCallback, TabRenderHeader } from '@ra/components/Tabs';

import styles from './styles.scss';
import ProjectDocumentInput from 'components/Input/ProjectDocumentInput';
import { camelCase } from 'lodash';

const titleExtractor = (item: OptionsType) => item?.title;
const keyExtractor = (item: OptionsType) => item?.id;

const organizationIdExtractor: SelectInputChangeCallback<Organization> = ({ option }) =>
    option?.id || null;

type ImplementorTab = {
    title: string;
};

const initialImplementors: ImplementorTab[] = [{ title: 'Implementor 1' }];

const AddButton: React.FC<{ onAddClick: React.MouseEventHandler }> = (props) => {
    const { onAddClick } = props;

    return (
        <div className={styles.addButton} onClick={onAddClick}>
            <span className={cs(styles.addIcon, 'material-symbols-rounded')}>add</span>
            <span className={styles.addText}>
                <Localize>Add implementor</Localize>
            </span>
        </div>
    );
};

type DonorInfoFormProps = {
    onSubmit: FormSubmitCallback;
    error: any;
};

const DonorInfoForm: React.FC<DonorInfoFormProps> = ({ onSubmit, error }) => {
    const { organizations } = useAppSelector((state) => state.organization);
    const { projectDocumentTypes } = useAppSelector((state) => state.projectDocumentType);

    const [implementors, setImplementors] = useState<ImplementorTab[]>(initialImplementors);

    const agreementDocumentTypeInputs = useMemo(() => {
        return projectDocumentTypes.filter((docType) =>
            agreementDocumentTypeTitles.includes(docType.title)
        );
    }, [projectDocumentTypes]);

    const handleInvalidSubmit = useCallback((reason: string) => {
        if (reason === 'required') {
            Toast.show(
                _(
                    'Some required fields are empty. Please check the form and fill up all required fields!'
                ),
                Toast.DANGER
            );
        }
    }, []);

    const [activeImplementor, setActiveImplementor] = useState<string>(
        initialImplementors[0].title.replace(/ /g, '-').toLowerCase()
    );

    const handleTabChange: TabChangeCallback = useCallback(({ activeTab }) => {
        setActiveImplementor(activeTab);
    }, []);

    const handleAddImplementor = useCallback(() => {
        const addedImplementor = { title: `Implementor ${implementors.length + 1}` };
        const newImplementors = [...implementors];
        newImplementors.push(addedImplementor);
        setImplementors(newImplementors);
        setActiveImplementor(addedImplementor.title.replace(/ /g, '-').toLowerCase());
    }, [implementors]);

    const handleRemoveImplementor = useCallback(
        (e: React.MouseEvent) => {
            e.stopPropagation();
            if (implementors.length > 1) {
                const newImplementors = [...implementors];
                newImplementors.pop();
                setImplementors(newImplementors);
                if (
                    implementors.findIndex(
                        (i) => i.title.toLowerCase().replace(/ /g, '-') === activeImplementor
                    ) ===
                    implementors.length - 1
                ) {
                    setActiveImplementor(
                        newImplementors[newImplementors.length - 1].title
                            .replace(/ /g, '-')
                            .toLowerCase()
                    );
                }
            }
        },
        [implementors, activeImplementor]
    );

    const renderTabsHeader: TabRenderHeader = useCallback(
        (tabHeaderProps) => {
            const { title, active, ...rest } = tabHeaderProps;

            return (
                <div
                    className={cs(styles.headerTabItem, {
                        [styles.headerTabItemActive]: active
                    })}
                    {...rest}
                >
                    <span>{title}</span>
                    {rest.index !== 0 && rest.index === implementors.length - 1 && (
                        <span
                            onClick={handleRemoveImplementor}
                            className={cs('material-symbols-rounded', styles.removeIcon)}
                        >
                            remove_circle_outline
                        </span>
                    )}
                </div>
            );
        },
        [implementors]
    );

    return (
        <Form
            error={error}
            formErrorClassName={styles.formErrorMessage}
            onSubmit={onSubmit}
            onInvalidSubmit={handleInvalidSubmit}
            id="donor-project-form"
        >
            <div className={styles.formSection}>
                <h3 className={styles.formSectionTitle}>
                    <Localize>1. TITLE AND DURATION</Localize>
                </h3>
                <Form.Input
                    name="title"
                    component={AuthInput}
                    label={_('Project Title*')}
                    labelClassName={styles.inputLabel}
                    placeholder={_('Enter project title')}
                    required
                />
                <Form.Input
                    name="titleNe"
                    component={AuthInput}
                    label={_('Project Title in Nepali (optional)')}
                    labelClassName={styles.inputLabel}
                    placeholder={_('परियोजना शीर्षक नेपालीमा लेख्नुहोस्')}
                />
                <Form.Input
                    name="description"
                    component={TextareaInput}
                    label={_('Project Description*')}
                    labelClassName={styles.inputLabel}
                    containerClassName={styles.inputSpacing}
                    className={styles.textarea}
                    rows={4}
                    placeholder={_('Enter project description')}
                    required
                />
                <Form.Input
                    name="descriptionNe"
                    component={TextareaInput}
                    label={_('Project Description in Nepali (optional)')}
                    labelClassName={styles.inputLabel}
                    className={styles.textarea}
                    rows={4}
                    placeholder={_('परियोजना विवरण नेपालीमा लेख्नुहोस्')}
                />
            </div>
            <div className={styles.formSection}>
                <h3 className={styles.formSectionTitle}>
                    <Localize>2. PROJECT AGREEMENT DOCUMENTS</Localize>
                </h3>
                {agreementDocumentTypeInputs.map((docType, idx) => {
                    return (
                        <Form.Input
                            key={idx}
                            component={ProjectDocumentInput}
                            name={camelCase(docType.title)}
                            containerClassName={styles.inputSpacing}
                            label={docType.title}
                            labelClassName={styles.inputLabel}
                            info={_('PDF (max. 2MB)')}
                            maxSize={2048}
                            accept="application/pdf"
                        />
                    );
                })}
            </div>
            <div className={styles.formSection}>
                <h3 className={styles.formSectionTitle}>
                    <Localize>3. IMPLEMENTORS</Localize>
                </h3>
                <Tabs
                    renderHeader={renderTabsHeader}
                    headerContainerClassName={styles.tabsHeaderContainer}
                    headerClassName={styles.tabsHeader}
                    PostHeaderComponent={<AddButton onAddClick={handleAddImplementor} />}
                    disableUnmount
                    activeTab={activeImplementor}
                    onChange={handleTabChange}
                >
                    {implementors.map((implementor, idx) => (
                        <Tab
                            label={implementor.title.replace(/ /g, '-').toLowerCase()}
                            title={implementor.title}
                        >
                            <Form.InputGroup name={`implementors[${idx}]`}>
                                <CurrencyInputGroup
                                    budgetLabel={_('Total Budget*')}
                                    exchangeRateLabel={_('Exchange Rate*')}
                                />
                                <div className={styles.inputSpacing}>
                                    <Label className={styles.inputLabel}>
                                        {_('Administrative Budget*')}
                                    </Label>
                                    <FormCurrencyInput
                                        currencyInputName="administrativeBudgetCurrency"
                                        amountInputName="administrativeBudget"
                                        lockedCurrency={currencies[0]}
                                        amountRequired
                                    />
                                </div>
                                <Form.Input
                                    component={OrganizationSelectInput}
                                    label={_('Organization*')}
                                    labelClassName={styles.inputLabel}
                                    containerClassName={styles.selectInput}
                                    name="implementor"
                                    options={organizations}
                                    valueExtractor={titleExtractor}
                                    fieldValueExtractor={organizationIdExtractor}
                                    keyExtractor={keyExtractor}
                                    placeholder={_('Select organization')}
                                    required
                                    optionsDirection="up"
                                />
                            </Form.InputGroup>
                        </Tab>
                    ))}
                </Tabs>
            </div>
        </Form>
    );
};

export default DonorInfoForm;
