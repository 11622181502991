import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import type { Region } from 'services/types';

interface RegionReducerState {
    regions: Region[];
}

const initialState: RegionReducerState = {
    regions: []
};

const regionSlice = createSlice({
    name: 'region',
    initialState,
    reducers: {
        setRegions: (state: RegionReducerState, { payload }: PayloadAction<Region[]>) => {
            state.regions = payload;
        }
    }
});

export const { setRegions } = regionSlice.actions;

export default regionSlice.reducer;
