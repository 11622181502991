import React, { useCallback, useMemo } from 'react';
import { Link } from 'react-router-dom';
import Button from 'components/Button';
import Marquee from 'components/Marquee';

import { Localize } from '@ra/components/I18n';
import List, { KeyExtractor, ListRenderItem } from '@ra/components/List';

import { useAppSelector } from 'hooks/store';

import type { Organization } from 'services/types';

import styles from './styles.scss';

const keyExtractor: KeyExtractor<Organization> = (item) => item.id;

const OrganizationSection: React.FC = () => {
    const { organizations } = useAppSelector((state) => state.organization);

    const organizationData = useMemo(
        () => organizations.filter((org) => !!org.logo),
        [organizations]
    );

    const renderItems: ListRenderItem<Organization> = useCallback(({ item }) => {
        return (
            <div className={styles.logoWrapper}>
                <img className={styles.logo} src={item.logo as string}></img>
            </div>
        );
    }, []);

    return (
        <section className={styles.organizationSection}>
            <p className={styles.sectionTitle}>
                <Localize>Organizations Contributing</Localize>
            </p>
            {organizationData.length > 0 && (
                <>
                    {organizationData.length > 1 && (
                        <Marquee>
                            <List
                                className={styles.organizationList}
                                keyExtractor={keyExtractor}
                                data={organizationData.slice(
                                    0,
                                    Math.floor(organizationData.length / 2)
                                )}
                                renderItem={renderItems}
                            />
                        </Marquee>
                    )}
                    <Marquee direction="right">
                        <List
                            className={styles.organizationList}
                            keyExtractor={keyExtractor}
                            data={organizationData.slice(Math.floor(organizationData.length / 2))}
                            renderItem={renderItems}
                        />
                    </Marquee>
                </>
            )}
            <div className={styles.register}>
                <p className={styles.registerText}>
                    <Localize>Register here for your presence</Localize>
                </p>
                <Link className={styles.registerLink} to="/request-register">
                    <Button large leftIcon="Add">
                        <Localize>Register</Localize>
                    </Button>
                </Link>
            </div>
        </section>
    );
};

export default OrganizationSection;
