import { Link } from 'react-router-dom';

import Container from 'components/Container';

import { Localize } from 'services/i18n';

import cs from '@ra/cs';
import { DocumentTypeEnum } from 'services/types';

import logo from 'assets/images/MDSA_logo_dark.svg';

import styles from './styles.scss';

const Footer: React.FC = () => {
    return (
        <section className={styles.footerWrapper}>
            <Container>
                <div className={styles.linkContent}>
                    <div className={styles.infoWrapper}>
                        <img src={logo} className={styles.logo} alt="mdsa logo" />
                        <p className={styles.textInfo}>
                            <Localize>
                                MDSA is a web based, centralized system for logging and monitoring
                                projects being operated around the nation that deliver against the
                                National Disaster Risk Reduction Strategic Action Plan 2018-2030. It
                                also links with BIPAD system for cross linking information with
                                disasters.
                            </Localize>
                        </p>
                    </div>
                    <div className={styles.quickLinks}>
                        <h2 className={styles.heading}>
                            <Localize>Quick Links</Localize>
                        </h2>
                        <a
                            className={styles.quickLink}
                            target="_blank"
                            rel="noopener noreferrer"
                            href="https://bipad.gov.np/"
                        >
                            <p className={styles.quickLinkTitle}>
                                <Localize>NDDRMA Website</Localize>
                            </p>
                            <span className={cs('material-symbols-rounded', styles.quickLinkIcon)}>
                                open_in_new
                            </span>
                        </a>
                        <a
                            className={styles.quickLink}
                            target="_blank"
                            rel="noopener noreferrer"
                            href="https://bipadportal.gov.np/"
                        >
                            <p className={styles.quickLinkTitle}>
                                <Localize>BIPAD Portal</Localize>
                            </p>
                            <span className={cs('material-symbols-rounded', styles.quickLinkIcon)}>
                                open_in_new
                            </span>
                        </a>
                    </div>
                    <div className={styles.usefulResource}>
                        <h2 className={styles.heading}>
                            <Localize>Useful Resources</Localize>
                        </h2>
                        <a
                            className={styles.resourceLink}
                            target="_blank"
                            rel="noopener noreferrer"
                            href="https://www.opmcm.gov.np/"
                        >
                            <Localize>Office of the Prime Minister</Localize>
                        </a>
                        <a
                            className={styles.resourceLink}
                            target="_blank"
                            rel="noopener noreferrer"
                            href="https://www.moha.gov.np/"
                        >
                            <Localize>Ministry of Home Affairs</Localize>
                        </a>
                    </div>
                    <div className={styles.contactInfo}>
                        <h2 className={styles.heading}>
                            <Localize>Contact Info</Localize>
                        </h2>
                        <p className={styles.contactName}>
                            <Localize>
                                National Disaster Risk Reduction and Management Authority
                            </Localize>
                        </p>
                        <p className={styles.contactPoBox}>P.O. Box no. 213213</p>
                        <div className={styles.contactLinkWrapper}>
                            <div className={styles.contactLink}>
                                <span
                                    className={cs('material-symbols-rounded', styles.contactIcon)}
                                >
                                    call
                                </span>
                                <p className={styles.contactLinkTitle}>
                                    +977-1-4493847, +977-1-4439485
                                </p>
                            </div>
                            <a className={styles.contactLink} href="mailto: info@bipad.gov.np">
                                <span
                                    className={cs('material-symbols-rounded', styles.contactIcon)}
                                >
                                    mail
                                </span>
                                <p className={styles.contactLinkTitle}>info@bipad.gov.np</p>
                            </a>
                            <a
                                className={styles.contactLink}
                                href="https://bipad.gov.np"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <span
                                    className={cs('material-symbols-rounded', styles.contactIcon)}
                                >
                                    public
                                </span>
                                <p className={styles.contactLinkTitle}>www.bipad.gov.np</p>
                            </a>
                        </div>
                    </div>
                </div>
                <hr className={styles.hairline} />
                <div className={styles.copyrightContent}>
                    <p className={styles.copyright}>
                        © {new Date().getFullYear()}{' '}
                        <Localize>NDRRMA. All rights reserved.</Localize>
                    </p>
                    <div className={styles.legalLinks}>
                        <Link
                            className={styles.legalLink}
                            to={`/legal-document#${DocumentTypeEnum.TermsAndConditions}`}
                        >
                            <Localize>Terms</Localize>
                        </Link>
                        <Link
                            className={styles.legalLink}
                            to={`/legal-document#${DocumentTypeEnum.PrivacyPolicy}`}
                        >
                            <Localize>Privacy</Localize>
                        </Link>
                        <Link
                            className={styles.legalLink}
                            to={`/legal-document#${DocumentTypeEnum.CookiePolicy}`}
                        >
                            <Localize>Cookies</Localize>
                        </Link>
                    </div>
                </div>
            </Container>
        </section>
    );
};

export default Footer;
