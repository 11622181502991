import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import type { LanguageModel, TranslationsModel } from 'services/i18n';

interface LocaleReducerState {
    currentLanguage: string;
    languages: LanguageModel[];
    translations: TranslationsModel;
}

const initialState: LocaleReducerState = {
    currentLanguage: 'ENG',
    languages: [],
    translations: {}
};

const localeSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setCurrentLanguage: (state: LocaleReducerState, { payload }: PayloadAction<string>) => {
            state.currentLanguage = payload;
        },
        setLanguages: (state: LocaleReducerState, { payload }: PayloadAction<LanguageModel[]>) => {
            state.languages = payload;
        },
        setTranslations: (
            state: LocaleReducerState,
            { payload }: PayloadAction<TranslationsModel>
        ) => {
            state.translations = payload;
        }
    }
});

export const { setCurrentLanguage, setLanguages, setTranslations } = localeSlice.actions;

export default localeSlice.reducer;
