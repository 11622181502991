import React from 'react';

import Button from 'components/Button';
import AuthInput from 'components/Input/AuthInput';
import Form, { InputField, type FormSubmitCallback } from '@ra/components/Form';
import { _, Localize } from 'services/i18n';

import styles from './styles.scss';

interface ChangePasswordProps {
    onChangePassword: FormSubmitCallback;
    onForgotPasswordClick?: () => void;
    loading?: boolean;
    error?: any;
}

const ChangePassword = React.forwardRef((props: ChangePasswordProps, ref) => {
    const { error, onChangePassword, loading, onForgotPasswordClick } = props;

    return (
        <Form
            formErrorClassName={styles.errorMessage}
            onSubmit={onChangePassword}
            error={error}
            ref={ref}
        >
            <h1 className={styles.title}>
                <Localize>Change Password</Localize>
            </h1>
            <InputField
                component={AuthInput}
                type="password"
                label={_('Current Password*')}
                labelClassName={styles.inputLabel}
                placeholder={_('Enter your current password')}
                name="oldPassword"
                required
            />
            <InputField
                component={AuthInput}
                type="password"
                label={_('New Password*')}
                labelClassName={styles.inputLabel}
                placeholder={_('Enter new password')}
                name="newPassword"
                required
            />
            <InputField
                component={AuthInput}
                type="password"
                label={_('Confirm New Password*')}
                labelClassName={styles.inputLabel}
                placeholder={_('Re-enter new password')}
                name="reNewPassword"
                required
            />
            <Button loading={loading} className={styles.button}>
                <Localize>Change Password</Localize>
            </Button>
            <p className={styles.forgotPasswordLink} onClick={onForgotPasswordClick}>
                <Localize>Forgot password?</Localize>
            </p>
        </Form>
    );
});

export default ChangePassword;
