import { Outlet } from 'react-router-dom';

import NavBar from 'components/NavBar';

import styles from './styles.scss';

type LayoutProps = {
    children?: React.ReactNode;
};

const Layout: React.FC<LayoutProps> = () => {
    return (
        <main className={styles.main}>
            <NavBar />
            <Outlet />
        </main>
    );
};

export default Layout;
