import xlsx, { type IContent } from 'json-as-xlsx';

import store from 'store';

import type { Project, Output, Activity, Target, TargetReport } from 'services/types';

export interface ExpandedTarget extends Target {
    reports: TargetReport[];
}

export interface ExpandedActivity extends Activity {
    targets: ExpandedTarget[];
}

export interface ExpandedOutput extends Output {
    activities: ExpandedActivity[];
}

export interface ExpandedProject extends Project {
    outputs: ExpandedOutput[];
}

interface LocationFields {
    activityWard: string;
    activityLocalLevel: string;
    activityDistrict: string;
    activityProvince: string;
}

interface PriorityIndicatorFields {
    priorityAreaTitle: string;
    priorityActionTitle: string;
    priorityActivityTitle: string;
}

class ReportGenerator {
    priorityActivityId?: number;
    priorityIndicatorFields!: PriorityIndicatorFields;
    projectData!: ExpandedProject[];
    reportData!: any;

    constructor(priorityActivityId?: number) {
        if (priorityActivityId) {
            this.priorityActivityId = priorityActivityId;
            this.setPriorityIndicatorFields(priorityActivityId);
        }
    }

    private setPriorityIndicatorFields(activityId: number) {
        const {
            priorityIndicator: { priorityIndicators }
        } = store.getState();
        const priorityActivity = priorityIndicators.find((pi) => pi.id === activityId);
        const priorityActivityTitle = priorityActivity?.title || '';
        const priorityAction = priorityIndicators.find(
            (pi) => pi.id === Number(priorityActivity?.parent)
        );
        const priorityActionTitle = priorityAction?.title || '';
        const priorityAreaTitle =
            priorityIndicators.find((pi) => pi.id === priorityAction?.parent)?.title || '';
        this.priorityIndicatorFields = {
            priorityAreaTitle,
            priorityActionTitle,
            priorityActivityTitle
        };
    }

    private fillLocationFields(fieldsObject: LocationFields, locationId: number) {
        const fieldsNames = [
            'activityProvince',
            'activityDistrict',
            'activityLocalLevel',
            'activityWard'
        ];
        const {
            region: { regions }
        } = store.getState();
        const regionItem = regions.find((reg) => reg.id === locationId);
        if (!regionItem) {
            return fieldsObject;
        }
        fieldsObject[fieldsNames[regionItem.level - 1] as keyof LocationFields] = regionItem.title;
        if (regionItem.level > 0) {
            this.fillLocationFields(fieldsObject, regionItem.parent as number);
        }
    }

    private getLocationLevels(locationId: number | null) {
        const locationFields = {
            activityWard: '',
            activityLocalLevel: '',
            activityDistrict: '',
            activityProvince: ''
        };
        if (!locationId) {
            return locationFields;
        }
        this.fillLocationFields(locationFields, locationId);
        return locationFields;
    }

    private getProjectFields(project: ExpandedProject, index: number) {
        return {
            projectTitle: project.title,
            projectTitleNe: project.titleNe,
            projectDescription: project.description,
            projectDescriptionNe: project.descriptionNe,
            projectType: project.projectType,
            projectDateFrom: project.dateFrom,
            projectDateTo: project.dateTo,
            projectLocation: project.location,
            projectStatus: project.status,
            projectBudgetCurrency: project.budgetCurrency,
            projectBudget: project.budget,
            projectExchangeRate: project.exchangeRate,
            projectAdministrativeBudgetCurrency: project.administrativeBudgetCurrency,
            projectAdministrativeBudget: project.administrativeBudget,
            projectDonor: project.donor,
            projectImplementor: project.organization,
            projectContactName: project.contactName,
            projectContactEmail: project.contactEmail,
            projectContactPhone: project.contactPhone,
            ...(index === 0 ? this.priorityIndicatorFields : {})
        };
    }

    private getOutputFields(output: ExpandedOutput) {
        return {
            outputTitle: output.title,
            outputDateFrom: output.dateFrom,
            outputDateTo: output.dateTo
        };
    }

    private getActivityFields(activity: ExpandedActivity) {
        const locationFields = this.getLocationLevels(activity.location);
        return {
            activityTitle: activity.title,
            activityBudget: activity.budget,
            ...locationFields
        };
    }

    private getTargetFields(target: ExpandedTarget) {
        return {
            targetTitle: target.title,
            targetValue: target.value
        };
    }

    private getTargetReportFields(targetReport: TargetReport) {
        return {
            targetReportExpense: targetReport.expense,
            targetReportValue: targetReport.value
        };
    }

    public loadProjectData(projectData: ExpandedProject[]) {
        this.reportData = projectData.reduce((acc: any, currentProject, index) => {
            const projectFields = this.getProjectFields(currentProject, index);
            const projectOutputs = currentProject.outputs || [];
            if (projectOutputs.length === 0) {
                acc.push(projectFields);
                return acc;
            }
            projectOutputs.forEach((output, outputIdx) => {
                const outputFields = this.getOutputFields(output);
                const outputActivities = output.activities || [];
                if (outputActivities.length > 0) {
                    outputActivities.forEach((activity, activityIdx) => {
                        const activityFields = this.getActivityFields(activity);
                        const activityTargets = activity.targets || [];
                        if (activityTargets.length > 0) {
                            activityTargets.forEach((target, targetIdx) => {
                                const targetFields = this.getTargetFields(target);
                                const targetReports = target.reports || [];
                                if (targetReports.length > 0) {
                                    targetReports.forEach((report, reportIdx) => {
                                        acc.push({
                                            ...(outputIdx === 0 &&
                                            activityIdx === 0 &&
                                            targetIdx === 0 &&
                                            reportIdx === 0
                                                ? projectFields
                                                : {}),
                                            ...(activityIdx === 0 &&
                                            targetIdx === 0 &&
                                            reportIdx === 0
                                                ? outputFields
                                                : {}),
                                            ...(targetIdx === 0 && reportIdx === 0
                                                ? activityFields
                                                : {}),
                                            ...(reportIdx === 0 ? targetFields : {}),
                                            ...this.getTargetReportFields(report)
                                        });
                                        return acc;
                                    });
                                } else {
                                    acc.push({
                                        ...(outputIdx === 0 && activityIdx === 0 && targetIdx === 0
                                            ? projectFields
                                            : {}),
                                        ...(activityIdx === 0 && targetIdx === 0
                                            ? outputFields
                                            : {}),
                                        ...(targetIdx === 0 ? activityFields : {}),

                                        ...targetFields
                                    });
                                    return acc;
                                }
                            });
                        } else {
                            acc.push({
                                ...(outputIdx === 0 && activityIdx === 0 ? projectFields : {}),
                                ...(activityIdx === 0 ? outputFields : {}),
                                ...activityFields
                            });
                            return acc;
                        }
                    });
                } else {
                    acc.push({
                        ...(outputIdx === 0 ? projectFields : {}),
                        ...outputFields
                    });
                    return acc;
                }
            });
            return acc;
        }, []);

        return this;
    }

    public downloadXlsxReport(fileName: string) {
        const {
            organization: { organizations },
            region: { regions }
        } = store.getState();

        const activityColumns = [
            { label: 'Priority Area', value: 'priorityAreaTitle' },
            { label: 'Priority Action', value: 'priorityActionTitle' },
            { label: 'Priority Activity', value: 'priorityActivityTitle' }
        ];

        xlsx(
            [
                {
                    sheet: this.priorityActivityId ? 'Activity Report' : 'Project List',
                    columns: [
                        ...(this.priorityActivityId ? activityColumns : []),
                        ...(this.priorityActivityId
                            ? []
                            : [
                                  { label: 'Project', value: 'projectTitle' },
                                  { label: 'Project (In Nepali)', value: 'projectTitleNe' }
                              ]),
                        {
                            label: 'Donor Organization',
                            value: (row) =>
                                organizations.find((org) => org.id === row.projectDonor)?.title ||
                                ''
                        },
                        {
                            label: 'Implementing Organization',
                            value: (row) =>
                                organizations.find((org) => org.id === row.projectImplementor)
                                    ?.title || ''
                        },
                        ...(this.priorityActivityId
                            ? [
                                  { label: 'Project', value: 'projectTitle' },
                                  { label: 'Project (In Nepali)', value: 'projectTitleNe' }
                              ]
                            : []),
                        { label: 'Project Start Date', value: 'projectDateFrom' },
                        { label: 'Project End Date', value: 'projectDateTo' },
                        { label: 'Project Description', value: 'projectDescription' },
                        { label: 'Project Description (In Nepali)', value: 'projectDescriptionNe' },
                        {
                            label: 'Project Location',
                            value: (row) =>
                                regions.find((reg) => reg.id === row.projectLocation)?.title || ''
                        },
                        { label: 'Project Status', value: 'projectStatus' },
                        {
                            label: 'Project Budget',
                            value: (row) => {
                                if (row.projectBudget) {
                                    return `${row.projectBudgetCurrency} ${row.projectBudget}`;
                                }
                                return '';
                            }
                        },
                        { label: 'Budget Exchange Rate', value: 'projectExchangeRate' },
                        {
                            label: 'Project Administrative Budget',
                            value: (row) => {
                                if (row.projectAdministrativeBudget) {
                                    return `${row.projectAdministrativeBudgetCurrency} ${row.projectAdministrativeBudget}`;
                                }
                                return '';
                            }
                        },
                        { label: 'Contact Name', value: 'projectContactName' },
                        { label: 'Contact Email', value: 'projectContactEmail' },
                        { label: 'Contact Phone no.', value: 'projectContactPhone' },
                        { label: 'Output', value: 'outputTitle' },
                        { label: 'Output Start Date', value: 'outputDateFrom' },
                        { label: 'Output End Date', value: 'outputDateTo' },
                        { label: 'Activity', value: 'activityTitle' },
                        {
                            label: 'Activity Budget',
                            value: (row) => {
                                if (row.activityBudget) {
                                    return `NPR ${row.activityBudget}`;
                                }
                                return '';
                            }
                        },
                        { label: 'Province', value: 'activityProvince' },
                        { label: 'District', value: 'activityDistrict' },
                        { label: 'Local Level', value: 'activityLocalLevel' },
                        { label: 'Ward', value: 'activityWard' },
                        { label: 'Target', value: 'targetTitle' },
                        { label: 'Target Values', value: 'targetValue' },
                        { label: 'Target Values Met', value: 'targetReportValue' },
                        {
                            label: 'Expense',
                            value: (row) => {
                                if (row.targetReportExpense) {
                                    return `NPR ${row.targetReportExpense}`;
                                }
                                return '';
                            }
                        }
                    ],
                    content: this.reportData as unknown as IContent[]
                }
            ],
            {
                fileName: fileName,
                writeMode: 'writeFile'
            }
        );
    }
}

export default ReportGenerator;
