import React from 'react';

import cs from '@ra/cs';

import styles from './styles.scss';

type LoaderProps = {
    primary?: boolean;
    dark?: boolean;
};

export const RectLoader: React.FC<LoaderProps> = ({ dark, primary }) => {
    return (
        <div
            className={cs(styles.ldsLoader, {
                [styles.ldsLoaderDark]: dark,
                [styles.ldsLoaderPrimary]: primary
            })}
        >
            <div></div>
            <div></div>
            <div></div>
        </div>
    );
};
