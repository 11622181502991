import React, { useMemo, useCallback, useState } from 'react';

import Button from 'components/Button';

import cs from '@ra/cs';
import { Localize } from 'services/i18n';

import styles from './styles.scss';

const options: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'short', day: 'numeric' };

const DateRangePickerInput = React.forwardRef(
    (
        {
            onClick,
            onBlur,
            startDate,
            endDate
        }: {
            onClick?: React.MouseEventHandler;
            onBlur?: React.MouseEventHandler;
            startDate: Date | null;
            endDate: Date | null;
        },
        ref: React.ForwardedRef<HTMLButtonElement>
    ) => {
        const hasValue = useMemo(() => startDate || endDate, [startDate, endDate]);

        const [isOpen, setOpen] = useState<boolean>(false);

        const handleClick = useCallback(
            (e: React.MouseEvent) => {
                if (!isOpen) {
                    onClick?.(e);
                    return setOpen(true);
                }
                onBlur?.(e);
                setOpen(false);
            },
            [onClick, onBlur, isOpen]
        );

        return (
            <Button
                small
                onClick={handleClick}
                ref={ref}
                tertiary
                leftIcon="calendar_month"
                iconClassName={styles.icon}
                className={cs(styles.button, { [styles.buttonPadded]: hasValue })}
            >
                {hasValue ? (
                    <>
                        {startDate?.toLocaleDateString(undefined, options)}
                        {' - '}
                        {endDate?.toLocaleDateString(undefined, options)}
                    </>
                ) : (
                    <Localize>Date range</Localize>
                )}
            </Button>
        );
    }
);

export default DateRangePickerInput;
