import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';

import SelectInput, {
    KeyExtractor,
    ValueExtractor,
    SelectInputChangeCallback
} from '@ra/components/Form/SelectInput';

import cs from '@ra/cs';
import { _, objTranslator } from 'services/i18n';
import { getRegions } from 'services/bootstrap';

import { useFilters, useFiltersDispatch } from 'hooks/filters';

import type { Region } from 'services/types';
import type { RootState } from 'store';

import styles from './styles.scss';

const keyExtractor: KeyExtractor<Region> = (item) => item.id;
const valueExtractor: ValueExtractor<Region, string> = (item) => objTranslator(item, 'title');

type RegionDivisions = {
    provinces: Region[];
    districts: Region[];
    localLevels: Region[];
    wards: Region[];
};

interface FilterRegionSelectorProps {
    filterType: string;
    isClearable?: boolean;
    disableWard?: boolean;
}

const FilterRegionSelector: React.FC<FilterRegionSelectorProps> = (props) => {
    const { filterType, isClearable, disableWard } = props;

    const filtersDispatch = useFiltersDispatch();

    const filters = useFilters();
    const regionFilter = useMemo(() => {
        return filters.find((el) => el.filterType === filterType);
    }, [filters, filterType]);

    const { regions }: { regions: Region[] } = useSelector((state: RootState) => state.region);
    const { provinces, districts, localLevels, wards }: RegionDivisions = useMemo(() => {
        return regions.reduce(
            (acc: RegionDivisions, curRegion: Region) => {
                if (curRegion.level === 4) {
                    acc.wards.push(curRegion);
                } else if (curRegion.level === 3) {
                    acc.localLevels.push(curRegion);
                } else if (curRegion.level === 2) {
                    acc.districts.push(curRegion);
                } else if (curRegion.level === 1) {
                    acc.provinces.push(curRegion);
                }
                return acc;
            },
            { provinces: [], districts: [], localLevels: [], wards: [] }
        );
    }, [regions]);

    const [activeProvince, setActiveProvince] = useState<Region | undefined | null>(null);
    const [activeDistrict, setActiveDistrict] = useState<Region | undefined | null>(null);
    const [activeLocalLevel, setActiveLocalLevel] = useState<Region | undefined | null>(null);
    const [activeWard, setActiveWard] = useState<Region | undefined | null>(null);

    const [defaultProvince, defaultDistrict, defaultLocalLevel, defaultWard]: (
        | Region
        | null
        | undefined
    )[] = useMemo(() => {
        if (regionFilter) {
            const regionObj = regionFilter.filterValue as Region;
            if (regionObj.level === 1) {
                setActiveProvince(regionObj);
                return [regionObj, null, null, null];
            } else if (regionObj.level === 2) {
                const newActivePrv = provinces.find((prv) => prv.id === regionObj.parent);
                setActiveProvince(newActivePrv);
                setActiveDistrict(regionObj);
                return [newActivePrv, regionObj, null, null];
            } else if (regionObj.level === 3) {
                const newActiveDist = districts.find((dis) => dis.id === regionObj.parent);
                const newActivePrv = provinces.find((prv) => prv.id === newActiveDist?.parent);
                setActiveProvince(newActivePrv);
                setActiveDistrict(newActiveDist);
                setActiveLocalLevel(regionObj);
                return [newActivePrv, newActiveDist, regionObj, null];
            } else if (regionObj.level === 4) {
                const newActiveLocalLevel = localLevels.find((ll) => ll.id === regionObj.parent);
                const newActiveDist = districts.find(
                    (dis) => dis.id === newActiveLocalLevel?.parent
                );
                const newActivePrv = provinces.find((prv) => prv.id === newActiveDist?.parent);
                setActiveProvince(newActivePrv);
                setActiveDistrict(newActiveDist);
                setActiveLocalLevel(newActiveLocalLevel);
                setActiveWard(regionObj);
                return [newActivePrv, newActiveDist, newActiveLocalLevel, regionObj];
            }
        }
        setActiveWard(null);
        setActiveLocalLevel(null);
        setActiveDistrict(null);
        setActiveProvince(null);
        return [null, null, null, null];
    }, [regionFilter, provinces, districts, localLevels]);

    useEffect(() => {
        if (!regions.length) {
            getRegions();
        }
    }, [regions]);

    const handleProvinceChange: SelectInputChangeCallback<Region> = useCallback(
        ({ option }) => {
            if (activeProvince && activeProvince?.id === option?.id) {
                return;
            }
            setActiveProvince(option);
            if (option) {
                return filtersDispatch({
                    type: 'add',
                    filter: {
                        filterType,
                        filterValue: option,
                        query: {
                            location: option.id
                        }
                    }
                });
            }
            filtersDispatch({
                type: 'remove',
                filter: {
                    filterType
                }
            });
            setActiveWard(null);
            setActiveLocalLevel(null);
            setActiveDistrict(null);
        },
        [filtersDispatch, filterType, activeProvince]
    );

    const handleDistrictChange: SelectInputChangeCallback<Region> = useCallback(
        ({ option }) => {
            if (activeDistrict && activeDistrict?.id === option?.id) {
                return;
            }
            setActiveDistrict(option);
            if (option) {
                return filtersDispatch({
                    type: 'add',
                    filter: {
                        filterType,
                        filterValue: option,
                        query: {
                            location: option.id
                        }
                    }
                });
            }
            if (activeProvince) {
                filtersDispatch({
                    type: 'add',
                    filter: {
                        filterType,
                        filterValue: activeProvince,
                        query: {
                            location: activeProvince.id
                        }
                    }
                });
            }
            setActiveWard(null);
            setActiveLocalLevel(null);
        },
        [filtersDispatch, filterType, activeProvince, activeDistrict]
    );

    const handleLocalLevelChange: SelectInputChangeCallback<Region> = useCallback(
        ({ option }) => {
            if (activeLocalLevel && activeLocalLevel?.id === option?.id) {
                return;
            }
            setActiveLocalLevel(option);
            if (option) {
                return filtersDispatch({
                    type: 'add',
                    filter: { filterType, filterValue: option, query: { location: option.id } }
                });
            }
            if (activeDistrict) {
                filtersDispatch({
                    type: 'add',
                    filter: {
                        filterType,
                        filterValue: activeDistrict,
                        query: {
                            location: activeDistrict.id
                        }
                    }
                });
            }
            setActiveWard(null);
        },
        [activeDistrict, filtersDispatch, filterType, activeLocalLevel]
    );

    const handleWardChange: SelectInputChangeCallback<Region> = useCallback(
        ({ option }) => {
            setActiveWard(option);
            if (option) {
                return filtersDispatch({
                    type: 'add',
                    filter: {
                        filterType,
                        filterValue: option,
                        query: {
                            location: option.id
                        }
                    }
                });
            }
            if (activeLocalLevel) {
                filtersDispatch({
                    type: 'add',
                    filter: {
                        filterType,
                        filterValue: activeLocalLevel,
                        query: {
                            location: activeLocalLevel.id
                        }
                    }
                });
            }
        },
        [activeLocalLevel, filtersDispatch, filterType]
    );

    return (
        <div className={styles.container}>
            <span className={cs('material-symbols-rounded', styles.leftIcon)}>place</span>
            <SelectInput
                className={styles.select}
                optionsWrapperClassName={styles.optionsWrapper}
                selectOptionClassName={styles.selectOption}
                optionItemClassName={styles.optionItem}
                controlClassName={cs(styles.selectControl, {
                    [styles.selectControlFilled]: activeProvince
                })}
                searchable
                clearable={isClearable}
                options={provinces}
                keyExtractor={keyExtractor}
                valueExtractor={valueExtractor}
                onChange={handleProvinceChange}
                placeholder={_('Region')}
                defaultValue={defaultProvince}
                anchorOrigin="bottom right"
                transformOrigin="top right"
                loading={regions.length === 0}
            />
            {activeProvince && (
                <SelectInput
                    className={styles.select}
                    optionsWrapperClassName={styles.optionsWrapper}
                    selectOptionClassName={styles.selectOption}
                    optionItemClassName={styles.optionItem}
                    controlClassName={cs(styles.selectControl, {
                        [styles.selectControlFilled]: activeDistrict
                    })}
                    searchable
                    clearable
                    options={districts.filter((dis: Region) => dis.parent === activeProvince.id)}
                    keyExtractor={keyExtractor}
                    valueExtractor={valueExtractor}
                    onChange={handleDistrictChange}
                    placeholder={_('District')}
                    defaultValue={defaultDistrict}
                    anchorOrigin="bottom right"
                    transformOrigin="top right"
                />
            )}
            {activeDistrict && (
                <SelectInput
                    className={styles.select}
                    optionsWrapperClassName={styles.optionsWrapper}
                    selectOptionClassName={styles.selectOption}
                    optionItemClassName={styles.optionItem}
                    controlClassName={cs(styles.selectControl, {
                        [styles.selectControlFilled]: activeWard
                    })}
                    searchable
                    clearable
                    options={localLevels.filter((ll: Region) => ll.parent === activeDistrict.id)}
                    keyExtractor={keyExtractor}
                    valueExtractor={valueExtractor}
                    onChange={handleLocalLevelChange}
                    placeholder={_('Local level')}
                    defaultValue={defaultLocalLevel}
                    anchorOrigin="bottom right"
                    transformOrigin="top right"
                />
            )}
            {activeLocalLevel && !disableWard && (
                <SelectInput
                    className={styles.select}
                    optionsWrapperClassName={styles.optionsWrapper}
                    selectOptionClassName={styles.selectOption}
                    optionItemClassName={styles.optionItem}
                    controlClassName={cs(styles.selectControl, {
                        [styles.selectControlFilled]: activeWard
                    })}
                    searchable
                    clearable
                    options={wards.filter((wrd: Region) => wrd.parent === activeLocalLevel.id)}
                    keyExtractor={keyExtractor}
                    valueExtractor={valueExtractor}
                    onChange={handleWardChange}
                    placeholder={_('Ward')}
                    defaultValue={defaultWard}
                    anchorOrigin="bottom right"
                    transformOrigin="top right"
                />
            )}
        </div>
    );
};

export default FilterRegionSelector;
