import React, { useMemo } from 'react';
import SVG from 'react-inlinesvg';

import Button from 'components/Button';

import { Localize } from 'services/i18n';
import PDFIcon from 'assets/icons/pdf_icon.svg';

import styles from './styles.scss';

interface PDFDetailsProps {
    file: string;
    size?: string;
}

const PDFDetails: React.FC<PDFDetailsProps> = (props) => {
    const { file } = props;

    const title = useMemo(() => {
        return file?.split('/')?.pop();
    }, [file]);

    return (
        <div className={styles.container}>
            <div className={styles.content}>
                <div className={styles.iconContainer}>
                    <SVG src={PDFIcon} />
                </div>
                <div className={styles.details}>
                    <span className={styles.title}>{title}</span>
                </div>
            </div>
            <div className={styles.footer}>
                <a target="_blank" href={file} rel="noopener noreferrer">
                    <Button small secondary leftIcon="visibility">
                        <Localize>Preview</Localize>
                    </Button>
                </a>
            </div>
        </div>
    );
};

export default PDFDetails;
