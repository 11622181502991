import React, { useCallback, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import AuthInput from 'components/Input/AuthInput';
import Button from 'components/Button';
import CheckboxInput from '@ra/components/Form/CheckboxInput';
import FileInput from 'components/FileInput';
import Form, { FormSubmitCallback, InputField } from '@ra/components/Form';
import Input from 'components/Input';
import { OrganizationSelectInput, OptionsType } from 'components/SelectInput';

import Api from 'services/api';
import { Localize, _ } from 'services/i18n';
import { useAppSelector } from 'hooks/store';
import usePromise from '@ra/hooks/usePromise';
import Toast from 'services/toast';
import { phoneNumberValidationRegex } from 'utils/form';

import type { Organization } from 'services/types';
import type { SelectInputChangeCallback } from '@ra/components/Form/SelectInput';
import type { FileInputChangeCallback } from '@ra/components/Form/DragDropFileInput';

import styles from './styles.scss';

const titleExtractor = (item: OptionsType) => item?.title;
const keyExtractor = (item: OptionsType) => item?.id;

const organizationIdExtractor: SelectInputChangeCallback<Organization> = ({ option }) =>
    option?.id || null;
const fileExtractor: FileInputChangeCallback = ({ files }) => (files.length > 0 ? files[0] : null);

const Register: React.FC = () => {
    const navigate = useNavigate();

    const { organizations } = useAppSelector((state) => state.organization);

    const [error, setError] = useState<any>(null);
    const [doesAgreeDetails, setDoesAgreeDetails] = useState<boolean>(false);

    const handleAgreeDetailsChange = useCallback((payload: HTMLInputElement) => {
        setDoesAgreeDetails(payload.checked);
    }, []);

    const [{ loading }, requestRegistration] = usePromise(Api.claimOrganization);

    const handleRegister: FormSubmitCallback = useCallback(
        async (formData) => {
            setError(null);
            const submitData = new FormData();
            const name = formData.fullName.split(' ');
            const firstName = name[0];
            const lastName = formData.fullName.substring(name[0].length).trim();

            if (!lastName) {
                setError({ fullName: _('Last name cannot be empty') });
                return;
            }
            for (const key in formData) {
                if (formData[key] && !['organization', 'fullName'].includes(key)) {
                    submitData.append(key, formData[key]);
                }
            }
            submitData.append('firstName', firstName);
            submitData.append('lastName', lastName);
            try {
                if (!phoneNumberValidationRegex.test(submitData.get('phoneNumber') as string)) {
                    throw {
                        errors: { phoneNumber: ['The phone number you entered is not valid.'] }
                    };
                }
                const result = await requestRegistration(formData.organization, submitData, {
                    headers: new Headers()
                });
                if (result) {
                    navigate('/');
                    Toast.show(
                        _(
                            'Your registration request has been successfully sent. The focal person will be notified by our administrators within a few working days.'
                        ),
                        Toast.SUCCESS,
                        null
                    );
                }
            } catch (err: any) {
                setError(err?.errors || err);
                if (err?.errors) {
                    Toast.show(
                        _('An error occured! Please ensure you have entered valid data.'),
                        Toast.DANGER
                    );
                }
            }
        },
        [navigate, requestRegistration]
    );

    const handleInvalidSubmit = useCallback((reason: string) => {
        if (reason === 'required') {
            Toast.show(_('Please complete all required fields!'), Toast.DANGER);
        }
    }, []);

    return (
        <div className={styles.authContainer}>
            <div className={styles.authBox}>
                <h1 className={styles.authHeading}>
                    <Localize>Request to register an account</Localize>
                </h1>
                <p className={styles.authSubHeading}>
                    <Localize>Please enter the details</Localize>
                </p>
                <Form
                    error={error}
                    formErrorClassName={styles.errorMessage}
                    className={styles.formWrapper}
                    onSubmit={handleRegister}
                    onInvalidSubmit={handleInvalidSubmit}
                >
                    <InputField
                        component={OrganizationSelectInput}
                        label={_('Organization*')}
                        labelClassName={styles.inputLabel}
                        containerClassName={styles.selectInput}
                        name="organization"
                        options={organizations}
                        valueExtractor={titleExtractor}
                        fieldValueExtractor={organizationIdExtractor}
                        keyExtractor={keyExtractor}
                        placeholder={_('Select your organization')}
                        required
                    />
                    <InputField
                        component={AuthInput}
                        label={_('Focal Person Name*')}
                        labelClassName={styles.inputLabel}
                        placeholder={_('Eg. Subas Pathak')}
                        name="fullName"
                        required
                    />
                    <InputField
                        component={AuthInput}
                        name="designation"
                        label={_('Focal Person Designation*')}
                        labelClassName={styles.inputLabel}
                        placeholder={_('Eg. Director')}
                        required
                    />
                    <InputField
                        component={AuthInput}
                        type="email"
                        labelClassName={styles.inputLabel}
                        name="email"
                        label={_('Focal Person Email*')}
                        placeholder={_('Eg. subas@mail.com')}
                        required
                    />
                    <InputField
                        component={Input}
                        className={styles.input}
                        containerClassName={styles.contactInput}
                        name="phoneNumber"
                        labelClassName={styles.inputLabel}
                        placeholder={_('+97798XXXXXXXX')}
                        label={_('Focal Person Contact Number* (with country code)')}
                        required
                    />
                    <InputField
                        component={FileInput}
                        containerClassName={styles.fileInput}
                        name="letter"
                        label={_('Organization Letter*')}
                        labelClassName={styles.inputLabel}
                        info={_('PDF (max. 2MB)')}
                        maxSize={2048}
                        fieldValueExtractor={fileExtractor}
                        accept="application/pdf"
                        required
                    />
                    <div className={styles.msgWrapper}>
                        <div className={styles.checkWrapper}>
                            <CheckboxInput
                                id="agree_conditions_checkbox"
                                className={styles.checkbox}
                                onChange={handleAgreeDetailsChange}
                            />
                            <label htmlFor="agree_conditions_checkbox" className={styles.message}>
                                <Localize>I agree all the details entered are correct</Localize>
                            </label>
                        </div>
                    </div>
                    <Button
                        disabled={!doesAgreeDetails}
                        loading={loading}
                        type="submit"
                        className={styles.authButton}
                    >
                        <Localize>Request Registration</Localize>
                    </Button>
                </Form>
                <div className={styles.linkWrapper}>
                    <p className={styles.registerMessage}>
                        <Localize>Already registered?</Localize>
                        <span>
                            <Link className={styles.registerLink} to="/login">
                                <Localize>Log in here</Localize>
                            </Link>
                        </span>
                    </p>
                </div>
            </div>
        </div>
    );
};

export default Register;
