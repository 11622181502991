import React, { useEffect, useMemo } from 'react';
import { Link } from 'react-router-dom';

import Container from 'components/Container';
import Button from 'components/Button';
import { RectLoader } from 'components/Loader';

import Api from 'services/api';

import { Localize } from '@ra/components/I18n';
import usePromise from '@ra/hooks/usePromise';

import styles from './styles.scss';

const HeroSection: React.FC = () => {
    const [{ result, loading }, getContent] = usePromise(Api.getHeroContent);

    useEffect(() => {
        getContent();
    }, []);

    const data = useMemo(() => result?.results[0], [result]);

    return (
        <div className={styles.header} style={{ backgroundImage: `url(${data?.backgroundImage})` }}>
            <div className={styles.overlayBg}>
                <Container>
                    {loading ? (
                        <div className={styles.loadingWrapper}>
                            <RectLoader />
                        </div>
                    ) : (
                        <div className={styles.heroInfo}>
                            <h1 className={styles.heroTitle}>
                                <Localize dataKey="title">{data}</Localize>
                            </h1>
                            <p className={styles.heroDesc}>
                                <Localize dataKey="description">{data}</Localize>
                            </p>
                            <Link className={styles.exploreLink} to="/map">
                                <Button className={styles.exploreButton}>
                                    <Localize>Explore</Localize>
                                </Button>
                            </Link>
                        </div>
                    )}
                </Container>
            </div>
        </div>
    );
};

export default HeroSection;
