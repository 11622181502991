import React, { useMemo, useEffect, useState, useCallback } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';

import Button from 'components/Button';
import HTMLParser from 'components/HTMLParser';
import List, { KeyExtractor } from '@ra/components/List';
import Map from 'components/Map';
import { OrganizationLogo } from 'components/OrganizationList';
import OverviewStatItem from 'components/OverviewStatItem';
import ProjectTable, { getDefaultProjectColumns } from 'components/ProjectTable';
import Tabs, { Tab } from 'components/Tabs';

import Api from 'services/api';
import cs from '@ra/cs';
import { statsData } from 'services/data';
import { Localize, _ } from 'services/i18n';

import { useAppSelector } from 'hooks/store';
import { useFiltersDispatch } from 'hooks/filters';
import useAdministrativeDivisions, { DivisionLevelModel } from 'hooks/useAdministrativeDivisions';
import usePromise from '@ra/hooks/usePromise';

import type { Organization, Project, MPTTStats } from 'services/types';

import styles from './styles.scss';

const keyExtractor: KeyExtractor<{ id: number }> = (item) => item.id;

const OrganizationDetails: React.FC = () => {
    const { organizationId } = useParams();

    const filtersDispatch = useFiltersDispatch();

    const { organizations } = useAppSelector((state) => state.organization);

    const navigate = useNavigate();

    const projectColumns = useMemo(() => {
        const defaultColumns = getDefaultProjectColumns();
        defaultColumns.splice(1, 0, {
            Header: _('BUDGET (NPR)'),
            accessor: 'budget'
        });
        return defaultColumns.filter((col) => col.accessor !== 'organization');
    }, []);

    useEffect(() => {
        if (!organizationId) {
            navigate('/organizations');
        }
    }, [navigate, organizationId]);

    const activeOrganization = useMemo(() => {
        if (organizationId) {
            return organizations.find((org) => org.id === +organizationId) as Organization;
        }
    }, [organizations, organizationId]);

    useEffect(() => {
        if (organizations?.length && !activeOrganization) {
            navigate('/organizations');
        }
    }, [organizations, activeOrganization, navigate]);

    const [projectOrdering, setProjectOrdering] = useState<string>('-budget');
    const handleSortProjects = useCallback(setProjectOrdering, []);

    const [{ loading, result, error }, getProjects] = usePromise(Api.getProjects);
    useEffect(() => {
        const queryObj = {
            organization: organizationId,
            limit: 5
        };
        if (projectOrdering) {
            Object.assign(queryObj, { ordering: projectOrdering });
        }
        getProjects(queryObj);
    }, [getProjects, projectOrdering, organizationId]);
    const projectData: Project[] = useMemo(() => result?.results || [], [result]);

    const [{ result: countResult }, getStats] = usePromise(Api.getProjectCounts);
    useEffect(() => {
        getStats({ organization: organizationId });
    }, [getStats, organizationId]);
    const countStats = useMemo(() => {
        return statsData.map((st) => ({
            ...st,
            value: countResult?.[st.key] || ''
        }));
    }, [countResult]);

    const divisionLevels: DivisionLevelModel[] = useAdministrativeDivisions();
    const [activeLevel, setActiveLevel] = useState<DivisionLevelModel>(divisionLevels[0]);
    const handleLevelChange = useCallback(
        ({ activeTab }: { activeTab: string }) => {
            const newActiveLevel = divisionLevels.find((dvl) => dvl.label === activeTab);
            if (newActiveLevel) {
                setActiveLevel(newActiveLevel);
            }
        },
        [divisionLevels]
    );

    const [{ result: statResult }, getMapStats] = usePromise(Api.getLocationStats);
    useEffect(() => {
        getMapStats({ organization: organizationId });
    }, [getMapStats, organizationId]);
    const mapData = useMemo(() => {
        return statResult?.filter((res: MPTTStats) => res.level === Number(activeLevel.code)) || [];
    }, [statResult, activeLevel]);

    const handleAllProjects = useCallback(() => {
        if (activeOrganization) {
            localStorage.setItem('activeProjectsTab', 'all');
            filtersDispatch({
                type: 'set',
                filters: [
                    {
                        filterType: 'Project-Organization',
                        filterValue: [activeOrganization],
                        query: { organization__in: activeOrganization.id }
                    }
                ],
                category: 'Project'
            });
        }
    }, [filtersDispatch, activeOrganization]);

    return (
        <div className={styles.container}>
            <header className={styles.header}>
                <div className={styles.logoContainer}>
                    <OrganizationLogo
                        className={styles.logo}
                        organization={activeOrganization as Organization}
                    />
                </div>
                <div className={styles.organizationMeta}>
                    <h1 className={styles.title}>
                        {activeOrganization?.title || ''}
                        {activeOrganization?.acronym ? ` (${activeOrganization.acronym})` : ''}
                    </h1>
                    <p className={styles.description}>
                        <HTMLParser content={activeOrganization?.description || ''} />
                    </p>
                </div>
            </header>
            <main className={styles.content}>
                <List
                    className={cs(styles.section, styles.statsList)}
                    keyExtractor={keyExtractor}
                    data={countStats}
                    renderItem={OverviewStatItem}
                />
                <h3 className={styles.sectionTitle}>
                    <Localize>Heatmap of Projects</Localize>
                </h3>
                <section className={styles.section}>
                    <Tabs
                        className={styles.mapTabs}
                        secondary
                        defaultActiveTab={divisionLevels[0].label}
                        onChange={handleLevelChange}
                    >
                        {divisionLevels.slice(0, 4).map((lvl) => (
                            <Tab key={lvl.id} label={lvl.label} title={lvl.title} />
                        ))}
                    </Tabs>
                    <div className={styles.mapContainer}>
                        <Map
                            activeDivisionLevel={activeLevel}
                            navigationControlStyle={{
                                marginRight: '1.25rem',
                                marginTop: '1.25rem'
                            }}
                            style={{ width: '100%', height: 572 }}
                            legendClassName={styles.mapLegend}
                            mapData={mapData}
                            showActiveFeaturePopup
                            hideActiveFeaturePopupButton
                        />
                    </div>
                </section>
                <h3 className={styles.sectionTitle}>
                    <Localize>Projects with largest budget</Localize>
                </h3>
                <div className={styles.tableContainer}>
                    <ProjectTable
                        className={styles.table}
                        headerClassName={styles.tableHeader}
                        loading={(!result && !error) || loading}
                        data={projectData}
                        columns={projectColumns}
                        maxRows={10}
                        onSort={handleSortProjects}
                        ordering={projectOrdering}
                    />
                </div>
                <div className={styles.buttonContainer}>
                    <Link to="/projects" onClick={handleAllProjects}>
                        <Button large>
                            <Localize>View all projects</Localize>
                        </Button>
                    </Link>
                </div>
            </main>
        </div>
    );
};

export default OrganizationDetails;
