import React, { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import HTMLParser from 'components/HTMLParser';
import Footer from 'components/Footer';
import Container from 'components/Container';
import Tabs, { Tab, type TabChangeCallback } from 'components/Tabs';

import { RectLoader } from 'components/Loader';

import { DocumentTypeEnum } from 'services/types';

import { useAppSelector } from 'hooks/store';

import styles from './styles.scss';

const LegalDocument: React.FC = () => {
    const navigate = useNavigate();

    const { legalDocuments, isLoading } = useAppSelector((state) => state.legalDocument);

    const activeTab = useMemo(() => {
        if (!window.location.hash) {
            return DocumentTypeEnum.TermsAndConditions;
        }
        return window.location.hash.replace('#', '');
    }, [window.location.hash]);

    const handleTabChange: TabChangeCallback = useCallback(
        ({ activeTab: newActiveTab }) => {
            navigate(`/legal-document#${newActiveTab}`);
        },
        [navigate]
    );

    return (
        <>
            <Container>
                <div className={styles.policyTabs}>
                    {isLoading ? (
                        <div className={styles.loaderWrapper}>
                            <RectLoader primary />
                        </div>
                    ) : (
                        <Tabs
                            primary
                            activeTab={activeTab}
                            headerContainerClassName={styles.tabsHeaderContainer}
                            tabItemClassName={styles.tabsHeaderItem}
                            contentContainerClassName={styles.tabsContent}
                            onChange={handleTabChange}
                        >
                            {legalDocuments.map((legalDocument) => (
                                <Tab
                                    key={String(legalDocument.id)}
                                    label={legalDocument.documentType}
                                    title={legalDocument.documentType
                                        .replace(/-/g, ' ')
                                        .toUpperCase()}
                                >
                                    <HTMLParser content={legalDocument.description} />
                                </Tab>
                            ))}
                        </Tabs>
                    )}
                </div>
            </Container>
            <Footer />
        </>
    );
};

export default LegalDocument;
