import React, { useCallback, useState, useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';

import AuthInput from 'components/Input/AuthInput';
import AuthModals from 'components/AuthModals';
import Button from 'components/Button';

import Form, { FormSubmitCallback, InputField } from '@ra/components/Form';

import Api from 'services/api';
import { Localize, _ } from 'services/i18n';
import Toast from 'services/toast';

import usePromise from '@ra/hooks/usePromise';
import { useAppDispatch, useAppSelector } from 'hooks/store';
import useAuthModals from 'hooks/useAuthModals';

import * as authActions from 'store/slices/auth';

import styles from './styles.scss';

const Login: React.FC = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const authModalsConfig = useAuthModals();

    const { isAuthenticated } = useAppSelector((state) => state.auth);

    const location = useLocation();

    const [{ loading }, login] = usePromise(Api.login);

    const [error, setError] = useState<any>(null);
    const [newUsername, setNewUsername] = useState<string | null>(null);
    const [isNewPassword, setIsNewPassword] = useState<boolean>(false);

    const handleLogin: FormSubmitCallback = useCallback(
        async (formData) => {
            try {
                const { access, refresh } = await login(formData);
                dispatch(authActions.setToken(access));
                dispatch(authActions.setRefreshToken(refresh));
                dispatch(authActions.login());
                Api.loadUserToStore();
                navigate('/dashboard');
            } catch (err) {
                console.log(err);
                setError(err);
            }
        },
        [login, dispatch, navigate]
    );

    const [{ loading: newPasswordLoading }, sendNewPasswordEmail] = usePromise(Api.forgotPassword);
    const handleSendEmail = useCallback(
        async (username: string) => {
            try {
                await sendNewPasswordEmail({
                    username,
                    mailFormat: 'new'
                });
            } catch (err) {
                Toast.show(
                    _(
                        'An error occured when sending you the code for setting your password. Please verify that you are using the valid link that was sent to you. If you ARE using the valid link, please contact our administrators for further information.'
                    ),
                    Toast.DANGER,
                    null
                );
                authModalsConfig.dispatch({ type: 'hideModals' });
                navigate('/login');
            }
        },
        [sendNewPasswordEmail, navigate]
    );

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        if (searchParams.has('user')) {
            if (isAuthenticated) {
                Toast.show(
                    _(
                        'A user is already logged in. Please logout first in order to set a new password!'
                    ),
                    Toast.DANGER
                );
                navigate('/dashboard');
            } else {
                setIsNewPassword(true);
                setNewUsername((prevNewUsername) => {
                    const userName = searchParams.get('user') as string;
                    authModalsConfig.dispatch({ type: 'showVerifyEmail' });
                    if (!prevNewUsername) {
                        handleSendEmail(userName);
                    }
                    return userName;
                });
            }
        } else {
            setIsNewPassword(false);
        }
    }, [location.search, isAuthenticated, navigate]);

    const handleShowSendEmail = useCallback(() => {
        setIsNewPassword(false);
        authModalsConfig.dispatch({ type: 'showSendEmail' });
    }, [authModalsConfig]);

    const handleCloseSetPassword = useCallback(() => {
        setIsNewPassword(false);
        navigate('/login');
    }, [navigate]);

    return (
        <div className={styles.authContainer}>
            <div className={styles.authBox}>
                <h1 className={styles.authHeading}>
                    <Localize>Log in to your account</Localize>
                </h1>
                <p className={styles.authSubHeading}>
                    <Localize>Please enter your details</Localize>
                </p>
                <Form
                    formErrorClassName={styles.errorMessage}
                    error={error}
                    onSubmit={handleLogin}
                    className={styles.formWrapper}
                >
                    <InputField
                        component={AuthInput}
                        name="username"
                        label={_('Email')}
                        labelClassName={styles.inputLabel}
                        placeholder={_('Enter your email')}
                        required
                    />
                    <InputField
                        component={AuthInput}
                        name="password"
                        inputType="password"
                        label={_('Password')}
                        labelClassName={styles.inputLabel}
                        placeholder={_('Enter your password')}
                        required
                    />
                    <div className={styles.msgWrapper}>
                        <div onClick={handleShowSendEmail} className={styles.forgotLink}>
                            <Localize>Forgot Password?</Localize>
                        </div>
                    </div>
                    <Button
                        loading={loading || newPasswordLoading}
                        type="submit"
                        className={styles.authButton}
                    >
                        <Localize>Log in</Localize>
                    </Button>
                </Form>
                <div className={styles.linkWrapper}>
                    <p className={styles.registerMessage}>
                        <Localize>Account not registered?</Localize>
                    </p>
                    <Link className={styles.registerLink} to="/register">
                        <Localize>Register here</Localize>
                    </Link>
                </div>
            </div>
            <AuthModals
                {...authModalsConfig}
                isNewPassword={isNewPassword}
                defaultEmail={isNewPassword ? (newUsername as string) : undefined}
                onResetPassword={handleCloseSetPassword}
                onClose={isNewPassword ? handleCloseSetPassword : undefined}
            />
        </div>
    );
};

export default Login;
