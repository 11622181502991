/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface AcceptClaimOrganization200Response {
    /** @default "Organization claim accepted" */
    detail?: string;
}

export interface AcceptClaimOrganization400Response {
    errors: Record<string, string>;
}

export interface AcceptNewOrganization200Response {
    /** @default "New organization accepted" */
    detail?: string;
}

export interface AcceptNewOrganization400Response {
    errors: Record<string, string>;
}

/**
 * A ModelSerializer that takes additional arguments for
 * "fields", "omit" and "expand" in order to
 * control which fields are displayed, and whether to replace simple
 * values with complex, nested serializations
 */
export interface Activity {
    id: number;
    budgetCurrency: ExpenseCurrencyEnum;
    /** @format date-time */
    createdAt: string;
    /** @format date-time */
    modifiedAt: string;
    /** @maxLength 512 */
    title: string;
    /**
     * @format decimal
     * @pattern ^-?\d{0,15}(?:\.\d{0,4})?$
     */
    budget: string;
    /** @format double */
    exchangeRate: number;
    createdBy: string | null;
    updatedBy: string | null;
    output: number;
    location: number;
    partners?: number[];
    priorityIndicators: number[];
}

export interface ChangePassword {
    oldPassword: string;
    newPassword: string;
    reNewPassword: string;
}

export interface ChangePasswordResponse {
    /** @default "Password successfully updated" */
    detail?: string;
}

export interface ClaimOrganization201Response {
    /** @format uuid */
    id: string;
}

export interface ClaimOrganization400Response {
    errors: Record<string, string>;
}

export interface ClaimOrganizationRequest {
    id: number;
    status: string;
    reason?: string | null;
    /**
     * @format uri
     * @pattern (?:pdf)$
     */
    letter?: string | null;
    /** @maxLength 150 */
    firstName: string;
    /** @maxLength 150 */
    lastName: string;
    /**
     * Email address
     * @format email
     * @maxLength 254
     */
    email: string;
    /** @maxLength 128 */
    phoneNumber?: string | null;
    /** @maxLength 255 */
    designation: string;
    /** @format uuid */
    identifier: string;
    organization: number;
}

export interface CountStats {
    projectCount: number;
    organizationCount: number;
    partnerCount: number;
    donorCount: number;
}

/** Adds nested create feature */
export interface CreateActivity {
    id: number;
    budgetCurrency: ExpenseCurrencyEnum;
    targets?: CreateTarget[];
    priorityIndicators?: CreatePriorityIndicator[];
    /** @format date-time */
    createdAt: string;
    /** @format date-time */
    modifiedAt: string;
    /** @maxLength 512 */
    title: string;
    /**
     * @format decimal
     * @pattern ^-?\d{0,15}(?:\.\d{0,4})?$
     */
    budget: string;
    /** @format double */
    exchangeRate: number;
    createdBy: string | null;
    updatedBy: string | null;
    location: number;
    partners?: number[];
}

export interface CreateActivity201Response {
    /** @default "Activity successfully created" */
    detail?: string;
}

export interface CreateActivity400Response {
    errors: Record<string, string>;
}

export interface CreateDocument400Response {
    errors: Record<string, string>;
}

/** Adds nested create feature */
export interface CreateOutput {
    id: number;
    activities?: CreateActivity[];
    /** @format date-time */
    createdAt: string;
    /** @format date-time */
    modifiedAt: string;
    /** @maxLength 512 */
    title: string;
    /** @format date */
    dateFrom: string;
    /** @format date */
    dateTo: string;
    createdBy: string | null;
    updatedBy: string | null;
}

export interface CreateOutput201Response {
    /** @default "Output successfully created" */
    detail?: string;
}

export interface CreateOutput400Response {
    errors: Record<string, string>;
}

export interface CreatePriorityIndicator {
    id: number;
    /** @format date-time */
    createdAt: string;
    /** @format date-time */
    modifiedAt: string;
    /** @maxLength 512 */
    title: string;
    /** Custom indicator */
    isCustomIndicator?: boolean;
    lft: number;
    rght: number;
    treeId: number;
    level: number;
    createdBy: string | null;
    updatedBy: string | null;
    /** Parent priority indicator */
    parent?: number | null;
}

/** Adds nested create feature */
export interface CreateProject {
    id: number;
    budgetCurrency: ExpenseCurrencyEnum;
    administrativeBudgetCurrency: ExpenseCurrencyEnum;
    documents?: CreateProjectDocument[];
    outputs?: CreateOutput[];
    /** @format date-time */
    createdAt: string;
    /** @format date-time */
    modifiedAt: string;
    /** @maxLength 512 */
    title: string;
    /**
     * Title [en]
     * @maxLength 512
     */
    titleEn?: string | null;
    /**
     * Title [ne]
     * @maxLength 512
     */
    titleNe?: string | null;
    description?: string;
    /** Description [en] */
    descriptionEn?: string | null;
    /** Description [ne] */
    descriptionNe?: string | null;
    /** @format date */
    dateFrom: string;
    /** @format date */
    dateTo: string;
    /**
     * @format decimal
     * @pattern ^-?\d{0,15}(?:\.\d{0,4})?$
     */
    budget: string;
    /**
     * @format decimal
     * @pattern ^-?\d{0,15}(?:\.\d{0,4})?$
     */
    administrativeBudget: string;
    /** @format double */
    exchangeRate: number;
    status?: StatusEnum;
    /** Type */
    projectType?: ProjectTypeEnum;
    /** @maxLength 255 */
    contactName: string;
    /**
     * @format email
     * @maxLength 254
     */
    contactEmail: string;
    /** @maxLength 255 */
    contactPhone: string;
    createdBy: string | null;
    updatedBy: string | null;
    location: number;
    hazards?: number[];
}

export interface CreateProject201Response {
    /** @default "Project Donor successfully created" */
    detail?: string;
}

export interface CreateProject400Response {
    errors: Record<string, string>;
}

export interface CreateProjectDocument {
    id: number;
    /** @format date-time */
    createdAt: string;
    /** @format date-time */
    modifiedAt: string;
    /** @maxLength 512 */
    title: string;
    description?: string;
    /** @format uri */
    document?: string | null;
    /**
     * @format uri
     * @maxLength 200
     */
    documentUrl?: string | null;
    createdBy: string | null;
    updatedBy: string | null;
    documentType: number;
}

/**
 * A ModelSerializer that takes additional arguments for
 * "fields", "omit" and "expand" in order to
 * control which fields are displayed, and whether to replace simple
 * values with complex, nested serializations
 */
export interface CreateProjectDonor {
    id: number;
    implementors: CreateProjectDonorImplementor[];
    /** @format date-time */
    createdAt: string;
    /** @format date-time */
    modifiedAt: string;
    /** @maxLength 512 */
    title: string;
    description?: string;
    lft: number;
    rght: number;
    treeId: number;
    level: number;
    createdBy: string | null;
    updatedBy: string | null;
}

/**
 * A ModelSerializer that takes additional arguments for
 * "fields", "omit" and "expand" in order to
 * control which fields are displayed, and whether to replace simple
 * values with complex, nested serializations
 */
export interface CreateProjectDonorImplementor {
    id: number;
    budgetCurrency: ExpenseCurrencyEnum;
    administrativeBudgetCurrency: ExpenseCurrencyEnum;
    /** @format date-time */
    createdAt: string;
    /** @format date-time */
    modifiedAt: string;
    /**
     * @format decimal
     * @pattern ^-?\d{0,15}(?:\.\d{0,4})?$
     */
    budget: string;
    /**
     * @format decimal
     * @pattern ^-?\d{0,15}(?:\.\d{0,4})?$
     */
    administrativeBudget: string;
    /** @format double */
    exchangeRate: number;
    createdBy: string | null;
    updatedBy: string | null;
    implementor: number;
}

/**
 * A ModelSerializer that takes additional arguments for
 * "fields", "omit" and "expand" in order to
 * control which fields are displayed, and whether to replace simple
 * values with complex, nested serializations
 */
export interface CreateTarget {
    id: number;
    /** @format date-time */
    createdAt: string;
    /** @format date-time */
    modifiedAt: string;
    /** @maxLength 512 */
    title: string;
    /** @format double */
    value: number;
    createdBy: string | null;
    updatedBy: string | null;
}

export interface CreateTarget201Response {
    /** @default "Target successfully created" */
    detail?: string;
}

export interface CreateTarget400Response {
    errors: Record<string, string>;
}

export interface CreateTargetReport {
    id: number;
    expenseCurrency: ExpenseCurrencyEnum;
    /** @format date-time */
    createdAt: string;
    /** @format date-time */
    modifiedAt: string;
    /** @maxLength 512 */
    title: string;
    description?: string;
    /** @format double */
    value: number;
    /** @format date */
    date: string;
    /**
     * @format decimal
     * @pattern ^-?\d{0,15}(?:\.\d{0,4})?$
     */
    expense: string;
    /** @format double */
    exchangeRate: number;
    /** @format uri */
    attachment?: string | null;
    createdBy: string | null;
    updatedBy: string | null;
    organization?: number | null;
}

export interface CreateTargetReport201Response {
    /** @default "Target report successfully created" */
    detail?: string;
}

export interface CreateTargetReport400Response {
    errors: Record<string, string>;
}

export enum DocumentTypeEnum {
    TermsAndConditions = 'terms-and-conditions',
    PrivacyPolicy = 'privacy-policy',
    CookiePolicy = 'cookie-policy'
}

export interface EmailChange {
    /** @format email */
    newEmail: string;
    password: string;
}

export interface EmailChangePinVerify {
    pin: number;
}

export interface EmailChangeResponse {
    /** @default "Email change mail successfully sent" */
    detail?: string;
}

export interface EmailChangeVerifyResponse {
    /** @default "Email changed successfully" */
    detail?: string;
}

export enum ExpenseCurrencyEnum {
    XUA = 'XUA',
    AFN = 'AFN',
    AFA = 'AFA',
    ALL = 'ALL',
    ALK = 'ALK',
    DZD = 'DZD',
    ADP = 'ADP',
    AOA = 'AOA',
    AOK = 'AOK',
    AON = 'AON',
    AOR = 'AOR',
    ARA = 'ARA',
    ARS = 'ARS',
    ARM = 'ARM',
    ARP = 'ARP',
    ARL = 'ARL',
    AMD = 'AMD',
    AWG = 'AWG',
    AUD = 'AUD',
    ATS = 'ATS',
    AZN = 'AZN',
    AZM = 'AZM',
    BSD = 'BSD',
    BHD = 'BHD',
    BDT = 'BDT',
    BBD = 'BBD',
    BYN = 'BYN',
    BYB = 'BYB',
    BYR = 'BYR',
    BEF = 'BEF',
    BEC = 'BEC',
    BEL = 'BEL',
    BZD = 'BZD',
    BMD = 'BMD',
    BTN = 'BTN',
    BOB = 'BOB',
    BOL = 'BOL',
    BOV = 'BOV',
    BOP = 'BOP',
    VED = 'VED',
    BAM = 'BAM',
    BAD = 'BAD',
    BAN = 'BAN',
    BWP = 'BWP',
    BRC = 'BRC',
    BRZ = 'BRZ',
    BRE = 'BRE',
    BRR = 'BRR',
    BRN = 'BRN',
    BRB = 'BRB',
    BRL = 'BRL',
    GBP = 'GBP',
    BND = 'BND',
    BGL = 'BGL',
    BGN = 'BGN',
    BGO = 'BGO',
    BGM = 'BGM',
    BUK = 'BUK',
    BIF = 'BIF',
    XPF = 'XPF',
    KHR = 'KHR',
    CAD = 'CAD',
    CVE = 'CVE',
    KYD = 'KYD',
    XAF = 'XAF',
    CLE = 'CLE',
    CLP = 'CLP',
    CLF = 'CLF',
    CNX = 'CNX',
    CNY = 'CNY',
    CNH = 'CNH',
    COP = 'COP',
    COU = 'COU',
    KMF = 'KMF',
    CDF = 'CDF',
    CRC = 'CRC',
    HRD = 'HRD',
    HRK = 'HRK',
    CUC = 'CUC',
    CUP = 'CUP',
    CYP = 'CYP',
    CZK = 'CZK',
    CSK = 'CSK',
    DKK = 'DKK',
    DJF = 'DJF',
    DOP = 'DOP',
    NLG = 'NLG',
    XCD = 'XCD',
    DDM = 'DDM',
    ECS = 'ECS',
    ECV = 'ECV',
    EGP = 'EGP',
    GQE = 'GQE',
    ERN = 'ERN',
    EEK = 'EEK',
    ETB = 'ETB',
    EUR = 'EUR',
    XBA = 'XBA',
    XEU = 'XEU',
    XBB = 'XBB',
    XBC = 'XBC',
    XBD = 'XBD',
    FKP = 'FKP',
    FJD = 'FJD',
    FIM = 'FIM',
    FRF = 'FRF',
    XFO = 'XFO',
    XFU = 'XFU',
    GMD = 'GMD',
    GEK = 'GEK',
    GEL = 'GEL',
    DEM = 'DEM',
    GHS = 'GHS',
    GHC = 'GHC',
    GIP = 'GIP',
    XAU = 'XAU',
    GRD = 'GRD',
    GTQ = 'GTQ',
    GWP = 'GWP',
    GNF = 'GNF',
    GNS = 'GNS',
    GYD = 'GYD',
    HTG = 'HTG',
    HNL = 'HNL',
    HKD = 'HKD',
    HUF = 'HUF',
    IMP = 'IMP',
    ISK = 'ISK',
    ISJ = 'ISJ',
    INR = 'INR',
    IDR = 'IDR',
    IRR = 'IRR',
    IQD = 'IQD',
    IEP = 'IEP',
    ILS = 'ILS',
    ILP = 'ILP',
    ILR = 'ILR',
    ITL = 'ITL',
    JMD = 'JMD',
    JPY = 'JPY',
    JOD = 'JOD',
    KZT = 'KZT',
    KES = 'KES',
    KWD = 'KWD',
    KGS = 'KGS',
    LAK = 'LAK',
    LVL = 'LVL',
    LVR = 'LVR',
    LBP = 'LBP',
    LSL = 'LSL',
    LRD = 'LRD',
    LYD = 'LYD',
    LTL = 'LTL',
    LTT = 'LTT',
    LUL = 'LUL',
    LUC = 'LUC',
    LUF = 'LUF',
    MOP = 'MOP',
    MKD = 'MKD',
    MKN = 'MKN',
    MGA = 'MGA',
    MGF = 'MGF',
    MWK = 'MWK',
    MYR = 'MYR',
    MVR = 'MVR',
    MVP = 'MVP',
    MLF = 'MLF',
    MTL = 'MTL',
    MTP = 'MTP',
    MRU = 'MRU',
    MRO = 'MRO',
    MUR = 'MUR',
    MXV = 'MXV',
    MXN = 'MXN',
    MXP = 'MXP',
    MDC = 'MDC',
    MDL = 'MDL',
    MCF = 'MCF',
    MNT = 'MNT',
    MAD = 'MAD',
    MAF = 'MAF',
    MZE = 'MZE',
    MZN = 'MZN',
    MZM = 'MZM',
    MMK = 'MMK',
    NAD = 'NAD',
    NPR = 'NPR',
    ANG = 'ANG',
    TWD = 'TWD',
    NZD = 'NZD',
    NIO = 'NIO',
    NIC = 'NIC',
    NGN = 'NGN',
    KPW = 'KPW',
    NOK = 'NOK',
    OMR = 'OMR',
    PKR = 'PKR',
    XPD = 'XPD',
    PAB = 'PAB',
    PGK = 'PGK',
    PYG = 'PYG',
    PEI = 'PEI',
    PEN = 'PEN',
    PES = 'PES',
    PHP = 'PHP',
    XPT = 'XPT',
    PLN = 'PLN',
    PLZ = 'PLZ',
    PTE = 'PTE',
    GWE = 'GWE',
    QAR = 'QAR',
    XRE = 'XRE',
    RHD = 'RHD',
    RON = 'RON',
    ROL = 'ROL',
    RUB = 'RUB',
    RUR = 'RUR',
    RWF = 'RWF',
    SVC = 'SVC',
    WST = 'WST',
    SAR = 'SAR',
    RSD = 'RSD',
    CSD = 'CSD',
    SCR = 'SCR',
    SLE = 'SLE',
    SLL = 'SLL',
    XAG = 'XAG',
    SGD = 'SGD',
    SKK = 'SKK',
    SIT = 'SIT',
    SBD = 'SBD',
    SOS = 'SOS',
    ZAR = 'ZAR',
    ZAL = 'ZAL',
    KRH = 'KRH',
    KRW = 'KRW',
    KRO = 'KRO',
    SSP = 'SSP',
    SUR = 'SUR',
    ESP = 'ESP',
    ESA = 'ESA',
    ESB = 'ESB',
    XDR = 'XDR',
    LKR = 'LKR',
    SHP = 'SHP',
    XSU = 'XSU',
    SDD = 'SDD',
    SDG = 'SDG',
    SDP = 'SDP',
    SRD = 'SRD',
    SRG = 'SRG',
    SZL = 'SZL',
    SEK = 'SEK',
    CHF = 'CHF',
    SYP = 'SYP',
    STN = 'STN',
    STD = 'STD',
    TVD = 'TVD',
    TJR = 'TJR',
    TJS = 'TJS',
    TZS = 'TZS',
    XTS = 'XTS',
    THB = 'THB',
    TPE = 'TPE',
    TOP = 'TOP',
    TTD = 'TTD',
    TND = 'TND',
    TRY = 'TRY',
    TRL = 'TRL',
    TMT = 'TMT',
    TMM = 'TMM',
    USD = 'USD',
    USN = 'USN',
    USS = 'USS',
    UGX = 'UGX',
    UGS = 'UGS',
    UAH = 'UAH',
    UAK = 'UAK',
    AED = 'AED',
    UYW = 'UYW',
    UYU = 'UYU',
    UYP = 'UYP',
    UYI = 'UYI',
    UZS = 'UZS',
    VUV = 'VUV',
    VES = 'VES',
    VEB = 'VEB',
    VEF = 'VEF',
    VND = 'VND',
    VNN = 'VNN',
    CHE = 'CHE',
    CHW = 'CHW',
    XOF = 'XOF',
    YDD = 'YDD',
    YER = 'YER',
    YUN = 'YUN',
    YUD = 'YUD',
    YUM = 'YUM',
    YUR = 'YUR',
    ZWN = 'ZWN',
    ZRN = 'ZRN',
    ZRZ = 'ZRZ',
    ZMW = 'ZMW',
    ZMK = 'ZMK',
    ZWD = 'ZWD',
    ZWR = 'ZWR',
    ZWL = 'ZWL'
}

export interface FocalPerson {
    /** @maxLength 150 */
    firstName?: string;
    /** @maxLength 150 */
    lastName?: string;
    /** @maxLength 255 */
    designation?: string | null;
    /** @maxLength 128 */
    phoneNumber?: string | null;
}

export interface Hazard {
    id: number;
    /** @maxLength 512 */
    title: string;
    description?: string;
    /**
     * @format uri
     * @pattern (?:svg)$
     */
    icon?: string | null;
    /** Type */
    hazardType: HazardTypeEnum;
}

export enum HazardTypeEnum {
    Natural = 'natural',
    NonNatural = 'non_natural'
}

export interface HomePage {
    id: number;
    title: string;
    /** Title [en] */
    titleEn?: string | null;
    /** Title [ne] */
    titleNe?: string | null;
    description: string;
    /** Description [en] */
    descriptionEn?: string | null;
    /** Description [ne] */
    descriptionNe?: string | null;
    /**
     * @format uri
     * @pattern (?:jpg|jpeg|png|svg)$
     */
    backgroundImage: string;
}

export interface LegalDocument {
    id: number;
    /** @format date-time */
    createdAt: string;
    /** @format date-time */
    modifiedAt: string;
    documentType: DocumentTypeEnum;
    description: string;
    createdBy: string | null;
    updatedBy: string | null;
}

export interface MPTTStats {
    identifier: number | null;
    /** @format double */
    budget: number;
    count: number;
    level: number | null;
}

export enum MailFormatEnum {
    Reset = 'reset',
    New = 'new'
}

export interface MarkAllAsReadResponse {
    /** @default "Successfully marked all notification as read" */
    detail?: string;
}

export interface MarkAsReadResponse {
    /** @default "Successfully marked as read" */
    detail?: string;
}

export interface NewOrganization201Response {
    /** @format uuid */
    id: string;
}

export interface NewOrganization400Response {
    errors: Record<string, string>;
}

export interface NewOrganizationRequest {
    id: number;
    status: string;
    reason?: string | null;
    /**
     * @format uri
     * @pattern (?:pdf)$
     */
    letter?: string | null;
    /** @maxLength 512 */
    title: string;
    /** @maxLength 10 */
    acronym?: string | null;
    /**
     * @format uri
     * @pattern (?:jpg|jpeg|png|svg)$
     */
    logo?: string | null;
    description?: string;
    /**
     * Organization email address
     * @format email
     * @maxLength 254
     */
    organizationEmail?: string | null;
    /**
     * @format uri
     * @maxLength 200
     */
    organizationWebsite?: string | null;
    /** @maxLength 150 */
    firstName: string;
    /** @maxLength 150 */
    lastName: string;
    /**
     * Email address
     * @format email
     * @maxLength 254
     */
    email: string;
    /** @maxLength 128 */
    phoneNumber?: string | null;
    /** @maxLength 255 */
    designation: string;
    /** @format uuid */
    identifier: string;
    organizationType: number;
}

export interface Notification {
    id: number;
    actorContentType: string;
    targetContentType: string;
    actionObjectContentType: string;
    actorObjStr: string;
    targetObjStr: string;
    actionObjectObjStr: string;
    /** @format date-time */
    createdAt: string;
    /** @format date-time */
    modifiedAt: string;
    actorObjectId?: number | null;
    /** @maxLength 100 */
    verb: string;
    description: string;
    /** @maxLength 50 */
    notificationType?: string;
    /** @format date-time */
    timestamp?: string;
    actionObjectObjectId?: number | null;
    targetObjectId?: number | null;
    /** Read */
    hasRead: boolean;
    recipient: string;
}

/**
 * A ModelSerializer that takes additional arguments for
 * "fields", "omit" and "expand" in order to
 * control which fields are displayed, and whether to replace simple
 * values with complex, nested serializations
 */
export interface Organization {
    id: number;
    /** @format date-time */
    createdAt: string;
    /** @format date-time */
    modifiedAt: string;
    /** @maxLength 512 */
    title: string;
    /** @maxLength 10 */
    acronym?: string | null;
    /**
     * @format uri
     * @pattern (?:jpg|jpeg|png|svg)$
     */
    logo?: string | null;
    description?: string;
    /**
     * Organization email address
     * @format email
     * @maxLength 254
     */
    organizationEmail?: string | null;
    /**
     * @format uri
     * @maxLength 200
     */
    organizationWebsite?: string | null;
    createdBy: string | null;
    updatedBy: string | null;
    organizationType: number;
}

/**
 * A ModelSerializer that takes additional arguments for
 * "fields", "omit" and "expand" in order to
 * control which fields are displayed, and whether to replace simple
 * values with complex, nested serializations
 */
export interface OrganizationType {
    id: number;
    /** @maxLength 512 */
    title: string;
}

/**
 * A ModelSerializer that takes additional arguments for
 * "fields", "omit" and "expand" in order to
 * control which fields are displayed, and whether to replace simple
 * values with complex, nested serializations
 */
export interface Output {
    id: number;
    /** @format date-time */
    createdAt: string;
    /** @format date-time */
    modifiedAt: string;
    /** @maxLength 512 */
    title: string;
    /** @format date */
    dateFrom: string;
    /** @format date */
    dateTo: string;
    createdBy: string | null;
    updatedBy: string | null;
    project: number;
}

export interface PaginatedActivityList {
    /** @example 123 */
    count?: number;
    /**
     * @format uri
     * @example "http://api.example.org/accounts/?offset=400&limit=100"
     */
    next?: string | null;
    /**
     * @format uri
     * @example "http://api.example.org/accounts/?offset=200&limit=100"
     */
    previous?: string | null;
    results?: Activity[];
}

export interface PaginatedClaimOrganizationRequestList {
    /** @example 123 */
    count?: number;
    /**
     * @format uri
     * @example "http://api.example.org/accounts/?offset=400&limit=100"
     */
    next?: string | null;
    /**
     * @format uri
     * @example "http://api.example.org/accounts/?offset=200&limit=100"
     */
    previous?: string | null;
    results?: ClaimOrganizationRequest[];
}

export interface PaginatedHazardList {
    /** @example 123 */
    count?: number;
    /**
     * @format uri
     * @example "http://api.example.org/accounts/?offset=400&limit=100"
     */
    next?: string | null;
    /**
     * @format uri
     * @example "http://api.example.org/accounts/?offset=200&limit=100"
     */
    previous?: string | null;
    results?: Hazard[];
}

export interface PaginatedHomePageList {
    /** @example 123 */
    count?: number;
    /**
     * @format uri
     * @example "http://api.example.org/accounts/?offset=400&limit=100"
     */
    next?: string | null;
    /**
     * @format uri
     * @example "http://api.example.org/accounts/?offset=200&limit=100"
     */
    previous?: string | null;
    results?: HomePage[];
}

export interface PaginatedLegalDocumentList {
    /** @example 123 */
    count?: number;
    /**
     * @format uri
     * @example "http://api.example.org/accounts/?offset=400&limit=100"
     */
    next?: string | null;
    /**
     * @format uri
     * @example "http://api.example.org/accounts/?offset=200&limit=100"
     */
    previous?: string | null;
    results?: LegalDocument[];
}

export interface PaginatedMPTTStatsList {
    /** @example 123 */
    count?: number;
    /**
     * @format uri
     * @example "http://api.example.org/accounts/?offset=400&limit=100"
     */
    next?: string | null;
    /**
     * @format uri
     * @example "http://api.example.org/accounts/?offset=200&limit=100"
     */
    previous?: string | null;
    results?: MPTTStats[];
}

export interface PaginatedNewOrganizationRequestList {
    /** @example 123 */
    count?: number;
    /**
     * @format uri
     * @example "http://api.example.org/accounts/?offset=400&limit=100"
     */
    next?: string | null;
    /**
     * @format uri
     * @example "http://api.example.org/accounts/?offset=200&limit=100"
     */
    previous?: string | null;
    results?: NewOrganizationRequest[];
}

export interface PaginatedNotificationList {
    /** @example 123 */
    count?: number;
    /**
     * @format uri
     * @example "http://api.example.org/accounts/?offset=400&limit=100"
     */
    next?: string | null;
    /**
     * @format uri
     * @example "http://api.example.org/accounts/?offset=200&limit=100"
     */
    previous?: string | null;
    results?: Notification[];
}

export interface PaginatedOrganizationList {
    /** @example 123 */
    count?: number;
    /**
     * @format uri
     * @example "http://api.example.org/accounts/?offset=400&limit=100"
     */
    next?: string | null;
    /**
     * @format uri
     * @example "http://api.example.org/accounts/?offset=200&limit=100"
     */
    previous?: string | null;
    results?: Organization[];
}

export interface PaginatedOrganizationTypeList {
    /** @example 123 */
    count?: number;
    /**
     * @format uri
     * @example "http://api.example.org/accounts/?offset=400&limit=100"
     */
    next?: string | null;
    /**
     * @format uri
     * @example "http://api.example.org/accounts/?offset=200&limit=100"
     */
    previous?: string | null;
    results?: OrganizationType[];
}

export interface PaginatedOutputList {
    /** @example 123 */
    count?: number;
    /**
     * @format uri
     * @example "http://api.example.org/accounts/?offset=400&limit=100"
     */
    next?: string | null;
    /**
     * @format uri
     * @example "http://api.example.org/accounts/?offset=200&limit=100"
     */
    previous?: string | null;
    results?: Output[];
}

export interface PaginatedPriorityIndicatorList {
    /** @example 123 */
    count?: number;
    /**
     * @format uri
     * @example "http://api.example.org/accounts/?offset=400&limit=100"
     */
    next?: string | null;
    /**
     * @format uri
     * @example "http://api.example.org/accounts/?offset=200&limit=100"
     */
    previous?: string | null;
    results?: PriorityIndicator[];
}

export interface PaginatedProjectDocumentList {
    /** @example 123 */
    count?: number;
    /**
     * @format uri
     * @example "http://api.example.org/accounts/?offset=400&limit=100"
     */
    next?: string | null;
    /**
     * @format uri
     * @example "http://api.example.org/accounts/?offset=200&limit=100"
     */
    previous?: string | null;
    results?: ProjectDocument[];
}

export interface PaginatedProjectDocumentTypeList {
    /** @example 123 */
    count?: number;
    /**
     * @format uri
     * @example "http://api.example.org/accounts/?offset=400&limit=100"
     */
    next?: string | null;
    /**
     * @format uri
     * @example "http://api.example.org/accounts/?offset=200&limit=100"
     */
    previous?: string | null;
    results?: ProjectDocumentType[];
}

export interface PaginatedProjectDonorList {
    /** @example 123 */
    count?: number;
    /**
     * @format uri
     * @example "http://api.example.org/accounts/?offset=400&limit=100"
     */
    next?: string | null;
    /**
     * @format uri
     * @example "http://api.example.org/accounts/?offset=200&limit=100"
     */
    previous?: string | null;
    results?: ProjectDonor[];
}

export interface PaginatedProjectList {
    /** @example 123 */
    count?: number;
    /**
     * @format uri
     * @example "http://api.example.org/accounts/?offset=400&limit=100"
     */
    next?: string | null;
    /**
     * @format uri
     * @example "http://api.example.org/accounts/?offset=200&limit=100"
     */
    previous?: string | null;
    results?: Project[];
}

export interface PaginatedRegionList {
    /** @example 123 */
    count?: number;
    /**
     * @format uri
     * @example "http://api.example.org/accounts/?offset=400&limit=100"
     */
    next?: string | null;
    /**
     * @format uri
     * @example "http://api.example.org/accounts/?offset=200&limit=100"
     */
    previous?: string | null;
    results?: Region[];
}

export interface PaginatedStatsList {
    /** @example 123 */
    count?: number;
    /**
     * @format uri
     * @example "http://api.example.org/accounts/?offset=400&limit=100"
     */
    next?: string | null;
    /**
     * @format uri
     * @example "http://api.example.org/accounts/?offset=200&limit=100"
     */
    previous?: string | null;
    results?: Stats[];
}

export interface PaginatedTargetList {
    /** @example 123 */
    count?: number;
    /**
     * @format uri
     * @example "http://api.example.org/accounts/?offset=400&limit=100"
     */
    next?: string | null;
    /**
     * @format uri
     * @example "http://api.example.org/accounts/?offset=200&limit=100"
     */
    previous?: string | null;
    results?: Target[];
}

export interface PaginatedTargetReportList {
    /** @example 123 */
    count?: number;
    /**
     * @format uri
     * @example "http://api.example.org/accounts/?offset=400&limit=100"
     */
    next?: string | null;
    /**
     * @format uri
     * @example "http://api.example.org/accounts/?offset=200&limit=100"
     */
    previous?: string | null;
    results?: TargetReport[];
}

export interface PaginatedUserList {
    /** @example 123 */
    count?: number;
    /**
     * @format uri
     * @example "http://api.example.org/accounts/?offset=400&limit=100"
     */
    next?: string | null;
    /**
     * @format uri
     * @example "http://api.example.org/accounts/?offset=200&limit=100"
     */
    previous?: string | null;
    results?: User[];
}

export interface PasswordReset {
    username: string;
    /** @default "reset" */
    mailFormat?: MailFormatEnum;
}

export interface PasswordResetChangeResponse {
    /** @default "Password successfully changed" */
    detail?: string;
}

export interface PasswordResetPasswordChange {
    username: string;
    identifier: string;
    password: string;
    rePassword: string;
}

export interface PasswordResetResponse {
    /** @default "Password reset email successfully sent" */
    detail?: string;
}

export interface PasswordResetVerifyResponse {
    identifier: string;
}

export interface PatchedClaimOrganizationRequest {
    id?: number;
    status?: string;
    reason?: string | null;
    /**
     * @format uri
     * @pattern (?:pdf)$
     */
    letter?: string | null;
    /** @maxLength 150 */
    firstName?: string;
    /** @maxLength 150 */
    lastName?: string;
    /**
     * Email address
     * @format email
     * @maxLength 254
     */
    email?: string;
    /** @maxLength 128 */
    phoneNumber?: string | null;
    /** @maxLength 255 */
    designation?: string;
    /** @format uuid */
    identifier?: string;
    organization?: number;
}

/** Adds nested create feature */
export interface PatchedCreateActivity {
    id?: number;
    budgetCurrency?: ExpenseCurrencyEnum;
    targets?: CreateTarget[];
    priorityIndicators?: CreatePriorityIndicator[];
    /** @format date-time */
    createdAt?: string;
    /** @format date-time */
    modifiedAt?: string;
    /** @maxLength 512 */
    title?: string;
    /**
     * @format decimal
     * @pattern ^-?\d{0,15}(?:\.\d{0,4})?$
     */
    budget?: string;
    /** @format double */
    exchangeRate?: number;
    createdBy?: string | null;
    updatedBy?: string | null;
    location?: number;
    partners?: number[];
}

/** Adds nested create feature */
export interface PatchedCreateOutput {
    id?: number;
    activities?: CreateActivity[];
    /** @format date-time */
    createdAt?: string;
    /** @format date-time */
    modifiedAt?: string;
    /** @maxLength 512 */
    title?: string;
    /** @format date */
    dateFrom?: string;
    /** @format date */
    dateTo?: string;
    createdBy?: string | null;
    updatedBy?: string | null;
}

/** Adds nested create feature */
export interface PatchedCreateProject {
    id?: number;
    budgetCurrency?: ExpenseCurrencyEnum;
    administrativeBudgetCurrency?: ExpenseCurrencyEnum;
    documents?: CreateProjectDocument[];
    outputs?: CreateOutput[];
    /** @format date-time */
    createdAt?: string;
    /** @format date-time */
    modifiedAt?: string;
    /** @maxLength 512 */
    title?: string;
    /**
     * Title [en]
     * @maxLength 512
     */
    titleEn?: string | null;
    /**
     * Title [ne]
     * @maxLength 512
     */
    titleNe?: string | null;
    description?: string;
    /** Description [en] */
    descriptionEn?: string | null;
    /** Description [ne] */
    descriptionNe?: string | null;
    /** @format date */
    dateFrom?: string;
    /** @format date */
    dateTo?: string;
    /**
     * @format decimal
     * @pattern ^-?\d{0,15}(?:\.\d{0,4})?$
     */
    budget?: string;
    /**
     * @format decimal
     * @pattern ^-?\d{0,15}(?:\.\d{0,4})?$
     */
    administrativeBudget?: string;
    /** @format double */
    exchangeRate?: number;
    status?: StatusEnum;
    /** Type */
    projectType?: ProjectTypeEnum;
    /** @maxLength 255 */
    contactName?: string;
    /**
     * @format email
     * @maxLength 254
     */
    contactEmail?: string;
    /** @maxLength 255 */
    contactPhone?: string;
    createdBy?: string | null;
    updatedBy?: string | null;
    location?: number;
    hazards?: number[];
}

export interface PatchedHazard {
    id?: number;
    /** @maxLength 512 */
    title?: string;
    description?: string;
    /**
     * @format uri
     * @pattern (?:svg)$
     */
    icon?: string | null;
    /** Type */
    hazardType?: HazardTypeEnum;
}

export interface PatchedNewOrganizationRequest {
    id?: number;
    status?: string;
    reason?: string | null;
    /**
     * @format uri
     * @pattern (?:pdf)$
     */
    letter?: string | null;
    /** @maxLength 512 */
    title?: string;
    /** @maxLength 10 */
    acronym?: string | null;
    /**
     * @format uri
     * @pattern (?:jpg|jpeg|png|svg)$
     */
    logo?: string | null;
    description?: string;
    /**
     * Organization email address
     * @format email
     * @maxLength 254
     */
    organizationEmail?: string | null;
    /**
     * @format uri
     * @maxLength 200
     */
    organizationWebsite?: string | null;
    /** @maxLength 150 */
    firstName?: string;
    /** @maxLength 150 */
    lastName?: string;
    /**
     * Email address
     * @format email
     * @maxLength 254
     */
    email?: string;
    /** @maxLength 128 */
    phoneNumber?: string | null;
    /** @maxLength 255 */
    designation?: string;
    /** @format uuid */
    identifier?: string;
    organizationType?: number;
}

export interface PatchedPriorityIndicator {
    id?: number;
    /** @format date-time */
    createdAt?: string;
    /** @format date-time */
    modifiedAt?: string;
    /** @maxLength 512 */
    title?: string;
    /** Custom indicator */
    isCustomIndicator?: boolean;
    lft?: number;
    rght?: number;
    treeId?: number;
    level?: number;
    createdBy?: string | null;
    updatedBy?: string | null;
    /** Parent priority indicator */
    parent?: number | null;
}

export interface PatchedPrivateUser {
    /**
     * Required. Length can be between 5 to 30. Letters, digits and ./-/_ only.
     * @minLength 5
     * @maxLength 30
     * @pattern ^[\w.-]+$
     */
    username?: string;
    /** @maxLength 150 */
    firstName?: string;
    /** @maxLength 150 */
    lastName?: string;
    organization?: number | null;
    /** @maxLength 255 */
    designation?: string | null;
    isSuperuser?: boolean;
    permissions?: string;
    password?: string;
    /** @format email */
    email?: string;
    /** @maxLength 128 */
    phoneNumber?: string | null;
}

export interface PatchedProjectDocument {
    id?: number;
    /** @format date-time */
    createdAt?: string;
    /** @format date-time */
    modifiedAt?: string;
    /** @maxLength 512 */
    title?: string;
    description?: string;
    /** @format uri */
    document?: string | null;
    /**
     * @format uri
     * @maxLength 200
     */
    documentUrl?: string | null;
    createdBy?: string | null;
    updatedBy?: string | null;
    project?: number;
    documentType?: number;
}

/**
 * A ModelSerializer that takes additional arguments for
 * "fields", "omit" and "expand" in order to
 * control which fields are displayed, and whether to replace simple
 * values with complex, nested serializations
 */
export interface PatchedProjectDonor {
    id?: number;
    /** @format date-time */
    createdAt?: string;
    /** @format date-time */
    modifiedAt?: string;
    /** @maxLength 512 */
    title?: string;
    description?: string;
    lft?: number;
    rght?: number;
    treeId?: number;
    level?: number;
    createdBy?: string | null;
    updatedBy?: string | null;
    organization?: number;
    /** Parent project donor */
    parent?: number | null;
}

/**
 * A ModelSerializer that takes additional arguments for
 * "fields", "omit" and "expand" in order to
 * control which fields are displayed, and whether to replace simple
 * values with complex, nested serializations
 */
export interface PatchedTarget {
    id?: number;
    /** @format date-time */
    createdAt?: string;
    /** @format date-time */
    modifiedAt?: string;
    /** @maxLength 512 */
    title?: string;
    /** @format double */
    value?: number;
    createdBy?: string | null;
    updatedBy?: string | null;
    activity?: number;
}

export interface PatchedTargetReport {
    id?: number;
    expenseCurrency?: ExpenseCurrencyEnum;
    /** @format date-time */
    createdAt?: string;
    /** @format date-time */
    modifiedAt?: string;
    /** @maxLength 512 */
    title?: string;
    description?: string;
    /** @format double */
    value?: number;
    /** @format date */
    date?: string;
    /**
     * @format decimal
     * @pattern ^-?\d{0,15}(?:\.\d{0,4})?$
     */
    expense?: string;
    /** @format double */
    exchangeRate?: number;
    /** @format uri */
    attachment?: string | null;
    createdBy?: string | null;
    updatedBy?: string | null;
    target?: number;
    organization?: number | null;
}

export interface PinVerify {
    username: string;
    pin: number;
}

export interface PriorityIndicator {
    id: number;
    /** @format date-time */
    createdAt: string;
    /** @format date-time */
    modifiedAt: string;
    /** @maxLength 512 */
    title: string;
    /** Custom indicator */
    isCustomIndicator?: boolean;
    lft: number;
    rght: number;
    treeId: number;
    level: number;
    createdBy: string | null;
    updatedBy: string | null;
    /** Parent priority indicator */
    parent?: number | null;
}

export interface PrivateUser {
    /**
     * Required. Length can be between 5 to 30. Letters, digits and ./-/_ only.
     * @minLength 5
     * @maxLength 30
     * @pattern ^[\w.-]+$
     */
    username: string;
    /** @maxLength 150 */
    firstName?: string;
    /** @maxLength 150 */
    lastName?: string;
    organization?: number | null;
    /** @maxLength 255 */
    designation?: string | null;
    isSuperuser: boolean;
    permissions: string;
    password: string;
    /** @format email */
    email: string;
    /** @maxLength 128 */
    phoneNumber?: string | null;
}

/**
 * A ModelSerializer that takes additional arguments for
 * "fields", "omit" and "expand" in order to
 * control which fields are displayed, and whether to replace simple
 * values with complex, nested serializations
 */
export interface Project {
    id: number;
    /** @format date-time */
    createdAt: string;
    /** @format date-time */
    modifiedAt: string;
    /** @maxLength 512 */
    title: string;
    /**
     * Title [en]
     * @maxLength 512
     */
    titleEn?: string | null;
    /**
     * Title [ne]
     * @maxLength 512
     */
    titleNe?: string | null;
    description?: string;
    /** Description [en] */
    descriptionEn?: string | null;
    /** Description [ne] */
    descriptionNe?: string | null;
    /** @format date */
    dateFrom: string;
    /** @format date */
    dateTo: string;
    budgetCurrency: string;
    /**
     * @format decimal
     * @pattern ^-?\d{0,15}(?:\.\d{0,4})?$
     */
    budget: string;
    administrativeBudgetCurrency: string;
    /**
     * @format decimal
     * @pattern ^-?\d{0,15}(?:\.\d{0,4})?$
     */
    administrativeBudget: string;
    /** @format double */
    exchangeRate: number;
    status?: StatusEnum;
    /** Type */
    projectType?: ProjectTypeEnum;
    /** @maxLength 255 */
    contactName: string;
    /**
     * @format email
     * @maxLength 254
     */
    contactEmail: string;
    /** @maxLength 255 */
    contactPhone: string;
    createdBy: string | null;
    updatedBy: string | null;
    organization: number;
    location: number;
    donor: number | null;
    hazards?: number[];
}

export interface ProjectDocument {
    id: number;
    /** @format date-time */
    createdAt: string;
    /** @format date-time */
    modifiedAt: string;
    /** @maxLength 512 */
    title: string;
    description?: string;
    /** @format uri */
    document?: string | null;
    /**
     * @format uri
     * @maxLength 200
     */
    documentUrl?: string | null;
    createdBy: string | null;
    updatedBy: string | null;
    project: number;
    documentType: number;
}

export interface ProjectDocumentType {
    id: number;
    /** @format date-time */
    createdAt: string;
    /** @format date-time */
    modifiedAt: string;
    /** @maxLength 512 */
    title: string;
    createdBy: string | null;
    updatedBy: string | null;
}

/**
 * A ModelSerializer that takes additional arguments for
 * "fields", "omit" and "expand" in order to
 * control which fields are displayed, and whether to replace simple
 * values with complex, nested serializations
 */
export interface ProjectDonor {
    id: number;
    /** @format date-time */
    createdAt: string;
    /** @format date-time */
    modifiedAt: string;
    /** @maxLength 512 */
    title: string;
    description?: string;
    lft: number;
    rght: number;
    treeId: number;
    level: number;
    createdBy: string | null;
    updatedBy: string | null;
    organization: number;
    /** Parent project donor */
    parent?: number | null;
}

export enum ProjectTypeEnum {
    OnBudget = 'on_budget',
    OffBudget = 'off_budget'
}

/**
 * A ModelSerializer that takes additional arguments for
 * "fields", "omit" and "expand" in order to
 * control which fields are displayed, and whether to replace simple
 * values with complex, nested serializations
 */
export interface Region {
    id: number;
    title: string;
    /** Title [en] */
    titleEn?: string | null;
    /** Title [ne] */
    titleNe?: string | null;
    /** @maxLength 512 */
    code: string;
    envelope?: {
        type?: 'Polygon';
        /** @example [[[0,0],[0,50],[50,50],[50,0],[0,0]]] */
        coordinates?: number[][][];
    } | null;
    pointOnSurface?: {
        type?: 'Point';
        /**
         * @maxItems 3
         * @minItems 2
         * @example [12.9721,77.5933]
         */
        coordinates?: number[];
    } | null;
    lft: number;
    rght: number;
    treeId: number;
    level: number;
    /** Parent region */
    parent?: number | null;
}

export interface RejectClaimOrganization200Response {
    /** @default "Organization claim rejected" */
    detail?: string;
}

export interface RejectClaimOrganization400Response {
    errors: Record<string, string>;
}

export interface RejectNewOrganization200Response {
    /** @default "New organization rejected" */
    detail?: string;
}

export interface RejectNewOrganization400Response {
    errors: Record<string, string>;
}

export interface RejectRequest {
    reason: string;
}

export interface Stats {
    identifier: number | null;
    /**
     * @format decimal
     * @pattern ^-?\d{0,15}(?:\.\d{0,4})?$
     */
    budget: string;
    count: number;
}

export enum StatusEnum {
    Pipeline = 'pipeline',
    Active = 'active',
    Dropped = 'dropped',
    Completed = 'completed'
}

/**
 * A ModelSerializer that takes additional arguments for
 * "fields", "omit" and "expand" in order to
 * control which fields are displayed, and whether to replace simple
 * values with complex, nested serializations
 */
export interface Target {
    id: number;
    /** @format date-time */
    createdAt: string;
    /** @format date-time */
    modifiedAt: string;
    /** @maxLength 512 */
    title: string;
    /** @format double */
    value: number;
    createdBy: string | null;
    updatedBy: string | null;
    activity: number;
}

export interface TargetReport {
    id: number;
    expenseCurrency: ExpenseCurrencyEnum;
    /** @format date-time */
    createdAt: string;
    /** @format date-time */
    modifiedAt: string;
    /** @maxLength 512 */
    title: string;
    description?: string;
    /** @format double */
    value: number;
    /** @format date */
    date: string;
    /**
     * @format decimal
     * @pattern ^-?\d{0,15}(?:\.\d{0,4})?$
     */
    expense: string;
    /** @format double */
    exchangeRate: number;
    /** @format uri */
    attachment?: string | null;
    createdBy: string | null;
    updatedBy: string | null;
    target: number;
    organization?: number | null;
}

export interface TokenObtainPair {
    username: string;
    password: string;
    access: string;
    refresh: string;
}

export interface TokenRefresh {
    access: string;
    refresh: string;
}

export interface TokenVerify {
    token: string;
}

export interface UnReadCountResponse {
    unreadCount: number;
}

export interface UploadFile {
    /** @format uri */
    file: string;
}

export interface UploadFileResponse {
    name: string;
    /** @format uri */
    url: string;
}

export interface User {
    /**
     * Required. Length can be between 5 to 30. Letters, digits and ./-/_ only.
     * @minLength 5
     * @maxLength 30
     * @pattern ^[\w.-]+$
     */
    username: string;
    /** @maxLength 150 */
    firstName?: string;
    /** @maxLength 150 */
    lastName?: string;
    organization?: number | null;
    /** @maxLength 255 */
    designation?: string | null;
}
