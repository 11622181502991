import React, { useMemo, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { format } from 'date-fns';

import Map from 'components/Map';
import Tabs, { Tab } from 'components/Tabs';
import OrganizationList from 'components/OrganizationList';
import TargetTable from 'components/TargetTable';

import cs from '@ra/cs';
import { Localize, _ } from 'services/i18n';
import { formatNumber } from 'utils/formatter';

import useAdministrativeDivisions from 'hooks/useAdministrativeDivisions';
import { useAppSelector } from 'hooks/store';

import type { ExpandedOutput, ExpandedActivity } from 'services/report';
import type { Project } from 'services/types';

import styles from './styles.scss';

interface PriorityIndicatorContentProps {
    id: number;
}

const PriorityIndicatorContent: React.FC<PriorityIndicatorContentProps> = ({ id }) => {
    const { priorityIndicators } = useAppSelector((state) => state.priorityIndicator);

    const priorityActivity = useMemo(() => {
        return priorityIndicators.find((pi) => pi.id === id);
    }, [id, priorityIndicators]);
    const priorityAction = useMemo(() => {
        return priorityIndicators.find((pi) => pi.id === priorityActivity?.parent);
    }, [priorityActivity, priorityIndicators]);
    const priorityArea = useMemo(() => {
        return priorityIndicators.find((pi) => pi.id === priorityAction?.parent);
    }, [priorityAction, priorityIndicators]);

    return (
        <div className={styles.priorityIndicators}>
            <div className={styles.indicatorItem}>
                <p className={styles.indicatorItemTitle}>
                    <Localize>Priority area</Localize>
                </p>
                <p className={styles.indicatorItemValue}>{priorityArea?.title || '-'}</p>
            </div>
            <div className={styles.indicatorItem}>
                <p className={styles.indicatorItemTitle}>
                    <Localize>Priority action</Localize>
                </p>
                <p className={styles.indicatorItemValue}>{priorityAction?.title || '-'}</p>
            </div>
            <div className={styles.indicatorItem}>
                <p className={styles.indicatorItemTitle}>
                    <Localize>Priority activity</Localize>
                </p>
                <p className={styles.indicatorItemValue}>{priorityActivity?.title || '-'}</p>
            </div>
        </div>
    );
};

interface ActivityContentProps {
    activity: ExpandedActivity;
    onReportAdded?: () => void;
    project: Project;
}

const ActivityContent: React.FC<ActivityContentProps> = ({ activity, onReportAdded, project }) => {
    const { regions } = useAppSelector((state) => state.region);
    const { user } = useAppSelector((state) => state.auth);

    const navigate = useNavigate();

    const handleMapLinkClick = useCallback(() => {
        navigate('/map');
    }, [navigate]);

    const focusedRegion = useMemo(() => {
        if (regions.length && activity?.location) {
            return regions.find((reg) => reg.id === activity.location);
        }
        return null;
    }, [regions, activity]);

    const focusedRegionTitle = useMemo(() => {
        if (focusedRegion?.level === 0 || (focusedRegion?.level && focusedRegion.level < 4)) {
            return focusedRegion?.title || '-';
        }
        if (focusedRegion?.level === 4) {
            const parentRegion = regions.find((reg) => reg.id === focusedRegion.parent);
            if (parentRegion) {
                return (parentRegion?.title || '') + ' ' + _('ward') + ' ' + focusedRegion.title;
            }
            return focusedRegion?.title;
        }
        return '-';
    }, [focusedRegion, regions]);

    const divisionLevels = useAdministrativeDivisions();
    const activeDivisionLevel = useMemo(() => {
        if (focusedRegion) {
            return divisionLevels[focusedRegion.level];
        }
        return divisionLevels[1];
    }, [divisionLevels, focusedRegion]);

    const isPartnerUser = useMemo(() => {
        const partners = (activity?.partners || []).concat([project.organization]);
        return Boolean(user.organization && partners.includes(user.organization));
    }, [user, activity]);

    return (
        <>
            <h4 className={styles.activityTitle}>{activity.title}</h4>
            <div className={styles.infoItem}>
                <div className={styles.infoItemIconContainer}>
                    <span className={cs(styles.infoItemIcon, 'material-symbols-rounded')}>
                        payments
                    </span>
                </div>
                <div className={styles.infoItemContent}>
                    <p className={styles.infoItemContentKey}>
                        <Localize>Budget</Localize>
                    </p>
                    <p className={styles.infoItemContentValue}>{`NPR ${
                        formatNumber(activity.budget) || ''
                    }`}</p>
                </div>
            </div>
            <div className={styles.locationContainer}>
                <h5 className={styles.locationTitle}>
                    <Localize>LOCATION</Localize>
                </h5>
                <div className={styles.mapContainer}>
                    <div className={styles.mapHeader}>
                        <p className={styles.mapTitle}>{focusedRegionTitle}</p>
                        <div className={styles.viewMapLink} onClick={handleMapLinkClick}>
                            <span
                                className={cs(styles.viewMapLinkIcon, 'material-symbols-rounded')}
                            >
                                map
                            </span>
                            <span className={styles.viewMapLinkText}>
                                <Localize>View interactive map</Localize>
                            </span>
                        </div>
                    </div>
                    <Map
                        style={{ width: '100%', height: 300 }}
                        hideLegend
                        activeDivisionLevel={activeDivisionLevel}
                        focusedRegion={focusedRegion}
                        showUnfocused
                        focusedRegionPadding={{
                            top: 30,
                            bottom: 30,
                            left: 20,
                            right: 20
                        }}
                    />
                </div>
            </div>
            <h2 className={styles.sectionItemTitle}>
                <Localize>NDRR strategic action plan activities</Localize>
            </h2>
            {activity.priorityIndicators.length > 0 && (
                <Tabs
                    className={styles.priorityIndicatorTabs}
                    headerContainerClassName={styles.priorityTabsHeaderContainer}
                    headerClassName={styles.priorityTabsHeader}
                    tabItemClassName={styles.priorityHeaderTabItem}
                    activeTabItemClassName={styles.priorityHeaderTabItemActive}
                    defaultActiveTab="pi-1"
                >
                    {activity.priorityIndicators.map(
                        (priorityIndicatorId, priorityIndicatorIndex) => (
                            <Tab
                                key={`pi-${priorityIndicatorIndex}`}
                                label={`pi-${priorityIndicatorIndex + 1}`}
                                title={`PRIORITY INDICATOR ${priorityIndicatorIndex + 1}`}
                            >
                                <PriorityIndicatorContent id={priorityIndicatorId} />
                            </Tab>
                        )
                    )}
                </Tabs>
            )}
            <h2 className={styles.sectionItemTitle}>
                <Localize>Partners and targets</Localize>
            </h2>
            <h5 className={styles.subSectionTitle}>
                <Localize>PARTNERS</Localize>
            </h5>
            <div className={styles.subSectionContent}>
                <OrganizationList organizations={(activity?.partners as number[]) || []} />
            </div>
            <h5 className={styles.subSectionTitle}>
                <Localize>TARGETS</Localize>
            </h5>
            <TargetTable
                data={activity.targets}
                onReportAdded={onReportAdded}
                disableAdd={!isPartnerUser}
            />
        </>
    );
};

interface OutputDetailsProps {
    output: ExpandedOutput;
    onReportAdded?: () => void;
    project: Project;
}

const OutputDetails: React.FC<OutputDetailsProps> = ({ output, onReportAdded, project }) => {
    const outputDuration = useMemo(() => {
        if (output) {
            const startDate = new Date(output.dateFrom);
            const endDate = new Date(output.dateTo);
            return `${format(startDate, 'MMM dd, yyyy')} - ${format(endDate, 'MMM dd, yyyy')}`;
        }
        return '-';
    }, [output]);

    return (
        <div className={styles.container}>
            <div className={styles.sectionContent}>
                <h3 className={styles.outputTitle}>{output.title}</h3>
                <div className={styles.infoItem}>
                    <div className={styles.infoItemIconContainer}>
                        <span className={cs(styles.infoItemIcon, 'material-symbols-rounded')}>
                            calendar_month
                        </span>
                    </div>
                    <div className={styles.infoItemContent}>
                        <p className={styles.infoItemContentKey}>
                            <Localize>Duration</Localize>
                        </p>
                        <p className={styles.infoItemContentValue}>{outputDuration}</p>
                    </div>
                </div>
                {output.activities.length > 0 && (
                    <Tabs
                        className={styles.activityTabs}
                        headerContainerClassName={styles.tabsHeaderContainer}
                        headerClassName={styles.tabsHeader}
                        tabItemClassName={styles.headerTabItem}
                        activeTabItemClassName={styles.headerTabItemActive}
                        defaultActiveTab="activity-1"
                    >
                        {output.activities.map((activity, activityIdx) => (
                            <Tab
                                key={`activity-${activityIdx}`}
                                label={`activity-${activityIdx + 1}`}
                                title={`Activity ${activityIdx + 1}`}
                            >
                                <ActivityContent
                                    activity={activity}
                                    onReportAdded={onReportAdded}
                                    project={project}
                                />
                            </Tab>
                        ))}
                    </Tabs>
                )}
            </div>
        </div>
    );
};

export default OutputDetails;
