import { useState, useCallback, useEffect } from 'react';

import OTPInput from 'components/OTPInput';

import cs from '@ra/cs';
import Modal from '@ra/components/Modal';
import withVisibleCheck from '@ra/components/WithVisibleCheck';
import usePromise from '@ra/hooks/usePromise';

import { Localize, _ } from 'services/i18n';
import Api from 'services/api';
import Toast from 'services/toast';

import { AuthModalProps } from '..';

import styles from './styles.scss';

type VerifyEmailProps = AuthModalProps & {
    onComplete: () => void;
    verifyEmailPromise?: (arg: any) => Promise<any>;
    resendCodePromise?: (arg: any) => Promise<any>;
};

const VerifyEmail: React.FC<VerifyEmailProps> = (props) => {
    const {
        email,
        setIdentifier,
        onClose,
        onComplete,
        isNewPassword,
        verifyEmailPromise = Api.passwordResetVerify,
        resendCodePromise = Api.forgotPassword
    } = props;

    const [otpCode, setOtpCode] = useState('');
    const [error, setError] = useState<any>(null);

    const [{ loading }, passwordResetVerify] = usePromise(verifyEmailPromise);
    const [{ resendLoading }, forgotPassword] = usePromise(resendCodePromise);

    const handleOtpChange = useCallback((otp: string) => {
        setError(null);
        setOtpCode(otp);
    }, []);

    const handleVerifyOTP = useCallback(async () => {
        try {
            const result = await passwordResetVerify({
                username: email,
                pin: parseInt(otpCode)
            });
            if (result) {
                setIdentifier(result.identifier);
                onComplete();
            }
        } catch (err) {
            setError(err);
        }
    }, [otpCode, passwordResetVerify, setIdentifier, onComplete, email, otpCode]);

    useEffect(() => {
        if (otpCode.length === 6) {
            setTimeout(handleVerifyOTP, 1000);
        }
    }, [otpCode, handleVerifyOTP]);

    const handleResendEmail = useCallback(async () => {
        try {
            const result = await forgotPassword({
                username: email,
                mailFormat: isNewPassword ? 'new' : 'reset'
            });
            if (result) {
                Toast.show(
                    isNewPassword
                        ? _('OTP sent to your registered email')
                        : `OTP sent to your mail: ${email}`,
                    Toast.SUCCESS
                );
            }
        } catch (err) {
            console.log(err);
            setError(err);
        }
    }, [forgotPassword, email, isNewPassword]);

    return (
        <Modal className={styles.modal}>
            <div className={styles.header}>
                <div className={styles.lockWrapper}>
                    <span className={cs('material-symbols-rounded', styles.lockIcon)}>dialpad</span>
                </div>
                <span
                    onClick={onClose}
                    className={cs('material-symbols-rounded', styles.closeIcon)}
                >
                    close
                </span>
            </div>
            <h2 className={styles.title}>
                {isNewPassword ? (
                    <Localize>Verfiy your email</Localize>
                ) : (
                    <Localize>Enter the code</Localize>
                )}
            </h2>
            <p className={styles.description}>
                {isNewPassword ? (
                    <Localize>
                        You will need to verify your email address in order to set your new
                        password. We have sent a 6-digit code to your registered email address.
                        Please enter the code here:
                    </Localize>
                ) : (
                    <Localize>
                        Please enter the 6 digit code sent to your email:
                        {` ${email}`}
                    </Localize>
                )}
            </p>
            {loading && (
                <p className={styles.description}>
                    <Localize>Loading ....</Localize>
                </p>
            )}
            <div className={styles.formWrapper}>
                <div className={styles.inputWrapper}>
                    <OTPInput
                        autoFocus
                        isNumberInput
                        length={6}
                        className={styles.otpContainer}
                        inputClassName={cs(styles.otpInput, {
                            [styles.otpInputError]: error
                        })}
                        onChangeOTP={handleOtpChange}
                    />
                </div>
            </div>
            <div className={styles.resendButton}>
                <span className={styles.message}>
                    <Localize>Didn’t receive? </Localize>
                </span>
                <span className={styles.link} onClick={handleResendEmail}>
                    <Localize>{resendLoading ? 'Sending ....' : 'Send Again'}</Localize>
                </span>
            </div>
        </Modal>
    );
};

export default withVisibleCheck(VerifyEmail);
