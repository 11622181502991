import Input from 'components/Input';

import cs from '@ra/cs';

import styles from './styles.scss';

const AuthInput = ({
    inputContainerClassName,
    label,
    inputType = 'text',
    placeholder,
    ...otherProps
}: {
    inputContainerClassName?: string;
    label: string;
    inputType?: string;
    placeholder: string;
    [key: string]: any;
}) => {
    return (
        <div className={cs(styles.authInput, inputContainerClassName)}>
            <label className={styles.inputLabel}>{label}</label>
            <Input
                className={styles.input}
                type={inputType}
                placeholder={placeholder}
                {...otherProps}
            />
        </div>
    );
};

export default AuthInput;
