import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';

import { useAppSelector } from 'hooks/store';

const PrivateRoute: React.FC<{
    children: React.ReactNode;
}> = ({ children }) => {
    const { isAuthenticated } = useAppSelector((state) => state.auth);

    const location = useLocation();

    if (!isAuthenticated) {
        return <Navigate to="/login" state={{ from: location.pathname }} />;
    }

    return <>{children}</>;
};

export default PrivateRoute;
