import React, { useMemo, useCallback } from 'react';
import SVG from 'react-inlinesvg';

import List, { ListRenderItem, ListRenderItemProps, KeyExtractor } from '@ra/components/List';

import cs from '@ra/cs';
import { Localize } from 'services/i18n';

import { useFilters, useFiltersDispatch } from 'hooks/filters';
import { useAppSelector } from 'hooks/store';

import type { Hazard } from 'services/types';

import styles from './styles.scss';

interface HazardItemProps extends ListRenderItemProps<Hazard> {
    filterType?: string;
}

export const keyExtractor: KeyExtractor<Hazard> = (item) => item?.id;

export const HazardItem: React.FC<HazardItemProps> = (props) => {
    const { item, filterType } = props;

    const dispatch = useFiltersDispatch();
    const filters = useFilters();

    const isActive = useMemo(() => {
        return filters.some((el) => el.filterType === filterType && el.filterValue === item.title);
    }, [filterType, filters]);

    const handleChange = useCallback(() => {
        const filter = {
            filterType: filterType as string,
            filterValue: item.title,
            query: { hazards__in: item.id }
        };
        const actionType: 'add' | 'remove' = isActive ? 'remove' : 'add';
        dispatch({ type: actionType, filter });
    }, [dispatch, item, isActive, filterType]);

    return (
        <div
            className={cs(styles.hazardItem, {
                [styles.hazardItemActive]: isActive,
                [styles.hazardItemDisabled]: !filterType
            })}
            onClick={filterType ? handleChange : undefined}
        >
            {Boolean(item.icon) && (
                <SVG
                    className={cs(styles.hazardItemIcon, {
                        [styles.hazardItemIconActive]: isActive
                    })}
                    src={item.icon as string}
                />
            )}
            <span className={styles.hazardItemText}>{item.title}</span>
        </div>
    );
};

interface FilterHazardsProps {
    filterType: string;
    className?: string;
    hideTitle?: boolean;
}

const FilterHazards: React.FC<FilterHazardsProps> = (props) => {
    const { className, filterType, hideTitle } = props;

    const { hazards } = useAppSelector((state) => state.hazard);

    const dispatch = useFiltersDispatch();
    const filters = useFilters();

    const hazardFilters = useMemo(() => {
        return filters.filter((fil) => fil.filterType === filterType);
    }, [filters, filterType]);

    const renderHazardItem: ListRenderItem<Hazard> = useCallback(
        (listProps) => {
            return <HazardItem {...listProps} filterType={filterType} />;
        },
        [filterType]
    );

    const handleClearFilters = useCallback(() => {
        dispatch({
            type: 'clear',
            category: filterType
        });
    }, [dispatch, filterType]);

    return (
        <div className={styles.container}>
            {!hideTitle && (
                <div className={styles.header}>
                    <p className={styles.title}>
                        <Localize>Hazards</Localize>
                    </p>
                    {hazardFilters.length > 0 && (
                        <p className={styles.clearLink} onClick={handleClearFilters}>
                            <Localize>Clear</Localize>
                        </p>
                    )}
                </div>
            )}
            <List
                className={className}
                data={hazards}
                renderItem={renderHazardItem}
                keyExtractor={keyExtractor}
            />
        </div>
    );
};

export default FilterHazards;
