import React, { useCallback } from 'react';
import SVG from 'react-inlinesvg';

import Container from 'components/Container';

import { stepsData } from 'services/data';

import cs from '@ra/cs';
import { Localize } from '@ra/components/I18n';
import List from '@ra/components/List';

import { formatNumber } from 'utils/formatter';

import BlueArrowIcon from 'assets/icons/blue-arrow.svg';

import styles from './styles.scss';

const keyExtractor = (item: { id: number }) => item.id;

const StepCard = ({
    item
}: {
    item: {
        position: string;
        icon: string;
        title: string;
        subTitle: string;
        points: { title: string };
    };
}) => {
    const renderItems = useCallback(({ item }: { item: { title: string } }) => {
        return (
            <div className={styles.pointItem}>
                <SVG className={styles.pointListIcon} src={BlueArrowIcon}></SVG>
                <p className={styles.pointListTitle}>
                    <Localize>{item.title}</Localize>
                </p>
            </div>
        );
    }, []);
    return (
        <div className={styles.stepItem}>
            <p className={styles.position}>
                {formatNumber(item.position, { minimumIntegerDigits: 2 })}
            </p>
            <div className={styles.content}>
                <div className={styles.titleGroup}>
                    <span className={cs('material-symbols-rounded', styles.stepIcon)}>
                        {item.icon}
                    </span>
                    <h3 className={styles.title}>
                        <Localize>{item.title}</Localize>
                    </h3>
                </div>
                <p className={styles.subTitle}>
                    <Localize>{item.subTitle}</Localize>
                </p>
                <List
                    className={styles.point}
                    keyExtractor={keyExtractor}
                    data={item.points}
                    renderItem={renderItems}
                />
            </div>
        </div>
    );
};

const StepSection: React.FC = () => {
    const renderItems = useCallback(
        ({
            item
        }: {
            item: {
                position: string;
                icon: string;
                title: string;
                subTitle: string;
                points: { title: string };
            };
        }) => {
            return <StepCard item={item} />;
        },
        []
    );
    return (
        <section className={styles.stepsSection}>
            <Container jumbotron>
                <h1 className={styles.sectionTitle}>
                    <Localize>Steps in Project Creation</Localize>
                </h1>
                <p className={styles.sectionDesc}>
                    <Localize>
                        After organization and it's user is verified and provided access by admin
                        they can
                    </Localize>
                </p>
                <List
                    className={styles.stepsList}
                    keyExtractor={keyExtractor}
                    data={stepsData}
                    renderItem={renderItems}
                />
            </Container>
        </section>
    );
};

export default StepSection;
