import { isEmpty } from 'lodash';

import store from 'store';

import type { Organization } from 'services/types';

type FormatOptions = { [key: string]: string | number };

export const formatCurrency = (value = 0, options = {}, locale = 'en-IN', language?: string) => {
    const {
        locale: { currentLanguage }
    } = store.getState();

    const formatOptions: FormatOptions = {
        style: 'currency',
        currency: 'NPR',
        currencyDisplay: 'narrowSymbol',
        maximumSignificantDigits: 2,
        maximumFractionDigits: 2,
        notation: 'compact',
        ...options
    };
    if (currentLanguage === 'NEP' || language === 'NEP') {
        formatOptions.numberingSystem = 'deva';
    }

    const nFormat = new Intl.NumberFormat(
        currentLanguage === 'NEP' ? 'hi-IN' : locale,
        formatOptions
    );

    if (currentLanguage === 'ENG' && value > 999999999) {
        const valueOverArba = value / 1000000000;
        if (valueOverArba < 100) {
            return `Rs. ${valueOverArba.toPrecision(2)}Ar`;
        } else if (valueOverArba < 10000) {
            return `Rs. ${(valueOverArba / 100).toPrecision(2)}Kh`;
        }
        return `Rs. ${valueOverArba.toFixed(0)}Kh`;
    }

    const formattedValue = nFormat.format(value);
    if (currentLanguage === 'NEP' || language === 'NEP') {
        return formattedValue.replace('Rs', 'रु.');
    }
    return formattedValue.replace('Rs', 'Rs.');
};

export const formatNumber = (
    value: any,
    options: FormatOptions = {},
    locale = 'en-IN',
    language?: string
) => {
    const {
        locale: { currentLanguage }
    } = store.getState();

    if (isNaN(value)) {
        return value;
    }

    if (currentLanguage === 'NEP' || language === 'NEP') {
        options.numberingSystem = 'deva';
    }

    const formattedValue = new Intl.NumberFormat(
        currentLanguage === 'NEP' ? 'hi-IN' : locale,
        options
    ).format(value);

    return formattedValue;
};

export const formatDate = (
    date?: any,
    options: FormatOptions = {},
    locale = 'en-IN',
    language?: string
) => {
    const {
        locale: { currentLanguage }
    } = store.getState();

    if (date instanceof Date) {
        const formatOptions: FormatOptions = {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            ...options
        };

        if (currentLanguage === 'NEP' || language === 'NEP') {
            formatOptions.numberingSystem = 'deva';
        }
        const formattedParts = new Intl.DateTimeFormat(locale, formatOptions).formatToParts(date);
        const year = formattedParts.find((fp) => fp.type === 'year')?.value;
        const month = formattedParts.find((fp) => fp.type === 'month')?.value;
        const day = formattedParts.find((fp) => fp.type === 'day')?.value;
        return `${year}-${month}-${day}`;
    }
    return date;
};

export const formatFileSize = (bytes?: number) => {
    if (!bytes) {
        return '0.00 B';
    }
    const e = Math.floor(Math.log(bytes) / Math.log(1024));
    return ((bytes / Math.pow(1024, e)).toFixed(2) + ' ' + ' KMGTP'.charAt(e) + 'B').replace(
        '  ',
        ' '
    );
};

export const camelCaseToSnakeCase = (str: string) => {
    return str.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);
};

export const formatOrganizationTitle = (organization: Organization, maxChars = 25) => {
    if (!organization) {
        return '';
    }
    if (organization.title.length <= maxChars) {
        return organization.title;
    }
    if (organization.acronym) {
        return organization.acronym;
    }
    return organization.title.substring(0, maxChars) + '...';
};

export const formatLongTitle = (title: string, maxLength = 120) => {
    if (!title) {
        return '';
    }
    if (title.length <= maxLength) {
        return title;
    }
    return title.substring(0, maxLength - 3) + '...';
};

export const formatLongFileName = (fileName?: string, maxLength = 60) => {
    if (!fileName) {
        return '';
    }
    if (fileName.length <= maxLength) {
        return fileName;
    }
    const halfCutLength = Math.floor(maxLength / 2 - 2);
    return (
        fileName.substring(0, halfCutLength) +
        '...' +
        fileName.substring(fileName.length - halfCutLength)
    );
};

export const parseErrorObject = (error: any) => {
    let path = '';
    const errorObj: { [key: string]: string } = {};
    function traverseErrorPath(obj: any) {
        const objectEntries = Object.entries(obj);
        objectEntries.forEach(([key, value]) => {
            path += key;
            if (typeof value === 'string') {
                errorObj[path] = value;
                path = '';
                return;
            }
            if (Array.isArray(value)) {
                value.forEach((obj, idx) => {
                    if (isNaN(obj) && typeof obj === 'string') {
                        errorObj[path] = obj;
                        path = '';
                        return;
                    }
                    if (obj && !isEmpty(obj)) {
                        path += `[${idx}]`;
                        traverseErrorPath(obj);
                    }
                });
            } else if (value) {
                traverseErrorPath(value);
            }
        });
        return errorObj;
    }
    traverseErrorPath(error);
    return errorObj;
};
