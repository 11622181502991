import React, { useCallback, useState, useEffect } from 'react';

import cs from '@ra/cs';
import useSize from '@ra/hooks/useSize';
import { useFilters } from 'hooks/filters';

import styles from './styles.scss';

interface FilterProps {
    children: React.ReactNode;
    className?: string;
}

const Filters = React.forwardRef<HTMLDivElement, FilterProps>((props, ref) => {
    const { children, className } = props;

    const filters = useFilters();

    const [showLeftArrow, setShowLeftArrow] = useState<boolean>(false);
    const [showRightArrow, setShowRightArrow] = useState<boolean>(false);

    const { width } = useSize();

    const scrollHandler = useCallback((target: HTMLDivElement) => {
        if (target.scrollLeft > 5) {
            setShowLeftArrow(true);
        } else {
            setShowLeftArrow(false);
        }
        if (target.scrollLeft + target.clientWidth - target.scrollWidth < -5) {
            setShowRightArrow(true);
        } else {
            setShowRightArrow(false);
        }
    }, []);

    const handleScroll: React.UIEventHandler<HTMLDivElement> = useCallback(
        (e) => {
            const target = e.target as HTMLDivElement;
            scrollHandler(target);
        },
        [scrollHandler]
    );

    useEffect(() => {
        const targetRef = ref as React.RefObject<HTMLDivElement>;
        if (targetRef?.current) {
            scrollHandler(targetRef.current as HTMLDivElement);
        }
    }, [width, filters]);

    const handleScrollLeft: React.MouseEventHandler<HTMLDivElement> = useCallback(() => {
        const targetRef = ref as React.RefObject<HTMLDivElement>;
        if (targetRef?.current) {
            targetRef.current.scrollLeft = Math.max(0, targetRef.current.scrollLeft - 80);
        }
    }, []);

    const handleScrollRight: React.MouseEventHandler<HTMLDivElement> = useCallback(() => {
        const targetRef = ref as React.RefObject<HTMLDivElement>;
        if (targetRef?.current) {
            targetRef.current.scrollLeft = Math.min(
                targetRef.current.scrollWidth - targetRef.current.clientWidth,
                targetRef.current.scrollLeft + 80
            );
        }
    }, []);

    return (
        <div className={styles.contentContainer}>
            <div ref={ref} className={cs(styles.container, className)} onScroll={handleScroll}>
                {showLeftArrow && (
                    <div className={styles.leftArrow}>
                        <div className={styles.leftArrowContent} onClick={handleScrollLeft}>
                            <span className="material-symbols-rounded">chevron_left</span>
                        </div>
                    </div>
                )}
                {children}
                {showRightArrow && (
                    <div className={styles.rightArrow}>
                        <div className={styles.rightArrowContent} onClick={handleScrollRight}>
                            <span className="material-symbols-rounded">chevron_right</span>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
});

export default Filters;
