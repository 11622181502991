import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { Link } from 'react-router-dom';

import Input from 'components/Input';
import List, { ListRenderItem, ListRenderItemProps, KeyExtractor } from '@ra/components/List';
import Modal from '@ra/components/Modal';
import { OrganizationLogo } from 'components/OrganizationList';

import cs from '@ra/cs';
import Api from 'services/api';
import { _, Localize } from 'services/i18n';
import usePromise from '@ra/hooks/usePromise';
import { debounce } from '@ra/utils';
import { useAppSelector } from 'hooks/store';

import type { Project, Organization } from 'services/types';

import styles from './styles.scss';

type SearchBarProps = {
    className?: string;
    disabled?: boolean;
};

const KeyCodes = {
    K: 75
};

const keyExtractor: KeyExtractor<Project> = (item) => item.id;

interface ProjectItemProps extends ListRenderItemProps<Project> {
    onProjectClick?: () => void;
}

const ProjectItem: React.FC<ProjectItemProps> = (listProps) => {
    const { item, onProjectClick } = listProps;

    const { organizations } = useAppSelector((state) => state.organization);

    const organizationObj = useMemo(() => {
        return organizations.find((org) => org.id === item.organization);
    }, [organizations]);

    const statusData: { [key: string]: string } = useMemo(
        () => ({
            pipeline: _('Pipeline'),
            active: _('Active'),
            completed: _('Completed'),
            dropped: _('Dropped')
        }),
        []
    );

    return (
        <Link
            to={`/projects/${item.id}`}
            state={{ project: item }}
            className={styles.projectItemContainer}
            onClick={onProjectClick}
        >
            <div className={styles.projectItem}>
                <div className={styles.logoContainer}>
                    <OrganizationLogo
                        className={styles.logo}
                        iconClassName={styles.logoPlaceholder}
                        organization={organizationObj as Organization}
                    />
                </div>
                <div className={styles.details}>
                    <p className={styles.projectTitle}>
                        <Localize dataKey="title">{item}</Localize>
                    </p>
                    <div className={styles.projectInfo}>
                        <span className={styles.organizationName}>{organizationObj?.title} •</span>
                        <div className={styles.status}>
                            <div
                                className={cs(styles.statusIndicator, {
                                    [styles.statusIndicatorActive]:
                                        statusData[item.status as string] === _('Active'),
                                    [styles.statusIndicatorCompleted]:
                                        statusData[item.status as string] === _('Completed'),
                                    [styles.statusIndicatorDropped]:
                                        statusData[item.status as string] === _('Dropped')
                                })}
                            />
                            <span className={styles.statusText}>
                                {statusData[item.status as string]}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </Link>
    );
};

const SearchBar: React.FC<SearchBarProps> = (props) => {
    const { className, disabled } = props;

    const [showSearchModal, setShowSearchModal] = useState(false);

    const [{ result, loading, error }, searchProjects] = usePromise(Api.getProjects);

    const [searchQuery, setSearchQuery] = useState<string>('');

    const handleSearch = useCallback(debounce(searchProjects, 500), [searchProjects]);

    useEffect(() => {
        if (searchQuery) {
            handleSearch({ limit: 5, title__icontains: searchQuery });
        }
    }, [searchQuery, searchProjects]);

    const searchResultProjects = useMemo(() => {
        return result?.results || [];
    }, [result]);

    const handleSearchClick = useCallback(() => setShowSearchModal(true), []);
    const handleCloseSearchModal = useCallback(() => setShowSearchModal(false), []);

    const handleKeyDown = useCallback(
        (e: KeyboardEvent) => {
            if (!disabled) {
                const keyCode = e.which || e.keyCode;
                if (keyCode === KeyCodes.K && e.metaKey) {
                    setShowSearchModal(true);
                }
            }
        },
        [disabled]
    );

    const handleQueryChange = useCallback((target: HTMLInputElement) => {
        setSearchQuery(target.value);
    }, []);

    useEffect(() => {
        document.addEventListener('keydown', handleKeyDown);
        return () => document.removeEventListener('keydown', handleKeyDown);
    }, [handleKeyDown]);

    const renderProjectItem: ListRenderItem<Project> = useCallback(
        (listProps) => <ProjectItem {...listProps} onProjectClick={handleCloseSearchModal} />,
        [handleCloseSearchModal]
    );

    return (
        <>
            <div className={cs(styles.searchBar, className)} onClick={handleSearchClick}>
                <span className={cs('material-symbols-rounded', styles.searchIcon)}>search</span>
            </div>
            <Modal
                className={styles.modal}
                isVisible={showSearchModal}
                closeOnEscape
                closeOnOutsideClick
                onClose={handleCloseSearchModal}
            >
                <div className={styles.modalContent}>
                    <Input
                        containerClassName={styles.searchInputContainer}
                        className={styles.searchInput}
                        type="search"
                        leftIcon="search"
                        onChange={handleQueryChange}
                        placeholder={_('Search projects')}
                    />
                    <div className={styles.searchResult}>
                        {searchQuery ? (
                            <List
                                className={styles.results}
                                data={searchResultProjects}
                                keyExtractor={keyExtractor}
                                renderItem={renderProjectItem}
                                EmptyComponent={
                                    (!result && !error) || loading ? (
                                        <p>Loading...</p>
                                    ) : (
                                        <div className={styles.noResults}>
                                            <p className={styles.noResultsTitle}>
                                                <Localize>No results found</Localize>
                                            </p>
                                            <p className={styles.noResultsText}>
                                                <Localize>
                                                    You may try searching something else
                                                </Localize>
                                            </p>
                                        </div>
                                    )
                                }
                            />
                        ) : (
                            <p className={styles.emptyText}>
                                <Localize>
                                    Type a keyword and we'll search through projects and
                                    organizations
                                </Localize>
                            </p>
                        )}
                    </div>
                </div>
            </Modal>
        </>
    );
};

export default SearchBar;
