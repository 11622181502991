import React, { useCallback, useState, useEffect } from 'react';

import Input from 'components/Input';
import CheckboxInput from '@ra/components/Form/CheckboxInput';
import SelectInput, { type SelectInputChangeCallback } from '@ra/components/Form/SelectInput';
import Label from '@ra/components/Form/Label';

import cs from '@ra/cs';
import { _, Localize } from 'services/i18n';
import { getPriorityIndicators } from 'services/bootstrap';
import { useAppSelector } from 'hooks/store';
import {
    selectPriorityAreas,
    selectPriorityActions,
    selectPriorityActivities
} from 'store/selectors/priorityIndicator';
import Toast from 'services/toast';

import type { PriorityIndicator } from 'services/types';

import styles from './styles.scss';

const titleExtractor = (item: PriorityIndicator) => item.title;
const idExtractor = (item: PriorityIndicator) => item.id;

interface PriorityIndicatorInputProps {
    name?: string;
    onChange: (payload: { name?: string; value: string | number | null }) => void;
    showRequired?: boolean;
    defaultValue?: string | number;
}

const PriorityIndicatorInput: React.FC<PriorityIndicatorInputProps> = (props) => {
    const { name, onChange, showRequired, defaultValue } = props;

    const [isCustom, setCustom] = useState<boolean>(false);

    const { priorityIndicators } = useAppSelector((state) => state.priorityIndicator);

    const priorityAreas = useAppSelector((state) => selectPriorityAreas(state));
    const [activePriorityArea, setActivePriorityArea] = useState<
        PriorityIndicator | null | undefined
    >();

    const priorityActions = useAppSelector((state) =>
        selectPriorityActions(state, activePriorityArea?.id)
    );
    const [activePriorityAction, setActivePriorityAction] = useState<
        PriorityIndicator | null | undefined
    >();

    const priorityActivities = useAppSelector((state) =>
        selectPriorityActivities(state, activePriorityAction?.id)
    );
    const [activePriorityActivity, setActivePriorityActivity] = useState<
        PriorityIndicator | undefined | null
    >();

    useEffect(() => {
        if (!priorityAreas.length) {
            getPriorityIndicators();
        }
    }, []); // Deliberately kept empty

    useEffect(() => {
        if (defaultValue) {
            const priorityActivity = priorityIndicators.find(
                (pIndicator) => pIndicator.id === Number(defaultValue)
            );
            if (priorityActivity) {
                const newActivePriorityArea = priorityIndicators.find(
                    (pIndicator) =>
                        pIndicator.level === 0 && pIndicator.treeId === priorityActivity.treeId
                );
                const newActivePriorityAction = priorityIndicators.find(
                    (pIndicator) => pIndicator.id === priorityActivity.parent
                );
                setActivePriorityArea(newActivePriorityArea);
                setActivePriorityAction(newActivePriorityAction);
                setActivePriorityActivity(priorityActivity);
                return;
            }
            setActivePriorityArea(null);
            setActivePriorityAction(null);
            setActivePriorityActivity(null);
        }
    }, [defaultValue, priorityIndicators]);

    const handlePriorityAreaChange: SelectInputChangeCallback<PriorityIndicator> = useCallback(
        ({ option }) => {
            if (option) {
                setActivePriorityArea(option as PriorityIndicator);
                setActivePriorityAction(null);
                setActivePriorityActivity(null);
            }
        },
        [name]
    );

    const handlePriorityActionChange: SelectInputChangeCallback<PriorityIndicator> = useCallback(
        ({ option }) => {
            setActivePriorityAction(option);
            setActivePriorityActivity(null);
        },
        [name]
    );

    const handlePriorityActivityChange: SelectInputChangeCallback<PriorityIndicator> = useCallback(
        ({ option }) => {
            setActivePriorityActivity(option);
            if (option) {
                return onChange({ name, value: option.id });
            }
            if (!option) {
                onChange({ name, value: null });
            }
        },
        [onChange, name, activePriorityAction]
    );

    const handleChange = useCallback(
        (target: HTMLInputElement) => {
            if (!activePriorityAction) {
                return Toast.show(
                    _('Please select priority action to add custom indicator'),
                    Toast.DANGER
                );
            }
            if (target.checked) {
                onChange({ name, value: null });
                return setCustom(true);
            }
            onChange({ name, value: activePriorityActivity?.id || null });
            setCustom(false);
        },
        [activePriorityAction, onChange]
    );

    const handleCustomTitleChange = useCallback(
        (target: HTMLInputElement) => {
            if (target.value && activePriorityAction) {
                const value = {
                    title: target.value,
                    isCustomIndicator: true,
                    parent: activePriorityAction?.id
                };
                return onChange({ name, value: JSON.stringify(value) });
            }
            onChange({ name, value: null });
        },
        [onChange, activePriorityAction]
    );

    return (
        <div className={styles.container}>
            <div className={styles.inputContainer}>
                <Label className={styles.label}>
                    <Localize>Priority Area*</Localize>
                </Label>
                <SelectInput
                    keyExtractor={idExtractor}
                    placeholder={_('Select priority area')}
                    onChange={handlePriorityAreaChange}
                    valueExtractor={titleExtractor}
                    options={priorityAreas}
                    clearable={false}
                    className={styles.select}
                    optionsWrapperClassName={styles.optionsWrapper}
                    selectOptionClassName={styles.selectOptionStyle}
                    optionItemClassName={styles.optionItemStyle}
                    controlClassName={styles.selectControl}
                    showRequired={showRequired}
                    defaultValue={activePriorityArea}
                />
            </div>
            <div className={styles.inputContainer}>
                <Label className={styles.label}>
                    <Localize>Priority Action*</Localize>
                </Label>
                <SelectInput
                    keyExtractor={idExtractor}
                    placeholder={_('Select priority action')}
                    onChange={handlePriorityActionChange}
                    valueExtractor={titleExtractor}
                    options={priorityActions}
                    disabled={!activePriorityArea}
                    className={cs(styles.select, {
                        [styles.selectDisabled]: !activePriorityArea
                    })}
                    clearable={false}
                    optionsWrapperClassName={styles.optionsWrapper}
                    selectOptionClassName={styles.selectOptionStyle}
                    optionItemClassName={styles.optionItemStyle}
                    controlClassName={styles.selectControl}
                    defaultValue={activePriorityAction}
                    showRequired={showRequired}
                />
            </div>
            <div className={styles.inputContainer}>
                <div className={styles.inputHeader}>
                    <Label className={styles.label}>
                        <Localize>Priority Activity*</Localize>
                    </Label>
                    <div className={styles.customCheckboxContainer}>
                        <CheckboxInput
                            id={`customCheckbox-${name}`}
                            size="1em"
                            checkboxClassName={styles.checkbox}
                            onChange={handleChange}
                            checked={isCustom}
                        />
                        <label htmlFor={`customCheckbox-${name}`} className={styles.customLabel}>
                            <Localize>Custom Indicator</Localize>
                        </label>
                    </div>
                </div>
                {isCustom ? (
                    <Input
                        placeholder="Enter custom priority indicator title"
                        disabled={!activePriorityAction}
                        className={cs({ [styles.customInputEnabled]: isCustom })}
                        onChange={handleCustomTitleChange}
                    />
                ) : (
                    <SelectInput
                        keyExtractor={idExtractor}
                        placeholder={_('Select priority activity')}
                        onChange={handlePriorityActivityChange}
                        valueExtractor={titleExtractor}
                        options={priorityActivities}
                        disabled={!activePriorityAction}
                        className={cs(styles.select, {
                            [styles.selectDisabled]: !activePriorityAction
                        })}
                        clearable={false}
                        optionsWrapperClassName={styles.optionsWrapper}
                        selectOptionClassName={styles.selectOptionStyle}
                        optionItemClassName={styles.optionItemStyle}
                        controlClassName={styles.selectControl}
                        defaultValue={activePriorityActivity}
                        showRequired={showRequired}
                    />
                )}
            </div>
        </div>
    );
};

export default PriorityIndicatorInput;
