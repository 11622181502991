import React, { useCallback, Dispatch } from 'react';

import type { AuthModalsActionType } from 'hooks/useAuthModals';

import ConfirmPassword from './ConfirmPassword';
import SendEmail from './SendEmail';
import VerifyEmail from './VerifyEmail';
import ResetPassword from './ResetPassword';

export interface AuthModalProps {
    modals: {
        showConfirmPasswordModal: boolean;
        showSendEmailModal: boolean;
        showVerifyEmailModal: boolean;
        showResetPasswordModal: boolean;
    };
    loading?: boolean;
    defaultEmail?: string;
    email: string;
    setEmail: (email: string) => void;
    identifier: string;
    setIdentifier: (identifier: string) => void;
    onClose?: React.MouseEventHandler | any;
    onResetPassword?: () => void;
    onConfirmPassword?: (password: string) => void;
    dispatch: Dispatch<AuthModalsActionType>;
    verifyEmailPromise?: (arg: any) => Promise<any>;
    resendCodePromise?: (arg: any) => Promise<any>;
    isNewPassword?: boolean;
    onVerifyEmail?: () => void;
}

const AuthModals: React.FC<AuthModalProps> = (props) => {
    const {
        modals: {
            showConfirmPasswordModal,
            showSendEmailModal,
            showVerifyEmailModal,
            showResetPasswordModal
        },
        loading,
        defaultEmail,
        email,
        setEmail,
        identifier,
        setIdentifier,
        dispatch,
        onConfirmPassword,
        onResetPassword,
        verifyEmailPromise,
        resendCodePromise,
        onVerifyEmail,
        isNewPassword,
        onClose
    } = props;

    const hideModals = useCallback(() => {
        dispatch({ type: 'hideModals' });
        onClose?.();
    }, [dispatch, onClose]);

    const handleShowVerifyEmail = useCallback(
        () => dispatch({ type: 'showVerifyEmail' }),
        [dispatch]
    );
    const handleShowResetPassword = useCallback(
        () => dispatch({ type: 'showResetPassword' }),
        [dispatch]
    );

    return (
        <>
            <SendEmail
                isVisible={showSendEmailModal}
                onClose={hideModals}
                onShowVerifyEmail={handleShowVerifyEmail}
                defaultEmail={defaultEmail}
                setEmail={setEmail}
                isNewPassword={isNewPassword}
            />
            <VerifyEmail
                isVisible={showVerifyEmailModal}
                isNewPassword={isNewPassword}
                email={defaultEmail || email}
                setIdentifier={setIdentifier}
                onClose={hideModals}
                onComplete={onVerifyEmail ?? handleShowResetPassword}
                verifyEmailPromise={verifyEmailPromise}
                resendCodePromise={resendCodePromise}
            />
            <ResetPassword
                isNewPassword={isNewPassword}
                isVisible={showResetPasswordModal}
                onClose={hideModals}
                onComplete={onResetPassword ?? hideModals}
                email={defaultEmail || email}
                identifier={identifier}
            />
            <ConfirmPassword
                isVisible={showConfirmPasswordModal}
                onClose={hideModals}
                onSubmit={onConfirmPassword}
                loading={loading}
            />
        </>
    );
};

export default AuthModals;
