import RequestBuilder from '@ra/services/request';

import { _ } from './i18n';
import store from 'store';

import * as authActions from 'store/slices/auth';

export const dispatch = store.dispatch;

type QueryType = {
    [key: string]: string | number;
};

const TokenInterceptor = (req: Request) => {
    const {
        auth: { token }
    } = store.getState();
    if (token && !req.url.includes('jwt/refresh')) {
        req.headers.append('Authorization', `Bearer ${token}`);
    }
};

const RefreshTokenInterceptor = async (res: Response) => {
    const {
        auth: { isAuthenticated, refreshToken }
    } = store.getState();
    if (isAuthenticated && refreshToken && res.status === 403) {
        await ApiService.refreshToken(refreshToken);
    }
};

const apiVersion = process.env.REACT_APP_API_VERSION || 'v1';
export const request = new RequestBuilder(process.env.REACT_APP_API_BASE_URL)
    .setRequestInterceptors([TokenInterceptor, console.log])
    .setResponseInterceptors([RefreshTokenInterceptor, console.log])
    .setRetryConfig({ backoffFactor: 0, maxRetries: 2 })
    .build();

class Api {
    async get(url: string, options?: any) {
        const { error, data, response } = await request(`/api/${apiVersion}${url}`, options);
        if (error) {
            if (response.status === 500) {
                throw new Error(_('500 Internal Server Error'));
            }
            console.log(data);
            throw data || _('Request Error');
        }
        return data;
    }

    async post(url: string, body?: any, options?: any) {
        const headers = options?.headers || {
            'content-type': 'application/json'
        };
        const { error, data, response } = await request(`/api/${apiVersion}${url}`, {
            method: 'POST',
            headers,
            body: options?.headers ? body : JSON.stringify(body)
        });
        if (error) {
            if (response.status === 500) {
                throw new Error(_('500 Internal Server Error'));
            }
            console.log(data);
            throw data || 'Request Error';
        }
        return data;
    }

    async patch(url: string, body?: any, options?: any) {
        const headers = options?.headers || {
            'content-type': 'application/json'
        };
        const { error, data, response } = await request(`/api/${apiVersion}${url}`, {
            method: 'PATCH',
            headers,
            body: options?.headers ? body : JSON.stringify(body)
        });
        if (error) {
            if (response.status === 500) {
                throw new Error(_('500 Internal Server Error'));
            }
            console.log(data);
            throw data || _('Request Error');
        }
        return data;
    }

    async put(url: string, body?: any, options?: any) {
        const headers = options?.headers || {
            'content-type': 'application/json'
        };
        const { error, data, response } = await request(`/api/${apiVersion}${url}`, {
            method: 'PUT',
            headers,
            body: options?.headers ? body : JSON.stringify(body)
        });
        if (error) {
            if (response.status === 500) {
                throw new Error(_('500 Internal Server Error'));
            }
            console.log(data);
            throw data || _('Request Error');
        }
        return data;
    }

    async delete(url: string) {
        const { error, data, response } = await request(`/api/${apiVersion}${url}`, {
            method: 'DELETE'
        });
        if (error) {
            if (response.status === 500) {
                throw new Error(_('500 Internal Server Error'));
            }
            console.log(data);
            throw data || _('Request Error');
        }
        return data;
    }

    async refreshToken(refreshToken: string) {
        try {
            const data = await this.post('/jwt/refresh/', {
                refresh: refreshToken
            });
            if (data?.access) {
                return dispatch(authActions.setToken(data.access));
            }
            dispatch(authActions.logout());
        } catch (error) {
            dispatch(authActions.logout());
            console.log(error);
        }
    }

    getHeroContent = () => {
        return this.get('/home-page/');
    };

    login = (body: any) => {
        return this.post('/jwt/create/', body);
    };

    forgotPassword = (body: any) => {
        return this.post('/user/password_reset/', body);
    };

    passwordResetVerify = (body: any) => {
        return this.post('/user/password_reset/verify/', body);
    };

    resetPassword = (body: any) => {
        return this.post('/user/password_reset/change/', body);
    };

    claimOrganization = (organizationId: string | number, body: any, options: any) => {
        return this.post(`/organization/${organizationId}/claim/`, body, options);
    };

    getClaimOrganizationRequest = (requestId: string | number, query: QueryType) => {
        return this.get(`/claim-organization-request/${requestId}/`, { query });
    };

    acceptClaimOrganizationRequest = (requestId: string | number) => {
        return this.post(`/claim-organization-request/${requestId}/accept/`);
    };

    rejectClaimOrganizationRequest = (requestId: string | number, body: any) => {
        return this.post(`/claim-organization-request/${requestId}/reject/`, body);
    };

    loadUserToStore = async () => {
        try {
            const user = await this.get('/user/me/');
            if (user) {
                dispatch(authActions.setUser(user));
            }
        } catch (error) {
            console.log(error);
        }
    };

    getProjects = (query: QueryType) => {
        return this.get('/project/', { query });
    };

    getProject = (projectId: number | string, query: QueryType) => {
        return this.get(`/project/${projectId}/`, { query });
    };

    getProjectCounts = (query: QueryType) => {
        return this.get('/project/count/', { query });
    };

    getLocationStats = (query: QueryType) => {
        return this.get('/project/location_stats/', { query });
    };

    getProjectStartYearStats = (query: QueryType) => {
        return this.get('/project/start_year_stats/', { query });
    };

    getProjectEndYearStats = (query: QueryType) => {
        return this.get('/project/end_year_stats/', { query });
    };

    getOrganizationStats = (query: QueryType) => {
        return this.get('/project/organization_stats/', { query });
    };

    getDonorStats = (query: QueryType) => {
        return this.get('/project/donor_stats/', { query });
    };

    getPriorityIndicatorStats = (query: QueryType) => {
        return this.get('/project/priority_indicator_stats/', { query });
    };

    getRegions = (query: QueryType) => {
        return this.get('/region/', { query });
    };

    getOrganizations = (query: QueryType) => {
        return this.get('/organization/', { query });
    };

    requestNewOrganization = (body: any, options: any) => {
        return this.post('/organization/new/', body, options);
    };

    getOrganizationTypes = (query: QueryType) => {
        return this.get('/organization-type/', { query });
    };

    getNewOrganizationRequest = (requestId: string | number, query: QueryType) => {
        return this.get(`/new-organization-request/${requestId}/`, { query });
    };

    acceptNewOrganizationRequest = (requestId: string | number) => {
        return this.post(`/new-organization-request/${requestId}/accept/`);
    };

    rejectNewOrganizationRequest = (requestId: string | number, body: any) => {
        return this.post(`/new-organization-request/${requestId}/reject/`, body);
    };

    getNotifications = (query: QueryType) => {
        return this.get('/notification/', { query });
    };

    markNotificationRead = (notificationId: string | number) => {
        return this.post(`/notification/${notificationId}/mark_as_read/`);
    };

    markAllNotificationsRead = () => {
        return this.post('/notification/mark_all_as_read/');
    };

    getPriorityIndicators = (query: QueryType) => {
        return this.get('/priority-indicator/', { query });
    };

    getHazards = (query: QueryType) => {
        return this.get('/hazard/', { query });
    };

    getProjectDocumentTypes = () => {
        return this.get('/project-document-type/');
    };

    createProjectDonor = (organizationId: string | number, body: any, options: any) => {
        return this.post(`/organization/${organizationId}/create_project_donor/`, body, options);
    };

    createChildProjectDonor = (donorId: string | number, body: any, options: any) => {
        return this.post(`/project-donor/${donorId}/create_child_project_donor/`, body, options);
    };

    createImplementorProject = (donorId: string | number, body: any, options: any) => {
        return this.post(`/project-donor/${donorId}/create_project/`, body, options);
    };

    createProject = (body: any, options: any) => {
        return this.post('/project/', body, options);
    };

    patchProject = (projectId: number | string, body: any, options?: any) => {
        return this.patch(`/project/${projectId}/`, body, options);
    };

    getUnimplementedDonations = (query: QueryType) => {
        return this.get('/project-donor/unimplemented_donation/', { query });
    };

    createTargetReport = (targetId: string | number, body: any, options: any) => {
        return this.post(`/target/${targetId}/create_target_report/`, body, options);
    };

    createProjectDocument = (projectId: string | number, body: any, options: any) => {
        return this.post(`/project/${projectId}/create_document/`, body, options);
    };

    patchProjectDocument = (projectDocumentId: string | number, body: any, options: any) => {
        return this.patch(`/project-document/${projectDocumentId}/`, body, options);
    };

    getProjectDocuments = (query: QueryType) => {
        return this.get('/project-document/', { query });
    };

    getProjectDonorDocuments = (query: QueryType) => {
        return this.get('/project-donor-document/', { query });
    };

    getProjectDonors = (query: QueryType) => {
        return this.get('/project-donor/', { query });
    };

    getLegalDocuments = () => {
        return this.get('/legal-document/');
    };

    uploadFile = (body: any, options: any) => {
        return this.post('/user/upload_file/', body, options);
    };

    patchUser = (body: any, options: any) => {
        return this.patch('/user/me/', body, options);
    };

    requestEmailChange = (body: any) => {
        return this.post('/user/email_change/', body);
    };

    verifyEmailChange = (body: any) => {
        return this.post('/user/email_change/verify/', body);
    };

    changePassword = (body: any) => {
        return this.post('/user/me/change_password/', body);
    };
}

const ApiService = new Api();
export default ApiService;
