import { useContext, useMemo } from 'react';

import { FiltersContext, FiltersDispatchContext } from 'components/Filters/FiltersContext';

export type { FilterItemType } from 'components/Filters/FiltersContext';

export function useFilters(matchStart?: string) {
    const filters = useContext(FiltersContext);

    const requiredFilters = useMemo(() => {
        if (matchStart) {
            return filters.filter((el) => el.filterType.startsWith(matchStart));
        }
        return filters;
    }, [filters]);

    return requiredFilters;
}

export function useFiltersDispatch() {
    return useContext(FiltersDispatchContext);
}
