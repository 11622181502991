import React, { useState, useRef, useCallback, useMemo, useEffect } from 'react';
import Map, { NavigationControl, Source, Layer, Popup } from 'react-map-gl';
import type {
    MapRef,
    MapLayerMouseEvent,
    MapboxGeoJSONFeature,
    PaddingOptions
} from 'react-map-gl';
import bbox from '@turf/bbox';
import useResizeObserver from '@react-hook/resize-observer';

import Button from 'components/Button';
import { RectLoader } from 'components/Loader';

import cs from '@ra/cs';
import { Localize, _, mapPropertyTranslator } from 'services/i18n';
import useAdministrativeDivisions, { DivisionLevelModel } from 'hooks/useAdministrativeDivisions';
import { useAppSelector } from 'hooks/store';
import { formatCurrency, formatNumber } from 'utils/formatter';
import useControlledState from '@ra/hooks/useControlledState';

import type { Region, MPTTStats } from 'services/types';

import MapLegend, { legendData, LegendData } from './MapLegend';
import Overview, { OverviewDataType } from './Overview';

import 'mapbox-gl/dist/mapbox-gl.css';

import styles from './styles.scss';

export interface MapProps {
    activeDivisionLevel?: DivisionLevelModel;
    className?: string;
    legendClassName?: string;
    showActiveFeaturePopup?: boolean;
    hideActiveFeaturePopupButton?: boolean;
    style?: React.CSSProperties;
    navigationControlStyle?: React.CSSProperties;
    overviewData?: OverviewDataType;
    overviewDataCategory?: string;
    overviewDataAccessorState?: [
        'projects' | 'amount',
        React.Dispatch<React.SetStateAction<'projects' | 'amount'>>
    ];
    mapData?: MPTTStats[];
    padding?: PaddingOptions;
    hideLegend?: boolean;
    popupButtonText?: string;
    onPopupButtonClick?: (feature: MapboxGeoJSONFeature | null) => void;
    focusedRegion?: Region | null;
    showUnfocused?: boolean;
    focusedRegionPadding?: PaddingOptions;
    scrollZoom?: boolean;
}

type ExpArray = (number | string | ExpArray)[];
const regionColorExps = (accessor: 'projects' | 'amount') => {
    return legendData.reduce((acc: ExpArray, dt) => {
        acc.unshift(dt.color);
        acc.unshift([
            '>=',
            ['feature-state', accessor],
            dt[(accessor + 'LowerBound') as keyof LegendData]
        ]);
        return acc;
    }, []);
};

const MapComponent: React.FC<MapProps> = (props) => {
    const divisionLevels = useAdministrativeDivisions();

    const {
        className,
        legendClassName,
        style,
        navigationControlStyle,
        activeDivisionLevel = divisionLevels[0],
        showActiveFeaturePopup,
        hideActiveFeaturePopupButton,
        overviewData,
        overviewDataCategory,
        overviewDataAccessorState,
        mapData,
        padding,
        hideLegend,
        popupButtonText = _('More details'),
        onPopupButtonClick,
        focusedRegion,
        showUnfocused,
        focusedRegionPadding = { top: 50, bottom: 160, left: 50, right: 50 },
        scrollZoom
    } = props;

    const mapRef: React.RefObject<MapRef> = useRef(null);
    const containerRef: React.RefObject<HTMLDivElement> = useRef(null);

    const { regions } = useAppSelector((state) => state.region);

    const [isDataLoaded, setDataLoaded] = useState<boolean>(false);
    const [mapWidth, setMapWidth] = useState<number | null>(null);

    const [activeLegendAccessor, setActiveLegendAccessor] = useControlledState('projects', {
        value: overviewDataAccessorState?.[0],
        onChange: overviewDataAccessorState?.[1]
    });
    const handleLegendChange = useCallback(setActiveLegendAccessor, []);

    const [layerFilters, setLayerFilters] = useState<ExpArray>(['all']);

    const handleMapResize = useCallback(({ contentRect }: { contentRect: DOMRect }) => {
        mapRef?.current?.resize();
        setMapWidth(contentRect.width);
    }, []);

    useResizeObserver(containerRef, handleMapResize);

    const [activeFeature, setActiveFeature] = useState<MapboxGeoJSONFeature | null>(null);
    const handleClosePopup = useCallback(() => setActiveFeature(null), []);

    const [popupLng, popupLat] = useMemo(() => {
        if (activeDivisionLevel.code === '0') {
            return [84.124008, 28.394857];
        }
        if (!activeFeature?.properties?.centroid) {
            return [];
        }
        const centroid = JSON.parse(activeFeature.properties.centroid);
        return centroid?.coordinates || [];
    }, [activeFeature, activeDivisionLevel]);

    useEffect(() => {
        if (activeDivisionLevel) {
            setActiveFeature(null);
        }
    }, [activeDivisionLevel]);

    useEffect(() => {
        if (focusedRegion && !showUnfocused) {
            if (focusedRegion.level === 1) {
                setLayerFilters(['==', ['get', 'province'], Number(focusedRegion.code)]);
            } else if (focusedRegion.level === 2) {
                setLayerFilters(['==', ['get', 'district'], Number(focusedRegion.code)]);
            } else if (focusedRegion.level === 3) {
                setLayerFilters(['==', ['get', 'municipality'], Number(focusedRegion.code)]);
            } else {
                setLayerFilters(['==', ['get', 'id'], Number(focusedRegion.code)]);
            }
        } else {
            setLayerFilters(['all']);
        }
    }, [focusedRegion, showUnfocused]);

    useEffect(() => {
        if (focusedRegion?.envelope && focusedRegion?.level < 3) {
            const [minLng, minLat, maxLng, maxLat] = bbox(focusedRegion.envelope);
            mapRef.current?.fitBounds(
                [
                    [minLng, minLat],
                    [maxLng, maxLat]
                ],
                { padding: focusedRegionPadding, duration: 1000 }
            );
        }
    }, [focusedRegion, focusedRegionPadding]);

    const loadFeatureStates = useCallback(() => {
        mapRef?.current?.removeFeatureState({
            source: activeDivisionLevel.label,
            sourceLayer: activeDivisionLevel.tilesetLayer
        });
        if (regions.length && mapRef?.current && mapData && mapData?.length > 0) {
            if (activeDivisionLevel.code === '0') {
                const data = mapData[0];
                const provinceRegions = regions.filter((reg) => reg.level === 1);
                provinceRegions.forEach((prvRegion) => {
                    mapRef?.current?.setFeatureState(
                        {
                            source: activeDivisionLevel.label,
                            sourceLayer: activeDivisionLevel.tilesetLayer,
                            id: prvRegion.code
                        },
                        { projects: data.count, amount: Number(data.budget) }
                    );
                });
                return;
            }
            mapData.forEach((dt) => {
                const activeRegion = regions.find((reg) => reg.id === Number(dt.identifier));
                if (activeRegion?.code) {
                    mapRef?.current?.setFeatureState(
                        {
                            source: activeDivisionLevel.label,
                            sourceLayer: activeDivisionLevel.tilesetLayer,
                            id: activeRegion.code
                        },
                        { projects: dt.count, amount: Number(dt.budget) }
                    );
                }
            });
        }
    }, [regions, mapData, activeDivisionLevel]);

    useEffect(() => {
        if (isDataLoaded) {
            loadFeatureStates();
        }
    }, [isDataLoaded, loadFeatureStates]);

    const handleDataLoad = useCallback(() => {
        loadFeatureStates();
        setDataLoaded(true);
        if (focusedRegion?.envelope) {
            const [minLng, minLat, maxLng, maxLat] = bbox(focusedRegion.envelope);
            mapRef.current?.fitBounds(
                [
                    [minLng, minLat],
                    [maxLng, maxLat]
                ],
                { padding: focusedRegionPadding, duration: 1000 }
            );
        }
    }, [activeDivisionLevel, mapData, focusedRegion, focusedRegionPadding, loadFeatureStates]);

    const handleMapClick = useCallback(
        (event: MapLayerMouseEvent) => {
            if (!showActiveFeaturePopup) {
                return;
            }
            if (activeFeature) {
                return setActiveFeature(null);
            }
            if (mapRef?.current) {
                const map = mapRef.current.getMap();
                const selectedFeatures = map.queryRenderedFeatures(event.point, {
                    layers: [activeDivisionLevel.label]
                });
                if (selectedFeatures?.[0]?.properties) {
                    setActiveFeature(selectedFeatures[0]);
                }
            }
        },
        [activeDivisionLevel, activeFeature, showActiveFeaturePopup]
    );

    const handlePopupButtonClick = useCallback(() => {
        onPopupButtonClick?.(activeFeature);
    }, [onPopupButtonClick, activeFeature]);

    const fillColorFocusedExps = useMemo(() => {
        if (focusedRegion && showUnfocused) {
            if (focusedRegion.level === 0) {
                return [['!', false], '#9EAcc5'];
            }
            return [['==', ['get', 'title'], focusedRegion?.title], '#9EAcc5'];
        }
        return [];
    }, [focusedRegion, showUnfocused]);

    const lineColorFocusedExps = useMemo(() => {
        if (focusedRegion && showUnfocused) {
            return [['!=', ['get', 'title'], focusedRegion?.title], '#EAECF0'];
        }
        return [];
    }, [focusedRegion, showUnfocused]);

    const renderDivisionSource = useCallback(
        (divLevel: DivisionLevelModel, index: number) => {
            if (divLevel.id !== activeDivisionLevel.id) {
                return null;
            }
            return (
                <Source key={index} id={divLevel.label} type="vector" url={divLevel.tilesetURL}>
                    <Layer
                        id={divLevel.label}
                        type="fill"
                        source={divLevel.label}
                        source-layer={divLevel.tilesetLayer}
                        filter={layerFilters}
                        paint={{
                            'fill-color': [
                                'case',
                                ...fillColorFocusedExps,
                                ['==', ['feature-state', 'projects'], null],
                                '#ffffff',
                                ...regionColorExps(activeLegendAccessor),
                                '#ffffff'
                            ]
                        }}
                    />
                    <Layer
                        id={`${divLevel.label}-border`}
                        type="line"
                        source={divLevel.label}
                        source-layer={divLevel.tilesetLayer}
                        filter={layerFilters}
                        paint={{
                            'line-color': [
                                'case',
                                ...lineColorFocusedExps,
                                ['==', ['feature-state', 'projects'], null],
                                '#D0D5DD',
                                '#FFFFFF'
                            ],
                            'line-width': divLevel.code === '0' ? 0 : 1.5
                        }}
                    />
                </Source>
            );
        },
        [
            activeDivisionLevel,
            layerFilters,
            activeLegendAccessor,
            fillColorFocusedExps,
            lineColorFocusedExps
        ]
    );

    return (
        <div ref={containerRef} className={cs(styles.mapContainer, className)}>
            <Map
                initialViewState={{
                    latitude: 28.394857,
                    longitude: 84.124008,
                    zoom: 6
                }}
                ref={mapRef}
                style={style}
                minZoom={5}
                maxZoom={20}
                padding={padding}
                onClick={handleMapClick}
                onData={handleDataLoad}
                maxBounds={[
                    [58.374573, 15.603612],
                    [110.655823, 38.360576]
                ]}
                cooperativeGestures={!scrollZoom}
            >
                {divisionLevels.map(renderDivisionSource)}
                {showActiveFeaturePopup && activeFeature && (
                    <Popup
                        longitude={popupLng}
                        latitude={popupLat}
                        onClose={handleClosePopup}
                        closeOnClick
                        closeButton={false}
                    >
                        <div className={styles.popup}>
                            <h5 className={styles.featureTitle}>
                                {activeDivisionLevel.code === '0' ? (
                                    _('Nepal')
                                ) : (
                                    <Localize dataKey="title" translator={mapPropertyTranslator}>
                                        {activeFeature?.properties || {}}
                                    </Localize>
                                )}
                            </h5>
                            <div className={styles.featureInfoItem}>
                                <span className={styles.infoKey}>
                                    <Localize>No. of projects</Localize>
                                </span>
                                <span className={styles.infoValue}>
                                    {formatNumber(activeFeature.state?.projects) || '-'}
                                </span>
                            </div>
                            <div className={styles.featureInfoItem}>
                                <span className={styles.infoKey}>
                                    <Localize>Amount allocated</Localize>
                                </span>
                                <span className={styles.infoValue}>
                                    {formatCurrency(activeFeature.state?.amount) || '-'}
                                </span>
                            </div>
                            {activeDivisionLevel.code !== '0' && !hideActiveFeaturePopupButton && (
                                <Button
                                    secondary
                                    small
                                    rightIcon="launch"
                                    onClick={handlePopupButtonClick}
                                >
                                    {popupButtonText}
                                </Button>
                            )}
                        </div>
                    </Popup>
                )}
                <NavigationControl style={navigationControlStyle} showCompass={false} />
            </Map>
            {overviewData && (
                <Overview
                    className={styles.overview}
                    data={overviewData}
                    topDataCategory={overviewDataCategory || activeDivisionLevel.title}
                    topDataKey={activeLegendAccessor}
                />
            )}
            {!hideLegend && (
                <MapLegend
                    activeAccessor={activeLegendAccessor}
                    mapWidth={mapWidth}
                    className={cs(styles.legend, legendClassName)}
                    onChange={handleLegendChange}
                />
            )}
            {regions.length === 0 && (
                <div className={styles.loaderOverlay}>
                    <RectLoader />
                </div>
            )}
        </div>
    );
};

export default MapComponent;
