import React, { useMemo, useCallback, useState, useRef, useEffect } from 'react';
import useResizeObserver from '@react-hook/resize-observer';
import DatePicker from 'react-datepicker';
import { format } from 'date-fns';

import Button from 'components/Button';
import DateRangePickerButton from 'components/DateRangePickerButton';
import DropdownSelector from 'components/DropdownSelector';
import Filters from 'components/Filters';
import FilterBudgetSlider from 'components/Filters/FilterBudgetSlider';
import FilterHazards from 'components/Filters/FilterHazards';
import FilterOrganizations from 'components/Filters/FilterOrganizations';
import FilterProjectStatuses from 'components/Filters/FilterProjectStatuses';
import FilterRegionSelector from 'components/Filters/FilterRegionSelector';
import FilterThemes from 'components/Filters/FilterThemes';
import ProjectTable from 'components/ProjectTable';
import ProjectFormModal from 'components/ProjectFormModal';
import ProjectTypeModal from 'components/ProjectTypeModal';
import Tabs, { Tab } from 'components/Tabs';

import Pagination, { PageChangeCallback } from '@ra/components/Pagination';
import SelectInput, {
    SelectInputChangeCallback,
    ValueExtractor,
    KeyExtractor
} from '@ra/components/Form/SelectInput';

import cs from '@ra/cs';
import Api from 'services/api';
import { _, Localize } from 'services/i18n';
import { refreshStoreData } from 'services/bootstrap';
import ReportGenerator, { ExpandedProject } from 'services/report';
import Toast from 'services/toast';
import { debounce } from '@ra/utils';
import { getErrorMessage } from '@ra/utils/error';

import { useFilters, useFiltersDispatch } from 'hooks/filters';
import usePromise from '@ra/hooks/usePromise';
import { useAppSelector } from 'hooks/store';

import { PaginatedProjectList, Project } from 'services/types';
import type { TabChangeCallback } from '@ra/components/Tabs';

import 'react-datepicker/dist/react-datepicker.css';

import styles from './styles.scss';

export type MaxRowOption = {
    label: string;
    value: number;
};

export const maxRowsOptions: MaxRowOption[] = [
    {
        label: '10 rows',
        value: 10
    },
    {
        label: '20 rows',
        value: 20
    },
    {
        label: '50 rows',
        value: 50
    }
];

const keyExtractor: KeyExtractor<MaxRowOption> = (item: MaxRowOption) => item.value;
const labelExtractor: ValueExtractor<MaxRowOption, string> = (item) => item.label;

export type ProjectType = {
    code: 'donor' | 'implementor';
    title: string;
    description: string;
    iconName: string;
};

const Projects: React.FC = () => {
    const { isAuthenticated, user } = useAppSelector((state) => state.auth);

    const projectTypes: ProjectType[] = useMemo(
        () => [
            {
                code: 'donor',
                title: _('Donor'),
                description: _(
                    'Select this if the project is implemented by different organization and you are the donor'
                ),
                iconName: 'volunteer_activism'
            },
            {
                code: 'implementor',
                title: _('Implementor'),
                description: _('Select this if you are the implementor of this project'),
                iconName: 'manage_accounts'
            }
        ],
        []
    );

    const filtersDispatch = useFiltersDispatch();
    const projectFilters = useFilters('Project');

    const containerRef = useRef<HTMLDivElement>(null);

    const headerRef = useRef<HTMLDivElement>(null);
    const filterRef = useRef<HTMLDivElement>(null);
    const footerRef = useRef<HTMLDivElement>(null);

    const [page, setPage] = useState<number>(1);
    const [maxRows, setMaxRows] = useState<MaxRowOption>(maxRowsOptions[0]);

    const initialActiveProjectsTab = useMemo(() => {
        if (!isAuthenticated) {
            localStorage.setItem('activeProjectsTab', 'all');
            return 'all';
        }
        if (['all', 'my'].includes(localStorage.getItem('activeProjectsTab') as string)) {
            return localStorage.getItem('activeProjectsTab') as 'all' | 'my';
        }
        return 'all';
    }, [isAuthenticated]);
    const [activeProjectsTab, setActiveProjectsTab] = useState<'all' | 'my'>(
        initialActiveProjectsTab
    );
    const handleTabChange: TabChangeCallback = useCallback(({ activeTab }) => {
        setPage(1);
        localStorage.setItem('activeProjectsTab', activeTab);
        setActiveProjectsTab(activeTab as 'all' | 'my');
    }, []);

    const [isCreateVisible, setCreateVisible] = useState<boolean>(false);
    const toggleCreateModal = useCallback(() => {
        setCreateVisible((cv) => !cv);
    }, []);

    const [isSelectTypeVisible, setSelectTypeVisible] = useState<boolean>(false);
    const toggleProjectTypeModal = useCallback(() => setSelectTypeVisible((stv) => !stv), []);

    const [activeProjectType, setActiveProjectType] = useState<ProjectType | null>(null);
    const handleProjectTypeChange = useCallback(
        (prjType: ProjectType) => setActiveProjectType(prjType),
        []
    );
    const handleProjectTypeSelect = useCallback(
        (prjType: ProjectType) => {
            setActiveProjectType(prjType);
            toggleCreateModal();
        },
        [toggleCreateModal]
    );

    const [filtersActive, setFiltersActive] = useState<boolean>(Boolean(projectFilters.length));

    const toggleFilters = useCallback(() => setFiltersActive((prev) => !prev), []);
    const handleClearFilters = useCallback(() => {
        setDateRange([null, null]);
        filtersDispatch({ type: 'clear', category: 'Project' });
    }, [filtersDispatch]);

    const [dateRange, setDateRange] = useState<[Date | null, Date | null]>([null, null]);
    const [startDate, endDate] = useMemo(() => dateRange, [dateRange]);

    const [contentStyle, setContentStyle] = useState<React.CSSProperties>({});

    const [projectOrdering, setProjectOrdering] = useState<string>('');
    const handleSortProjects = useCallback(setProjectOrdering, []);

    const [{ loading, result, error }, getProjects] = usePromise(Api.getProjects);
    const loadProjects = useCallback(debounce(getProjects, 1000), [getProjects]);

    const filterQueries = useMemo(() => {
        const queries = projectFilters.reduce(
            (acc, cur) => {
                if (cur.query?.hazards__in) {
                    const addStr = acc.hazards__in
                        ? `,${cur.query.hazards__in}`
                        : cur.query.hazards__in;
                    acc.hazards__in += addStr;
                    return acc;
                }
                if (cur.filterType !== 'Project-Status') {
                    Object.assign(acc, cur.query);
                    return acc;
                }
                if (cur.query?.status__in) {
                    const addStr = acc.status__in
                        ? `,${cur.query.status__in}`
                        : cur.query.status__in;
                    acc.status__in += addStr;
                    return acc;
                }
                return acc;
            },
            {
                tab: activeProjectsTab === 'my' ? 'my_project' : '',
                limit: maxRows.value,
                status__in: '',
                hazards__in: '',
                offset: (page - 1) * maxRows.value
            }
        );
        if (projectOrdering) {
            Object.assign(queries, { ordering: projectOrdering });
        }
        return queries;
    }, [projectFilters, projectOrdering, maxRows, page, activeProjectsTab]);

    useEffect(() => {
        loadProjects(filterQueries);
    }, [loadProjects, filterQueries]);
    const projectData: Project[] = useMemo(() => result?.results || [], [result]);

    const handlePageChange: PageChangeCallback = useCallback(({ currentPage }) => {
        setPage(currentPage);
    }, []);
    const handleMaxRowsChange: SelectInputChangeCallback<MaxRowOption> = useCallback(
        ({ option }) => {
            if (option && option.value !== maxRows.value) {
                setPage(1);
                setMaxRows(option);
            }
        },
        [page, maxRows.value]
    );

    const handleContainerResize = useCallback(() => {
        if (containerRef?.current && headerRef?.current && footerRef?.current) {
            let tableHeight =
                containerRef.current.offsetHeight -
                headerRef.current.offsetHeight -
                footerRef.current.offsetHeight;
            let spacingHeight = 2.875;
            if (filterRef?.current) {
                tableHeight -= filterRef.current.offsetHeight;
                spacingHeight += 1;
            }
            setContentStyle({
                maxHeight: `calc(${tableHeight}px - ${spacingHeight}rem)`
            });
        }
    }, []);
    useResizeObserver(containerRef, handleContainerResize);

    useEffect(() => {
        handleContainerResize();
    }, [filtersActive, projectFilters]);

    const handleDateChange = useCallback(
        (date: [Date | null, Date | null]) => {
            if (date[0]) {
                filtersDispatch({
                    type: 'add',
                    filter: {
                        filterType: 'Project-From',
                        filterValue: date[0],
                        query: { date_from__gte: format(date[0], 'yyyy-MM-dd') }
                    }
                });
            } else if (dateRange[0]) {
                filtersDispatch({
                    type: 'remove',
                    filter: { filterType: 'Project-From', filterValue: dateRange[0] }
                });
            }
            if (date[1]) {
                filtersDispatch({
                    type: 'add',
                    filter: {
                        filterType: 'Project-To',
                        filterValue: date[1],
                        query: { date_to__lte: format(date[1], 'yyyy-MM-dd') }
                    }
                });
            } else if (dateRange[1]) {
                filtersDispatch({
                    type: 'remove',
                    filter: { filterType: 'Project-To', filterValue: dateRange[1] }
                });
            }
            setDateRange(date);
        },
        [filtersDispatch, dateRange]
    );

    const handleFormComplete = useCallback(() => {
        refreshStoreData();
        loadProjects(filterQueries);
    }, [loadProjects, filterQueries]);

    const [exporting, setExporting] = useState<boolean>(false);
    const handleExportProjects = useCallback(async () => {
        setExporting(true);
        try {
            const projectsData = await Api.getProjects({
                ...filterQueries,
                limit: -1,
                expand: 'outputs.activities.targets.reports'
            });
            new ReportGenerator()
                .loadProjectData(
                    (projectsData as PaginatedProjectList).results as ExpandedProject[]
                )
                .downloadXlsxReport('MDSA_ProjectsList');
        } catch (err) {
            Toast.show(getErrorMessage(err), Toast.DANGER, 10);
            console.log(err);
        }
        setExporting(false);
    }, [projectData, filterQueries]);

    return (
        <div className={styles.container} ref={containerRef}>
            <header ref={headerRef} className={styles.header}>
                <div className={styles.headerLeftContent}>
                    <h1 className={styles.title}>
                        <Localize>Project List</Localize>
                    </h1>
                    {isAuthenticated && (
                        <Tabs
                            secondary
                            tabItemClassName={styles.tabHeaderItem}
                            activeTabItemClassName={styles.tabHeaderItemActive}
                            activeTab={activeProjectsTab}
                            onChange={handleTabChange}
                        >
                            <Tab label="all" title={_('All projects')} />
                            <Tab label="my" title={_('My projects')} />
                        </Tabs>
                    )}
                </div>
                <div className={styles.buttons}>
                    {projectFilters.length > 0 && (
                        <p className={styles.clearLink} onClick={handleClearFilters}>
                            <Localize>Clear all</Localize>
                        </p>
                    )}
                    <Button
                        small
                        secondary
                        leftIcon="filter_alt"
                        onClick={toggleFilters}
                        className={cs({ [styles.filterButtonActive]: filtersActive })}
                    >
                        <Localize>
                            Filters{projectFilters.length > 0 && ` (${projectFilters.length})`}
                        </Localize>
                    </Button>
                    <Button
                        secondary={Boolean(isAuthenticated && user.organization)}
                        small
                        leftIcon="ios_share"
                        onClick={handleExportProjects}
                        loading={exporting}
                    >
                        <Localize>Export</Localize>
                    </Button>
                    {isAuthenticated && user.organization && (
                        <Button small leftIcon="add" onClick={toggleProjectTypeModal}>
                            <Localize>Add projects</Localize>
                        </Button>
                    )}
                </div>
            </header>
            {filtersActive && (
                <Filters ref={filterRef} className={styles.filters}>
                    <div className={styles.dateFilterContainer}>
                        <DatePicker
                            selectsRange
                            startDate={startDate}
                            endDate={endDate}
                            onChange={handleDateChange}
                            popperPlacement="bottom"
                            popperClassName={styles.datePopper}
                            customInput={
                                <DateRangePickerButton startDate={startDate} endDate={endDate} />
                            }
                            isClearable
                            showYearDropdown
                        />
                    </div>
                    <FilterOrganizations filterType="Project-Organization" />
                    <DropdownSelector
                        title={_('Budget')}
                        filterType="Project-Budget"
                        icon="payments"
                    >
                        <FilterBudgetSlider
                            showTitle
                            filterType="Project-Budget"
                            className={styles.filterBudget}
                            activeClassName={styles.filterBudgetActive}
                        />
                    </DropdownSelector>
                    <DropdownSelector
                        title={_('Status')}
                        filterType="Project-Status"
                        icon="monitor_heart"
                    >
                        <FilterProjectStatuses
                            filterType="Project-Status"
                            className={styles.filterItemList}
                        />
                    </DropdownSelector>
                    <FilterRegionSelector filterType="Project-Region" />

                    <FilterThemes filterType="Project-Theme" />
                    <DropdownSelector
                        title={_('Hazard(s)')}
                        filterType="Project-Hazard"
                        icon="warning"
                    >
                        <FilterHazards
                            filterType="Project-Hazard"
                            className={styles.filterItemList}
                        />
                    </DropdownSelector>
                </Filters>
            )}
            <main className={styles.tableContainer} style={contentStyle}>
                <ProjectTable
                    className={styles.table}
                    headerClassName={cs(styles.tableHeader, {
                        [styles.tableHeaderStatic]: filtersActive
                    })}
                    data={projectData}
                    maxRows={maxRows.value}
                    loading={(!result && !error) || loading}
                    onSort={handleSortProjects}
                    ordering={projectOrdering}
                />
            </main>
            <footer ref={footerRef} className={styles.footer}>
                <div className={styles.maxRowsSelect}>
                    <Localize>Show</Localize>
                    <SelectInput
                        className={styles.select}
                        controlClassName={styles.selectControl}
                        options={maxRowsOptions}
                        keyExtractor={keyExtractor}
                        valueExtractor={labelExtractor}
                        onChange={handleMaxRowsChange}
                        value={maxRows}
                        defaultValue={maxRowsOptions[0]}
                        clearable={false}
                        searchable={false}
                        optionsDirection="up"
                    />
                </div>
                <Pagination
                    showControlIcons
                    controlIconClassName={styles.paginationControl}
                    className={styles.pagination}
                    pageItemClassName={styles.paginationItem}
                    activePageItemClassName={styles.paginationItemActive}
                    onChange={handlePageChange}
                    totalRecords={result?.count || projectData.length}
                    pageNeighbours={1}
                    pageLimit={maxRows.value}
                    pageNum={page}
                />
            </footer>
            <ProjectFormModal
                projectTypes={projectTypes}
                isVisible={isCreateVisible}
                activeProjectType={activeProjectType}
                onChangeProjectType={handleProjectTypeChange}
                onComplete={handleFormComplete}
                onClose={toggleCreateModal}
            />
            <ProjectTypeModal
                projectTypes={projectTypes}
                isVisible={isSelectTypeVisible}
                onClose={toggleProjectTypeModal}
                onSelect={handleProjectTypeSelect}
            />
        </div>
    );
};
export default Projects;
