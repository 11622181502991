import React, { useMemo, useEffect, useState, useCallback } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import type { MapboxGeoJSONFeature } from 'react-map-gl';

import Button from 'components/Button';
import List, {
    type KeyExtractor,
    type ListRenderItem,
    type ListRenderItemProps
} from '@ra/components/List';
import Map from 'components/Map';
import OverviewStatItem from 'components/OverviewStatItem';
import ProjectTable, { getDefaultProjectColumns } from 'components/ProjectTable';
import ProjectFormModal from 'components/ProjectFormModal';
import ProjectTypeModal from 'components/ProjectTypeModal';
import Tabs, { Tab } from 'components/Tabs';

import Api from 'services/api';
import cs from '@ra/cs';
import { statsData } from 'services/data';
import { Localize, _ } from 'services/i18n';
import { refreshStoreData } from 'services/bootstrap';

import useAdministrativeDivisions, { DivisionLevelModel } from 'hooks/useAdministrativeDivisions';
import usePromise from '@ra/hooks/usePromise';
import { useFiltersDispatch } from 'hooks/filters';
import { useAppSelector } from 'hooks/store';

import type { ProjectType } from 'containers/Projects';
import type { Project, Region, MPTTStats, PatchedProjectDonor } from 'services/types';

import styles from './styles.scss';

const keyExtractor: KeyExtractor<{ id: number }> = (item) => item.id;

interface FormNoticeProps extends ListRenderItemProps<PatchedProjectDonor> {
    onFillUpClick: (donorId: number) => void;
}

const FormNotice: React.FC<FormNoticeProps> = ({ item, onFillUpClick }) => {
    const handleFillUp = useCallback(() => {
        onFillUpClick(item.id as number);
    }, [item, onFillUpClick]);

    return (
        <div className={styles.formNoticeContainer}>
            <div className={styles.noticeIconContainer}>
                <span className={cs('material-symbols-rounded', styles.noticeIcon)}>
                    person_add
                </span>
            </div>
            <p className={styles.noticeDescription}>
                <span className={styles.noticeBy}>{item.createdBy}</span>
                {' has added you as an implementor for the Project '}
                <span className={styles.noticeProject}>'{item.title}'</span>. Please fill up the
                details.
            </p>
            {Boolean(item.id) && (
                <Button onClick={handleFillUp}>
                    <Localize>Fill up</Localize>
                </Button>
            )}
        </div>
    );
};

const Dashboard: React.FC = () => {
    const navigate = useNavigate();

    const projectTypes: ProjectType[] = useMemo(
        () => [
            {
                code: 'donor',
                title: _('Donor'),
                description: _(
                    'Select this if the project is implemented by different organization and you are the donor'
                ),
                iconName: 'volunteer_activism'
            },
            {
                code: 'implementor',
                title: _('Implementor'),
                description: _('Select this if you are the implementor of this project'),
                iconName: 'manage_accounts'
            }
        ],
        []
    );

    const filtersDispatch = useFiltersDispatch();

    const [formDonorId, setFormDonorId] = useState<number | null>(null);

    const projectColumns = useMemo(() => {
        const defaultColumns = getDefaultProjectColumns();
        defaultColumns.splice(1, 0, {
            Header: _('BUDGET (NPR)'),
            accessor: 'budget'
        });
        return defaultColumns.filter((col) => col.accessor !== 'organization');
    }, []);

    const [{ result: noticeResult }, getUnimplementedDonations] = usePromise(
        Api.getUnimplementedDonations
    );
    const handleFormComplete = useCallback(() => {
        refreshStoreData();
        getUnimplementedDonations({ expand: 'created_by' });
    }, [getUnimplementedDonations]);

    useEffect(() => {
        getUnimplementedDonations({ expand: 'created_by' });
    }, [getUnimplementedDonations]);

    const formNotices = useMemo(() => {
        return noticeResult || [];
    }, [noticeResult]);

    const { regions } = useAppSelector((state) => state.region);

    const [projectOrdering, setProjectOrdering] = useState<string>('-budget');
    const handleSortProjects = useCallback(setProjectOrdering, []);

    const [{ loading, result, error }, getProjects] = usePromise(Api.getProjects);
    useEffect(() => {
        const queryObj = { limit: 5 };
        if (projectOrdering) {
            Object.assign(queryObj, { ordering: projectOrdering });
        }
        getProjects(queryObj);
    }, [getProjects, projectOrdering]);
    const projectData: Project[] = useMemo(() => result?.results || [], [result]);

    const [{ result: countResult }, getStats] = usePromise(Api.getProjectCounts);
    useEffect(() => {
        getStats();
    }, [getStats]);
    const countStats = useMemo(() => {
        return statsData.map((st) => ({
            ...st,
            value: countResult?.[st.key] || ''
        }));
    }, [countResult]);

    const divisionLevels: DivisionLevelModel[] = useAdministrativeDivisions();
    const [activeLevel, setActiveLevel] = useState<DivisionLevelModel>(divisionLevels[1]);
    const handleLevelChange = useCallback(
        ({ activeTab }: { activeTab: string }) => {
            const newActiveLevel = divisionLevels.find((dvl) => dvl.label === activeTab);
            if (newActiveLevel) {
                setActiveLevel(newActiveLevel);
            }
        },
        [divisionLevels]
    );

    const [{ result: statResult }, getMapStats] = usePromise(Api.getLocationStats);
    useEffect(() => {
        getMapStats();
    }, [getMapStats]);
    const mapData = useMemo(() => {
        return statResult?.filter((res: MPTTStats) => res.level === Number(activeLevel.code)) || [];
    }, [statResult, activeLevel]);

    const handleGoToProjects = useCallback(
        (feature: MapboxGeoJSONFeature | null) => {
            const featureRegion = regions.find(
                (reg: Region) =>
                    feature &&
                    Number(reg.code) === feature.id &&
                    reg.title === feature.properties?.title
            );
            if (!featureRegion) {
                return;
            }
            filtersDispatch({
                type: 'add',
                filter: {
                    filterType: 'Region-Region',
                    filterValue: featureRegion,
                    query: { location: featureRegion.id }
                }
            });
            navigate({ pathname: '/map/details', search: `?region=${featureRegion.id}` });
        },
        [navigate, regions, filtersDispatch]
    );

    const handleAllProjects = useCallback(() => {
        localStorage.setItem('activeProjectsTab', 'all');
        filtersDispatch({ type: 'clear', category: 'Project' });
    }, [filtersDispatch]);

    const [isCreateVisible, setCreateVisible] = useState<boolean>(false);
    const toggleCreateModal = useCallback(() => {
        setCreateVisible((cv) => !cv);
    }, []);

    const [isSelectTypeVisible, setSelectTypeVisible] = useState<boolean>(false);
    const toggleProjectTypeModal = useCallback(() => setSelectTypeVisible((stv) => !stv), []);

    const [activeProjectType, setActiveProjectType] = useState<ProjectType | null>(null);
    const handleProjectTypeChange = useCallback(
        (prjType: ProjectType) => setActiveProjectType(prjType),
        []
    );
    const handleProjectTypeSelect = useCallback(
        (prjType: ProjectType) => {
            setActiveProjectType(prjType);
            toggleCreateModal();
        },
        [toggleCreateModal]
    );

    const handleFillUpClick = useCallback(
        (id: number) => {
            toggleProjectTypeModal();
            setFormDonorId(id);
        },
        [toggleProjectTypeModal]
    );

    const renderFormNotice: ListRenderItem<PatchedProjectDonor> = useCallback(
        (listProps) => <FormNotice {...listProps} onFillUpClick={handleFillUpClick} />,
        [handleFillUpClick]
    );

    return (
        <div className={styles.container}>
            {formNotices.length > 0 && (
                <List
                    className={styles.formNotices}
                    keyExtractor={keyExtractor}
                    data={formNotices}
                    renderItem={renderFormNotice}
                />
            )}
            <List
                className={cs(styles.section, styles.statsList)}
                keyExtractor={keyExtractor}
                data={countStats}
                renderItem={OverviewStatItem}
            />
            <h3 className={styles.sectionTitle}>
                <Localize>Heatmap of Projects</Localize>
            </h3>
            <section className={styles.section}>
                <Tabs
                    className={styles.mapTabs}
                    secondary
                    defaultActiveTab={divisionLevels[1].label}
                    onChange={handleLevelChange}
                >
                    {divisionLevels.slice(0, 4).map((lvl) => (
                        <Tab key={lvl.id} label={lvl.label} title={lvl.title} />
                    ))}
                </Tabs>
                <div className={styles.mapContainer}>
                    <Map
                        activeDivisionLevel={activeLevel}
                        navigationControlStyle={{ marginRight: '1.25rem', marginTop: '1.25rem' }}
                        style={{ width: '100%', height: 572 }}
                        legendClassName={styles.mapLegend}
                        mapData={mapData}
                        showActiveFeaturePopup
                        onPopupButtonClick={handleGoToProjects}
                    />
                </div>
            </section>
            <h3 className={styles.sectionTitle}>
                <Localize>Projects with largest budget</Localize>
            </h3>
            <div className={styles.tableContainer}>
                <ProjectTable
                    className={styles.table}
                    headerClassName={styles.tableHeader}
                    loading={(!result && !error) || loading}
                    data={projectData}
                    columns={projectColumns}
                    onSort={handleSortProjects}
                    ordering={projectOrdering}
                />
            </div>
            <div className={styles.buttonContainer}>
                <Link to="/projects" onClick={handleAllProjects}>
                    <Button large>
                        <Localize>View all projects</Localize>
                    </Button>
                </Link>
            </div>
            <ProjectFormModal
                projectDonorId={formDonorId}
                projectTypes={projectTypes}
                isVisible={isCreateVisible}
                activeProjectType={activeProjectType}
                onChangeProjectType={handleProjectTypeChange}
                onClose={toggleCreateModal}
                onComplete={handleFormComplete}
            />
            <ProjectTypeModal
                projectTypes={projectTypes}
                isVisible={isSelectTypeVisible}
                onClose={toggleProjectTypeModal}
                onSelect={handleProjectTypeSelect}
            />
        </div>
    );
};

export default Dashboard;
