import React, { useEffect, useMemo, useState, useCallback } from 'react';

import { OrganizationLogo } from 'components/OrganizationList';
import ProjectDonorModal from 'components/ProjectDonorModal';
import List, { type ListRenderItem, type KeyExtractor } from '@ra/components/List';

import cs from '@ra/cs';
import Api from 'services/api';
import { formatOrganizationTitle } from 'utils/formatter';

import { useAppSelector } from 'hooks/store';
import usePromise from '@ra/hooks/usePromise';

import type { ProjectDonor } from 'services/types';

import styles from './styles.scss';

const idExtractor: KeyExtractor<ProjectDonor> = (item) => item.id;

const DonorItem: ListRenderItem<ProjectDonor> = (props) => {
    const { item, index } = props;

    const [isDonorModalVisible, setDonorModalVisible] = useState<boolean>(false);
    const toggleDonorModal = useCallback(() => setDonorModalVisible((dmv) => !dmv), []);

    const { organizations } = useAppSelector((state) => state.organization);
    const organization = useMemo(() => {
        return organizations.find((org) => org.id === item.organization);
    }, [item, organizations]);

    if (!organization) {
        return null;
    }

    return (
        <div title={organization.title} className={styles.listItem}>
            {index > 0 && (
                <span className={cs(styles.arrow, 'material-symbols-rounded')}>arrow_forward</span>
            )}
            <div className={styles.organizationItem} onClick={toggleDonorModal}>
                <OrganizationLogo organization={organization} className={styles.organizationLogo} />
                <div className={styles.organizationInfo}>
                    <p className={styles.organizationTitle}>
                        {formatOrganizationTitle(organization) || '-'}
                    </p>
                    <div className={styles.linkIconContainer}>
                        <span className={cs(styles.linkIcon, 'material-symbols-rounded')}>
                            chevron_right
                        </span>
                    </div>
                </div>
            </div>
            <ProjectDonorModal
                projectDonor={item}
                isVisible={isDonorModalVisible}
                onClose={toggleDonorModal}
            />
        </div>
    );
};

type DonorListProps = {
    className?: string;
    donorNode?: ProjectDonor | null;
};

const DonorList: React.FC<DonorListProps> = (props) => {
    const { className, donorNode } = props;

    const [{ result }, getProjectDonorTree] = usePromise(Api.getProjectDonors);

    useEffect(() => {
        if (donorNode?.treeId) {
            getProjectDonorTree({ tree_id: donorNode.treeId, expand: 'implementors' });
        }
    }, [donorNode, getProjectDonorTree]);

    const donors = useMemo(() => {
        if (result?.results) {
            return result.results
                .filter((donor: ProjectDonor) => donorNode?.rght && donor.lft < donorNode?.rght)
                .sort((a: ProjectDonor, b: ProjectDonor) => a.level - b.level);
        }
        if (donorNode) {
            return [donorNode];
        }
        return [];
    }, [result, donorNode]);

    return (
        <List
            className={cs(className, styles.list)}
            data={donors}
            renderItem={DonorItem}
            keyExtractor={idExtractor}
        />
    );
};

export default DonorList;
