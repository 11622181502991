import React, { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import StatusProgress from 'components/StatusProgress';
import Table, {
    TableProps,
    Column,
    TableRenderDataItem,
    TableRenderHeaderItem
} from '@ra/components/Table';

import cs from '@ra/cs';
import { Localize, _ } from 'services/i18n';
import { useAppSelector } from 'hooks/store';
import { camelCaseToSnakeCase, formatCurrency, formatDate, formatNumber } from 'utils/formatter';

import { Project } from 'services/types';

import styles from './styles.scss';

export const getDefaultProjectColumns = () => [
    {
        Header: _('PROJECT TITLE'),
        accessor: 'title'
    },
    {
        Header: _('ORGANIZATION'),
        accessor: 'organization'
    },
    {
        Header: _('PROJECT ID'),
        accessor: 'id'
    },
    {
        Header: _('DATE FROM'),
        accessor: 'dateFrom'
    },
    {
        Header: _('DATE TO'),
        accessor: 'dateTo'
    },
    {
        Header: _('STATUS'),
        accessor: 'status'
    }
];

type HeaderItemProps = { onSort?: (ordering: string) => void; ordering?: string };
type ProjectTableProps = Omit<TableProps<Project>, 'columns'> &
    HeaderItemProps & { columns?: Column[] };

const HeaderItem: React.FC<{ column: Column } & HeaderItemProps> = ({
    column,
    onSort,
    ordering
}) => {
    const sortAscending = useMemo(() => {
        if (!ordering) {
            return null;
        }
        const isAscending = ordering.startsWith('-');
        const orderAccessor = ordering.startsWith('-') ? ordering.slice(1) : ordering;
        if (orderAccessor === camelCaseToSnakeCase(column.accessor)) {
            return !isAscending;
        }
        return null;
    }, [ordering, column]);

    const handleSortClick = useCallback(() => {
        if (sortAscending === null) {
            onSort?.(camelCaseToSnakeCase(column.accessor));
        } else if (sortAscending) {
            onSort?.(`-${camelCaseToSnakeCase(column.accessor)}`);
        } else {
            onSort?.('');
        }
    }, [onSort, column, sortAscending]);

    return (
        <div className={styles.headerItem}>
            {column.Header}
            {column.accessor !== 'status' && (
                <span
                    onClick={handleSortClick}
                    className={cs(styles.headerItemIcon, 'material-symbols-rounded', {
                        [styles.headerItemIconActive]: sortAscending !== null
                    })}
                >
                    {sortAscending === null
                        ? 'unfold_more'
                        : sortAscending
                        ? 'expand_more'
                        : 'expand_less'}
                </span>
            )}
        </div>
    );
};

const ProjectTable: React.FC<ProjectTableProps> = (props) => {
    const { className, headerClassName, onSort, ordering, columns, ...otherProps } = props;

    const columnData = useMemo(() => {
        if (columns) {
            return columns;
        }
        return getDefaultProjectColumns();
    }, [columns]);

    const statusData: { [key: string]: string } = useMemo(
        () => ({
            pipeline: _('Pipeline'),
            active: _('Active'),
            completed: _('Completed'),
            dropped: _('Dropped')
        }),
        []
    );

    const { organizations } = useAppSelector((state) => state.organization);

    const navigate = useNavigate();

    const renderHeaderItem: TableRenderHeaderItem = useCallback(
        (tableProps) => {
            return <HeaderItem {...tableProps} onSort={onSort} ordering={ordering} />;
        },
        [onSort, ordering]
    );

    const renderDataItem: TableRenderDataItem<Project> = useCallback(
        ({ item, column }) => {
            if (column.accessor === 'title') {
                return (
                    <div className={styles.titleItem}>
                        <Localize dataKey={column.accessor}>{item}</Localize>
                    </div>
                );
            }
            if (column.accessor === 'organization') {
                const organization = organizations.find(
                    (org) => org.id === item[column.accessor as keyof Project]
                );
                const [titleShortened, title] =
                    organization?.title && organization?.title?.length > 25
                        ? [
                              organization.acronym || organization.title.slice(0, 25) + '...',
                              organization.title
                          ]
                        : [organization?.title || '', ''];

                return <span title={title}>{titleShortened}</span>;
            }
            if (column.accessor === 'id') {
                return (
                    <>
                        {formatNumber(item.id, { minimumIntegerDigits: 6 })?.replace(/,/g, '') ||
                            '-'}
                    </>
                );
            }
            if (column.accessor.startsWith('date')) {
                return (
                    <>{formatDate(new Date(item[column.accessor as keyof Project] as string))}</>
                );
            }
            if (column.accessor === 'budget') {
                let budgetValue = Number(item.budget);
                if (item.budgetCurrency !== 'NPR' && item.exchangeRate) {
                    budgetValue *= item.exchangeRate;
                }
                return (
                    <>
                        {formatCurrency(budgetValue as number)
                            ?.replace('Rs.', '')
                            ?.trim() || '-'}
                    </>
                );
            }
            if (column.accessor === 'status') {
                if (item[column.accessor]) {
                    return <StatusProgress title={statusData[item[column.accessor] as string]} />;
                }
                return '-';
            }
            return <>{item[column.accessor as keyof Project]}</>;
        },
        [organizations]
    );

    const handleRowClick = useCallback(
        (item: Project) => {
            navigate('../projects/' + item.id.toString(), { state: { project: item } });
        },
        [navigate]
    );

    return (
        <Table
            columns={columnData}
            className={cs(styles.table, className)}
            headerClassName={cs(styles.header, headerClassName)}
            renderHeaderItem={renderHeaderItem}
            renderDataItem={renderDataItem}
            bodyRowClassName={styles.bodyRow}
            dataClassName={styles.tableData}
            onRowClick={handleRowClick}
            EmptyComponent={
                <tr>
                    <td className={styles.tableMessage}>
                        <Localize>No projects found!</Localize>
                    </td>
                </tr>
            }
            LoadingComponent={
                <tr>
                    <td className={styles.tableMessage}>
                        <Localize>Loading projects...</Localize>
                    </td>
                </tr>
            }
            {...otherProps}
        />
    );
};

export default ProjectTable;
