import React, { useCallback } from 'react';
import { Link, NavLink, useMatch } from 'react-router-dom';

import Container from 'components/Container';
import Button from 'components/Button';
import LanguageSelect from 'components/LanguageSelect';
import SearchBar from 'components/SearchBar';

import logo from 'assets/images/MDSA_logo.svg';
import { Localize, _ } from 'services/i18n';
import { useAppSelector } from 'hooks/store';

import cs from '@ra/cs';

import styles from './styles.scss';

const NavBar: React.FC = () => {
    const match = useMatch({ path: 'register', end: false });

    const { isAuthenticated } = useAppSelector((state) => state.auth);

    const getActiveButtonClassName = useCallback(
        ({ isActive }: { isActive: boolean }) => {
            if (isActive) {
                return styles.activeAuthButton;
            }
            return cs({ [styles.authButton]: !match });
        },
        [match]
    );

    return (
        <div className={styles.navBarContainer}>
            <Container component="nav">
                <div className={styles.navBar}>
                    <div className={styles.navContent}>
                        <div className={styles.navBrand}>
                            <Link to="/">
                                <img className={styles.logo} src={logo} alt={_('MDSA Logo')} />
                            </Link>
                        </div>
                        <div className={styles.navMenu}>
                            <div className={styles.navLinks}>
                                <a
                                    className={styles.menuLink}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href="https://bipad.gov.np/"
                                >
                                    <span className={styles.menuLinkText}>
                                        <Localize>NDDRMA Website</Localize>
                                    </span>
                                    <span
                                        className={cs(
                                            'material-symbols-rounded',
                                            styles.menuLinkIcon
                                        )}
                                    >
                                        open_in_new
                                    </span>
                                </a>
                                <a
                                    className={styles.menuLink}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href="https://bipadportal.gov.np/"
                                >
                                    <span className={styles.menuLinkText}>
                                        <Localize>BIPAD Portal</Localize>
                                    </span>
                                    <span
                                        className={cs(
                                            'material-symbols-rounded',
                                            styles.menuLinkIcon
                                        )}
                                    >
                                        open_in_new
                                    </span>
                                </a>
                            </div>
                            <div className={styles.authButtons}>
                                {isAuthenticated ? (
                                    <NavLink to="/dashboard" className={getActiveButtonClassName}>
                                        <Button>
                                            <Localize>Dashboard</Localize>
                                        </Button>
                                    </NavLink>
                                ) : (
                                    <>
                                        <NavLink to="/login" className={getActiveButtonClassName}>
                                            <Button secondary={!match}>
                                                <Localize>Log in</Localize>
                                            </Button>
                                        </NavLink>
                                        <NavLink
                                            to="/register"
                                            className={getActiveButtonClassName}
                                        >
                                            <Button>
                                                <Localize>Register</Localize>
                                            </Button>
                                        </NavLink>
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className={styles.actionElements}>
                        <SearchBar className={styles.searchIcon} />
                        <LanguageSelect className={styles.languageSelect} />
                    </div>
                </div>
            </Container>
        </div>
    );
};

export default NavBar;
