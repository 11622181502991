import React from 'react';
import { CKEditor as Editor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

interface CkeditorProps {
    data: string;
    [key: string]: any;
}

const Ckeditor: React.FC<CkeditorProps> = (props) => {
    const { data, ...otherProps } = props;

    return <Editor editor={ClassicEditor} data={data} {...otherProps} />;
};

export default Ckeditor;
