import React from 'react';

import cs from '@ra/cs';

import styles from './styles.scss';

type ContainerProps = {
    className?: string;
    children: React.ReactNode;
    jumbotron?: boolean;
    component?: keyof JSX.IntrinsicElements;
};

const Container: React.FC<ContainerProps> = ({
    className,
    children,
    jumbotron,
    component: Component = 'section'
}) => {
    return (
        <Component
            className={cs(styles.container, className, { [styles.containerJumbotron]: jumbotron })}
        >
            {children}
        </Component>
    );
};

export default Container;
