import React, { useCallback } from 'react';
import SVG from 'react-inlinesvg';

import Container from 'components/Container';

import { rolesData } from 'services/data';

import { Localize } from '@ra/components/I18n';
import List from '@ra/components/List';

import RedArrowIcon from 'assets/icons/red-arrow.svg';

import styles from './styles.scss';

const keyExtractor = (item: { id: number }) => item.id;

const RoleCard = ({ item }: { item: { icon: string; title: string; list: { title: string } } }) => {
    const renderItems = useCallback(({ item }: { item: { title: string } }) => {
        return (
            <div className={styles.gridListItem}>
                <SVG className={styles.listItemIcon} src={RedArrowIcon}></SVG>
                <p className={styles.listItemContent}>
                    <Localize>{item.title}</Localize>
                </p>
            </div>
        );
    }, []);
    return (
        <div className={styles.roleCard}>
            <div className={styles.cardHeader}>
                <SVG className={styles.roleIcon} src={item.icon}></SVG>
                <p className={styles.title}>
                    <Localize>{item.title}</Localize>
                </p>
            </div>
            <List
                className={styles.gridList}
                keyExtractor={keyExtractor}
                data={item.list}
                renderItem={renderItems}
            />
        </div>
    );
};

const RolesSection: React.FC = () => {
    const renderItems = useCallback(
        ({ item }: { item: { icon: string; title: string; list: { title: string } } }) => {
            return <RoleCard item={item} />;
        },
        []
    );
    return (
        <Container jumbotron>
            <section className={styles.rolesSection}>
                <h1 className={styles.sectionTitle}>
                    <Localize>Roles of MDSA</Localize>
                </h1>
                <p className={styles.sectionDesc}>
                    <Localize>WHO, WHAT, WHERE, WHEN of projects</Localize>
                </p>
                <List
                    className={styles.roleList}
                    keyExtractor={keyExtractor}
                    data={rolesData}
                    renderItem={renderItems}
                />
            </section>
        </Container>
    );
};

export default RolesSection;
