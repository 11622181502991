import React, { useRef, useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Popup from '@ra/components/Popup';

import cs from '@ra/cs';
import { _ } from 'services/i18n';
import { useAppSelector, useAppDispatch } from 'hooks/store';
import { logout } from 'store/slices/auth';
import { formatOrganizationTitle } from 'utils/formatter';

import UserPlaceholder from 'assets/images/user-placeholder.webp';

import styles from './styles.scss';

const UserDropdown: React.FC = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const { user } = useAppSelector((state) => state.auth);
    const { organizations } = useAppSelector((state) => state.organization);

    const anchorRef: React.RefObject<HTMLDivElement> = useRef(null);

    const [isDropdownVisible, setDropdownVisible] = useState<boolean>(false);

    const nameValue = useMemo(() => {
        if (user.firstName) {
            return `${user.firstName} ${user.lastName || ''}`;
        }
        return user.username;
    }, [user]);

    const organizationTitle = useMemo(() => {
        if (user?.organization) {
            const organizationItem = organizations.find((org) => org.id === user.organization);
            if (organizationItem) {
                return formatOrganizationTitle(organizationItem);
            }
        }
        return '';
    }, [organizations, user]);

    const togglePopup = useCallback(() => setDropdownVisible((prev) => !prev), []);

    const handleGoToAccountSettings = useCallback(() => {
        setDropdownVisible(false);
        navigate('/account');
    }, [navigate]);

    const handleLogout = useCallback(() => {
        setDropdownVisible(false);
        navigate('/');
        dispatch(logout());
    }, [dispatch, navigate]);

    return (
        <>
            <div ref={anchorRef} className={styles.container} onClick={togglePopup}>
                <img className={styles.avatar} src={UserPlaceholder} alt={_('User Avatar')} />
                <div className={styles.userDetails}>
                    <p className={styles.userName}>{nameValue} </p>
                    <p className={styles.organization}>{organizationTitle}</p>
                </div>
                <span className={cs(styles.dropdownIcon, 'material-symbols-rounded')}>
                    expand_more
                </span>
            </div>
            <Popup
                isVisible={isDropdownVisible}
                anchor={anchorRef}
                anchorOrigin="bottom right"
                transformOrigin="top right"
                onClose={togglePopup}
                closeOnOutsideClick
                className={styles.dropdown}
            >
                <div className={styles.dropdownList}>
                    <p className={styles.dropdownItem} onClick={handleGoToAccountSettings}>
                        <span className={cs(styles.dropdownItemIcon, 'material-symbols-rounded')}>
                            settings
                        </span>
                        Account Settings
                    </p>
                    <p className={styles.dropdownItem} onClick={handleLogout}>
                        <span className={cs(styles.dropdownItemIcon, 'material-symbols-rounded')}>
                            logout
                        </span>
                        Logout
                    </p>
                </div>
            </Popup>
        </>
    );
};

export default UserDropdown;
