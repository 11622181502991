import { useMemo } from 'react';

import { _ } from 'services/i18n';

export interface DivisionLevelModel {
    id: string | number;
    code: string;
    label: string;
    title: string;
    tilesetURL: string;
    tilesetLayer: string;
}

const useAdministrativeDivisions = () => {
    const divisionLevels: DivisionLevelModel[] = useMemo(
        () => [
            {
                id: 1,
                code: '0',
                label: 'national',
                title: _('National'),
                tilesetURL: 'mapbox://neocmoha.05cn42md',
                tilesetLayer: 'provincegeo'
            },
            {
                id: 2,
                code: '1',
                label: 'provinces',
                title: _('Provincial'),
                tilesetURL: 'mapbox://neocmoha.05cn42md',
                tilesetLayer: 'provincegeo'
            },
            {
                id: 3,
                code: '2',
                label: 'districts',
                title: _('District'),
                tilesetURL: 'mapbox://neocmoha.05cn42md',
                tilesetLayer: 'districtgeo'
            },
            {
                id: 4,
                code: '3',
                label: 'municipalities',
                title: _('Local Level'),
                tilesetURL: 'mapbox://neocmoha.05cn42md',
                tilesetLayer: 'municipalitygeo'
            },
            {
                id: 5,
                code: '4',
                label: 'wards',
                title: _('Ward'),
                tilesetURL: 'mapbox://neocmoha.05cn42md',
                tilesetLayer: 'wardgeo'
            }
        ],
        []
    );

    return divisionLevels;
};

export default useAdministrativeDivisions;
