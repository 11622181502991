import React, { useEffect, useState, useCallback, useMemo } from 'react';

import SelectInput, {
    KeyExtractor,
    ValueExtractor,
    SelectInputChangeCallback
} from '@ra/components/Form/SelectInput';
import Label from '@ra/components/Form/Label';

import cs from '@ra/cs';
import { _, objTranslator, Localize } from 'services/i18n';
import { getRegions } from 'services/bootstrap';
import { useAppSelector } from 'hooks/store';

import type { Region } from 'services/types';

import styles from './styles.scss';

const idExtractor: KeyExtractor<Region> = (item) => item.id;
const titleExtractor: ValueExtractor<Region, string> = (item) => objTranslator(item, 'title');

interface LocationInputProps {
    name?: string;
    onChange: (payload: { name?: string; value: number }) => void;
    showRequired?: boolean;
    defaultValue?: number | string;
}

const LocationInput: React.FC<LocationInputProps> = (props) => {
    const { name, onChange, showRequired, defaultValue } = props;

    const { regions } = useAppSelector((state) => state.region);

    useEffect(() => {
        if (!regions.length) {
            getRegions();
        }
    }, []); // Deliberately kept empty

    const provinces = useMemo(() => regions.filter((r) => r.level === 1), [regions]);

    const [activeProvince, setActiveProvince] = useState<Region | null | undefined>();
    const [activeDistrict, setActiveDistrict] = useState<Region | null | undefined>();
    const [activeLocalLevel, setActiveLocalLevel] = useState<Region | null | undefined>();
    const [activeWard, setActiveWard] = useState<Region | null | undefined>();

    const districts = useMemo(() => {
        if (activeProvince) {
            return regions.filter((r) => r.level === 2 && r.parent === activeProvince.id);
        }
        return regions.filter((r) => r.level === 2);
    }, [regions, activeProvince]);

    const localLevels = useMemo(() => {
        if (activeDistrict) {
            return regions.filter((r) => r.level === 3 && r.parent === activeDistrict.id);
        }
        return regions.filter((r) => r.level === 3);
    }, [regions, activeDistrict]);

    useEffect(() => {
        if (defaultValue) {
            const regionObj = regions.find((reg) => reg.id === Number(defaultValue));
            if (regionObj) {
                if (regionObj.level === 1) {
                    setActiveProvince(regionObj);
                    return;
                } else if (regionObj.level === 2) {
                    const newActivePrv = regions.find((reg) => reg.id === regionObj.parent);
                    setActiveProvince(newActivePrv);
                    setActiveDistrict(regionObj);
                    return;
                } else if (regionObj.level === 3) {
                    const newActiveDist = regions.find((reg) => reg.id === regionObj.parent);
                    const newActivePrv = regions.find((reg) => reg.id === newActiveDist?.parent);
                    setActiveProvince(newActivePrv);
                    setActiveDistrict(newActiveDist);
                    setActiveLocalLevel(regionObj);
                    return;
                } else {
                    const newActiveLocalLevel = regions.find((reg) => reg.id === regionObj.parent);
                    const newActiveDist = regions.find(
                        (reg) => reg.id === newActiveLocalLevel?.parent
                    );
                    const newActivePrv = regions.find((reg) => reg.id === newActiveDist?.parent);
                    setActiveProvince(newActivePrv);
                    setActiveDistrict(newActiveDist);
                    setActiveLocalLevel(newActiveLocalLevel);
                    setActiveWard(regionObj);
                    return;
                }
            }
            setActiveWard(null);
            setActiveLocalLevel(null);
            setActiveDistrict(null);
            setActiveProvince(null);
        }
    }, [defaultValue, regions]);

    const handleProvinceChange: SelectInputChangeCallback<Region> = useCallback(
        ({ option }) => {
            if (option) {
                setActiveProvince(option);
                setActiveDistrict(null);
                setActiveLocalLevel(null);
                setActiveWard(null);
                onChange({ name, value: option.id });
            }
        },
        [onChange, name]
    );

    const handleDistrictChange: SelectInputChangeCallback<Region> = useCallback(
        ({ option }) => {
            setActiveDistrict(option);
            setActiveLocalLevel(null);
            setActiveWard(null);
            if (option) {
                return onChange({ name, value: option.id });
            }
            if (!option && activeProvince) {
                onChange({ name, value: activeProvince.id });
            }
        },
        [onChange, name, activeProvince]
    );

    const handleLocalLevelChange: SelectInputChangeCallback<Region> = useCallback(
        ({ option }) => {
            setActiveLocalLevel(option);
            setActiveWard(null);
            if (option) {
                return onChange({ name, value: option.id });
            }
            if (!option && activeDistrict) {
                onChange({ name, value: activeDistrict.id });
            }
        },
        [onChange, name, activeDistrict]
    );

    const wards = useMemo(() => {
        if (activeLocalLevel) {
            return regions
                .filter((r) => r.level === 4 && r.parent === activeLocalLevel.id)
                .sort((r1, r2) => Number(r1.title) - Number(r2.title));
        }
        return regions.filter((r) => r.level === 4);
    }, [regions, activeLocalLevel]);
    const handleWardChange: SelectInputChangeCallback<Region> = useCallback(
        ({ option }) => {
            setActiveWard(option);
            if (option) {
                return onChange({ name, value: option.id });
            }
            if (!option && activeLocalLevel) {
                onChange({ name, value: activeLocalLevel.id });
            }
        },
        [onChange, name, activeLocalLevel]
    );

    return (
        <div className={styles.container}>
            <div className={styles.inputContainer}>
                <Label className={styles.label}>
                    <Localize>Province*</Localize>
                </Label>
                <SelectInput
                    keyExtractor={idExtractor}
                    placeholder={_('Select province')}
                    onChange={handleProvinceChange}
                    valueExtractor={titleExtractor}
                    options={provinces}
                    clearable={false}
                    className={styles.select}
                    optionsWrapperClassName={styles.optionsWrapper}
                    selectOptionClassName={styles.selectOptionStyle}
                    optionItemClassName={styles.optionItemStyle}
                    controlClassName={styles.selectControl}
                    showRequired={showRequired}
                    defaultValue={activeProvince}
                    loading={regions.length === 0}
                />
            </div>
            <div className={styles.inputContainer}>
                <Label className={styles.label}>
                    <Localize>District</Localize>
                </Label>
                <SelectInput
                    keyExtractor={idExtractor}
                    placeholder={_('Select district')}
                    onChange={handleDistrictChange}
                    valueExtractor={titleExtractor}
                    options={districts}
                    disabled={!activeProvince}
                    className={cs(styles.select, {
                        [styles.selectDisabled]: !activeProvince
                    })}
                    optionsWrapperClassName={styles.optionsWrapper}
                    selectOptionClassName={styles.selectOptionStyle}
                    optionItemClassName={styles.optionItemStyle}
                    controlClassName={styles.selectControl}
                    defaultValue={activeDistrict}
                />
            </div>
            <div className={styles.inputContainer}>
                <Label className={styles.label}>
                    <Localize>Local level</Localize>
                </Label>
                <SelectInput
                    keyExtractor={idExtractor}
                    placeholder={_('Select local level')}
                    onChange={handleLocalLevelChange}
                    valueExtractor={titleExtractor}
                    options={localLevels}
                    disabled={!activeDistrict}
                    className={cs(styles.select, {
                        [styles.selectDisabled]: !activeDistrict
                    })}
                    optionsWrapperClassName={styles.optionsWrapper}
                    selectOptionClassName={styles.selectOptionStyle}
                    optionItemClassName={styles.optionItemStyle}
                    controlClassName={styles.selectControl}
                    defaultValue={activeLocalLevel}
                />
            </div>
            <div className={styles.inputContainer}>
                <Label className={styles.label}>
                    <Localize>Ward</Localize>
                </Label>
                <SelectInput
                    keyExtractor={idExtractor}
                    placeholder={_('Select ward')}
                    onChange={handleWardChange}
                    valueExtractor={titleExtractor}
                    options={wards}
                    disabled={!activeLocalLevel}
                    className={cs(styles.select, {
                        [styles.selectDisabled]: !activeLocalLevel
                    })}
                    optionsWrapperClassName={styles.optionsWrapper}
                    selectOptionClassName={styles.selectOptionStyle}
                    optionItemClassName={styles.optionItemStyle}
                    controlClassName={styles.selectControl}
                    defaultValue={activeWard}
                />
            </div>
        </div>
    );
};

export default LocationInput;
