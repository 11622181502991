import React, { useEffect, useState, useRef } from 'react';

import styles from './styles.scss';

interface MarqueeProps {
    direction?: 'left' | 'right';
    children?: React.ReactNode;
}

const Marquee: React.FC<MarqueeProps> = ({ direction = 'left', children }) => {
    const containerRef = useRef<HTMLDivElement>(null);
    const marqueeRef = useRef<HTMLDivElement>(null);
    const [containerWidth, setContainerWidth] = useState(0);
    const [marqueeWidth, setMarqueeWidth] = useState(0);

    useEffect(() => {
        const calculateWidth = () => {
            if (marqueeRef.current && containerRef.current) {
                setContainerWidth(containerRef.current.getBoundingClientRect().width);
                setMarqueeWidth(marqueeRef.current.getBoundingClientRect().width);
            }
        };

        calculateWidth();
        window.addEventListener('resize', calculateWidth);

        return () => {
            window.removeEventListener('resize', calculateWidth);
        };
    }, []);

    // Animation duration
    const speed = 90;
    const duration = marqueeWidth < containerWidth ? containerWidth / speed : marqueeWidth / speed;

    return (
        <div ref={containerRef} className={styles.marqueeContainer}>
            <div className={styles.overlay} />
            <div
                ref={marqueeRef}
                style={{
                    ['--direction' as string]: direction === 'left' ? 'normal' : 'reverse',
                    ['--duration' as string]: `${duration}s`
                }}
                className={styles.marquee}
            >
                {children}
            </div>
            <div
                style={{
                    ['--direction' as string]: direction === 'left' ? 'normal' : 'reverse',
                    ['--duration' as string]: `${duration}s`
                }}
                className={styles.marquee}
                aria-hidden="true"
            >
                {children}
            </div>
        </div>
    );
};

export default Marquee;
