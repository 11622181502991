import React, { useCallback, useEffect, useMemo } from 'react';

import HTMLParser from 'components/HTMLParser';
import { OrganizationItem } from 'components/OrganizationList';
import ProjectDocuments from 'components/ProjectDocuments';
import Tabs, { Tab } from 'components/Tabs';
import Modal, { type ModalProps } from '@ra/components/Modal';

import Api from 'services/api';
import cs from '@ra/cs';
import { Localize, _ } from 'services/i18n';
import { formatNumber } from 'utils/formatter';

import { useAppSelector } from 'hooks/store';
import usePromise from '@ra/hooks/usePromise';

import type { ProjectDonor, CreateProjectDonorImplementor } from 'services/types';

import styles from './styles.scss';

interface ExpandedProjectDonor extends ProjectDonor {
    implementors?: CreateProjectDonorImplementor[];
}

interface ImplementorItemProps {
    implementor: CreateProjectDonorImplementor;
}

const ImplementorItem: React.FC<ImplementorItemProps> = ({ implementor }) => {
    const { organizations } = useAppSelector((state) => state.organization);

    const organization = useMemo(() => {
        return organizations.find((org) => org.id === implementor.implementor);
    }, [organizations, implementor]);

    const budgetValue = useMemo(() => {
        if (implementor?.budget) {
            return formatNumber(implementor.budget);
        }
        return 'n/a';
    }, [implementor]);

    return (
        <>
            <h4 className={styles.contentTitle}>
                <Localize>BUDGET DETAILS</Localize>
            </h4>
            <div className={styles.contentRow}>
                <div className={styles.infoItemContainer}>
                    <div className={styles.infoItemContent}>
                        <p className={styles.infoItemContentKey}>
                            <Localize>Total budget</Localize>
                        </p>
                        <p className={styles.infoItemContentValue}>
                            {implementor.budgetCurrency + ' ' + budgetValue}
                        </p>
                        {implementor.budgetCurrency !== 'NPR' && (
                            <p className={styles.exchangeRate}>
                                {`1 ${implementor.budgetCurrency} = ${implementor.exchangeRate} NPR`}
                            </p>
                        )}
                    </div>
                </div>
                <div className={styles.infoItemContainer}>
                    <div className={styles.infoItemContent}>
                        <p className={styles.infoItemContentKey}>
                            <Localize>Administrative budget</Localize>
                        </p>
                        <p className={styles.infoItemContentValue}>{`NPR ${formatNumber(
                            implementor.administrativeBudget
                        )}`}</p>
                    </div>
                </div>
            </div>
            <h4 className={styles.contentTitle}>
                <Localize>ORGANIZATION</Localize>
            </h4>
            <div className={styles.contentItem}>
                {organization && <OrganizationItem index={0} item={organization} />}
            </div>
        </>
    );
};

type DonorModalProps = Omit<ModalProps, 'children'> & {
    projectDonor: ExpandedProjectDonor;
};

const DonorModal: React.FC<DonorModalProps> = (props) => {
    const { projectDonor, onClose, ...modalProps } = props;

    const handleClose = useCallback(() => {
        onClose?.();
    }, [onClose]);

    const [{ result: documentsResult }, getDocuments] = usePromise(Api.getProjectDonorDocuments);

    useEffect(() => {
        getDocuments({ project_donor: projectDonor?.id });
    }, [projectDonor, getDocuments]);

    return (
        <Modal className={styles.modal} {...modalProps}>
            <div className={styles.header}>
                <h2 className={styles.title}>{projectDonor.title}</h2>
                <span
                    className={cs('material-symbols-rounded', styles.closeIcon)}
                    onClick={handleClose}
                >
                    close
                </span>
            </div>
            <div className={styles.content}>
                <p className={styles.description}>
                    <HTMLParser content={projectDonor.description} />
                </p>
                <ProjectDocuments
                    type="agreements"
                    documents={documentsResult?.results ?? []}
                    headerClassName={styles.contentItemTitle}
                    headerTitle={_('Project Agreements')}
                    contentContainerClassName={styles.contentItem}
                />
                {Boolean(projectDonor?.implementors?.length) && (
                    <Tabs
                        primary
                        headerClassName={styles.tabHeader}
                        contentContainerClassName={styles.tabContentContainer}
                        defaultActiveTab="implementor-1"
                    >
                        {projectDonor?.implementors?.map((implementor, implementorIdx) => (
                            <Tab
                                label={`implementor-${implementorIdx + 1}`}
                                title={`Implementor ${implementorIdx + 1}`}
                            >
                                <ImplementorItem implementor={implementor} />
                            </Tab>
                        ))}
                    </Tabs>
                )}
            </div>
        </Modal>
    );
};

export default DonorModal;
