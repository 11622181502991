import React, { useState, useCallback, useRef, useMemo } from 'react';

import Button from 'components/Button';
import Popup from '@ra/components/Popup';

import cs from '@ra/cs';
import { _ } from 'services/i18n';
import { useFilters } from 'hooks/filters';

import styles from './styles.scss';

interface DropdownSelectorProps {
    title: string;
    icon: string;
    children?: React.ReactNode;
    filterType: string;
}

const DropdownSelector: React.FC<DropdownSelectorProps> = (props) => {
    const { children, title, icon, filterType } = props;

    const filters = useFilters();

    const [isDropdownVisible, setDropdownVisible] = useState<boolean>(false);

    const anchorRef = useRef<HTMLButtonElement>(null);

    const togglePopup = useCallback(() => setDropdownVisible((prev) => !prev), []);

    const thisFilters = useMemo(() => {
        return filters.filter((el) => el.filterType === filterType);
    }, [filters, filterType]);

    const titleText = useMemo(() => {
        if (thisFilters.length === 0) {
            return title;
        }
        if (filterType === 'Project-Status' || filterType === 'Region-Status') {
            let titleValue = thisFilters[0].filterValue as string;
            if (thisFilters.length > 1) {
                titleValue += ` + ${thisFilters.length - 1} ${_('more')}`;
            }
            return titleValue;
        }
        if (filterType === 'Project-Budget' || filterType === 'Region-Budget') {
            return thisFilters[0].valueExtractor?.(thisFilters[0]) || '';
        }
        if (thisFilters.length) {
            return thisFilters.length + ' ' + title.toLowerCase();
        }
    }, [thisFilters, filterType]);

    return (
        <>
            <Button
                ref={anchorRef}
                small
                tertiary
                leftIcon={icon}
                iconClassName={styles.icon}
                rightIcon={isDropdownVisible ? 'chevron_right' : 'expand_more'}
                className={cs(styles.labelButton, {
                    [styles.labelButtonActive]: isDropdownVisible,
                    [styles.labelButtonFilled]: thisFilters.length > 0
                })}
                onClick={togglePopup}
            >
                {titleText}
            </Button>
            <Popup
                isVisible={isDropdownVisible}
                anchor={anchorRef}
                closeOnOutsideClick
                onClose={togglePopup}
                anchorOrigin="bottom right"
                transformOrigin="top right"
                className={styles.dropdown}
            >
                {children}
            </Popup>
        </>
    );
};

export default DropdownSelector;
