import React, { useState, useCallback, useMemo, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import type { MapboxGeoJSONFeature } from 'react-map-gl';

import Map from 'components/Map';
import ProjectBar from 'components/ProjectBar';

import Api from 'services/api';
import cs from '@ra/cs';
import { _ } from 'services/i18n';
import { debounce } from '@ra/utils';

import useAdministrativeDivisions, { DivisionLevelModel } from 'hooks/useAdministrativeDivisions';
import usePromise from '@ra/hooks/usePromise';
import { useFilters, useFiltersDispatch } from 'hooks/filters';
import { useAppSelector } from 'hooks/store';

import type { Region, MPTTStats } from 'services/types';

import styles from './styles.scss';

const MapDashboard: React.FC = () => {
    const regions = useAppSelector((state) => state.region.regions);

    const mapFilters = useFilters('Map-');
    const filtersDispatch = useFiltersDispatch();

    const navigate = useNavigate();

    const divisionLevels = useAdministrativeDivisions();

    const [activeLevel, setActiveLevel] = useState<DivisionLevelModel>(divisionLevels[1]);

    const [collapsed, setCollapsed] = useState<boolean>(false);

    const toggleCollapsed = useCallback(() => setCollapsed((prev) => !prev), []);

    const [{ result, loading, error }, getMapStats] = usePromise(Api.getLocationStats);
    const [{ result: donorResult }, getDonorStats] = usePromise(Api.getDonorStats);

    const loadMapStats = useCallback(debounce(getMapStats, 1000), [getMapStats]);
    const loadDonorStats = useCallback(debounce(getDonorStats, 1000), [getDonorStats]);

    useEffect(() => {
        const filterQueries = mapFilters.reduce(
            (acc, cur) => {
                if (cur.filterType.includes('-Hazard:') && cur.query?.hazards__in) {
                    const addStr = acc.hazards__in
                        ? `,${cur.query.hazards__in}`
                        : cur.query.hazards__in;
                    acc.hazards__in += addStr;
                    return acc;
                }
                if (cur.filterType.includes('-Status') && cur.query?.status__in) {
                    const addStr = acc.status__in
                        ? `,${cur.query.status__in}`
                        : cur.query.status__in;
                    acc.status__in += addStr;
                    return acc;
                }
                if (cur.filterType === 'Map-Region:' && cur.query?.location) {
                    const addStr = acc.location__in ? `,${cur.query.location}` : cur.query.location;
                    acc.location__in += addStr;
                    return acc;
                }
                if (
                    cur.filterType === 'Map-Theme:' &&
                    cur.query?.outputs__activities__priority_indicators__in
                ) {
                    const addStr = acc.outputs__activities__priority_indicators__in
                        ? `,${cur.query.outputs__activities__priority_indicators__in}`
                        : cur.query.outputs__activities__priority_indicators__in;
                    acc.outputs__activities__priority_indicators__in += addStr;
                    return acc;
                }
                Object.assign(acc, cur.query);
                return acc;
            },
            {
                status__in: '',
                location__in: '',
                hazards__in: '',
                outputs__activities__priority_indicators__in: ''
            }
        );
        loadMapStats(filterQueries);
        loadDonorStats(filterQueries);
    }, [loadMapStats, loadDonorStats, mapFilters]);
    const mapData = useMemo(() => {
        return result?.filter((res: MPTTStats) => res.level === Number(activeLevel.code)) || [];
    }, [result, activeLevel]);

    const donorData = useMemo(() => {
        return donorResult || [];
    }, [donorResult]);

    const handleLevelChange = useCallback(
        ({ activeTab }: { activeTab: string }) => {
            const newActiveLevel = divisionLevels.find((dvl) => dvl.label === activeTab);
            if (newActiveLevel) {
                setActiveLevel(newActiveLevel);
            }
        },
        [divisionLevels]
    );

    const handleGoToProjects = useCallback(
        (feature: MapboxGeoJSONFeature | null) => {
            const featureRegion = regions.find(
                (reg: Region) =>
                    feature &&
                    Number(reg.code) === feature.id &&
                    reg.title === feature.properties?.title
            );
            if (!featureRegion) {
                return;
            }
            filtersDispatch({
                type: 'add',
                filter: {
                    filterType: 'Region-Region',
                    filterValue: featureRegion,
                    query: { location: featureRegion.id }
                }
            });
            navigate({ pathname: 'details', search: `?region=${featureRegion.id}` });
        },
        [navigate, regions, filtersDispatch]
    );

    return (
        <div className={styles.contentContainer}>
            <div
                className={cs(styles.controlIconContainer, {
                    [styles.controlIconContainerCollapsed]: collapsed
                })}
                onClick={toggleCollapsed}
            >
                <span className="material-symbols-rounded">
                    {collapsed ? 'chevron_right' : 'chevron_left'}
                </span>
            </div>
            <ProjectBar
                className={cs(styles.projectsBar, { [styles.projectsBarCollapsed]: collapsed })}
                mapData={mapData}
                donorData={donorData}
                divisionLevels={divisionLevels.slice(0, 4)}
                activeLevel={activeLevel}
                onLevelChange={handleLevelChange}
                loading={(!result && !error) || loading}
            />
            <Map
                navigationControlStyle={{ marginRight: '1.25rem', marginTop: '2rem' }}
                style={{ width: '100%', height: '100%' }}
                activeDivisionLevel={activeLevel}
                className={styles.mapContainer}
                legendClassName={styles.mapLegend}
                mapData={mapData}
                showActiveFeaturePopup
                popupButtonText={_('View projects')}
                onPopupButtonClick={handleGoToProjects}
                scrollZoom
            />
        </div>
    );
};

export default MapDashboard;
