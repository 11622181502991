import React, { useCallback, useState } from 'react';
import lodash from 'lodash';

import Button from 'components/Button';
import Modal, { type ModalProps } from '@ra/components/Modal';
import SelectInput, {
    SelectInputChangeCallback,
    ValueExtractor,
    KeyExtractor
} from '@ra/components/Form/SelectInput';
import Tabs, { Tab, type TabChangeCallback } from 'components/Tabs';
import Form, { type FormSubmitCallback } from '@ra/components/Form/Advanced';

import cs from '@ra/cs';
import { _, Localize } from 'services/i18n';
import Toast from 'services/toast';
import Api from 'services/api';
import {
    agreementDocumentTypeTitles,
    phoneNumberValidationRegex,
    reportDocumentTypeTitles
} from 'utils/form';
import { parseErrorObject } from 'utils/formatter';

import { useAppSelector } from 'hooks/store';
import usePromise from '@ra/hooks/usePromise';

import type { ProjectType } from 'containers/Projects';
import type { ExpandedProject } from 'services/report';
import type { ProjectDocument } from 'services/types';

import DonorInformationForm from './DonorInformationForm';
import ImplementorInformationForm from './ImplementorInformationForm';
import OutputForm from './OutputForm';

import styles from './styles.scss';

export type ProjectFormModalProps = Omit<ModalProps, 'children'> & {
    editMode?: boolean;
    projectTypes?: ProjectType[];
    onChangeProjectType?: (arg: ProjectType) => void;
    activeProjectType: ProjectType | null;
    projectDonorId?: number | null;
    onComplete?: () => void;
    project?: ExpandedProject;
    documents?: ProjectDocument[];
};

const codeExtractor: KeyExtractor<ProjectType> = (item) => item.code;
const titleExtractor: ValueExtractor<ProjectType, string> = (item) => item.title;

const ProjectFormModal: React.FC<ProjectFormModalProps> = (props) => {
    const {
        onClose,
        projectTypes,
        activeProjectType,
        onChangeProjectType,
        projectDonorId,
        onComplete,
        editMode,
        project,
        documents,
        ...modalProps
    } = props;

    const { user } = useAppSelector((state) => state.auth);
    const { projectDocumentTypes } = useAppSelector((state) => state.projectDocumentType);

    const [activeTab, setActiveTab] = useState<string>('information');
    const handleTabChange: TabChangeCallback = useCallback(({ activeTab: newTab }) => {
        setActiveTab(newTab);
    }, []);

    const [donorError, setDonorError] = useState<any>(null);
    const [implementorError, setImplementorError] = useState<any>(null);

    const handleContinuePress = useCallback(() => {
        setActiveTab('output');
    }, []);

    const handleProjectTypeChange: SelectInputChangeCallback<ProjectType> = useCallback(
        ({ option }) => {
            onChangeProjectType?.(option as ProjectType);
        },
        [onChangeProjectType]
    );

    const handleClose = useCallback(() => {
        setActiveTab('information');
        onClose?.();
    }, [onClose]);

    const [{ loading: loadingDonor }, createDonorProject] = usePromise(Api.createProjectDonor);
    const [{ loading: loadingDonorChild }, createDonorChildProject] = usePromise(
        Api.createChildProjectDonor
    );
    const handleSubmitDonorForm: FormSubmitCallback = useCallback(
        async (formData) => {
            setDonorError(null);
            let documentIdx = 0;
            for (const [key, value] of formData) {
                const agreementDocumentType = agreementDocumentTypeTitles.find((docName) => {
                    return key.endsWith(lodash.camelCase(docName));
                });
                if (agreementDocumentType && !['undefined', 'null'].includes(value as string)) {
                    const documentType = projectDocumentTypes.find(
                        (docType) => docType.title === agreementDocumentType
                    );
                    if (documentType) {
                        formData.append(
                            `documents[${documentIdx}]documentType`,
                            String(documentType.id)
                        );
                        formData.append(`documents[${documentIdx}]documentUrl`, value);
                        formData.append(`documents[${documentIdx}]title`, agreementDocumentType);
                        documentIdx += 1;
                    }
                }
            }
            agreementDocumentTypeTitles.forEach((doc) => formData.delete(lodash.camelCase(doc)));
            const createDonorProjectFormData: Record<string, any> = {};
            for (const [key, value] of formData) {
                lodash.set(createDonorProjectFormData, key, value);
            }
            if (!createDonorProjectFormData.documents) {
                createDonorProjectFormData.documents = [];
            }
            try {
                if (projectDonorId) {
                    await createDonorChildProject(
                        projectDonorId as number,
                        createDonorProjectFormData
                    );
                } else {
                    await createDonorProject(user.organization, createDonorProjectFormData);
                }
                Toast.show(
                    _(
                        'You have successfully created a project as donor. The implementors will be notified for further details.'
                    ),
                    Toast.SUCCESS,
                    10
                );
                onComplete?.();
                handleClose();
            } catch (err) {
                const parsedError = parseErrorObject(err);
                setDonorError(parsedError);
                const [[errorKey, errorValue]] = Object.entries(parsedError);
                const errorFormKey = (errorKey.charAt(0).toUpperCase() + errorKey.slice(1))
                    .replace(/\[/g, '-')
                    .replace(/\]/g, '-');
                const errorMessage: string = (errorFormKey ? errorFormKey + ': ' : '') + errorValue;
                Toast.show(errorMessage || _('An error occured'), Toast.DANGER, 10);
            }
        },
        [
            createDonorProject,
            createDonorChildProject,
            user,
            handleClose,
            projectDonorId,
            onComplete,
            projectDocumentTypes
        ]
    );

    const [{ loading: loadingImplementor }, createProjectAsImplementor] = usePromise(
        Api.createProject
    );
    const [{ loading: loadingDonationImplementor }, createDonationImplementorProject] = usePromise(
        Api.createImplementorProject
    );
    const [{ loading: loadingPatchProject }, patchProject] = usePromise(Api.patchProject);
    const handleSubmitImplementorForm: FormSubmitCallback = useCallback(
        async (formData) => {
            setImplementorError(null);
            let documentIdx = 0;
            for (const [key, value] of formData) {
                if (key.endsWith('duration')) {
                    const durationValue = value as string;
                    const [dateFrom, dateTo] = durationValue.split(',');
                    formData.append(key.replace(/duration$/, '') + 'dateFrom', dateFrom);
                    formData.append(key.replace(/duration$/, '') + 'dateTo', dateTo);
                    formData.delete(key);
                }
                if (key === 'hazards' && (value === 'undefined' || value === 'null')) {
                    formData.delete('hazards');
                }
                if (key === 'hazards' && !['null', 'undefined'].includes(value as string)) {
                    formData.delete('hazards');
                    const hazardPks = String(value).split(',');
                    hazardPks.forEach((pk, idx) => {
                        formData.append(`hazards[${idx}]`, pk);
                    });
                }
                if (key.endsWith('partners')) {
                    const partnerOrganizationsPks = String(value)
                        .split(',')
                        .filter((pkStr) => pkStr !== 'null');
                    if (partnerOrganizationsPks.length > 0) {
                        partnerOrganizationsPks.forEach((pk, idx) => {
                            formData.set(key.replace(/partners$/, `partners[${idx}]`), pk);
                        });
                    }
                }
                const agreementDocumentType = agreementDocumentTypeTitles.find((docName) => {
                    return key.endsWith(lodash.camelCase(docName));
                });
                if (key === `documents[${documentIdx}]id`) {
                    documentIdx += 1;
                }
                if (agreementDocumentType && !['undefined', 'null'].includes(value as string)) {
                    const documentType = projectDocumentTypes.find(
                        (docType) => docType.title === agreementDocumentType
                    );
                    if (documentType) {
                        formData.append(
                            `documents[${documentIdx}]documentType`,
                            String(documentType.id)
                        );
                        formData.append(`documents[${documentIdx}]documentUrl`, value);
                        formData.append(`documents[${documentIdx}]title`, agreementDocumentType);
                        documentIdx += 1;
                    }
                }
                if (/priorityIndicators\[[0-9]+\]id$/.test(key) && isNaN(Number(value))) {
                    const priorityIndicator = JSON.parse(value as string);
                    if (priorityIndicator) {
                        formData.append(key.replace(/id$/, 'title'), priorityIndicator.title);
                        formData.append(
                            key.replace(/id$/, 'isCustomIndicator'),
                            priorityIndicator.isCustomIndicator
                        );
                        formData.append(key.replace(/id$/, 'parent'), priorityIndicator.parent);
                        formData.delete(key);
                    }
                }
            }
            for (const [key, value] of formData) {
                if (key.endsWith('partners')) {
                    formData.delete(key);
                }
                if (['null', 'undefined'].includes(value as string)) {
                    formData.delete(key);
                }
            }
            agreementDocumentTypeTitles.forEach((doc) => formData.delete(lodash.camelCase(doc)));
            const reportDocumentTypes = reportDocumentTypeTitles
                .map((docTypeTitle) => {
                    return projectDocumentTypes.find((docType) => docType.title === docTypeTitle);
                })
                .filter(Boolean);
            for (const reportDocumentType of reportDocumentTypes) {
                const existingReportDocument = documents?.find(
                    (doc) => doc.documentType === reportDocumentType?.id
                );
                if (existingReportDocument) {
                    formData.append(
                        `documents[${documentIdx}]id`,
                        String(existingReportDocument.id)
                    );
                    documentIdx += 1;
                }
            }
            try {
                if (!phoneNumberValidationRegex.test(formData.get('contactPhone') as string)) {
                    throw {
                        errors: { phoneNumber: ['The phone number you entered is not valid.'] }
                    };
                }
                if (editMode) {
                    const jsonFormData: any = {};
                    for (const [key, value] of formData) {
                        lodash.set(jsonFormData, key, value);
                    }
                    await patchProject(project?.id as number, jsonFormData);
                    Toast.show(_('Project has been successfully edited!'), Toast.SUCCESS, 10);
                    onComplete?.();
                    return handleClose();
                }
                const createProjectFormData = {};
                for (const [key, value] of formData) {
                    lodash.set(createProjectFormData, key, value);
                }
                if (projectDonorId) {
                    await createDonationImplementorProject(projectDonorId, createProjectFormData);
                } else {
                    await createProjectAsImplementor(createProjectFormData);
                }
                Toast.show(
                    _('You have successfully created a project as implementor.'),
                    Toast.SUCCESS,
                    10
                );
                onComplete?.();
                handleClose();
            } catch (err: any) {
                console.log(err);
                if (err?.message) {
                    return Toast.show(err.message, Toast.DANGER);
                }
                const parsedError = parseErrorObject(err);
                setImplementorError(parsedError);
                const [[errorKey, errorValue]] = Object.entries(parsedError);
                const errorFormKey = (errorKey.charAt(0).toUpperCase() + errorKey.slice(1))
                    .replace(/\[/g, '-')
                    .replace(/\]/g, '-');
                const errorMessage: string = (errorFormKey ? errorFormKey + ': ' : '') + errorValue;
                Toast.show(errorMessage || _('An error occured'), Toast.DANGER, 10);
            }
        },
        [
            createProjectAsImplementor,
            createDonationImplementorProject,
            handleClose,
            projectDonorId,
            onComplete,
            project,
            editMode,
            patchProject,
            projectDocumentTypes,
            document
        ]
    );

    const handleInvalidSubmit = useCallback((reason: string) => {
        if (reason === 'required') {
            Toast.show(
                _(
                    'Some required fields are empty. Please check the form and fill up any required fields!'
                ),
                Toast.DANGER
            );
        }
    }, []);

    return (
        <Modal className={styles.modal} {...modalProps}>
            <div className={styles.header}>
                <div className={cs(styles.contentLayout, styles.headerContent)}>
                    <div className={styles.headerTitle}>
                        <h1 className={styles.title}>
                            {editMode ? _('Edit project') : _('Create a project')}
                        </h1>
                        {!editMode && (
                            <div className={styles.subText}>
                                <Localize>as</Localize>
                                <SelectInput
                                    className={styles.select}
                                    controlClassName={styles.selectControl}
                                    options={projectTypes}
                                    keyExtractor={codeExtractor}
                                    valueExtractor={titleExtractor}
                                    onChange={handleProjectTypeChange}
                                    value={activeProjectType}
                                    defaultValue={projectTypes?.[0]}
                                    clearable={false}
                                    searchable={false}
                                />
                            </div>
                        )}
                    </div>
                    <Button
                        type="submit"
                        loading={
                            loadingDonor ||
                            loadingDonorChild ||
                            loadingDonationImplementor ||
                            loadingImplementor ||
                            loadingPatchProject
                        }
                        form={
                            activeProjectType?.code === 'implementor'
                                ? 'implementor-project-form'
                                : 'donor-project-form'
                        }
                        className={styles.headerButton}
                        tabIndex={1}
                    >
                        <Localize>Save project</Localize>
                    </Button>
                </div>
                <div className={styles.closeIconContainer} onClick={handleClose}>
                    <span className={cs('material-symbols-rounded', styles.closeIcon)}>close</span>
                </div>
            </div>
            {activeProjectType?.code === 'implementor' ? (
                <Form
                    error={implementorError}
                    id="implementor-project-form"
                    onSubmit={handleSubmitImplementorForm}
                    onInvalidSubmit={handleInvalidSubmit}
                >
                    <Tabs
                        className={styles.contentContainer}
                        headerContainerClassName={styles.tabHeaderContainer}
                        headerClassName={styles.contentLayout}
                        tabItemClassName={styles.tabHeaderItem}
                        activeTabItemClassName={styles.tabHeaderItemActive}
                        contentContainerClassName={styles.tabContentContainer}
                        tertiary
                        activeTab={activeTab}
                        onChange={handleTabChange}
                        disableUnmount
                    >
                        <Tab
                            label="information"
                            title={_('Project Information')}
                            className={cs(styles.contentLayout, styles.formContainer)}
                        >
                            <ImplementorInformationForm
                                project={project}
                                documents={documents}
                                editMode={editMode}
                                onContinuePress={handleContinuePress}
                            />
                        </Tab>
                        <Tab label="output" title={_('Output')} className={styles.contentLayout}>
                            <OutputForm defaultOutputs={project?.outputs} />
                        </Tab>
                    </Tabs>
                </Form>
            ) : (
                <div className={styles.contentLayoutWrapper}>
                    <div className={cs(styles.contentLayout, styles.formContainer)}>
                        <DonorInformationForm error={donorError} onSubmit={handleSubmitDonorForm} />
                    </div>
                </div>
            )}
        </Modal>
    );
};

export default ProjectFormModal;
