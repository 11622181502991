import React, { useCallback, useMemo } from 'react';

import List, { ListRenderItem, KeyExtractor } from '@ra/components/List';

import cs from '@ra/cs';
import { Localize } from 'services/i18n';
import { formatLongTitle } from 'utils/formatter';

import { useFilters, useFiltersDispatch, FilterItemType } from 'hooks/filters';

import styles from './styles.scss';

const keyExtractor: KeyExtractor<FilterItemType> = (item, index) => index;

const FilterItem: ListRenderItem<FilterItemType> = (props) => {
    const { item } = props;

    const dispatch = useFiltersDispatch();

    const handleRemoveFilter = useCallback(() => {
        dispatch({ type: 'remove', filter: item });
    }, [item, dispatch]);

    const textValue = useMemo(() => {
        if (item.valueExtractor) {
            return item.valueExtractor(item);
        }
        return `${item.filterType.replace('Map-', '')} ${item.filterValue}`;
    }, [item]);

    return (
        <div className={styles.filterItem}>
            <span title={textValue as string} className={styles.filterItemText}>
                {formatLongTitle(textValue as string, 72)}
            </span>
            <span
                onClick={handleRemoveFilter}
                className={cs(styles.filterItemIcon, 'material-symbols-rounded')}
            >
                close
            </span>
        </div>
    );
};

const MapFilterList: React.FC = () => {
    const dispatch = useFiltersDispatch();
    const filters = useFilters('Map-');

    const handleClearFilters = useCallback(() => {
        dispatch({ type: 'clear', category: 'Map' });
    }, [dispatch]);

    if (filters.length === 0) {
        return null;
    }

    return (
        <div className={styles.container}>
            <List
                className={styles.list}
                data={filters}
                renderItem={FilterItem}
                keyExtractor={keyExtractor}
            />
            <p className={styles.clearLink} tabIndex={0} onClick={handleClearFilters}>
                <Localize>Clear all</Localize> ({filters.length})
            </p>
        </div>
    );
};

export default MapFilterList;
