import React, { useCallback, useMemo, useState, useRef } from 'react';

import CheckboxInput from '@ra/components/Form/CheckboxInput';
import List, { ListRenderItem, ListRenderItemProps, KeyExtractor } from '@ra/components/List';

import cs from '@ra/cs';
import { objTranslator } from 'services/i18n';

import { useAppSelector } from 'hooks/store';
import { useFilters, useFiltersDispatch, FilterItemType } from 'hooks/filters';

import type { Region } from 'services/types';

import styles from './styles.scss';

type ChecklistItemType = {
    id: string | number;
    title: string;
    [key: string]: any;
};

const keyExtractor: KeyExtractor<ChecklistItemType> = (item) => item.id;

const checklistFilterValueExtractor = (payload: FilterItemType) => {
    if (!payload.filterValue) {
        return '';
    }
    if (typeof payload.filterValue === 'string') {
        return payload.filterValue;
    }
    const regionValue = payload.filterValue as Region;
    return objTranslator(regionValue, 'title');
};

interface DistrictSubItemProps extends ListRenderItemProps<ChecklistItemType> {
    onToggle?: (payload: { item: ChecklistItemType; checked: boolean }) => void;
    filters: FilterItemType[];
    filterType: string;
}

const DistrictSubItem: React.FC<DistrictSubItemProps> = (props) => {
    const { item, index, onToggle, filters, filterType } = props;

    const isActive = useMemo(() => {
        return filters.some((el) => {
            if (el.filterType === filterType) {
                const filterValue = el.filterValue as Region;
                return filterValue?.id === item.id;
            }
            return false;
        });
    }, [filters, item, filterType]);

    const handleChange = useCallback(
        (target: HTMLInputElement) => {
            onToggle?.({ item, checked: target.checked });
        },
        [onToggle]
    );

    return (
        <div className={styles.subItem}>
            <CheckboxInput
                id={`district-${item.code}-${index}`}
                size="0.75em"
                checkboxClassName={styles.checkbox}
                onChange={handleChange}
                checked={isActive}
            />
            <label htmlFor={`district-${item.code}-${index}`} className={styles.subItemLabel}>
                {objTranslator(item, 'title')}
            </label>
        </div>
    );
};

interface ChecklistItemProps {
    item: ChecklistItemType;
    index: number;
    isRegionChecklist?: boolean;
    filterType: string;
    queryKey: string;
}

const FilterChecklistItem: React.FC<ChecklistItemProps> = (props) => {
    const { item, index, isRegionChecklist, filterType, queryKey } = props;

    const dispatch = useFiltersDispatch();
    const filters = useFilters();

    const checkboxRef = useRef<HTMLInputElement>(null);

    const { regions } = useAppSelector((state) => state.region);

    const districts = useMemo(() => {
        return regions.filter((region) => region.level === 2 && region.parent === item.id);
    }, [regions]);

    const hasSubItemsSelected = useMemo(() => {
        if (isRegionChecklist) {
            return filters.some((el) => {
                const filterValue = el.filterValue as Region;
                return el.filterType === filterType && item.id === filterValue.parent;
            });
        }
        return false;
    }, [isRegionChecklist, filters, filterType]);

    const [isExpanded, setExpanded] = useState<boolean>(hasSubItemsSelected);

    const isActive = useMemo(() => {
        return filters.some(
            (el) =>
                el.filterType === filterType &&
                checklistFilterValueExtractor(el as any) ===
                    checklistFilterValueExtractor({ filterValue: item } as any)
        );
    }, [filters, item, filterType]);

    const toggleItem = useCallback(() => {
        setExpanded((prev) => !prev);
    }, []);

    const handleChange = useCallback(
        (target: HTMLInputElement) => {
            const filter = {
                filterType: filterType,
                filterValue: isRegionChecklist ? (item as Region) : item.title,
                query: { [queryKey as string]: item.id },
                valueExtractor: checklistFilterValueExtractor
            };
            if (isRegionChecklist) {
                const newFilters = [...filters].filter((el) => {
                    if (el.filterType === filterType) {
                        const regionFilterValue = el.filterValue as Region;
                        return !(
                            regionFilterValue.level === 2 &&
                            regionFilterValue.treeId === item.treeId
                        );
                    }
                    return true;
                });
                if (!target.checked) {
                    return dispatch({ type: 'remove', filter });
                }
                newFilters.push(filter);
                return dispatch({ type: 'set', filters: newFilters });
            }

            const actionType: 'add' | 'remove' = target.checked ? 'add' : 'remove';
            dispatch({ type: actionType, filter });
        },
        [filters, dispatch, item, filterType, queryKey, isRegionChecklist]
    );

    const handleDistrictFilterToggle = useCallback(
        (payload: any) => {
            const { item: district, checked } = payload;
            const parentRegion = regions.find((reg) => reg.id === district.parent);
            if (checked) {
                if (isActive) {
                    const filterPayload = {
                        filterType,
                        filterValue: parentRegion
                    };
                    dispatch({ type: 'remove', filter: filterPayload });
                }
                return dispatch({
                    type: 'add',
                    filter: {
                        filterType,
                        filterValue: district,
                        query: { [queryKey as string]: district.id },
                        valueExtractor: checklistFilterValueExtractor
                    }
                });
            }
            dispatch({
                type: 'remove',
                filter: {
                    filterType,
                    filterValue: district
                }
            });
        },
        [isActive, regions]
    );

    const renderChecklistSubItem: ListRenderItem<ChecklistItemType> = useCallback(
        (listProps) => {
            return (
                <DistrictSubItem
                    {...listProps}
                    filters={filters}
                    filterType={filterType}
                    onToggle={handleDistrictFilterToggle}
                />
            );
        },
        [handleDistrictFilterToggle, filters, filterType]
    );

    return (
        <div>
            <div
                className={cs(styles.projectChecklistItem, {
                    [styles.projectChecklistItemLong]: !isRegionChecklist
                })}
            >
                <CheckboxInput
                    id={`${filterType}Item-${index}`}
                    onChange={handleChange}
                    size="1em"
                    checked={isActive}
                    inputRef={checkboxRef}
                    checkboxClassName={styles.checkbox}
                    indeterminate={hasSubItemsSelected}
                />
                <label
                    htmlFor={`${filterType}Item-${index}`}
                    className={styles.projectChecklistItemText}
                >
                    {objTranslator(item, 'title')}
                </label>
                {isRegionChecklist && (
                    <span
                        className={cs('material-symbols-rounded', styles.projectChecklistItemIcon)}
                        onClick={toggleItem}
                    >
                        {isExpanded ? 'expand_less' : 'expand_more'}
                    </span>
                )}
            </div>
            <List
                className={cs(styles.districtList, {
                    [styles.districtListHidden]: !isRegionChecklist || !isExpanded
                })}
                data={districts}
                renderItem={renderChecklistSubItem}
                keyExtractor={keyExtractor}
            />
        </div>
    );
};

interface FilterChecklistProps {
    data: ChecklistItemType[];
    className?: string;
    isRegionChecklist?: boolean;
    filterType: string;
    queryKey: string;
}

const FilterChecklist: React.FC<FilterChecklistProps> = (props) => {
    const { data, className, isRegionChecklist, filterType, queryKey } = props;
    const { regions } = useAppSelector((state) => state.region);

    const renderChecklistItem: ListRenderItem<ChecklistItemType> = useCallback(
        (listProps) => (
            <FilterChecklistItem
                {...listProps}
                isRegionChecklist={isRegionChecklist}
                filterType={filterType}
                queryKey={queryKey}
            />
        ),
        [isRegionChecklist, filterType, queryKey]
    );

    return (
        <List
            className={className}
            data={data}
            renderItem={renderChecklistItem}
            keyExtractor={keyExtractor}
            loading={isRegionChecklist && regions.length === 0}
        />
    );
};

export default FilterChecklist;
