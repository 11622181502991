import React, { useMemo } from 'react';

import Form from '@ra/components/Form/Advanced';
import Input from 'components/Input';

import cs from '@ra/cs';
import { _ } from 'services/i18n';

import styles from './styles.scss';

const getTargetInputColumns = () => [
    {
        Header: _('SN'),
        accessor: 'index'
    },
    {
        Header: _('TITLE'),
        accessor: 'title'
    },
    {
        Header: _('VALUE'),
        accessor: 'value'
    },
    {
        Header: _('ACTIONS'),
        accessor: 'actions'
    }
];

export type TargetInputRow = {
    title?: string;
    value?: string | number;
    report?: any;
    actions?: any;
    data?: any;
};

type TargetInputTableProps = {
    data: any[];
    onRemoveTarget: () => void;
};

const TargetInputTable: React.FC<TargetInputTableProps> = (props) => {
    const { onRemoveTarget, data } = props;

    const columnsData = useMemo(() => {
        return getTargetInputColumns();
    }, []);

    return (
        <div className={styles.container}>
            <div className={styles.header}>
                {columnsData.map((col, idx) => (
                    <div key={idx.toString()} className={styles.headerItem}>
                        {col.Header}
                    </div>
                ))}
            </div>
            {data.map((dt, idx) => (
                <Form.InputGroup name={`targets[${idx}]`}>
                    {Boolean(dt.data?.id) && (
                        <Form.Input type="hidden" name="id" component={Input} value={dt.data.id} />
                    )}
                    <div className={styles.bodyRow}>
                        <div className={styles.bodyRowItem}>
                            <span className={styles.number}>{idx + 1}.</span>
                        </div>
                        <div className={styles.bodyRowItem}>
                            <Form.Input
                                name="title"
                                component={Input}
                                placeholder={_('Enter title')}
                                className={styles.targetInput}
                                required
                                defaultValue={dt.title}
                            />
                        </div>
                        <div className={styles.bodyRowItem}>
                            <Form.Input
                                name="value"
                                component={Input}
                                type="number"
                                placeholder={_('Target value')}
                                className={styles.targetInput}
                                defaultValue={dt.value}
                                required
                            />
                        </div>
                        <div className={styles.bodyRowItem}>
                            <p className={styles.removeIconContainer}>
                                {idx > 0 && idx === data.length - 1 ? (
                                    <span
                                        onClick={onRemoveTarget}
                                        className={cs(
                                            styles.removeIcon,
                                            'material-symbols-rounded'
                                        )}
                                    >
                                        delete
                                    </span>
                                ) : (
                                    '-'
                                )}
                            </p>
                        </div>
                    </div>
                </Form.InputGroup>
            ))}
        </div>
    );
};

export default TargetInputTable;
