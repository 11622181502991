import React, { useRef, useMemo, useEffect, useState, useCallback } from 'react';
import * as d3 from 'd3';
import { ResponsiveContainer } from 'recharts';

import cs from '@ra/cs';
import useSize from '@ra/hooks/useSize';
import { formatCurrency } from 'utils/formatter';

import { COLORS, darkColorIndices } from '../utils';

import styles from './styles.scss';

function charge(d: any) {
    return Math.pow(d.radius, 2.0) * 0.01;
}

interface BubbleChartDataType {
    id: number | string;
    [key: string]: any;
}

interface BubbleChartProps {
    data: BubbleChartDataType[];
    dataKey?: string;
    label?: string;
    width?: number | string;
    height?: number;
    forceStrength?: number;
    maxBubbleSize?: number;
    textSizeRange?: number[];
}

const BubbleChart: React.FC<BubbleChartProps> = (props) => {
    const {
        data,
        dataKey = 'value',
        label,
        width = '100%',
        height = 375,
        forceStrength = 0.03,
        maxBubbleSize = 50,
        textSizeRange = [9, 14]
    } = props;

    const bubbleChartRef = useRef<HTMLDivElement>(null);

    const size = useSize();

    const [showLegend, setShowLegend] = useState<boolean>(false);
    const [mounted, setMounted] = useState<boolean>(false);

    const computedWidth: number = useMemo(() => {
        if (typeof width === 'string' && bubbleChartRef?.current) {
            const rect = bubbleChartRef.current.getBoundingClientRect();
            return rect.width as number;
        }
        return width as number;
    }, [width, size?.width, mounted]);

    const centre = useMemo(() => {
        if (size.width && size?.width <= 767) {
            return { x: computedWidth / 2, y: height / 2 };
        }
        return { x: computedWidth / 3, y: height / 2 };
    }, [computedWidth, height, size]);

    const maxValue = useMemo(() => d3.max(data, (d) => +d[dataKey]) as number, [data]);
    const textSizeScale = useMemo(
        () => d3.scaleSqrt().domain([2, maxValue]).range(textSizeRange),
        [maxValue, textSizeRange]
    );

    const nodes = useMemo(() => {
        const radiusScale = d3.scaleSqrt().domain([0, maxValue]).range([15, maxBubbleSize]);

        return data.map((d) => ({
            ...d,
            radius: radiusScale(+d[dataKey as keyof typeof d]),
            value: +d[dataKey as keyof typeof d],
            x: 50 + Math.random() * (computedWidth / 5),
            y: Math.random() * height
        }));
    }, [maxValue, maxBubbleSize, dataKey, height, computedWidth]);

    const containerRefCallback = useCallback((node: HTMLDivElement | null) => {
        if (node !== null) {
            setMounted(true);
        }
    }, []);

    useEffect(() => {
        const simulation = d3
            .forceSimulation()
            .force('charge', d3.forceManyBody().strength(charge))
            .force('x', d3.forceX().strength(forceStrength).x(centre.x))
            .force('y', d3.forceY().strength(forceStrength).y(centre.y))
            .force(
                'collision',
                d3.forceCollide().radius((d: any) => d.radius)
            );

        simulation.stop();

        let bubbles: any = null;
        let labels: any = null;

        function ticked() {
            if (bubbles && labels) {
                bubbles.attr('cx', (d: any) => d.x).attr('cy', (d: any) => d.y);
                labels.attr('x', (d: any) => d.x).attr('y', (d: any) => d.y);
            }
        }

        if (bubbleChartRef?.current && !bubbleChartRef.current.hasChildNodes()) {
            const container = d3
                .select(bubbleChartRef.current)
                .style('width', width)
                .style('height', `${height + 150}px`);

            const svg = container.append('svg').attr('width', '100%').attr('height', '100%');

            const elements = svg
                .selectAll('.bubble')
                .data(nodes, (d: any) => d.id)
                .enter()
                .append('g');

            bubbles = elements
                .append('circle')
                .classed('bubble', true)
                .attr('r', (d) => d.radius)
                .attr('fill', (_, idx) => COLORS[idx % COLORS.length]);

            labels = elements
                .filter((d) => +d[dataKey as keyof typeof d] >= 2)
                .append('text')
                .attr('dy', '.3em')
                .attr('fill', (_, idx) => (darkColorIndices.includes(idx) ? '#32343D' : '#FFFFFF'))
                .style('text-anchor', 'middle')
                .style('font-size', (d) => textSizeScale(+d[dataKey as keyof typeof d]))
                .style('font-weight', 700)
                .text((d: any) => formatCurrency(d[dataKey as keyof typeof d]));

            setShowLegend(true);
            simulation.nodes(nodes).on('tick', ticked).restart();
        }
        return () => {
            d3.select(bubbleChartRef.current).html('');
        };
    }, [nodes, dataKey, computedWidth, mounted, height, centre]);

    const formatLegendText = useCallback((text: string) => {
        if (text.length <= 20) {
            return text;
        }
        return text.substring(0, 17) + '...';
    }, []);

    return (
        <ResponsiveContainer
            width={width}
            height={window.innerWidth <= 767 ? height + 150 : height}
        >
            <div ref={containerRefCallback} className={styles.container}>
                <div ref={bubbleChartRef} />
                {showLegend && (
                    <div
                        className={cs(styles.bubbleLegend, {
                            [styles.bubbleLegendVertical]: window.innerWidth <= 767
                        })}
                    >
                        {data.map((dt, idx) => (
                            <div key={idx} className={styles.bubbleLegendItem}>
                                <div
                                    className={styles.legendIndicator}
                                    style={{ backgroundColor: COLORS[idx % COLORS.length] }}
                                />
                                <span
                                    title={dt[label as keyof typeof dt]}
                                    className={styles.legendText}
                                >
                                    {formatLegendText(dt[label as keyof typeof dt])}
                                </span>
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </ResponsiveContainer>
    );
};

export default BubbleChart;
