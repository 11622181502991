import React, { useMemo } from 'react';

import List, { type ListRenderItemProps, type KeyExtractor } from '@ra/components/List';

import cs from '@ra/cs';
import { useAppSelector } from 'hooks/store';
import { formatOrganizationTitle } from 'utils/formatter';

import type { Organization } from 'services/types';

import styles from './styles.scss';

const idExtractor: KeyExtractor<Organization> = (item) => item.id;

type OrganizationLogoProps = {
    organization: Organization;
    className?: string;
    iconClassName?: string;
};

export const OrganizationLogo: React.FC<OrganizationLogoProps> = ({
    organization,
    className,
    iconClassName
}) => {
    if (organization?.logo) {
        return (
            <img
                className={cs(styles.organizationLogo, className)}
                src={organization.logo as string}
                alt={organization.title as string}
            />
        );
    }
    return (
        <div className={cs(styles.placeholderLogo, className)}>
            <div className={styles.iconWrapper}>
                <span
                    className={cs(
                        'material-symbols-rounded',
                        styles.placeholderIcon,
                        iconClassName
                    )}
                >
                    workspaces
                </span>
            </div>
        </div>
    );
};

type OrganizationItemProps = ListRenderItemProps<Organization> & {
    className?: string;
    imageClassName?: string;
    titleClassName?: string;
};

export const OrganizationItem: React.FC<OrganizationItemProps> = ({
    item,
    className,
    imageClassName,
    titleClassName
}) => {
    return (
        <div title={item.title} className={cs(styles.organizationItem, className)}>
            <OrganizationLogo
                className={cs(styles.organizationLogo, imageClassName)}
                organization={item}
            />
            <p className={cs(styles.organizationTitle, titleClassName)}>
                {formatOrganizationTitle(item)}
            </p>
        </div>
    );
};

interface OrganizationListProps {
    organizations: number[] | Organization[];
    className?: string;
}

const OrganizationList: React.FC<OrganizationListProps> = (props) => {
    const { organizations, className } = props;

    const { organizations: storeOrganizations } = useAppSelector((store) => store.organization);

    const organizationData = useMemo(() => {
        return organizations.map((org) => {
            if (isNaN(org as number)) {
                return org;
            }
            return storeOrganizations.find((o) => o.id === org);
        });
    }, [storeOrganizations, organizations]);

    return (
        <List
            className={cs(styles.list, className)}
            data={organizationData}
            keyExtractor={idExtractor}
            renderItem={OrganizationItem}
        />
    );
};

export default OrganizationList;
