import React from 'react';

import Tabs, {
    Tab as _Tab,
    TabsProps,
    TabChangeCallback,
    HeaderClickCallback,
    TabRenderHeader
} from '@ra/components/Tabs';

import cs from '@ra/cs';

import styles from './styles.scss';

type CustomTabsProps = TabsProps & {
    primary?: boolean;
    secondary?: boolean;
    tertiary?: boolean;
};

const _Tabs: React.FC<CustomTabsProps> = (props) => {
    const {
        primary,
        secondary,
        tertiary,
        headerClassName,
        tabItemClassName,
        activeTabItemClassName,
        ...otherProps
    } = props;

    return (
        <Tabs
            headerClassName={cs(
                styles.tabHeader,
                {
                    [styles.tabHeaderPrimary]: primary,
                    [styles.tabHeaderSecondary]: secondary,
                    [styles.tabHeaderTertiary]: tertiary
                },
                headerClassName
            )}
            tabItemClassName={cs(
                styles.tabHeaderItem,
                {
                    [styles.tabHeaderItemPrimary]: primary,
                    [styles.tabHeaderItemSecondary]: secondary,
                    [styles.tabHeaderItemTertiary]: tertiary
                },
                tabItemClassName
            )}
            activeTabItemClassName={cs(
                styles.tabHeaderItemActive,
                {
                    [styles.tabHeaderItemPrimaryActive]: primary,
                    [styles.tabHeaderItemSecondaryActive]: secondary,
                    [styles.tabHeaderItemTertiaryActive]: tertiary
                },
                activeTabItemClassName
            )}
            {...otherProps}
        />
    );
};

export const Tab = _Tab;
export type { TabChangeCallback, HeaderClickCallback, TabRenderHeader };

export default _Tabs;
