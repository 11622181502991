import React, { useEffect, useState, useCallback, useMemo } from 'react';

import SelectInput, { SelectInputChangeCallback } from '@ra/components/Form/SelectInput';

import { selectPriorityAreas, selectPriorityActions } from 'store/selectors/priorityIndicator';
import { getPriorityIndicators } from 'services/bootstrap';

import { useAppSelector } from 'hooks/store';
import { useFiltersDispatch, useFilters } from 'hooks/filters';

import cs from '@ra/cs';
import { _ } from 'services/i18n';

import type { PriorityIndicator } from 'services/types';

import styles from './styles.scss';

const titleExtractor = (item: PriorityIndicator) => item.title;
const idExtractor = (item: PriorityIndicator) => item.id;

interface FilterPriorityIndicatorsProps {
    filterType: string;
}

const FilterPriorityIndicators: React.FC<FilterPriorityIndicatorsProps> = (props) => {
    const { filterType } = props;

    const priorityAreas = useAppSelector((state) => selectPriorityAreas(state));
    const filtersDispatch = useFiltersDispatch();

    const filters = useFilters();
    const priorityIndicatorFilter = useMemo(() => {
        return filters.find((el) => el.filterType === filterType);
    }, [filters, filterType]);

    useEffect(() => {
        if (!priorityAreas.length) {
            getPriorityIndicators();
        }
    }, []); // Deliberately kept empty

    const [activePriorityArea, setActivePriorityArea] = useState<
        PriorityIndicator | null | undefined
    >();
    const [activePriorityAction, setActivePriorityAction] = useState<
        PriorityIndicator | null | undefined
    >();

    const [defaultPriorityArea, defaultPriorityAction]: (PriorityIndicator | null | undefined)[] =
        useMemo(() => {
            if (priorityIndicatorFilter) {
                const piObject = priorityIndicatorFilter.filterValue as PriorityIndicator;
                if (piObject.level === 0) {
                    setActivePriorityArea(piObject);
                    return [piObject, null];
                } else {
                    const newActivePriorityArea = priorityAreas.find(
                        (pa) => pa.id === piObject.parent
                    );
                    setActivePriorityArea(newActivePriorityArea);
                    setActivePriorityAction(piObject);
                    return [newActivePriorityArea, piObject];
                }
            }
            setActivePriorityArea(null);
            setActivePriorityAction(null);
            return [null, null];
        }, [priorityIndicatorFilter, priorityAreas]);

    const handlePriorityAreaChange: SelectInputChangeCallback<PriorityIndicator> = useCallback(
        ({ option }) => {
            setActivePriorityArea(option);
            if (option) {
                return filtersDispatch({
                    type: 'add',
                    filter: {
                        filterType,
                        filterValue: option,
                        query: {
                            outputs__activities__priority_indicators: option.id
                        }
                    }
                });
            }
            filtersDispatch({ type: 'remove', filter: { filterType } });
            setActivePriorityAction(null);
        },
        [filtersDispatch, filterType]
    );

    const handlePriorityActionChange: SelectInputChangeCallback<PriorityIndicator> = useCallback(
        ({ option }) => {
            setActivePriorityAction(option);
            if (option) {
                return filtersDispatch({
                    type: 'add',
                    filter: {
                        filterType,
                        filterValue: option,
                        query: { outputs__activities__priority_indicators: option.id }
                    }
                });
            }
            if (activePriorityArea) {
                filtersDispatch({
                    type: 'add',
                    filter: {
                        filterType,
                        filterValue: activePriorityArea,
                        query: {
                            outputs__activities__priority_indicators: activePriorityArea.id
                        }
                    }
                });
            }
        },
        [filtersDispatch, filterType, activePriorityArea]
    );

    const priorityActions = useAppSelector((state) =>
        selectPriorityActions(state, activePriorityArea?.id)
    );

    return (
        <div className={styles.container}>
            <span className={cs('material-symbols-rounded', styles.leftIcon)}>
                signal_cellular_alt
            </span>
            <SelectInput
                className={styles.select}
                optionsWrapperClassName={styles.optionsWrapper}
                selectOptionClassName={styles.selectOptionStyle}
                optionItemClassName={styles.optionItem}
                controlClassName={cs(styles.selectControl, {
                    [styles.selectControlFilled]: activePriorityArea
                })}
                searchable
                clearable
                options={priorityAreas}
                keyExtractor={idExtractor}
                valueExtractor={titleExtractor}
                onChange={handlePriorityAreaChange}
                placeholder={_('Priority Area')}
                defaultValue={defaultPriorityArea}
                anchorOrigin="bottom right"
                transformOrigin="top right"
            />
            {activePriorityArea && (
                <SelectInput
                    keyExtractor={idExtractor}
                    placeholder={_('Select priority action')}
                    onChange={handlePriorityActionChange}
                    valueExtractor={titleExtractor}
                    options={priorityActions}
                    className={styles.select}
                    clearable={false}
                    optionsWrapperClassName={styles.optionsWrapper}
                    selectOptionClassName={styles.selectOptionStyle}
                    optionItemClassName={styles.optionItemStyle}
                    controlClassName={cs(styles.selectControl, {
                        [styles.selectControlFilled]: activePriorityAction
                    })}
                    defaultValue={defaultPriorityAction}
                />
            )}
        </div>
    );
};

export default FilterPriorityIndicators;
