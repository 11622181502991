import { useState, useReducer } from 'react';

export type AuthModalsState = {
    showConfirmPasswordModal: boolean;
    showSendEmailModal: boolean;
    showVerifyEmailModal: boolean;
    showResetPasswordModal: boolean;
};

export type AuthModalsActionType = {
    type:
        | 'showConfirmPassword'
        | 'showSendEmail'
        | 'showVerifyEmail'
        | 'showResetPassword'
        | 'hideModals';
};

const initialModalsState: AuthModalsState = {
    showConfirmPasswordModal: false,
    showSendEmailModal: false,
    showVerifyEmailModal: false,
    showResetPasswordModal: false
};

function authModalsReducer(authModalsState: AuthModalsState, action: AuthModalsActionType) {
    switch (action.type) {
        case 'showConfirmPassword':
            return { ...initialModalsState, showConfirmPasswordModal: true };
        case 'showSendEmail':
            return { ...initialModalsState, showSendEmailModal: true };
        case 'showVerifyEmail':
            return { ...initialModalsState, showVerifyEmailModal: true };
        case 'showResetPassword':
            return { ...initialModalsState, showResetPasswordModal: true };
        case 'hideModals':
            return initialModalsState;
        default:
            return authModalsState;
    }
}

const useAuthModals = () => {
    const [modals, dispatch] = useReducer(authModalsReducer, initialModalsState);

    const [email, setEmail] = useState('');
    const [identifier, setIdentifier] = useState('');

    return {
        dispatch,
        modals,
        email,
        setEmail,
        identifier,
        setIdentifier
    };
};

export default useAuthModals;
