import React, { useCallback } from 'react';

import Button from 'components/Button';
import { ApprovedIcon, DeclineIcon } from 'components/Icons';
import Modal, { ModalProps } from '@ra/components/Modal';

import type { CustomButtonProps } from 'components/Button';

import cs from '@ra/cs';
import { _ } from 'services/i18n';

import styles from './styles.scss';

type ConfirmModalProps = Omit<ModalProps, 'children'> & {
    decline?: boolean;
    onConfirm?: React.MouseEventHandler;
    titleText: string;
    descriptionText?: string;
    renderDescription?: () => React.ReactNode;
    confirmButtonText?: string;
    cancelButtonText?: string;
    confirmButtonProps?: CustomButtonProps;
};

const ConfirmModal: React.FC<ConfirmModalProps> = (props) => {
    const {
        decline,
        onClose,
        onConfirm,
        titleText,
        descriptionText = '',
        renderDescription,
        confirmButtonText,
        cancelButtonText,
        confirmButtonProps = {},
        ...modalProps
    } = props;

    const handleClose = useCallback(() => {
        onClose?.();
    }, [onClose]);

    return (
        <Modal className={styles.modal} {...modalProps}>
            <div className={styles.header}>
                <div className={styles.headerControls}>
                    {decline ? <DeclineIcon /> : <ApprovedIcon />}
                    <span
                        className={cs('material-symbols-rounded', styles.closeIcon)}
                        onClick={handleClose}
                    >
                        close
                    </span>
                </div>
                <h2 className={styles.title}>{titleText}</h2>
            </div>
            {renderDescription ? (
                renderDescription()
            ) : (
                <p className={styles.description}>{descriptionText}</p>
            )}
            <div className={styles.buttons}>
                <Button className={styles.button} tertiary onClick={handleClose}>
                    {cancelButtonText ?? _('Cancel')}
                </Button>
                <Button
                    accent={decline}
                    className={styles.button}
                    onClick={onConfirm}
                    {...confirmButtonProps}
                >
                    {confirmButtonText ?? decline ? _('Decline') : _('Confirm')}
                </Button>
            </div>
        </Modal>
    );
};

export default ConfirmModal;
