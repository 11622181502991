import React, { useCallback, useMemo } from 'react';
import { camelCase } from 'lodash';

import Button from 'components/Button';
import DateRangeInput from 'components/Input/DateRangeInput';
import { FilePreview } from 'components/FileInput';
import Form from '@ra/components/Form/Advanced';
import CurrencyInputGroup, {
    CurrencyInput as FormCurrencyInput
} from 'components/Input/CurrencyInputGroup';
import ProjectDocumentInput from 'components/Input/ProjectDocumentInput';
import LocationInput from 'components/Input/LocationInput';
import Label from '@ra/components/Form/Label';
import AuthInput from 'components/Input/AuthInput';
import Input from '@ra/components/Form/Input';
import TextareaInput from 'components/Input/TextareaInput';
import MultiSelectInput from '@ra/components/Form/MultiSelectInput';
import SelectInput from '@ra/components/Form/SelectInput';

import { _, Localize } from 'services/i18n';
import {
    agreementDocumentTypeTitles,
    currencies,
    fundingTypes,
    type FundingType
} from 'utils/form';

import { useAppSelector } from 'hooks/store';

import type { Hazard, ProjectDocument } from 'services/types';
import type { ExpandedProject } from 'services/report';

import styles from './styles.scss';

const titleExtractor = (item: FundingType | Hazard) => item.title;
const keyExtractor = (item: FundingType | Hazard) => item.id;
const hazardIdsExtractor = ({ value }: { value: Hazard[] }) => {
    if (value?.length > 0) {
        return value.map((v) => v.id).join(',');
    }
    return null;
};
const defaultHazardIdsExtractor = (value: Hazard[]) => {
    if (value?.length > 0) {
        return value.map((v) => v.id).join(',');
    }
    return null;
};
const codeExtractor = (item: FundingType) => item.code;
const fieldCodeExtractor = ({ option }: { option: FundingType }) => option.code;

interface ImplementorInfoFormProps {
    onContinuePress: () => void;
    project?: ExpandedProject;
    editMode?: boolean;
    documents?: ProjectDocument[];
}

const ImplementorInfoForm: React.FC<ImplementorInfoFormProps> = (props) => {
    const { onContinuePress, project, editMode, documents } = props;

    const { hazards } = useAppSelector((state) => state.hazard);
    const { projectDocumentTypes } = useAppSelector((state) => state.projectDocumentType);
    const defaultHazards: (Hazard | undefined)[] | undefined = useMemo(() => {
        return project?.hazards
            ?.map((projectHazard: number | string) => {
                return hazards.find((hzd) => hzd?.id === Number(projectHazard));
            })
            ?.filter((hz) => Boolean(hz));
    }, [project, hazards]);

    const handleContinuePress = useCallback(() => {
        onContinuePress();
    }, [onContinuePress]);

    const agreementDocumentTypeInputs = useMemo(() => {
        return projectDocumentTypes.filter((docType) =>
            agreementDocumentTypeTitles.includes(docType.title)
        );
    }, [projectDocumentTypes]);

    return (
        <div>
            <div className={styles.formSection}>
                <h3 className={styles.formSectionTitle}>
                    <Localize>1. TITLE AND DURATION</Localize>
                </h3>
                <Form.Input
                    name="title"
                    component={AuthInput}
                    label={_('Project Title*')}
                    labelClassName={styles.inputLabel}
                    placeholder={_('Enter project title')}
                    defaultValue={project?.title}
                    required
                />
                <Form.Input
                    name="titleNe"
                    component={AuthInput}
                    label={_('Project Title in Nepali (optional)')}
                    labelClassName={styles.inputLabel}
                    placeholder={_('परियोजना शीर्षक नेपालीमा लेख्नुहोस्')}
                    defaultValue={project?.titleNe}
                />
                <Form.Input
                    name="description"
                    component={TextareaInput}
                    label={_('Project Description*')}
                    labelClassName={styles.inputLabel}
                    containerClassName={styles.inputSpacing}
                    className={styles.textarea}
                    rows={4}
                    placeholder={_('Enter project description')}
                    defaultValue={project?.description}
                    required
                />
                <Form.Input
                    name="descriptionNe"
                    component={TextareaInput}
                    label={_('Project Description in Nepali (optional)')}
                    labelClassName={styles.inputLabel}
                    containerClassName={styles.inputSpacing}
                    className={styles.textarea}
                    rows={4}
                    placeholder={_('परियोजना विवरण नेपालीमा लेख्नुहोस्')}
                    defaultValue={project?.descriptionNe}
                />
                <Form.Input
                    name="duration"
                    containerClassName={styles.inputContainer}
                    component={DateRangeInput}
                    label={_('Project Duration*')}
                    labelClassName={styles.inputLabel}
                    placeholder="mm/dd/yyyy - mm/dd/yyyy"
                    defaultValue={
                        project ? project.dateFrom?.concat(',', project.dateTo) : undefined
                    }
                    required
                />
            </div>
            <div className={styles.formSection}>
                <h3 className={styles.formSectionTitle}>
                    <Localize>2. BUDGET DETAILS</Localize>
                </h3>
                <CurrencyInputGroup
                    defaultCurrency={currencies.find((cur) => cur.code === project?.budgetCurrency)}
                    defaultAmount={project?.budget}
                    defaultExchangeRate={project?.exchangeRate}
                    budgetLabel={_('Total Budget*')}
                    exchangeRateLabel={_('Exchange Rate*')}
                />
                <div className={styles.inputSpacing}>
                    <Label className={styles.inputLabel}>{_('Administrative Budget*')}</Label>
                    <FormCurrencyInput
                        currencyInputName="administrativeBudgetCurrency"
                        amountInputName="administrativeBudget"
                        lockedCurrency={currencies[0]}
                        amountRequired
                        defaultAmountValue={project?.administrativeBudget}
                    />
                </div>
                <Form.Input
                    component={SelectInput}
                    label={_('Funding type*')}
                    labelClassName={styles.inputLabel}
                    containerClassName={styles.inputSpacing}
                    name="projectType"
                    options={fundingTypes}
                    valueExtractor={titleExtractor}
                    fieldValueExtractor={fieldCodeExtractor}
                    formValueExtractor={codeExtractor}
                    keyExtractor={keyExtractor}
                    placeholder={_('Select Funding type')}
                    defaultValue={
                        fundingTypes.find((ft) => ft.code === project?.projectType) ||
                        fundingTypes[0]
                    }
                    required
                    clearable={false}
                    searchable={false}
                />
            </div>
            <div className={styles.formSection}>
                <h3 className={styles.formSectionTitle}>
                    <Localize>3. PROJECT AGREEMENT DOCUMENTS</Localize>
                </h3>
                {agreementDocumentTypeInputs.map((docType, idx) => {
                    const existingDocument = documents?.find(
                        (doc) => doc.documentType === docType.id
                    );
                    if (editMode && existingDocument) {
                        return (
                            <>
                                <p className={styles.inputTitle}>{existingDocument.title}</p>
                                <Form.Input
                                    component={Input}
                                    type="hidden"
                                    name={`documents[${idx}]id`}
                                    value={existingDocument.id}
                                />
                                <FilePreview file={existingDocument} />
                            </>
                        );
                    }
                    return (
                        <Form.Input
                            component={ProjectDocumentInput}
                            name={camelCase(docType.title)}
                            containerClassName={styles.inputSpacing}
                            label={docType.title}
                            labelClassName={styles.inputLabel}
                            info={_('PDF (max. 2MB)')}
                            maxSize={2048}
                            accept="application/pdf"
                        />
                    );
                })}
            </div>
            <div className={styles.formSection}>
                <h3 className={styles.formSectionTitle}>
                    <Localize>4. LOCATION AND HAZARD DETAILS</Localize>
                </h3>
                <h4 className={styles.inputTitle}>
                    <Localize>Location</Localize>
                </h4>
                <div className={styles.locationInputContainer}>
                    <Form.Input
                        name="location"
                        component={LocationInput}
                        required
                        defaultValue={project?.location}
                    />
                </div>
                <Form.Input
                    component={MultiSelectInput}
                    label={_('Hazards (optional)')}
                    labelClassName={styles.inputLabel}
                    containerClassName={styles.inputSpacing}
                    name="hazards"
                    options={hazards}
                    defaultValue={defaultHazards}
                    valueExtractor={titleExtractor}
                    fieldValueExtractor={hazardIdsExtractor}
                    formValueExtractor={defaultHazardIdsExtractor}
                    keyExtractor={keyExtractor}
                    placeholder={_('Select hazard categories')}
                />
            </div>
            <div className={styles.formSection}>
                <h3 className={styles.formSectionTitle}>
                    <Localize>5. CONTACT DETAILS FOR FURTHER INFO</Localize>
                </h3>
                <Form.Input
                    name="contactName"
                    component={AuthInput}
                    label={_('Name*')}
                    labelClassName={styles.inputLabel}
                    placeholder={_('Enter name')}
                    defaultValue={project?.contactName}
                    required
                />
                <Form.Input
                    name="contactEmail"
                    component={AuthInput}
                    type="email"
                    label={_('Email*')}
                    labelClassName={styles.inputLabel}
                    placeholder={_('Enter email')}
                    defaultValue={project?.contactEmail}
                    required
                />
                <Form.Input
                    name="contactPhone"
                    type="tel"
                    component={AuthInput}
                    label={_('Phone Number (with country code)*')}
                    labelClassName={styles.inputLabel}
                    placeholder={_('+97798XXXXXXXX')}
                    defaultValue={project?.contactPhone}
                    required
                />
            </div>
            <Button
                large
                type="button"
                className={styles.continueButton}
                onClick={handleContinuePress}
            >
                <Localize>Continue</Localize>
            </Button>
        </div>
    );
};

export default ImplementorInfoForm;
