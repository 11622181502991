import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import List, { KeyExtractor, ListRenderItem } from '@ra/components/List';
import { OrganizationLogo } from 'components/OrganizationList';

import { Localize } from 'services/i18n';
import { useAppSelector } from 'hooks/store';

import type { Organization } from 'services/types';

import styles from './styles.scss';

const keyExtractor: KeyExtractor<Organization> = (item) => item.id;

const OrganizationItem: ListRenderItem<Organization> = ({ item }) => {
    const navigate = useNavigate();

    const handleClick = useCallback(() => {
        navigate('/organizations/' + item.id);
    }, [navigate]);

    return (
        <div className={styles.organizationItem} onClick={handleClick}>
            <OrganizationLogo organization={item} className={styles.organizationLogo} />
            <p className={styles.organizationTitle}>
                {item.title}
                {item.acronym ? ` (${item.acronym})` : ''}
            </p>
        </div>
    );
};

const Organizations: React.FC = () => {
    const { organizations } = useAppSelector((state) => state.organization);

    return (
        <div className={styles.container}>
            <h1 className={styles.title}>
                <Localize>All Organizations</Localize>
            </h1>
            <List
                loading={organizations.length === 0}
                data={organizations}
                className={styles.organizations}
                keyExtractor={keyExtractor}
                renderItem={OrganizationItem}
                LoadingComponent={
                    <p className={styles.emptyMessage}>
                        <Localize>Loading organizations...</Localize>
                    </p>
                }
                EmptyComponent={
                    <p className={styles.emptyMessage}>
                        <Localize>No organizations found!</Localize>
                    </p>
                }
            />
        </div>
    );
};

export default Organizations;
