import React, { Dispatch, createContext, useReducer } from 'react';

import type { Region, Organization, PriorityIndicator } from 'services/types';

export type FilterItemType = {
    filterType: string;
    filterValue?:
        | string
        | number
        | Date
        | Region
        | PriorityIndicator
        | PriorityIndicator[]
        | Organization[]
        | number[];
    query?: { [key: string]: string | number };
    valueExtractor?: (filterItem: FilterItemType) => string | number;
};

export type FilterActionType =
    | {
          type: 'add' | 'remove';
          filter: FilterItemType;
      }
    | { type: 'set'; filters: FilterItemType[]; category?: string }
    | { type: 'clear'; category: string };

export const FiltersContext = createContext<FilterItemType[]>([]);
export const FiltersDispatchContext = createContext<Dispatch<FilterActionType>>(() => true);

const initialFilters: FilterItemType[] = [];
const multiFilterTypes = [
    'Map-Status:',
    'Map-Hazard:',
    'Map-Region:',
    'Map-Theme:',
    'Project-Hazard',
    'Project-Status',
    'Region-Hazard',
    'Region-Status',
    'PriorityActivityProject-Hazard',
    'PriorityActivityProject-Status'
];

function filtersReducer(filters: FilterItemType[], action: FilterActionType) {
    switch (action.type) {
        case 'add': {
            const filterIdx = filters.findIndex((el) => el.filterType === action.filter.filterType);
            if (filterIdx > -1 && !multiFilterTypes.includes(action.filter.filterType)) {
                const newFilters = [...filters];
                newFilters.splice(filterIdx, 1, action.filter);
                return newFilters;
            }
            return [...filters, action.filter];
        }
        case 'remove': {
            const filterIdx = filters.findIndex(
                (el) =>
                    el.filterType === action.filter.filterType &&
                    (action.filter.filterValue
                        ? el.valueExtractor
                            ? el.valueExtractor(el) === el.valueExtractor(action.filter)
                            : el.filterValue === action.filter.filterValue
                        : true)
            );
            const newFilters = [...filters];
            if (filterIdx > -1) {
                newFilters.splice(filterIdx, 1);
            }
            return newFilters;
        }
        case 'set': {
            if (action.category) {
                return [
                    ...filters.filter((el) => !el.filterType.startsWith(action.category as string)),
                    ...action.filters
                ];
            }
            return action.filters;
        }
        case 'clear': {
            return filters.filter(
                (el) =>
                    el.filterType === 'Region-Region' || !el.filterType.startsWith(action.category)
            );
        }
        default: {
            return filters;
        }
    }
}

const FiltersProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [filters, dispatch] = useReducer(filtersReducer, initialFilters);

    return (
        <FiltersContext.Provider value={filters}>
            <FiltersDispatchContext.Provider value={dispatch}>
                {children}
            </FiltersDispatchContext.Provider>
        </FiltersContext.Provider>
    );
};

export default FiltersProvider;
