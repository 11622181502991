import React, { useRef, useCallback, useState, useMemo, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { formatDistanceStrict } from 'date-fns';

import Button from 'components/Button';
import { NotificationIcon } from 'components/Icons';
import List, { KeyExtractor, ListRenderItem, ListRenderItemProps } from '@ra/components/List';
import Popup from '@ra/components/Popup';

import Api from 'services/api';
import cs from '@ra/cs';
import { Localize } from 'services/i18n';

import useSize from '@ra/hooks/useSize';
import usePromise from '@ra/hooks/usePromise';

import type { Notification } from 'services/types';

import styles from './styles.scss';

const keyExtractor: KeyExtractor<Notification> = (item) => item.id;

interface NotificationItemProps extends ListRenderItemProps<Notification> {
    onCloseNotifications?: () => void;
}

export const NotificationItem: React.FC<NotificationItemProps> = ({
    item,
    onCloseNotifications
}) => {
    const navigate = useNavigate();

    const dateDistance = useMemo(() => {
        return formatDistanceStrict(new Date(item.createdAt), new Date(), { addSuffix: true });
    }, [item]);

    const handleReviewClick = useCallback(async () => {
        if (!item.hasRead) {
            try {
                Api.markNotificationRead(item.id);
            } catch (err) {
                console.log(err);
            }
        }
        onCloseNotifications?.();
        navigate('/notifications/review-registration', { state: { notification: item } });
    }, [navigate, item, onCloseNotifications]);

    return (
        <div className={styles.notificationItem}>
            <div
                className={cs(styles.iconContainer, {
                    [styles.iconContainerAccount]: item.notificationType === 'account_request'
                })}
            >
                <span className={cs(styles.notificationIcon, 'material-symbols-rounded')}>
                    {item.notificationType === 'account_request' ? 'person' : 'work'}
                </span>
            </div>
            <div className={styles.notificationContent}>
                <div className={styles.notificationBody}>
                    <p className={styles.description}>{item.description}</p>
                    <Button secondary small onClick={handleReviewClick}>
                        <Localize>Review</Localize>
                    </Button>
                </div>
                <p className={styles.notificationDate}>{dateDistance}</p>
            </div>
            {!item.hasRead && <div className={styles.newIndicator} />}
        </div>
    );
};

const NotificationsDropdown: React.FC = () => {
    const anchorRef = useRef<HTMLDivElement>(null);

    const { width } = useSize();

    const [{ result, loading }, loadNotifications] = usePromise(Api.getNotifications);
    useEffect(() => {
        loadNotifications({ limit: 4, ordering: '-createdAt' });
        const interval = setInterval(() => {
            loadNotifications({ limit: 4, ordering: '-createdAt' });
        }, 120 * 1000);
        return () => {
            clearInterval(interval);
        };
    }, [loadNotifications]);
    const notifications: Notification[] = useMemo(() => {
        return result?.results || [];
    }, [result]);

    const [isNotificationsVisible, setNotificationsVisible] = useState<boolean>(false);

    const toggleNotifications = useCallback(() => {
        loadNotifications({ limit: 4, ordering: '-createdAt' });
        setNotificationsVisible((prev) => !prev);
    }, [loadNotifications]);

    const renderNotificationItem: ListRenderItem<Notification> = useCallback(
        (listProps) => {
            return <NotificationItem {...listProps} onCloseNotifications={toggleNotifications} />;
        },
        [toggleNotifications]
    );

    return (
        <>
            <div
                className={styles.notificationIconContainer}
                ref={anchorRef}
                onClick={toggleNotifications}
            >
                <NotificationIcon
                    numActiveNotifications={notifications.filter((noti) => !noti.hasRead).length}
                />
            </div>
            <Popup
                isVisible={isNotificationsVisible}
                anchor={anchorRef}
                anchorOrigin={width && width < 578 ? 'bottom center' : 'bottom right'}
                transformOrigin={width && width < 578 ? 'top center' : 'top right'}
                onClose={toggleNotifications}
                closeOnOutsideClick
                className={styles.notificationsPopup}
            >
                <List
                    loading={loading}
                    className={styles.notificationsDropdown}
                    keyExtractor={keyExtractor}
                    data={notifications}
                    renderItem={renderNotificationItem}
                />
                <Link
                    to="/notifications"
                    onClick={toggleNotifications}
                    className={styles.seeAllLink}
                >
                    <Localize>See all notifications</Localize>
                </Link>
            </Popup>
        </>
    );
};

export default NotificationsDropdown;
