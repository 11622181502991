import React, { useState, useCallback, useMemo } from 'react';
import { Rectangle } from 'recharts';

import Tabs, { Tab, TabChangeCallback } from 'components/Tabs';

import cs from '@ra/cs';
import { _ } from 'services/i18n';

import styles from './styles.scss';

type MapLegendProps = {
    className?: string;
    mapWidth?: number | null;
    onChange?: (accessor: 'projects' | 'amount') => void;
    activeAccessor?: 'projects' | 'amount';
};

export type LegendData = {
    amountLabel: string;
    projectsLabel: string;
    color: string;
    projectsLowerBound: number;
    amountLowerBound: number;
};
export const legendData: LegendData[] = [
    {
        amountLabel: 'Rs. 1-10Cr',
        projectsLabel: '1-19',
        color: '#D6DFF0',
        projectsLowerBound: 1,
        amountLowerBound: 1
    },
    {
        amountLabel: 'Rs. 10Cr-25Cr',
        projectsLabel: '20-39',
        color: '#C0CBDF',
        projectsLowerBound: 20,
        amountLowerBound: 100000000
    },
    {
        amountLabel: 'Rs. 25Cr-45Cr',
        projectsLabel: '40-59',
        color: '#ACB8CF',
        projectsLowerBound: 40,
        amountLowerBound: 250000000
    },
    {
        amountLabel: 'Rs. 45Cr-70Cr',
        projectsLabel: '60-79',
        color: '#7D8EAC',
        projectsLowerBound: 60,
        amountLowerBound: 450000000
    },
    {
        amountLabel: 'Rs. 70Cr-1Ar',
        projectsLabel: '80-99',
        color: '#566A8E',
        projectsLowerBound: 80,
        amountLowerBound: 700000000
    },
    {
        amountLabel: 'Rs. 1Ar+',
        projectsLabel: '100+',
        color: '#203864',
        projectsLowerBound: 100,
        amountLowerBound: 1000000000
    }
];

const MapLegend: React.FC<MapLegendProps> = (props) => {
    const { className, mapWidth, onChange, activeAccessor } = props;

    const [isCollapsed, setCollapsed] = useState<boolean>(false);

    const toggleCollapse = useCallback(() => setCollapsed((prev) => !prev), []);

    const legendItemWidth = useMemo(() => {
        if (!mapWidth) {
            return 60;
        }
        return Math.max(Math.min((0.75 * mapWidth) / 6 || 60, 60), 32);
    }, [mapWidth]);

    const handleTabChange: TabChangeCallback = useCallback(
        ({ activeTab }) => onChange?.(activeTab as 'projects' | 'amount'),
        []
    );

    return (
        <div className={className}>
            {isCollapsed ? (
                <div className={styles.legendCollapsed}>
                    <span className={styles.legendText}>Legend</span>
                    <span
                        className={cs('material-symbols-rounded', styles.controlIcon)}
                        onClick={toggleCollapse}
                    >
                        expand_less
                    </span>
                </div>
            ) : (
                <div className={styles.legendBox}>
                    <div className={styles.legendHeader}>
                        <Tabs tertiary onChange={handleTabChange} defaultActiveTab={activeAccessor}>
                            <Tab label="projects" title={_('Projects')} />
                            <Tab label="amount" title={_('Amount')} />
                        </Tabs>
                        <span
                            className={cs('material-symbols-rounded', styles.legendControlIcon)}
                            onClick={toggleCollapse}
                        >
                            expand_more
                        </span>
                    </div>
                    <svg height={30} width={legendData.length * legendItemWidth}>
                        {legendData.map((lgnd, idx) => (
                            <React.Fragment key={idx}>
                                <Rectangle
                                    x={idx * legendItemWidth}
                                    width={legendItemWidth}
                                    height={10}
                                    fill={lgnd.color}
                                />
                                <text
                                    x={idx * legendItemWidth + legendItemWidth / 2}
                                    textAnchor="middle"
                                    y={
                                        28 +
                                        (idx * legendItemWidth + legendItemWidth / 2) *
                                            Math.tan((5 * Math.PI) / 180)
                                    }
                                    className={styles.legendLabel}
                                    transform="rotate(-5)"
                                >
                                    {lgnd[(activeAccessor + 'Label') as keyof LegendData]}
                                </text>
                            </React.Fragment>
                        ))}
                    </svg>
                </div>
            )}
        </div>
    );
};

export default MapLegend;
