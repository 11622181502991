import React, { useCallback } from 'react';

import FileInput from 'components/FileInput';

import Api from 'services/api';
import usePromise from '@ra/hooks/usePromise';
import { getErrorMessage } from '@ra/utils/error';
import Toast from 'services/toast';
import { _ } from 'services/i18n';

interface ProjectDocumentInputProps {
    name?: string;
    onChange?: (payload: { name?: string; value: string | null }) => void;
}

const ProjectDocumentInput: React.FC<ProjectDocumentInputProps> = (props) => {
    const { name, onChange, ...otherProps } = props;

    const [, uploadFile] = usePromise(Api.uploadFile);

    const handleChange = useCallback(
        async ({ name, files }: any) => {
            const file = files[0];
            if (!file) {
                return onChange?.({ name, value: null });
            }
            try {
                const formData = new FormData();
                formData.append('file', file);
                const response = await uploadFile(formData, { headers: new Headers() });
                if (response?.url) {
                    onChange?.({ name, value: response.url });
                } else {
                    Toast.show(_('An error occured while uploading file!'), Toast.DANGER);
                }
            } catch (error) {
                Toast.show(getErrorMessage(error), Toast.DANGER);
                console.log(error);
            }
        },
        [onChange, name]
    );

    return <FileInput {...otherProps} onChange={handleChange} />;
};

export default ProjectDocumentInput;
