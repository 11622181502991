import { useState, useCallback } from 'react';

import AuthInput from 'components/Input/AuthInput';
import Button from 'components/Button';

import cs from '@ra/cs';
import Modal from '@ra/components/Modal';
import withVisibleCheck from '@ra/components/WithVisibleCheck';
import Form, { FormSubmitCallback, InputField } from '@ra/components/Form';
import usePromise from '@ra/hooks/usePromise';

import { _ } from 'services/i18n';
import Toast from 'services/toast';
import Api from 'services/api';

import { AuthModalProps } from '..';

import styles from './styles.scss';

type ResetPasswordProps = AuthModalProps & {
    onComplete?: () => void;
};

const ResetPassword: React.FC<ResetPasswordProps> = (props) => {
    const { email, identifier, onComplete, onClose, isNewPassword } = props;

    const [error, setError] = useState<any>(null);

    const [{ loading }, resetPassword] = usePromise(Api.resetPassword);

    const handleResetPassword: FormSubmitCallback = useCallback(
        async (formData) => {
            try {
                const result = await resetPassword({
                    username: email,
                    identifier: identifier,
                    password: formData.password,
                    rePassword: formData.rePassword
                });
                if (result) {
                    Toast.show(
                        isNewPassword
                            ? _(
                                  'Your new password has been set. You can now login with your new credentials!'
                              )
                            : _('New password created successfully !!'),
                        Toast.SUCCESS
                    );
                    onComplete?.();
                    onClose();
                }
            } catch (err) {
                console.log(err);
                setError(err);
            }
        },
        [resetPassword, email, identifier, onComplete, onClose, isNewPassword]
    );

    return (
        <Modal className={styles.modal}>
            <div className={styles.header}>
                <div className={styles.lockWrapper}>
                    <span className={cs('material-symbols-rounded', styles.lockIcon)}>lock</span>
                </div>
                <span
                    onClick={onClose}
                    className={cs('material-symbols-rounded', styles.closeIcon)}
                >
                    close
                </span>
            </div>
            <h2 className={styles.title}>
                {isNewPassword ? _('Set new password') : _('Reset your password')}
            </h2>
            <Form
                formErrorClassName={styles.errorMessage}
                error={error}
                onSubmit={handleResetPassword}
                className={styles.formWrapper}
            >
                <InputField
                    component={AuthInput}
                    name="password"
                    inputType="password"
                    label={_('New Password')}
                    labelClassName={styles.inputLabel}
                    placeholder={_('Enter your new password')}
                    required
                />
                <InputField
                    component={AuthInput}
                    name="rePassword"
                    inputType="password"
                    label={_('Confirm new password')}
                    labelClassName={styles.inputLabel}
                    placeholder={_('Confirm your new password')}
                    required
                />
                <Button loading={loading} className={styles.button}>
                    {isNewPassword ? _('Set password') : _('Reset password')}
                </Button>
            </Form>
        </Modal>
    );
};

export default withVisibleCheck(ResetPassword);
