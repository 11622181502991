import React from 'react';

import cs from '@ra/cs';
import { _ } from 'services/i18n';

import styles from './styles.scss';

interface StatusProgressProps {
    title: string;
    percent?: number;
    indicatorClassName?: string;
}

const StatusProgress: React.FC<StatusProgressProps> = (props) => {
    const { title, indicatorClassName } = props;

    /*
    const percentValue = React.useMemo(() => {
        if (title === _('Completed')) {
            return 100;
        }
        if (title === _('Pipeline')) {
            return 0;
        }
        if (!percent || isNaN(percent)) {
            return 0;
        }
        return percent;
    }, [title, percent]);

    const activeTrackStyle = React.useMemo(() => {
        if (percentValue > -1) {
            return { width: `${percentValue}%` };
        }
        return {};
    }, [percentValue]);
     */

    return (
        <div className={styles.container}>
            <div className={styles.title}>
                <div
                    className={cs(
                        styles.indicator,
                        {
                            [styles.indicatorActive]: title === _('Active'),
                            [styles.indicatorCompleted]: title === _('Completed'),
                            [styles.indicatorDropped]: title === _('Dropped')
                        },
                        indicatorClassName
                    )}
                />
                <p className={styles.titleText}>{title}</p>
            </div>
            {/* TODO: Progress Percentage
            <div className={styles.content}>
                <div
                    className={cs(styles.progressBar, {
                        [styles.progressBarPipeline]: title === _('Pipeline')
                    })}
                >
                    <div
                        className={cs(styles.activeTrack, {
                            [styles.activeTrackActive]: title === _('Active'),
                            [styles.activeTrackCompleted]: title === _('Completed'),
                            [styles.activeTrackDropped]: title === _('Dropped')
                        })}
                        style={activeTrackStyle}
                    />
                </div>
                <div className={styles.percentage}>
                    {title !== _('Completed') ? (
                        `${percentValue}%`
                    ) : (
                        <span className={cs('material-symbols-rounded', styles.completeIcon)}>
                            check_circle
                        </span>
                    )}
                </div>
            </div>
              */}
        </div>
    );
};

export default StatusProgress;
