import React, { useRef, useState, useCallback, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import List, { KeyExtractor, ListRenderItem, ListRenderItemProps } from '@ra/components/List';
import Popup from '@ra/components/Popup';

import cs from '@ra/cs';
import { useI18nContext } from '@ra/components/I18n';
import * as localeActions from 'store/slices/locale';

import type { RootState } from 'store';
import type { LanguageModel } from 'services/i18n';

import styles from './styles.scss';

type LanguageSelectProps = {
    className?: string;
};

interface RenderLanguageItemProps extends ListRenderItemProps<LanguageModel> {
    onClick?: (item?: LanguageModel) => void;
}

const keyExtractor: KeyExtractor<LanguageModel> = (item) => item.id;

const LanguageItem = ({ item, onClick }: RenderLanguageItemProps) => {
    const handleClick = useCallback(() => {
        onClick?.(item);
    }, [item, onClick]);

    const handleKeyDown = useCallback(
        (e: React.KeyboardEvent) => {
            switch (e.which || e.keyCode) {
                case 32:
                case 13:
                    onClick?.(item);
                    break;
                case 27:
                    onClick?.();
                    break;
                default:
                    break;
            }
        },
        [item, onClick]
    );

    return (
        <li
            className={styles.languageItem}
            onClick={handleClick}
            tabIndex={0}
            onKeyDown={handleKeyDown}
            role="menuitem"
        >
            <span className={styles.languageTitle}>{item.title}</span>
            <span className={styles.languageCode}>{item.code}</span>
        </li>
    );
};

const LanguageSelect: React.FC<LanguageSelectProps> = (props) => {
    const { className } = props;

    const { changeLanguage } = useI18nContext();

    const dispatch = useDispatch();
    const { languages, currentLanguage }: { languages: LanguageModel[]; currentLanguage: string } =
        useSelector((state: RootState) => state.locale);

    const currentLanguageItem: LanguageModel | undefined = useMemo(
        () => languages.find((lng) => lng.code === currentLanguage),
        [languages, currentLanguage]
    );

    const anchorRef: React.RefObject<HTMLDivElement> = useRef(null);

    const [isDropdownVisible, setDropdownVisible] = useState<boolean>(false);

    const togglePopup = useCallback(() => setDropdownVisible((prev) => !prev), []);

    const handleKeyDown = useCallback(
        (e: React.KeyboardEvent) => {
            switch (e.which || e.keyCode) {
                case 32:
                case 13:
                    togglePopup();
                    break;
                case 38:
                    if (isDropdownVisible) {
                        togglePopup();
                    }
                    break;
                case 40:
                    if (!isDropdownVisible) {
                        togglePopup();
                    }
                    break;
                default:
                    break;
            }
        },
        [togglePopup, isDropdownVisible]
    );

    const handleLanguageClick = useCallback(
        (language?: LanguageModel) => {
            if (language) {
                changeLanguage(language.code);
                dispatch(localeActions.setCurrentLanguage(language.code));
            }
            togglePopup();
        },
        [togglePopup, dispatch, changeLanguage]
    );

    const renderLanguageItem: ListRenderItem<LanguageModel> = useCallback(
        (listProps) => {
            return <LanguageItem {...listProps} onClick={handleLanguageClick} />;
        },
        [handleLanguageClick]
    );

    return (
        <>
            <div
                ref={anchorRef}
                className={cs(
                    styles.popupLabel,
                    {
                        [styles.popupLabelActive]: isDropdownVisible
                    },
                    className
                )}
                onClick={togglePopup}
                onKeyDown={handleKeyDown}
                tabIndex={0}
                role="menu"
                aria-haspopup="menu"
            >
                <span className={cs('material-symbols-rounded', styles.languageIcon)}>
                    language
                </span>
                <span className={styles.languageCode}>
                    {currentLanguageItem?.displayTitle || ''}
                </span>
                <span className={cs('material-symbols-rounded', styles.dropdownIcon)}>
                    expand_more
                </span>
            </div>
            <Popup
                isVisible={isDropdownVisible}
                anchor={anchorRef}
                anchorOrigin="bottom right"
                transformOrigin="top right"
                onClose={togglePopup}
                closeOnOutsideClick
                className={styles.languagePopup}
            >
                <List
                    className={styles.languageDropdown}
                    keyExtractor={keyExtractor}
                    data={languages}
                    renderItem={renderLanguageItem}
                    component="ul"
                />
            </Popup>
        </>
    );
};

export default LanguageSelect;
