import React, { useCallback, useMemo } from 'react';

import CheckboxInput from '@ra/components/Form/CheckboxInput';
import List, { ListRenderItem, KeyExtractor } from '@ra/components/List';

import cs from '@ra/cs';
import { _ } from 'services/i18n';

import { useFilters, useFiltersDispatch } from 'hooks/filters';

import styles from './styles.scss';

type ProjectStatusItemType = {
    id: string | number;
    key: string;
};

type ProjectStatusProps = {
    item: ProjectStatusItemType;
    filterType?: string;
};

const projectStatuses = [
    { id: 1, key: 'active' },
    { id: 2, key: 'completed' },
    { id: 3, key: 'dropped' },
    { id: 4, key: 'pipeline' }
];

const keyExtractor: KeyExtractor<ProjectStatusItemType> = (item) => item.id;

const ProjectStatusItem: React.FC<ProjectStatusProps> = (props) => {
    const { item, filterType = 'Status:' } = props;

    const dispatch = useFiltersDispatch();
    const filters = useFilters();

    const statusData: { [key: string]: string } = useMemo(
        () => ({
            pipeline: _('Pipeline'),
            active: _('Active'),
            completed: _('Completed'),
            dropped: _('Dropped')
        }),
        []
    );

    const isActive = useMemo(() => {
        return filters.some(
            (el) => el.filterType === filterType && el.filterValue === statusData[item.key]
        );
    }, [item, filters, filterType, statusData]);

    const handleChange = useCallback(
        (target: HTMLInputElement) => {
            const filter = {
                filterType,
                filterValue: statusData[item.key],
                query: { status__in: item.key }
            };
            const actionType: 'add' | 'remove' = target.checked ? 'add' : 'remove';
            dispatch({ type: actionType, filter });
        },
        [dispatch, item, filterType, statusData]
    );

    return (
        <label
            htmlFor={`${filterType}-${item.id}`}
            className={cs(styles.projectStatusItem, { [styles.projectStatusItemActive]: isActive })}
        >
            <CheckboxInput
                id={`${filterType}-${item.id}`}
                size="1em"
                onChange={handleChange}
                checked={isActive}
            />
            <span className={styles.projectStatusItemText}>{statusData[item.key]}</span>
        </label>
    );
};

interface ProjectStatusesProps {
    className?: string;
    filterType?: string;
}

const ProjectStatuses: React.FC<ProjectStatusesProps> = (props) => {
    const { className, filterType } = props;

    const renderProjectStatusItem: ListRenderItem<ProjectStatusItemType> = useCallback(
        (listProps) => {
            return <ProjectStatusItem {...listProps} filterType={filterType} />;
        },
        [filterType]
    );

    return (
        <List
            className={className}
            data={projectStatuses}
            renderItem={renderProjectStatusItem}
            keyExtractor={keyExtractor}
        />
    );
};

export default ProjectStatuses;
