import React, { useState, useCallback, useMemo } from 'react';

import Button from 'components/Button';
import Form from '@ra/components/Form/Advanced';
import AuthInput from 'components/Input/AuthInput';
import CurrencyInputGroup from 'components/Input/CurrencyInputGroup';
import DateRangeInput from 'components/Input/DateRangeInput';
import Input from '@ra/components/Form/Input';
import LocationInput from 'components/Input/LocationInput';
import PriorityIndicatorInput from 'components/Input/PriorityIndicatorInput';
import MultiSelectInput from '@ra/components/Form/MultiSelectInput';
import Tabs, { Tab } from 'components/Tabs';
import TargetInputTable, { type TargetInputRow } from 'components/TargetInputTable';

import cs from '@ra/cs';
import { useAppSelector } from 'hooks/store';
import { _, Localize } from 'services/i18n';
import { currencies } from 'utils/form';

import type { TabChangeCallback, TabRenderHeader } from '@ra/components/Tabs';
import type { Organization } from 'services/types';
import type { ExpandedOutput } from 'services/report';
import type { OptionsType } from 'components/SelectInput';

import styles from './styles.scss';

const titleExtractor = (item: OptionsType) => item?.title;
const idExtractor = (item: OptionsType) => item?.id;
const organizationIdsExtractor = ({ value }: { value: Organization[] }) => {
    if (value?.length > 0) {
        return value.map((v) => v.id).join(',');
    }
    return null;
};
const organizationFormValueExtractor = (value: Organization[]) => {
    if (value?.length > 0) {
        return value.map((v) => v.id).join(',');
    }
    return null;
};

const AddButton: React.FC<{
    onAddClick?: React.MouseEventHandler;
    title: string;
}> = (props) => {
    const { onAddClick, title } = props;

    return (
        <div className={styles.addButton} onClick={onAddClick}>
            <span className={cs(styles.addIcon, 'material-symbols-rounded')}>add</span>
            <span className={styles.addText}>{title}</span>
        </div>
    );
};

const initialTargets: TargetInputRow[] = [{ title: '', value: '' }];

type PriorityIndicatorItem = {
    title: string;
    code: string;
    value?: number | string;
};
const initialPriorityIndicators: PriorityIndicatorItem[] = [
    {
        title: 'Priority Indicator 1',
        code: 'priority-indicator-1'
    }
];

type ActivityTabItem = {
    title: string;
    code: string;
    data?: any;
    priorityIndicators: PriorityIndicatorItem[];
    targets: TargetInputRow[];
};
const initialActivities: ActivityTabItem[] = [
    {
        title: 'Activity 1',
        code: 'output-1-activity-1',
        priorityIndicators: initialPriorityIndicators,
        targets: initialTargets
    }
];

type OutputTabItem = {
    title: string;
    code: string;
    data?: any;
    activities: ActivityTabItem[];
};
const initialOutputs: OutputTabItem[] = [
    {
        title: 'Output 1',
        code: 'output-1',
        activities: initialActivities
    }
];

const OutputForm: React.FC<{ defaultOutputs?: ExpandedOutput[] }> = ({ defaultOutputs }) => {
    const initialOutputTabs = useMemo(() => {
        if (!defaultOutputs) {
            return initialOutputs;
        }
        return defaultOutputs.map((output, idx) => ({
            title: `Output ${idx + 1}`,
            code: `output-${idx + 1}`,
            data: {
                id: output.id,
                title: output.title,
                dateFrom: output.dateFrom,
                dateTo: output.dateTo
            },
            activities: (output.activities || []).map((act, actIdx) => ({
                title: `Activity ${actIdx + 1}`,
                code: `output-${idx + 1}-activity-${actIdx + 1}`,
                data: {
                    id: act.id,
                    title: act.title,
                    budget: act.budget,
                    location: act.location,
                    partners: act.partners
                },
                priorityIndicators: act.priorityIndicators.map((pi, piIdx) => ({
                    title: `Priority Indicator ${piIdx + 1}`,
                    code: `priority-indicator-${piIdx + 1}`,
                    value: pi
                })),
                targets: (act.targets || []).map((tar) => ({
                    title: tar.title,
                    value: tar.value,
                    data: { id: tar.id }
                }))
            }))
        }));
    }, [defaultOutputs]);

    const [outputs, setOutputs] = useState<OutputTabItem[]>(initialOutputTabs);
    const [activeOutput, setActiveOutput] = useState<OutputTabItem>(outputs[0]);
    const [activeActivity, setActiveActivity] = useState<ActivityTabItem>(
        activeOutput.activities[0]
    );

    const handleAddPriorityIndicatorInput = useCallback(() => {
        const addedIdx = activeActivity.priorityIndicators.length + 1;
        const addedInput = {
            title: `Priority Indicator ${addedIdx}`,
            code: `priority-indicator-${addedIdx}`
        };
        const newPriorityIndicatorInputs = [...activeActivity.priorityIndicators, addedInput];
        const newActivities = [...activeOutput.activities];
        const changedActivityIdx = newActivities.findIndex((na) => na.code === activeActivity.code);
        const newActiveActivity = {
            ...activeActivity,
            priorityIndicators: newPriorityIndicatorInputs
        };
        newActivities.splice(changedActivityIdx, 1, newActiveActivity);
        const newOutputs = [...outputs];
        const changedOutputIdx = newOutputs.findIndex((no) => no.code === activeOutput.code);
        const newActiveOutput = { ...activeOutput, activities: newActivities };
        newOutputs.splice(changedOutputIdx, 1, newActiveOutput);
        setOutputs(newOutputs);
        setActiveOutput(newActiveOutput);
        setActiveActivity(newActiveActivity);
    }, [activeActivity, activeOutput, outputs]);

    const handleRemovePriorityIndicatorInput = useCallback(() => {
        if (activeActivity.priorityIndicators.length > 1) {
            const newPriorityIndicatorInputs = [...activeActivity.priorityIndicators];
            newPriorityIndicatorInputs.pop();
            const newActivities = [...activeOutput.activities];
            const changedActivityIdx = newActivities.findIndex(
                (na) => na.code === activeActivity.code
            );
            const newActiveActivity = {
                ...activeActivity,
                priorityIndicators: newPriorityIndicatorInputs
            };
            newActivities.splice(changedActivityIdx, 1, newActiveActivity);
            const newOutputs = [...outputs];
            const changedOutputIdx = newOutputs.findIndex((o) => o.code === activeOutput.code);
            const newActiveOutput = {
                ...activeOutput,
                activities: newActivities
            };
            newOutputs.splice(changedOutputIdx, 1, newActiveOutput);
            setOutputs(newOutputs);
            setActiveOutput(newActiveOutput);
            setActiveActivity(newActiveActivity);
        }
    }, [activeActivity, activeOutput, outputs]);

    const handleOutputTabChange: TabChangeCallback = useCallback(
        ({ activeTab: activeTabCode }) => {
            const newActiveOutput = outputs.find((o) => o.code === activeTabCode) as OutputTabItem;
            if (newActiveOutput) {
                setActiveOutput(newActiveOutput);
                if (newActiveOutput.activities?.length > 0) {
                    setActiveActivity(newActiveOutput.activities[0]);
                }
            }
        },
        [outputs]
    );

    const handleAddOutput = useCallback(() => {
        const newIdx = outputs.length + 1;
        const addedOutput = {
            title: `Output ${newIdx}`,
            code: `output-${newIdx}`,
            activities: [
                {
                    title: 'Activity 1',
                    code: `output-${newIdx}-activity-1`,
                    priorityIndicators: initialPriorityIndicators,
                    targets: initialTargets
                }
            ]
        };
        const newOutputs = [...outputs];
        newOutputs.push(addedOutput);
        setOutputs(newOutputs);
        setActiveOutput(addedOutput);
        setActiveActivity(addedOutput.activities[0]);
    }, [outputs]);

    const handleRemoveOutput = useCallback(
        (e: React.MouseEvent) => {
            e.stopPropagation();
            if (outputs.length > 1) {
                const newOutputs = [...outputs];
                newOutputs.pop();
                setOutputs(newOutputs);
                if (activeOutput.code === outputs[outputs.length - 1].code) {
                    setActiveOutput(newOutputs[newOutputs.length - 1]);
                }
                setActiveActivity(newOutputs[0].activities[0]);
            }
        },
        [outputs, activeOutput]
    );

    const handleActivityTabChange: TabChangeCallback = useCallback(
        ({ activeTab: activeTabCode }) => {
            const newActivity = activeOutput.activities.find(
                (a) => a.code === activeTabCode
            ) as ActivityTabItem;
            if (newActivity) {
                setActiveActivity(newActivity);
            }
        },
        [activeOutput.activities]
    );

    const handleAddActivity = useCallback(() => {
        const newIdx = activeOutput.activities.length + 1;
        const addedActivity = {
            title: `Activity ${newIdx}`,
            code: activeOutput.code.concat('-', `activity-${newIdx}`),
            priorityIndicators: initialPriorityIndicators,
            targets: initialTargets
        };
        const newActivities = [...activeOutput.activities, addedActivity];
        const newOutputs = [...outputs];
        const changedOutputIdx = newOutputs.findIndex((no) => no.code === activeOutput.code);
        const newActiveOutput = { ...activeOutput, activities: newActivities };
        newOutputs.splice(changedOutputIdx, 1, newActiveOutput);
        setOutputs(newOutputs);
        setActiveOutput(newActiveOutput);
        setActiveActivity(addedActivity);
    }, [activeOutput.activities, activeOutput.code, outputs, activeActivity]);

    const handleRemoveActivity = useCallback(
        (e: React.MouseEvent) => {
            e.stopPropagation();
            if (activeOutput.activities.length > 1) {
                const newActivities = [...activeOutput.activities];
                newActivities.pop();
                const newOutputs = [...outputs];
                const changedOutputIdx = newOutputs.findIndex((o) => o.code === activeOutput.code);
                const newActiveOutput = {
                    ...activeOutput,
                    activities: newActivities
                };
                newOutputs.splice(changedOutputIdx, 1, newActiveOutput);
                setOutputs(newOutputs);
                setActiveOutput(newActiveOutput);
                if (
                    activeActivity.code ===
                    activeOutput.activities[activeOutput.activities.length - 1].code
                ) {
                    setActiveActivity(activeOutput.activities[newActivities.length - 1]);
                }
            }
        },
        [outputs, activeActivity, activeOutput.activities, activeOutput.code]
    );

    const handleAddTarget = useCallback(() => {
        const addedTarget = { title: '', value: '' };
        const newTargets = [...activeActivity.targets, addedTarget];
        const newActivities = [...activeOutput.activities];
        const changedActivityIdx = newActivities.findIndex((na) => na.code === activeActivity.code);
        const newActiveActivity = { ...activeActivity, targets: newTargets };
        newActivities.splice(changedActivityIdx, 1, newActiveActivity);
        const newOutputs = [...outputs];
        const changedOutputIdx = newOutputs.findIndex((no) => no.code === activeOutput.code);
        const newActiveOutput = { ...activeOutput, activities: newActivities };
        newOutputs.splice(changedOutputIdx, 1, newActiveOutput);
        setOutputs(newOutputs);
        setActiveOutput(newActiveOutput);
        setActiveActivity(newActiveActivity);
    }, [activeActivity, activeOutput, outputs]);

    const handleRemoveTarget = useCallback(() => {
        if (activeActivity.targets.length > 1) {
            const newTargets = [...activeActivity.targets];
            newTargets.pop();
            const newActivities = [...activeOutput.activities];
            const changedActivityIdx = newActivities.findIndex(
                (na) => na.code === activeActivity.code
            );
            const newActiveActivity = { ...activeActivity, targets: newTargets };
            newActivities.splice(changedActivityIdx, 1, newActiveActivity);
            const newOutputs = [...outputs];
            const changedOutputIdx = newOutputs.findIndex((o) => o.code === activeOutput.code);
            const newActiveOutput = {
                ...activeOutput,
                activities: newActivities
            };
            newOutputs.splice(changedOutputIdx, 1, newActiveOutput);
            setOutputs(newOutputs);
            setActiveOutput(newActiveOutput);
            setActiveActivity(newActiveActivity);
        }
    }, [activeActivity, activeOutput, outputs]);

    const renderOutputTabsHeader: TabRenderHeader = useCallback(
        (tabHeaderProps) => {
            const { title, active, ...rest } = tabHeaderProps;

            return (
                <div
                    className={cs(styles.headerTabItem, {
                        [styles.headerTabItemActive]: active
                    })}
                    {...rest}
                >
                    <span>{title}</span>
                    {rest.index !== 0 && rest.index === outputs.length - 1 && (
                        <span
                            onClick={handleRemoveOutput}
                            className={cs('material-symbols-rounded', styles.removeIcon)}
                        >
                            remove_circle_outline
                        </span>
                    )}
                </div>
            );
        },
        [outputs]
    );

    const renderActivityTabsHeader: TabRenderHeader = useCallback(
        (tabHeaderProps) => {
            const { title, active, ...rest } = tabHeaderProps;

            return (
                <div
                    className={cs(styles.headerTabItem, {
                        [styles.headerTabItemActive]: active
                    })}
                    {...rest}
                >
                    <span>{title}</span>
                    {rest.index !== 0 && rest.index === activeOutput.activities.length - 1 && (
                        <span
                            onClick={handleRemoveActivity}
                            className={cs('material-symbols-rounded', styles.removeIcon)}
                        >
                            remove_circle_outline
                        </span>
                    )}
                </div>
            );
        },
        [activeOutput.activities, handleRemoveActivity]
    );

    return (
        <div className={styles.container}>
            <Tabs
                renderHeader={renderOutputTabsHeader}
                headerContainerClassName={styles.tabsHeaderContainer}
                headerClassName={styles.tabsHeader}
                tabItemClassName={styles.tabHeaderItem}
                activeTabItemClassName={styles.tabHeaderItemActive}
                PostHeaderComponent={
                    <AddButton title={_('Add output')} onAddClick={handleAddOutput} />
                }
                disableUnmount
                activeTab={activeOutput.code}
                onChange={handleOutputTabChange}
                contentContainerClassName={styles.formContainer}
            >
                {outputs.map((output, outputIdx) => (
                    <Tab key={output.code} label={output.code} title={output.title}>
                        <Form.InputGroup name={`outputs[${outputIdx}]`}>
                            <div className={styles.formSection}>
                                <h3 className={styles.formSectionTitle}>
                                    <Localize>1. DESCRIPTION AND DURATION</Localize>
                                </h3>
                                {Boolean(output.data?.id) && (
                                    <Form.Input
                                        type="hidden"
                                        name="id"
                                        component={Input}
                                        value={output.data.id}
                                    />
                                )}
                                <Form.Input
                                    name="title"
                                    component={AuthInput}
                                    label={_('Output Title*')}
                                    labelClassName={styles.inputLabel}
                                    placeholder={_('Enter output title')}
                                    defaultValue={output.data?.title}
                                    required
                                />
                                <Form.Input
                                    name="duration"
                                    containerClassName={styles.inputContainer}
                                    component={DateRangeInput}
                                    label={_('Output Duration*')}
                                    labelClassName={styles.inputLabel}
                                    defaultValue={
                                        output.data?.dateFrom
                                            ? output.data.dateFrom.concat(',', output.data.dateTo)
                                            : undefined
                                    }
                                    placeholder="mm/dd/yyyy - mm/dd/yyyy"
                                    required
                                />
                            </div>
                            <Tabs
                                renderHeader={renderActivityTabsHeader}
                                headerContainerClassName={styles.tabsHeaderContainer}
                                headerClassName={styles.tabsHeader}
                                PostHeaderComponent={
                                    <AddButton
                                        title={_('Add activity')}
                                        onAddClick={handleAddActivity}
                                    />
                                }
                                disableUnmount
                                activeTab={activeActivity.code}
                                onChange={handleActivityTabChange}
                            >
                                {output.activities.map((activity, idx) => (
                                    <Tab
                                        key={activity.code}
                                        label={activity.code}
                                        title={activity.title}
                                    >
                                        <Form.InputGroup name={`activities[${idx}]`}>
                                            <div className={styles.formSection}>
                                                <h3 className={styles.formSectionTitle}>
                                                    <Localize>
                                                        1. ACTIVITY TITLE AND BUDGET
                                                    </Localize>
                                                </h3>
                                                {Boolean(activity.data?.id) && (
                                                    <Form.Input
                                                        type="hidden"
                                                        name="id"
                                                        component={Input}
                                                        value={activity.data.id}
                                                    />
                                                )}

                                                <Form.Input
                                                    name="title"
                                                    component={AuthInput}
                                                    label={_('Activity Title*')}
                                                    labelClassName={styles.inputLabel}
                                                    placeholder={_('Enter activity title')}
                                                    defaultValue={activity.data?.title}
                                                    required
                                                />
                                                <CurrencyInputGroup
                                                    budgetLabel={_('Budget*')}
                                                    defaultAmount={activity.data?.budget}
                                                    lockedCurrency={currencies[0]}
                                                />
                                            </div>
                                            <div className={styles.formSection}>
                                                <h3 className={styles.formSectionTitle}>
                                                    <Localize>2. LOCATION</Localize>
                                                </h3>
                                                <Form.Input
                                                    name="location"
                                                    component={LocationInput}
                                                    required
                                                    defaultValue={activity.data?.location}
                                                />
                                            </div>
                                            <div className={styles.formSection}>
                                                <h3 className={styles.formSectionTitle}>
                                                    <Localize>
                                                        3. NDRR STRATEGIC ACTION PLAN ACTIVITIES
                                                    </Localize>
                                                </h3>
                                                {activity.priorityIndicators.map(
                                                    (priInput, idx) => (
                                                        <div
                                                            key={priInput.code}
                                                            className={
                                                                styles.priorityIndicatorInputGroup
                                                            }
                                                        >
                                                            <div
                                                                className={
                                                                    styles.priorityIndicatorHeader
                                                                }
                                                            >
                                                                <h4
                                                                    className={
                                                                        styles.priorityIndicatorTitle
                                                                    }
                                                                >
                                                                    {priInput.title}
                                                                </h4>
                                                                {idx !== 0 &&
                                                                    idx ===
                                                                        activeActivity
                                                                            .priorityIndicators
                                                                            .length -
                                                                            1 && (
                                                                        <span
                                                                            className={cs(
                                                                                'material-symbols-rounded',
                                                                                styles.removeIcon
                                                                            )}
                                                                            onClick={
                                                                                handleRemovePriorityIndicatorInput
                                                                            }
                                                                        >
                                                                            delete
                                                                        </span>
                                                                    )}
                                                            </div>
                                                            <Form.Input
                                                                name={`priorityIndicators[${idx}]id`}
                                                                component={PriorityIndicatorInput}
                                                                required
                                                                defaultValue={
                                                                    activity.priorityIndicators?.[
                                                                        idx
                                                                    ]?.value
                                                                }
                                                                isCustom
                                                            />
                                                        </div>
                                                    )
                                                )}
                                                <Button
                                                    type="button"
                                                    secondary
                                                    small
                                                    leftIcon="add"
                                                    onClick={handleAddPriorityIndicatorInput}
                                                >
                                                    <Localize>Add priority indicator</Localize>
                                                </Button>
                                            </div>
                                            <div className={styles.formSection}>
                                                <h3 className={styles.formSectionTitle}>
                                                    <Localize>4. PARTNERS</Localize>
                                                </h3>
                                                <PartnersInput activity={activity} />
                                                <div className={styles.targetsContainer}>
                                                    <h4 className={styles.targetsTableTitle}>
                                                        <Localize>Target List</Localize>
                                                    </h4>
                                                    <TargetInputTable
                                                        data={activity.targets}
                                                        onRemoveTarget={handleRemoveTarget}
                                                    />
                                                    <Button
                                                        type="button"
                                                        className={styles.addTargetButton}
                                                        secondary
                                                        small
                                                        leftIcon="add"
                                                        onClick={handleAddTarget}
                                                    >
                                                        <Localize>Add target</Localize>
                                                    </Button>
                                                </div>
                                            </div>
                                        </Form.InputGroup>
                                    </Tab>
                                ))}
                            </Tabs>
                        </Form.InputGroup>
                    </Tab>
                ))}
            </Tabs>
        </div>
    );
};

function PartnersInput(props: { activity: ActivityTabItem }) {
    const { activity } = props;

    const { organizations } = useAppSelector((state) => state.organization);

    const [selectedPartners, setSelectedPartners] = useState<Organization[] | undefined>(undefined);
    const initialValue = useMemo(() => {
        return activity.data?.partners?.map((pt: number | string) => {
            return organizations.find((org) => org.id === Number(pt));
        });
    }, [activity]);

    const handleChange = useCallback(
        ({ value }: { value: Organization[] }) => setSelectedPartners(value),
        []
    );

    return (
        <Form.Input
            component={MultiSelectInput}
            label={_('Partner Organizations')}
            labelClassName={styles.inputLabel}
            containerClassName={styles.selectInput}
            name="partners"
            options={organizations}
            valueExtractor={titleExtractor}
            fieldValueExtractor={organizationIdsExtractor}
            formValueExtractor={organizationFormValueExtractor}
            keyExtractor={idExtractor}
            placeholder={_('Select partner organizations')}
            defaultValue={selectedPartners ? selectedPartners : initialValue}
            onChange={handleChange}
            hideFooter
        />
    );
}

export default OutputForm;
