import { useCallback, useMemo, useRef, useState } from 'react';

import Button from 'components/Button';
import ProjectReportFormModal from 'components/ProjectReportFormModal';
import List, { KeyExtractor, ListRenderItem, ListRenderItemProps } from '@ra/components/List';
import Popup from '@ra/components/Popup';

import { Localize } from 'services/i18n';
import { ProjectDocument, ProjectDocumentType } from 'services/types';
import { reportDocumentTypeTitles } from 'utils/form';

import { useAppSelector } from 'hooks/store';

import styles from './styles.scss';

const idExtractor: KeyExtractor<ProjectDocumentType> = (item) => item.id;

const ReportDocumentItem = ({
    item,
    onClick
}: ListRenderItemProps<ProjectDocumentType> & {
    onClick?: (item: ProjectDocumentType) => void;
}) => {
    const handleClick = useCallback(() => onClick?.(item), [item, onClick]);

    return (
        <li className={styles.reportsDropdownItem} onClick={handleClick} role="menuitem">
            {item.title}
        </li>
    );
};

const ProjectReportsDropdown: React.FC<{
    buttonClassName?: string;
    onReportAdded: () => void;
    documents: ProjectDocument[];
}> = ({ buttonClassName, onReportAdded, documents }) => {
    const { projectDocumentTypes } = useAppSelector((state) => state.projectDocumentType);
    const reportDocumentTypes = useMemo(() => {
        return projectDocumentTypes.filter((docType) =>
            reportDocumentTypeTitles.includes(docType.title)
        );
    }, [projectDocumentTypes]);

    const addableReportDocumentTypes = useMemo(() => {
        return reportDocumentTypes.filter((docType) => {
            return documents.every((doc) => {
                return doc.documentType !== docType.id;
            });
        });
    }, [documents, reportDocumentTypes]);

    const anchorRef = useRef<HTMLButtonElement>(null);

    const [activeDocumentType, setActiveDocumentType] = useState<ProjectDocumentType | null>(null);

    const [showReportsDropdown, setShowReportsDropdown] = useState<boolean>(false);
    const toggleReportsDropdown = useCallback(() => setShowReportsDropdown((srd) => !srd), []);

    const [showReportFormModal, setShowReportFormModal] = useState<boolean>(false);
    const handleShowReportFormModal = useCallback(() => setShowReportFormModal(true), []);
    const handleHideReportFormModal = useCallback(() => setShowReportFormModal(false), []);

    const handleReportDocumentClick = useCallback(
        (documentType: ProjectDocumentType) => {
            toggleReportsDropdown();
            setActiveDocumentType(documentType);
            handleShowReportFormModal();
        },
        [toggleReportsDropdown, handleShowReportFormModal]
    );

    const handleComplete = useCallback(() => {
        handleHideReportFormModal();
        onReportAdded?.();
    }, [handleHideReportFormModal, onReportAdded]);

    const renderReportDocumentItem: ListRenderItem<ProjectDocumentType> = useCallback(
        (listProps) => {
            return <ReportDocumentItem {...listProps} onClick={handleReportDocumentClick} />;
        },
        [handleReportDocumentClick]
    );

    if (addableReportDocumentTypes.length === 0) {
        return null;
    }

    return (
        <>
            <Button
                ref={anchorRef}
                className={buttonClassName}
                small
                tertiary
                onClick={toggleReportsDropdown}
                rightIcon="keyboard_arrow_down"
            >
                <Localize>Add Report</Localize>
            </Button>
            <Popup
                isVisible={showReportsDropdown}
                anchor={anchorRef}
                anchorOrigin="bottom right"
                transformOrigin="top right"
                onClose={toggleReportsDropdown}
                closeOnOutsideClick
                className={styles.reportsDropdown}
            >
                <List
                    keyExtractor={idExtractor}
                    data={addableReportDocumentTypes}
                    renderItem={renderReportDocumentItem}
                    component="ul"
                    className={styles.reportsDropdownContent}
                />
            </Popup>
            {activeDocumentType && (
                <ProjectReportFormModal
                    documentType={activeDocumentType}
                    isVisible={showReportFormModal}
                    onClose={handleHideReportFormModal}
                    onComplete={handleComplete}
                />
            )}
        </>
    );
};

export default ProjectReportsDropdown;
