import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { Localize } from 'services/i18n';
import SelectInput from '@ra/components/Form/SelectInput';

import styles from './styles.scss';

export type OptionsType = {
    id: number;
    title: string;
};

interface SelectProps {
    options: { id: number; title: string }[];
    valueExtractor: (item: OptionsType) => string;
    keyExtractor: (item: OptionsType) => number;
    placeholder: string;
    onChange?: React.ChangeEventHandler<HTMLInputElement>;
    hideFooter?: boolean;
}

const EmptyComponent = () => {
    return (
        <div className={styles.empty}>
            <Localize>No results found.</Localize>
        </div>
    );
};

const FooterComponent = () => {
    const navigate = useNavigate();

    const handleGoToRegisterRequest = useCallback(() => navigate('/request-register'), [navigate]);

    return (
        <div className={styles.footerWrapper}>
            <span className={styles.message}>
                <Localize>Not listed here?</Localize>
            </span>
            <span className={styles.link} onClick={handleGoToRegisterRequest}>
                <Localize>Request for registration</Localize>
            </span>
        </div>
    );
};

const OrganizationSelectInput = (props: SelectProps) => {
    const { hideFooter, ...selectInputProps } = props;
    return (
        <div className={styles.container}>
            <SelectInput
                className={styles.select}
                optionsWrapperClassName={styles.optionsWrapper}
                selectOptionClassName={styles.selectOptionStyle}
                optionItemClassName={styles.optionItemStyle}
                controlClassName={styles.selectControl}
                clearable={true}
                searchable={true}
                FilterEmptyComponent={EmptyComponent}
                EmptyComponent={EmptyComponent}
                FooterComponent={hideFooter ? undefined : FooterComponent}
                {...selectInputProps}
            />
        </div>
    );
};

export { OrganizationSelectInput };
