import React, { useState, useCallback, useRef, useMemo, useEffect } from 'react';
import { Link, NavLink, Outlet } from 'react-router-dom';

import Breadcrumbs from 'components/Breadcrumbs';
import Button from 'components/Button';
import LanguageSelect from 'components/LanguageSelect';
import NotificationsDropdown from 'components/NotificationsDropdown';
import SearchBar from 'components/SearchBar';
import UserDropdown from 'components/UserDropdown';

import cs from '@ra/cs';
import { Localize, _ } from 'services/i18n';
import useSize from '@ra/hooks/useSize';
import { useAppSelector } from 'hooks/store';

import logo from 'assets/images/MDSA_logo.svg';

import styles from './styles.scss';

interface DashboardContainerProps {
    children?: React.ReactNode;
    fitHeight?: boolean;
    breadcrumbs: { title: string; path: string }[];
}

interface NavItemProps {
    to: string;
    icon: string;
    title: string;
    collapsed?: boolean | null;
}

const NavItem: React.FC<NavItemProps> = (props) => {
    const { to, icon, title, collapsed } = props;

    const getActiveClassName = useCallback(
        ({ isActive }: { isActive: boolean }) => {
            return cs(styles.menuItem, {
                [styles.menuItemActive]: isActive,
                [styles.menuItemCollapsed]: collapsed
            });
        },
        [collapsed]
    );

    return (
        <NavLink title={title} to={to} className={getActiveClassName}>
            <span className="material-symbols-rounded">{icon}</span>
            {!collapsed && (
                <span
                    className={cs(styles.menuItemLabel, {
                        [styles.menuItemText]: collapsed === null,
                        [styles.menuItemTextUncollapsed]: collapsed === false
                    })}
                >
                    {title}
                </span>
            )}
        </NavLink>
    );
};

const DashboardContainer: React.FC<DashboardContainerProps> = (props) => {
    const { breadcrumbs, fitHeight } = props;
    const { isAuthenticated } = useAppSelector((state) => state.auth);

    const navBarRef = useRef<HTMLDivElement>(null);

    const size = useSize(window);
    const [collapsed, setCollapsed] = useState<boolean | null>(
        window.innerWidth <= 480 ? true : null
    );

    useEffect(() => {
        if (size?.width && size.width <= 480 && collapsed === null) {
            setCollapsed(true);
        }
    }, [size]);

    const toggleCollapsed = useCallback(() => {
        setCollapsed((prev) => !prev);
    }, []);

    const navBarHeight = useMemo(() => {
        if (navBarRef?.current) {
            return navBarRef.current.offsetHeight;
        }
    }, [size, collapsed, fitHeight]);

    const contentStyle = useMemo(() => {
        if (navBarHeight) {
            if (fitHeight) {
                return {
                    height: `calc(100vh - ${navBarHeight}px)`,
                    maxHeight: `calc(100vh - ${navBarHeight}px)`
                };
            }
            return { paddingTop: `${navBarHeight}px` };
        }
        return {};
    }, [size, fitHeight, navBarHeight]);

    return (
        <div className={styles.container}>
            <div
                className={cs(styles.sideBar, {
                    [styles.sideBarCollapsed]: collapsed
                })}
            >
                <div className={styles.header}>
                    <div
                        className={cs(styles.hamburgerMenu, {
                            [styles.hamburgerMenuCollapsed]: collapsed
                        })}
                        onClick={toggleCollapsed}
                    >
                        <span className="material-symbols-rounded">menu</span>
                    </div>
                    {!collapsed && (
                        <Link to="/">
                            <img
                                className={cs({
                                    [styles.sideBarLogo]: collapsed === null,
                                    [styles.sideBarLogoUncollapsed]: collapsed === false
                                })}
                                src={logo}
                                alt={_('MDSA Logo')}
                            />
                        </Link>
                    )}
                </div>
                <div className={styles.menu}>
                    {isAuthenticated && (
                        <>
                            <NavItem
                                collapsed={collapsed}
                                to="/dashboard"
                                icon="grid_view"
                                title={_('Dashboard')}
                            />
                            <NavItem
                                collapsed={collapsed}
                                to="/projects"
                                icon="folder"
                                title={_('Projects')}
                            />
                        </>
                    )}
                    <NavItem collapsed={collapsed} to="/map" icon="map" title={_('Map')} />
                    {!isAuthenticated && (
                        <NavItem
                            collapsed={collapsed}
                            to="/projects"
                            icon="folder"
                            title={_('Projects')}
                        />
                    )}
                    <NavItem
                        collapsed={collapsed}
                        to="/organizations"
                        icon="work"
                        title={_('Organizations')}
                    />
                    <NavItem
                        collapsed={collapsed}
                        to="/activities"
                        icon="show_chart"
                        title={_('NDRRSAP Activities')}
                    />
                </div>
            </div>
            {!collapsed && <div className={styles.overlay} onClick={toggleCollapsed} />}
            <div
                ref={navBarRef}
                className={cs(styles.navBar, {
                    [styles.navBarSticky]: fitHeight,
                    [styles.navBarCollapsed]: collapsed
                })}
            >
                {collapsed && (
                    <Link to="/">
                        <img className={styles.navBarLogo} src={logo} alt={_('MDSA Logo')} />
                    </Link>
                )}
                <Breadcrumbs
                    className={styles.breadcrumbs}
                    separator={<span className="material-symbols-rounded">chevron_right</span>}
                >
                    {breadcrumbs.map((breadcrumb, index) => (
                        <Link
                            key={index}
                            to={breadcrumb.path}
                            className={cs(styles.breadcrumbsLink, {
                                [styles.breadcrumbsLinkDisabled]: index === breadcrumbs.length - 1
                            })}
                        >
                            <span
                                className={cs(styles.breadcrumbItem, {
                                    [styles.breadcrumbItemActive]:
                                        breadcrumbs.length > 1 && index === breadcrumbs.length - 1
                                })}
                            >
                                {breadcrumb.title}
                            </span>
                        </Link>
                    ))}
                </Breadcrumbs>
                <div className={styles.navbarRight}>
                    <SearchBar className={styles.searchInput} />
                    <div className={styles.controlIcons}>
                        {isAuthenticated && <NotificationsDropdown />}
                        <LanguageSelect />
                        {isAuthenticated ? (
                            <UserDropdown />
                        ) : (
                            <Link to="/login">
                                <Button className={styles.button}>
                                    <Localize>Log in</Localize>
                                </Button>
                            </Link>
                        )}
                    </div>
                </div>
            </div>
            <div
                style={contentStyle}
                className={cs(styles.contentContainer, {
                    [styles.contentContainerCollapsed]: collapsed
                })}
            >
                <Outlet context={{ navBarHeight }} />
            </div>
        </div>
    );
};

export default DashboardContainer;
