import React, { useCallback, useState, useRef } from 'react';
import { isEmpty } from 'lodash';

import AuthModals from 'components/AuthModals';
import Tabs, { Tab, type TabRenderHeader, type TabChangeCallback } from '@ra/components/Tabs';

import Api from 'services/api';
import cs from '@ra/cs';
import { _ } from 'services/i18n';
import Toast from 'services/toast';
import { logout } from 'store/slices/auth';
import { phoneNumberValidationRegex } from 'utils/form';

import useAuthModals from 'hooks/useAuthModals';
import usePromise from '@ra/hooks/usePromise';
import { useAppSelector, useAppDispatch } from 'hooks/store';

import type { FormSubmitCallback } from '@ra/components/Form';

import ChangePassword from './ChangePassword';
import EditProfile from './EditProfile';

import styles from './styles.scss';

const tabIcons = {
    profile: 'edit',
    password: 'key'
};

const AccountSettings: React.FC = () => {
    const authModalsConfig = useAuthModals();

    const dispatch = useAppDispatch();

    const { user } = useAppSelector((state) => state.auth);

    const [error, setError] = useState<any>(null);

    const [{ loading }, editUserProfile] = usePromise(Api.patchUser);
    const [{ loading: loadingPassword }, changeUserPassword] = usePromise(Api.changePassword);
    const [{ loading: emailLoading }, requestEmailChange] = usePromise(Api.requestEmailChange);

    const [activeTab, setActiveTab] = useState<'profile' | 'password'>('profile');
    const handleTabChange: TabChangeCallback = useCallback(
        ({ activeTab: newActiveTab }) => {
            if (activeTab !== newActiveTab) {
                setError(null);
            }
            setActiveTab(newActiveTab as 'password' | 'profile');
        },
        [activeTab]
    );

    const [formData, setFormData] = useState<{ [key: string]: string }>({});

    const renderTabsHeader: TabRenderHeader = useCallback((tabHeaderProps) => {
        const { active, title, ...rest } = tabHeaderProps;

        return (
            <div
                className={cs(styles.headerItem, {
                    [styles.headerItemActive]: active
                })}
                {...rest}
            >
                <span
                    title={title}
                    className={cs(styles.tabIcon, 'material-symbols-rounded', {
                        [styles.tabIconActive]: active
                    })}
                >
                    {tabIcons[tabHeaderProps.label as keyof typeof tabIcons]}
                </span>
                <span className={styles.tabTitle}>{title}</span>
            </div>
        );
    }, []);

    const handleSubmitData = useCallback(
        async (pass: string) => {
            setError(null);
            try {
                if (formData.newEmail) {
                    await requestEmailChange({ newEmail: formData.newEmail, password: pass });
                    Toast.show(_('An email has been sent to the new address'), Toast.SUCCESS);
                    return authModalsConfig.dispatch({ type: 'showVerifyEmail' });
                }
                if (
                    formData.phoneNumber &&
                    !phoneNumberValidationRegex.test(formData.phoneNumber as string)
                ) {
                    throw {
                        errors: { phoneNumber: ['The phone number you entered is not valid.'] }
                    };
                }
                const submitParams = { ...formData, password: pass };
                await editUserProfile(submitParams);
                authModalsConfig.dispatch({ type: 'hideModals' });
                Api.loadUserToStore();
                Toast.show(_('Changes successfully saved!'), Toast.SUCCESS);
            } catch (err: any) {
                console.log(err);
                if (err.password) {
                    Toast.show(err.password?.[0], Toast.DANGER);
                } else {
                    setError(err);
                    authModalsConfig.dispatch({ type: 'hideModals' });
                }
            }
        },
        [editUserProfile, requestEmailChange, formData, authModalsConfig]
    );

    const handleEditProfile: FormSubmitCallback = useCallback(
        (formDataValues) => {
            const { fullName, newEmail, phoneNumber, designation } = formDataValues;
            const name = fullName.split(' ');
            const firstName = name[0];
            const lastName = fullName.substring(name[0].length).trim();
            const submitData: { [key: string]: string } = {};
            if (user.firstName !== firstName || user.lastName !== lastName) {
                submitData.firstName = firstName;
                submitData.lastName = lastName;
            }
            if (user.email !== newEmail) {
                submitData.newEmail = newEmail;
            }
            if (user.phoneNumber !== phoneNumber) {
                submitData.phoneNumber = phoneNumber;
            }
            if (user.designation !== designation) {
                submitData.designation = designation;
            }
            if (isEmpty(submitData)) {
                return Toast.show(_('No changes to save!'), Toast.DANGER);
            }
            setFormData(submitData);
            authModalsConfig.dispatch({ type: 'showConfirmPassword' });
        },
        [user, authModalsConfig]
    );

    const passwordFormRef = useRef<HTMLFormElement>(null);
    const handleChangePassword: FormSubmitCallback = useCallback(
        async (passwordFormData) => {
            setError(null);
            try {
                if (passwordFormData.oldPassword === passwordFormData.newPassword) {
                    return Toast.show(
                        _('New password is the same as the current password!'),
                        Toast.DANGER
                    );
                }
                if (passwordFormData.newPassword !== passwordFormData.reNewPassword) {
                    return Toast.show(_('Passwords do not match!'), Toast.DANGER);
                }
                await changeUserPassword(passwordFormData);
                Toast.show(_('Your password has been successfully updated.'), Toast.SUCCESS);
                passwordFormRef?.current?.reset();
            } catch (err) {
                console.log(err);
                setError(err);
            }
        },
        [changeUserPassword]
    );

    const handleEmailChanged = useCallback(() => {
        dispatch(logout());
        Toast.show(
            _(
                'Your email has changed, and you have been logged out. Please log in again with your new email!'
            ),
            Toast.SUCCESS,
            null
        );
    }, [dispatch]);

    const handleForgotPasswordClick = useCallback(() => {
        authModalsConfig.dispatch({ type: 'showSendEmail' });
    }, [authModalsConfig]);

    return (
        <div className={styles.container}>
            <Tabs
                className={styles.tabs}
                renderHeader={renderTabsHeader}
                headerClassName={styles.tabsHeader}
                contentContainerClassName={styles.contentContainer}
                activeTab={activeTab}
                onChange={handleTabChange}
            >
                <Tab label="profile" title={_('Edit Profile')} className={styles.tabContent}>
                    <EditProfile
                        loading={loadingPassword || loading || emailLoading}
                        onEditProfile={handleEditProfile}
                        error={error}
                    />
                </Tab>
                <Tab label="password" title={_('Change Password')} className={styles.tabContent}>
                    <ChangePassword
                        onChangePassword={handleChangePassword}
                        loading={loadingPassword}
                        error={error}
                        onForgotPasswordClick={handleForgotPasswordClick}
                        ref={passwordFormRef}
                    />
                </Tab>
            </Tabs>
            <AuthModals
                {...authModalsConfig}
                onConfirmPassword={handleSubmitData}
                loading={loading || loadingPassword || emailLoading}
                verifyEmailPromise={
                    activeTab === 'profile' ? Api.verifyEmailChange : Api.passwordResetVerify
                }
                resendCodePromise={
                    activeTab === 'profile' ? Api.requestEmailChange : Api.forgotPassword
                }
                onVerifyEmail={activeTab === 'profile' ? handleEmailChanged : undefined}
            />
        </div>
    );
};

export default AccountSettings;
