export const COLORS = [
    '#FF6B6B',
    '#54A0FF',
    '#FECA57',
    '#C253B3',
    '#18ECB3',
    '#FF9FF3',
    '#94A3B3',
    '#955FFF',
    '#FF8A65',
    '#0CB4BE'
];

export const darkColorIndices = [2, 4, 5];
