import React, { useCallback, useMemo, useState } from 'react';

import ProjectReportFormModal from 'components/ProjectReportFormModal';
import List, { ListProps, ListRenderItem, ListRenderItemProps } from '@ra/components/List';

import cs from '@ra/cs';
import { Localize, _ } from 'services/i18n';
import { ProjectDocument } from 'services/types';
import { agreementDocumentTypeTitles, reportDocumentTypeTitles } from 'utils/form';
import { formatLongFileName } from 'utils/formatter';

import { useAppSelector } from 'hooks/store';

import styles from './styles.scss';

interface ProjectDocumentsProps
    extends Omit<ListProps<any>, 'data' | 'keyExtractor' | 'renderItem'> {
    type: 'agreements' | 'reports';
    documents: ProjectDocument[];
    headerClassName?: string;
    headerTitle: string;
    onEditComplete?: () => void;
}

const ProjectDocuments: React.FC<ProjectDocumentsProps> = (props) => {
    const {
        type,
        documents,
        className,
        contentContainerClassName,
        headerTitle,
        headerClassName,
        onEditComplete,
        ...listProps
    } = props;

    const { projectDocumentTypes } = useAppSelector((state) => state.projectDocumentType);

    const documentIds = useMemo(() => {
        const agreements: number[] = [];
        const reports: number[] = [];
        for (const projectDocumentType of projectDocumentTypes) {
            if (
                agreementDocumentTypeTitles.some(
                    (docTitle) => projectDocumentType.title === docTitle
                )
            ) {
                agreements.push(projectDocumentType.id);
            }
            if (
                reportDocumentTypeTitles.some((docTitle) => projectDocumentType.title === docTitle)
            ) {
                reports.push(projectDocumentType.id);
            }
        }
        return {
            agreements,
            reports
        };
    }, [projectDocumentTypes]);

    const documentsData = useMemo(
        () =>
            documents
                .filter((doc) => documentIds[type].includes(doc.documentType))
                .sort((docA, docB) => docA.documentType - docB.documentType),
        [documents]
    );

    const renderProjectDocumentItem: ListRenderItem<ProjectDocument> = useCallback(
        (listProps) => {
            return (
                <ProjectDocumentItem {...listProps} onEditComplete={onEditComplete} type={type} />
            );
        },
        [type, onEditComplete]
    );

    return (
        <div className={className}>
            <h2 className={headerClassName}>{headerTitle}</h2>
            <List
                data={documentsData}
                keyExtractor={(item: { id: string | number }) => item.id}
                renderItem={renderProjectDocumentItem}
                className={cs(contentContainerClassName, styles.projectDocumentsContainer)}
                EmptyComponent={
                    <p className={styles.emptyMessage}>
                        <Localize>No document found</Localize>
                    </p>
                }
                {...listProps}
            />
        </div>
    );
};

const ProjectDocumentItem: React.FC<
    ListRenderItemProps<ProjectDocument> & {
        type: 'agreements' | 'reports';
        onEditComplete?: () => void;
    }
> = ({ item, type, onEditComplete }) => {
    const filename = useMemo(() => {
        return (item.documentUrl ?? item.document)?.split('/')?.pop();
    }, [item]);

    const { projectDocumentTypes } = useAppSelector((state) => state.projectDocumentType);
    const activeProjectDocumentType = useMemo(() => {
        return projectDocumentTypes.find((docType) => docType.id === item.documentType);
    }, [projectDocumentTypes, item]);

    const [showReportFormModal, setShowReportFormModal] = useState<boolean>(false);
    const handleShowReportFormModal = useCallback(() => setShowReportFormModal(true), []);
    const handleHideReportFormModal = useCallback(() => setShowReportFormModal(false), []);

    const handleEditComplete = useCallback(() => {
        handleHideReportFormModal();
        onEditComplete?.();
    }, [handleHideReportFormModal, onEditComplete]);

    return (
        <div className={styles.projectDocument}>
            <h5 className={styles.projectDocumentTitle}>{item.title || _('Error!')}</h5>
            <div className={styles.projectDocumentContent}>
                <div className={styles.projectDocumentIconContainer}>
                    <span className={cs(styles.projectDocumentIcon, 'material-symbols-rounded')}>
                        description
                    </span>
                </div>
                <p className={styles.projectDocumentName}>{formatLongFileName(filename, 30)}</p>
                {Boolean(item.documentUrl || item.document) && (
                    <a
                        target="_blank"
                        href={(item.documentUrl ?? item.document) as string}
                        className={cs(styles.projectVisibilityIcon, 'material-symbols-rounded')}
                    >
                        visibility
                    </a>
                )}
                {type === 'reports' && (
                    <span
                        className={cs(styles.projectVisibilityIcon, 'material-symbols-rounded')}
                        onClick={handleShowReportFormModal}
                    >
                        edit
                    </span>
                )}
            </div>
            {Boolean(item.description) && (
                <p className={styles.projectDocumentRemarks}>{item.description}</p>
            )}
            {activeProjectDocumentType && (
                <ProjectReportFormModal
                    documentType={activeProjectDocumentType}
                    editMode
                    document={item}
                    isVisible={showReportFormModal}
                    onClose={handleHideReportFormModal}
                    onComplete={handleEditComplete}
                />
            )}
        </div>
    );
};

export default ProjectDocuments;
