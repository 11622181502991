import React from 'react';
import { Link } from 'react-router-dom';

import { formatNumber } from 'utils/formatter';

import styles from './styles.scss';

interface DataItemProps {
    title: string;
    value: string | number;
}

const OverviewDataItem: React.FC<DataItemProps> = (props) => {
    const { title, value } = props;

    return (
        <Link to="/map" className={styles.dataItem}>
            <p className={styles.dataItemKey}>{title} </p>
            <p className={styles.dataItemValue}>{formatNumber(value)}</p>
        </Link>
    );
};

export default OverviewDataItem;
