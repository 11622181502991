import React, { useState, useCallback } from 'react';

import Input, { type CustomInputProps } from '..';
import Label from '@ra/components/Form/Label';
import SelectInput, {
    ValueExtractor,
    KeyExtractor,
    SelectInputChangeCallback
} from '@ra/components/Form/SelectInput';
import Form, { type FormFieldValueExtractor } from '@ra/components/Form/Advanced';

import cs from '@ra/cs';
import { currencies, type Currency } from 'utils/form';
import { Localize } from 'services/i18n';

import styles from './styles.scss';

const idExtractor: KeyExtractor<Currency> = (item) => item.id;
const codeExtractor: ValueExtractor<Currency, string> = (item) => item.code;
const currencyCodeExtractor: FormFieldValueExtractor<{ option: Currency }, string> = ({ option }) =>
    option.code;

interface CurrencyInputProps extends Omit<CustomInputProps, 'value' | 'onChange'> {
    amountValue?: string | number;
    defaultAmountValue?: string | number;
    currencyValue?: Currency;
    currencyInputName?: string;
    amountInputName?: string;
    amountInputStep?: number;
    disabled?: boolean;
    lockedCurrency?: Currency;
    onCurrencyChange?: SelectInputChangeCallback<Currency>;
    onAmountChange?: (arg: HTMLInputElement) => void;
    currencyRequired?: boolean;
    amountRequired?: boolean;
}

export const CurrencyInput: React.FC<CurrencyInputProps> = ({
    amountValue,
    defaultAmountValue,
    currencyValue,
    currencyInputName,
    amountInputName,
    amountInputStep,
    disabled,
    lockedCurrency,
    onCurrencyChange,
    onAmountChange,
    currencyRequired,
    amountRequired
}) => {
    const handleWheel = useCallback(({ target }: { target: HTMLInputElement }) => {
        target.blur();
    }, []);

    return (
        <div
            className={cs(styles.inputContainer, {
                [styles.inputContainerDisabled]: disabled
            })}
        >
            <Form.Input
                name={currencyInputName}
                component={SelectInput}
                className={styles.select}
                controlClassName={cs(styles.selectControl, {
                    [styles.selectControlDisabled]: disabled || lockedCurrency
                })}
                options={currencies}
                keyExtractor={idExtractor}
                valueExtractor={codeExtractor}
                fieldValueExtractor={currencyCodeExtractor}
                onChange={onCurrencyChange}
                value={lockedCurrency ?? currencyValue}
                defaultValue={lockedCurrency ?? currencyValue}
                clearable={false}
                searchable={false}
                disabled={disabled || lockedCurrency}
                required={currencyRequired}
            />
            <Form.Input
                name={amountInputName}
                component={Input}
                type="number"
                step={amountInputStep}
                containerClassName={styles.amountInputContainer}
                className={styles.input}
                disabled={disabled}
                value={amountValue}
                defaultValue={defaultAmountValue}
                onChange={onAmountChange}
                required={amountRequired}
                onWheel={handleWheel}
            />
        </div>
    );
};

interface CurrencyInputGroupProps {
    budgetLabel?: string;
    exchangeRateLabel?: string;
    defaultCurrency?: Currency;
    defaultAmount?: string | number;
    defaultExchangeRate?: string | number;
    lockedCurrency?: Currency;
}

const CurrencyInputGroup = (props: CurrencyInputGroupProps) => {
    const {
        exchangeRateLabel = 'Exchange Rate',
        budgetLabel = 'Budget',
        lockedCurrency,
        defaultCurrency,
        defaultAmount,
        defaultExchangeRate
    } = props;

    const [activeCurrency, setActiveCurrency] = useState<Currency>(
        lockedCurrency || defaultCurrency || currencies[0]
    );
    const [exchangeRate, setExchangeRate] = useState<string | number>(defaultExchangeRate || '1');

    const handleCurrencyChange: SelectInputChangeCallback<Currency> = useCallback(({ option }) => {
        if (option) {
            setActiveCurrency(option);
            if (option.code === 'NPR') {
                setExchangeRate('1');
            }
        }
    }, []);

    const handleExchangeRateChange = useCallback(({ value }: HTMLInputElement) => {
        setExchangeRate(value);
    }, []);

    return (
        <div className={styles.container}>
            <div className={styles.inputField}>
                <Label className={styles.budgetLabel}>
                    <Localize>{budgetLabel}</Localize>
                </Label>
                <CurrencyInput
                    currencyInputName="budgetCurrency"
                    amountInputName="budget"
                    amountInputStep={1000}
                    lockedCurrency={lockedCurrency}
                    currencyValue={activeCurrency}
                    onCurrencyChange={handleCurrencyChange}
                    defaultAmountValue={defaultAmount}
                    currencyRequired
                    amountRequired
                />
            </div>
            <div className={styles.exchangeRateContainer}>
                <Label className={styles.exchangeRateLabel}>{exchangeRateLabel}</Label>
                <div className={styles.exchangeRateInputs}>
                    <div className={styles.exchangeRateInput}>
                        <CurrencyInput currencyValue={activeCurrency} amountValue="1" disabled />
                    </div>
                    <div className={styles.exchangeRateInput}>
                        <CurrencyInput
                            amountInputName="exchangeRate"
                            amountInputStep={0.1}
                            lockedCurrency={currencies[0]}
                            currencyValue={activeCurrency}
                            amountValue={exchangeRate}
                            onAmountChange={handleExchangeRateChange}
                            disabled={activeCurrency.code === 'NPR'}
                            amountRequired={activeCurrency.code !== 'NPR'}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CurrencyInputGroup;
