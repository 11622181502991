import React, { useCallback, useState, useMemo, useRef } from 'react';

import DragDropFileInput, {
    DragDropFileInputProps,
    FileInputChangeCallback
} from '@ra/components/Form/DragDropFileInput';

import cs from '@ra/cs';
import { Localize, _ } from 'services/i18n';
import { formatFileSize, formatLongFileName } from 'utils/formatter';
import Toast from 'services/toast';

import type { ProjectDocument } from 'services/types';

import styles from './styles.scss';

interface DropZoneComponentProps {
    info?: string;
}

const DropZoneComponent: React.FC<DropZoneComponentProps> = ({ info }) => {
    return (
        <div className={styles.dropzoneContent}>
            <div className={styles.uploadIconContainer}>
                <span className={cs(styles.uploadIcon, 'material-symbols-rounded')}>upload</span>
            </div>
            <p className={styles.uploadTitle}>
                <span className={styles.link}>
                    <Localize>Click to upload file</Localize>
                </span>{' '}
                <Localize>or drag and drop</Localize>
            </p>
            {!!info && <p className={styles.uploadText}>{info}</p>}
        </div>
    );
};

export interface FilePreviewProps {
    file?: File | ProjectDocument | null;
    className?: string;
    onRemove?: React.MouseEventHandler;
}

export const FilePreview: React.FC<FilePreviewProps> = ({ file, className, onRemove }) => {
    const fileName = useMemo(() => {
        if (!file) {
            return '';
        }
        if (isProjectDocument(file)) {
            return (file.documentUrl || file.document)?.split('/').pop();
        }
        if (file?.name) {
            return file?.name;
        }
        return '';
    }, [file]);

    if (!file) {
        return null;
    }

    return (
        <div className={cs(styles.filePreviewContainer, className)}>
            <div className={styles.fileIconContainer}>
                <span className={cs(styles.fileIcon, 'material-symbols-rounded')}>
                    text_snippet
                </span>
            </div>
            <div className={styles.content}>
                <div className={styles.header}>
                    <p className={styles.fileName}>{formatLongFileName(fileName)}</p>
                    {isProjectDocument(file) ? (
                        <a
                            target="_blank"
                            href={(file.documentUrl ?? file.document) as string}
                            className={cs(styles.previewIcon, 'material-symbols-rounded')}
                        >
                            visibility
                        </a>
                    ) : (
                        <span
                            className={cs(styles.removeIcon, 'material-symbols-rounded')}
                            onClick={onRemove}
                        >
                            delete
                        </span>
                    )}
                </div>
                {!isProjectDocument(file) && (
                    <span className={styles.size}>{formatFileSize(file.size)}</span>
                )}
            </div>
        </div>
    );
};

type CustomFileInputProps = DragDropFileInputProps & {
    info?: string;
    hidePreview?: boolean;
    fileInputContainerClassName?: string;
    previewClassName?: string;
    PreviewComponent?: React.ComponentType<any>;
};

const FileInput: React.FC<CustomFileInputProps> = (props) => {
    const {
        info,
        onChange,
        previewClassName,
        hidePreview,
        PreviewComponent,
        fileInputContainerClassName,
        ...otherProps
    } = props;

    const inputRef = useRef<HTMLInputElement>(null);

    const [previewFile, setPreviewFile] = useState<File | undefined | null>();

    const handleChange: FileInputChangeCallback = useCallback(
        (payload) => {
            onChange?.(payload);
            const { files, rejections } = payload;
            if (rejections.length > 0 && rejections[0]?.errors?.length > 0) {
                const errorMessage =
                    rejections[0].errors.find((err) => err && err.message)?.message ||
                    _('File invalid');
                Toast.show(errorMessage, Toast.DANGER);
            }
            if (files.length > 0) {
                setPreviewFile(files[0]);
            }
        },
        [onChange]
    );

    const handleRemoveFile = useCallback(() => {
        if (inputRef?.current) {
            inputRef.current.value = '';
        }
        onChange?.({
            name: otherProps.name as string,
            files: [] as unknown as FileList,
            rejections: []
        });
        setPreviewFile(null);
    }, [onChange, otherProps]);

    const Preview = useMemo(() => PreviewComponent ?? FilePreview, [PreviewComponent]);

    return (
        <div className={fileInputContainerClassName}>
            {!hidePreview && (
                <Preview
                    file={previewFile}
                    className={previewClassName}
                    onRemove={handleRemoveFile}
                />
            )}
            <DragDropFileInput
                dropZoneClassName={styles.dropzone}
                activeDropZoneClassName={styles.dropzoneActive}
                DropZoneComponent={<DropZoneComponent info={info} />}
                onChange={handleChange}
                inputRef={inputRef}
                {...otherProps}
            />
        </div>
    );
};

export default FileInput;

function isProjectDocument(file: ProjectDocument | File): file is ProjectDocument {
    if (file instanceof File) {
        return false;
    }
    return Boolean((file as ProjectDocument)?.documentUrl || (file as ProjectDocument)?.document);
}
