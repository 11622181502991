import React, { useState, useRef, useCallback, useMemo, useEffect } from 'react';
import { useOutletContext } from 'react-router-dom';
import useResizeObserver from '@react-hook/resize-observer';

import List, { KeyExtractor } from '@ra/components/List';

import { NotificationItem } from 'components/NotificationsDropdown';

import Api from 'services/api';
import { Localize } from 'services/i18n';
import usePromise from '@ra/hooks/usePromise';

import type { Notification } from 'services/types';

import styles from './styles.scss';

const keyExtractor: KeyExtractor<Notification> = (item) => item.id;

const Notifications: React.FC = () => {
    const { navBarHeight }: { navBarHeight?: number } = useOutletContext();

    const [{ loading, result }, loadNotifications] = usePromise(Api.getNotifications);
    useEffect(() => {
        loadNotifications();
    }, [loadNotifications]);
    const notifications = useMemo(() => {
        return result?.results || [];
    }, [result]);

    const hasUnreadNotis = useMemo(() => {
        return notifications.some((noti: Notification) => !noti.hasRead);
    }, [notifications]);

    const handleMarkAllRead = useCallback(async () => {
        try {
            await Api.markAllNotificationsRead();
            loadNotifications();
        } catch (error) {
            console.log(error);
        }
    }, [loadNotifications]);

    const headerRef = useRef<HTMLDivElement>(null);
    const containerRef = useRef<HTMLDivElement>(null);

    const [listStyle, setListStyle] = useState<React.CSSProperties>({});

    const handleContainerResize = useCallback(() => {
        if (navBarHeight && headerRef?.current) {
            const extraSpace = navBarHeight + headerRef.current.offsetHeight;
            setListStyle({
                maxHeight: `calc(100vh - ${extraSpace}px - 3.75rem)`
            });
        }
    }, [navBarHeight]);
    useResizeObserver(containerRef, handleContainerResize);

    return (
        <div className={styles.container} ref={containerRef}>
            <div className={styles.content}>
                <div ref={headerRef} className={styles.header}>
                    <h1 className={styles.title}>
                        <Localize>Notifications</Localize>
                    </h1>
                    {hasUnreadNotis && (
                        <p className={styles.markReadLink} onClick={handleMarkAllRead}>
                            <Localize>Mark all as read</Localize>
                        </p>
                    )}
                </div>
                <List
                    loading={loading}
                    style={listStyle}
                    className={styles.notifications}
                    keyExtractor={keyExtractor}
                    data={notifications}
                    renderItem={NotificationItem}
                />
            </div>
        </div>
    );
};

export default Notifications;
