import React, { useState, useCallback, useMemo, useEffect } from 'react';

import Button from 'components/Button';
import FilterChecklist from 'components/Filters/FilterChecklist';
import FilterBarChart from 'components/Filters/FilterBarChart';
import FilterBudgetSlider from 'components/Filters/FilterBudgetSlider';
import FilterHazards from 'components/Filters/FilterHazards';
import FilterProjectStatuses from 'components/Filters/FilterProjectStatuses';
import List, { ListRenderItem, ListRenderItemProps, KeyExtractor } from '@ra/components/List';
import Tabs, { Tab, TabChangeCallback } from 'components/Tabs';

import Api from 'services/api';
import cs from '@ra/cs';
import { Localize, _, objTranslator } from 'services/i18n';
import { formatCurrency, formatNumber } from 'utils/formatter';
import { selectPriorityAreas } from 'store/selectors/priorityIndicator';

import { useFilters } from 'hooks/filters';
import { useAppSelector } from 'hooks/store';
import usePromise from '@ra/hooks/usePromise';

import type { MPTTStats, Stats } from 'services/types';
import type { DivisionLevelModel } from 'hooks/useAdministrativeDivisions';

import MapFilterList from './MapFilterList';
import InfoSection from './InfoSection';

import styles from './styles.scss';

const keyExtractor: KeyExtractor<MPTTStats> = (item) => String(item.identifier);

type StatItemProps<T> = ListRenderItemProps<T> & {
    accessor: 'projects' | 'amount';
};

const RegionStatItem: React.FC<StatItemProps<MPTTStats>> = ({ item, accessor }) => {
    const { regions } = useAppSelector((state) => state.region);

    const regionTitle = useMemo(
        () =>
            objTranslator(
                regions.find((reg) => reg.id === item.identifier),
                'title'
            ),
        [regions, item]
    );

    const dataValue = useMemo(() => {
        if (accessor === 'projects') {
            return formatNumber(item.count);
        }
        return formatCurrency(Number(item.budget));
    }, [item, accessor]);

    if (!regionTitle) {
        return null;
    }

    return (
        <div className={styles.projectDataItem}>
            <p className={styles.projectDataItemLabel}>{regionTitle}</p>
            <p className={styles.projectDataItemValue}>{dataValue}</p>
        </div>
    );
};

const DonorStatItem: React.FC<StatItemProps<Stats>> = ({ item, accessor }) => {
    const { organizations } = useAppSelector((state) => state.organization);

    const [donorTitleShortened, donorTitle] = useMemo(() => {
        const activeOrganization = organizations.find((org) => org.id === item.identifier);
        if (activeOrganization?.title && activeOrganization?.title?.length > 25) {
            return [
                activeOrganization.acronym ||
                    objTranslator(activeOrganization, 'title').slice(0, 25) + '...',
                objTranslator(activeOrganization, 'title')
            ];
        }
        return [objTranslator(activeOrganization, 'title'), ''];
    }, [organizations, item]);

    const dataValue = useMemo(() => {
        if (accessor === 'projects') {
            return formatNumber(item.count);
        }
        return formatCurrency(Number(item.budget));
    }, [item, accessor]);

    if (!donorTitleShortened) {
        return null;
    }

    return (
        <div className={styles.projectDataItem}>
            <p className={styles.projectDataItemLabel} title={donorTitle}>
                {donorTitleShortened}
            </p>
            <p className={styles.projectDataItemValue}>{dataValue}</p>
        </div>
    );
};

interface ProjectBarProps {
    className?: string;
    mapData?: MPTTStats[];
    donorData?: Stats[];
    divisionLevels: DivisionLevelModel[];
    activeLevel: DivisionLevelModel;
    onLevelChange?: ({
        activeTab,
        previousTab
    }: {
        activeTab: string;
        previousTab?: string;
    }) => void;
    loading?: boolean;
}

const ProjectBar: React.FC<ProjectBarProps> = (props) => {
    const {
        className,
        mapData = [],
        donorData = [],
        activeLevel,
        divisionLevels,
        onLevelChange,
        loading
    } = props;

    const { regions } = useAppSelector((state) => state.region);
    const priorityAreas = useAppSelector((state) => selectPriorityAreas(state));

    const filters = useFilters('Map-');

    const provinces = useMemo(
        () =>
            regions
                .filter((region) => region.level === 1)
                .sort((a, b) => Number(a.code) - Number(b.code)),
        [regions]
    );

    const [activeDataAccessor, setActiveDataAccessor] = useState<'projects' | 'amount'>('projects');
    const handleDataAccessorChange: TabChangeCallback = useCallback(
        ({ activeTab }) => setActiveDataAccessor(activeTab as 'projects' | 'amount'),
        []
    );

    const [{ result: startYearData }, getStartYearStats] = usePromise(Api.getProjectStartYearStats);
    const [{ result: endYearData }, getEndYearStats] = usePromise(Api.getProjectEndYearStats);

    useEffect(() => {
        getStartYearStats();
        getEndYearStats();
    }, [getStartYearStats, getEndYearStats]);

    const [showFilters, setShowFilters] = useState<boolean>(false);

    const toggleFilters = useCallback(() => setShowFilters((prev) => !prev), []);

    const renderRegionStatItem: ListRenderItem<MPTTStats> = useCallback(
        (listProps) => <RegionStatItem {...listProps} accessor={activeDataAccessor} />,
        [activeDataAccessor]
    );

    const renderDonorStatItem: ListRenderItem<Stats> = useCallback(
        (listProps) => <DonorStatItem {...listProps} accessor={activeDataAccessor} />,
        [activeDataAccessor]
    );

    const overviewData = useMemo(() => {
        const overview = mapData.reduce(
            (acc, cur) => {
                acc.projects += cur.count || 0;
                acc.amount += Number(cur.budget) || 0;
                return acc;
            },
            { projects: 0, amount: 0, donors: 0 }
        );
        overview.donors = donorData?.length || 0;
        return overview;
    }, [mapData, donorData]);

    const [sortedMapData, sortedDonorData] = useMemo(() => {
        const accessor = activeDataAccessor === 'amount' ? 'budget' : 'count';
        return [
            [...mapData].sort((a, b) => Number(b[accessor]) - Number(a[accessor])),
            [...donorData].sort((a, b) => Number(b[accessor]) - Number(a[accessor]))
        ];
    }, [donorData, mapData, activeDataAccessor]);

    const handleLevelChange = useCallback(
        ({ activeTab }: { activeTab: string; previousTab?: string }) => {
            if (activeTab === activeLevel.label) {
                onLevelChange?.({ activeTab: divisionLevels[0].label });
            } else {
                onLevelChange?.({ activeTab });
            }
        },
        [onLevelChange, activeLevel, divisionLevels]
    );

    return (
        <div className={className}>
            <div className={styles.header}>
                <h2 className={styles.title}>{showFilters ? _('Filters') : _('Projects')}</h2>
                {showFilters ? (
                    <div className={styles.closeIconContainer} onClick={toggleFilters}>
                        <span className="material-symbols-rounded">close</span>
                    </div>
                ) : (
                    <Button secondary small leftIcon="filter_alt" onClick={toggleFilters}>
                        <Localize>Filters</Localize>
                        {filters.length > 0 && ` (${formatNumber(filters.length)})`}
                    </Button>
                )}
            </div>
            {showFilters && <MapFilterList />}
            <InfoSection
                className={styles.projectInfoGroup}
                headerClassName={styles.projectInfoGroupHeader}
                title={filters.length ? _('OVERVIEW') : activeLevel.title + ' Overview'}
            >
                <div className={styles.projectInfo}>
                    <div className={styles.overviewItems}>
                        <div className={styles.overviewItem}>
                            <p className={styles.overviewItemLabel}>
                                <Localize>Projects</Localize>
                            </p>
                            <p className={styles.overviewItemValue}>
                                {formatNumber(overviewData.projects)}
                            </p>
                        </div>
                        <div className={styles.overviewItem}>
                            <p className={styles.overviewItemLabel}>
                                <Localize>Budget</Localize>
                            </p>
                            <p className={styles.overviewItemValue}>
                                {formatCurrency(overviewData.amount)}
                            </p>
                        </div>
                        <div className={styles.overviewItem}>
                            <p className={styles.overviewItemLabel}>
                                <Localize>Donors</Localize>
                            </p>
                            <p className={styles.overviewItemValue}>
                                {formatNumber(overviewData.donors)}
                            </p>
                        </div>
                    </div>
                </div>
            </InfoSection>
            {showFilters ? (
                <>
                    <InfoSection
                        className={styles.projectInfoGroup}
                        headerClassName={styles.projectInfoGroupHeader}
                        title={_('Project Status')}
                    >
                        <FilterProjectStatuses
                            filterType="Map-Status:"
                            className={cs(styles.projectInfo, styles.projectInfoItemList)}
                        />
                    </InfoSection>
                    <InfoSection
                        className={styles.projectInfoGroup}
                        headerClassName={styles.projectInfoGroupHeader}
                        titleClassName={styles.projectInfoGroupHeaderTitle}
                        title={_('Administrative divisions')}
                    >
                        <FilterChecklist
                            data={provinces}
                            filterType="Map-Region:"
                            className={cs(styles.projectInfo, styles.projectInfoChecklist)}
                            queryKey="location"
                            isRegionChecklist
                        />
                    </InfoSection>
                    <InfoSection
                        className={styles.projectInfoGroup}
                        headerClassName={styles.projectInfoGroupHeader}
                        title={_('Starting Projects')}
                    >
                        <div className={cs(styles.projectInfoChart, styles.projectInfo)}>
                            <FilterBarChart
                                filterType="Map-From"
                                data={startYearData || []}
                                queryKey="date_from__gte"
                            />
                        </div>
                    </InfoSection>
                    <InfoSection
                        className={styles.projectInfoGroup}
                        headerClassName={styles.projectInfoGroupHeader}
                        title={_('Ending Projects')}
                    >
                        <div className={cs(styles.projectInfoChart, styles.projectInfo)}>
                            <FilterBarChart
                                filterType="Map-Upto"
                                data={endYearData || []}
                                queryKey="date_to__lte"
                            />
                        </div>
                    </InfoSection>
                    <InfoSection
                        className={styles.projectInfoGroup}
                        headerClassName={styles.projectInfoGroupHeader}
                        title={_('Budget')}
                    >
                        <FilterBudgetSlider
                            filterType="Map-Budget:"
                            className={styles.projectInfo}
                        />
                    </InfoSection>
                    <InfoSection
                        className={styles.projectInfoGroup}
                        headerClassName={styles.projectInfoGroupHeader}
                        titleClassName={styles.projectInfoGroupHeaderTitle}
                        title={_('Hazards')}
                        expandable
                    >
                        <FilterHazards
                            filterType="Map-Hazard:"
                            className={cs(styles.projectInfo, styles.projectInfoItemList)}
                            hideTitle
                        />
                    </InfoSection>
                    <InfoSection
                        className={styles.projectInfoGroup}
                        headerClassName={styles.projectInfoGroupHeader}
                        titleClassName={styles.projectInfoGroupHeaderTitle}
                        title={_('Themes')}
                        expandable
                    >
                        <FilterChecklist
                            data={priorityAreas}
                            filterType="Map-Theme:"
                            className={cs(styles.projectInfo, styles.projectInfoChecklist)}
                            queryKey="outputs__activities__priority_indicators__in"
                        />
                    </InfoSection>
                </>
            ) : (
                <>
                    <InfoSection
                        className={styles.projectInfoGroup}
                        headerClassName={styles.projectInfoGroupHeader}
                        title={_('ADMINISTRATIVE DIVISIONS')}
                    >
                        <div className={styles.projectInfo}>
                            <Tabs
                                className={styles.divisionLevelTabs}
                                tabItemClassName={styles.tabHeaderItem}
                                secondary
                                activeTab={activeLevel.label}
                                onChange={handleLevelChange}
                            >
                                {divisionLevels.slice(1, 4).map((lvl) => (
                                    <Tab key={lvl.id} label={lvl.label} title={lvl.title} />
                                ))}
                            </Tabs>
                            <Tabs
                                activeTab={activeDataAccessor as string}
                                onChange={handleDataAccessorChange}
                                tertiary
                            >
                                <Tab label="projects" title={_('Projects')} />
                                <Tab label="amount" title={_('Amount')} />
                            </Tabs>
                            <List
                                loading={loading}
                                className={styles.projectDataList}
                                keyExtractor={keyExtractor}
                                renderItem={renderRegionStatItem}
                                data={sortedMapData}
                                EmptyComponent={
                                    <span className={styles.infoMessage}>
                                        <Localize>
                                            No projects found for the selected administrative
                                            division level!
                                        </Localize>
                                    </span>
                                }
                                LoadingComponent={
                                    <span className={styles.infoMessage}>
                                        <Localize>Loading...</Localize>
                                    </span>
                                }
                            />
                        </div>
                    </InfoSection>
                    <InfoSection
                        className={styles.projectInfoGroup}
                        headerClassName={styles.projectInfoGroupHeader}
                        title={_('DONORS')}
                    >
                        <div className={styles.projectInfo}>
                            <Tabs
                                activeTab={activeDataAccessor as string}
                                onChange={handleDataAccessorChange}
                                tertiary
                            >
                                <Tab label="projects" title={_('Projects')} />
                                <Tab label="amount" title={_('Amount')} />
                            </Tabs>
                            <List
                                loading={loading}
                                className={styles.projectDataList}
                                keyExtractor={keyExtractor}
                                renderItem={renderDonorStatItem}
                                data={sortedDonorData}
                                EmptyComponent={
                                    <span className={styles.infoMessage}>
                                        <Localize>No projects found!</Localize>
                                    </span>
                                }
                                LoadingComponent={
                                    <span className={styles.infoMessage}>
                                        <Localize>Loading...</Localize>
                                    </span>
                                }
                            />
                        </div>
                    </InfoSection>
                </>
            )}
        </div>
    );
};

export default ProjectBar;
