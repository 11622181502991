import React, { useCallback, useMemo } from 'react';
import { format } from 'date-fns';

import Modal, { type ModalProps } from '@ra/components/Modal';
import { OrganizationItem } from 'components/OrganizationList';
import PDFDetails from 'components/PDFDetails';
import Tabs, { Tab } from 'components/Tabs';

import cs from '@ra/cs';
import { _, Localize } from 'services/i18n';
import { formatNumber } from 'utils/formatter';

import { useAppSelector } from 'hooks/store';

import type { ExpandedTarget } from 'services/report';
import type { TargetReport } from 'services/types';

import styles from './styles.scss';

type ViewTargetReportsModalProps = Omit<ModalProps, 'children'> & {
    target: ExpandedTarget;
};

type TargetReportItemProps = {
    report: TargetReport;
};

const TargetReportItem: React.FC<TargetReportItemProps> = ({ report }) => {
    const { organizations } = useAppSelector((state) => state.organization);

    const organization = useMemo(() => {
        return organizations.find((org) => org.id === report.organization);
    }, [organizations, report]);

    return (
        <>
            <h3 className={styles.reportTitle}>{report.title}</h3>
            <p className={styles.reportDescription}>{report.description}</p>
            <div className={styles.reportInfo}>
                <div className={styles.infoItemContainer}>
                    <div className={styles.infoItemIconContainer}>
                        <span className={cs(styles.infoItemIcon, 'material-symbols-rounded')}>
                            payments
                        </span>
                    </div>
                    <div className={styles.infoItemContent}>
                        <p className={styles.infoItemContentKey}>
                            <Localize>Expense</Localize>
                        </p>
                        <p className={styles.infoItemContentValue}>{`NPR ${
                            formatNumber(report.expense) || ''
                        }`}</p>
                    </div>
                </div>
                <div className={styles.infoItemContainer}>
                    <div className={styles.infoItemIconContainer}>
                        <span className={cs(styles.infoItemIcon, 'material-symbols-rounded')}>
                            fact_check
                        </span>
                    </div>
                    <div className={styles.infoItemContent}>
                        <p className={styles.infoItemContentKey}>
                            <Localize>Value</Localize>
                        </p>
                        <p className={styles.infoItemContentValue}>{report.value}</p>
                    </div>
                </div>
                <div className={styles.infoItemContainer}>
                    <div className={styles.infoItemIconContainer}>
                        <span className={cs(styles.infoItemIcon, 'material-symbols-rounded')}>
                            calendar_month
                        </span>
                    </div>
                    <div className={styles.infoItemContent}>
                        <p className={styles.infoItemContentKey}>
                            <Localize>Completion date</Localize>
                        </p>
                        <p className={styles.infoItemContentValue}>
                            {format(new Date(report.date), 'MMM dd, yyyy')}
                        </p>
                    </div>
                </div>
            </div>
            <div className={styles.reportRow}>
                <div className={styles.contentItemContainer}>
                    <h4 className={styles.contentTitle}>
                        <Localize>ATTACHMENT</Localize>
                    </h4>
                    <div className={styles.contentItem}>
                        <PDFDetails file={report.attachment as string} />
                    </div>
                </div>
                <div className={styles.contentItemContainer}>
                    <h4 className={styles.contentTitle}>
                        <Localize>ORGANIZATION</Localize>
                    </h4>
                    <div className={styles.contentItem}>
                        {organization && (
                            <OrganizationItem
                                index={0}
                                item={organization}
                                className={styles.organizationItem}
                            />
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};

const ViewTargetReportsModal: React.FC<ViewTargetReportsModalProps> = (props) => {
    const { target, onClose, ...modalProps } = props;

    const handleClose = useCallback(() => {
        onClose?.();
    }, [onClose]);

    return (
        <Modal className={styles.modal} {...modalProps}>
            <div className={styles.header}>
                <h2 className={styles.title}>{target?.title ?? _('View Target Reports')}</h2>
                <span
                    className={cs('material-symbols-rounded', styles.closeIcon)}
                    onClick={handleClose}
                >
                    close
                </span>
            </div>
            <div className={styles.content}>
                <Tabs
                    primary
                    headerClassName={styles.tabHeader}
                    contentContainerClassName={styles.tabContentContainer}
                    defaultActiveTab="report-1"
                >
                    {target?.reports.map((report, reportIdx) => (
                        <Tab label={`report-${reportIdx + 1}`} title={`Report ${reportIdx + 1}`}>
                            <TargetReportItem report={report} />
                        </Tab>
                    ))}
                </Tabs>
            </div>
        </Modal>
    );
};

export default ViewTargetReportsModal;
