import React, { useCallback } from 'react';

import List, {
    type ListRenderItem,
    type KeyExtractor,
    type ListRenderItemProps
} from '@ra/components/List';
import Modal, { ModalProps } from '@ra/components/Modal';

import cs from '@ra/cs';
import { Localize } from 'services/i18n';

import type { ProjectType } from 'containers/Projects';

import styles from './styles.scss';

const codeExtractor: KeyExtractor<ProjectType> = (item) => item.code;

interface ProjectTypeOptionProps extends ListRenderItemProps<ProjectType> {
    onSelect: (arg: ProjectType) => void;
}

const ProjectTypeOption: React.FC<ProjectTypeOptionProps> = ({ item, onSelect }) => {
    const handleClick = useCallback(() => {
        onSelect?.(item);
    }, [onSelect, item]);

    return (
        <div className={styles.optionItem} onClick={handleClick}>
            <span className={cs('material-symbols-rounded', styles.optionIcon)}>
                {item.iconName}
            </span>
            <h4 className={styles.optionTitle}>
                <Localize>{item.title}</Localize>
            </h4>
            <p className={styles.optionDescription}>
                <Localize>{item.description}</Localize>
            </p>
        </div>
    );
};

type ProjectTypeModalProps = Omit<ModalProps, 'children'> & {
    onSelect?: ProjectTypeOptionProps['onSelect'];
    projectTypes: ProjectType[];
};

const ProjectTypeModal: React.FC<ProjectTypeModalProps> = (props) => {
    const { projectTypes, onClose, onSelect, ...modalProps } = props;

    const handleClose = useCallback(() => {
        onClose?.();
    }, [onClose]);

    const handleSelect = useCallback(
        (opt: ProjectType) => {
            onSelect?.(opt);
            onClose?.();
        },
        [onSelect, onClose]
    );

    const renderProjectTypeOption: ListRenderItem<ProjectType> = useCallback(
        (listProps) => <ProjectTypeOption {...listProps} onSelect={handleSelect} />,
        [handleSelect]
    );

    return (
        <Modal className={styles.modal} {...modalProps}>
            <div className={styles.header}>
                <h2 className={styles.title}>
                    <Localize>Create a project</Localize>
                </h2>
                <span
                    className={cs('material-symbols-rounded', styles.closeIcon)}
                    onClick={handleClose}
                >
                    close
                </span>
            </div>
            <p className={styles.description}>
                <Localize>Select your role in the project</Localize>
            </p>
            <List
                className={styles.options}
                data={projectTypes}
                keyExtractor={codeExtractor}
                renderItem={renderProjectTypeOption}
            />
        </Modal>
    );
};

export default ProjectTypeModal;
