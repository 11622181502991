import React, { useMemo, useCallback, useState } from 'react';

import Table, { TableProps, TableRenderDataItem } from '@ra/components/Table';
import TargetReportFormModal from 'components/TargetReportFormModal';
import ViewTargetReportsModal from 'components/ViewTargetReportsModal';

import cs from '@ra/cs';
import { _, Localize } from 'services/i18n';
import Toast from 'services/toast';

import type { ExpandedTarget } from 'services/report';

import styles from './styles.scss';

const getTargetColumns = () => [
    {
        Header: _('TARGET'),
        accessor: 'title'
    },
    {
        Header: _('TARGET VALUE'),
        accessor: 'value'
    },
    {
        Header: _('ACTIONS'),
        accessor: 'actionLinks'
    }
];

type TargetTableProps = Omit<TableProps<ExpandedTarget>, 'columns'> & {
    onReportAdded?: () => void;
    disableAdd?: boolean;
};

const TargetTable: React.FC<TargetTableProps> = (props) => {
    const { className, headerClassName, onReportAdded, disableAdd, ...otherProps } = props;

    const [isReportFormVisible, setReportFormVisible] = useState<boolean>(false);
    const [isReportViewVisible, setReportViewVisible] = useState<boolean>(false);

    const [activeTarget, setActiveTarget] = useState<null | ExpandedTarget>(null);

    const columnData = useMemo(() => {
        return getTargetColumns();
    }, []);

    const handleAddReportClick = useCallback(
        (target: ExpandedTarget) => {
            if (disableAdd) {
                return Toast.show(
                    _(
                        'Only members of partner organizations or implementing organization may add reports!'
                    ),
                    Toast.DANGER
                );
            }
            setReportFormVisible(true);
            setActiveTarget(target);
        },
        [disableAdd]
    );

    const handleCloseFormModal = useCallback(() => {
        setReportFormVisible(false);
        setActiveTarget(null);
    }, []);

    const handleViewReportsClick = useCallback((target: ExpandedTarget) => {
        setReportViewVisible(true);
        setActiveTarget(target);
    }, []);

    const handleCloseViewModal = useCallback(() => {
        setReportViewVisible(false);
        setActiveTarget(null);
    }, []);

    const renderDataItem: TableRenderDataItem<ExpandedTarget> = useCallback(
        ({ item, column }) => {
            if (column.accessor === 'title') {
                return (
                    <div className={styles.titleItem}>
                        <>{item[column.accessor as keyof ExpandedTarget]}</>
                    </div>
                );
            }
            if (column.accessor === 'actionLinks') {
                return (
                    <div className={styles.actionLinks}>
                        {item.reports?.length > 0 && (
                            <p
                                className={styles.actionLink}
                                onClick={() => handleViewReportsClick(item)}
                            >
                                <Localize>View reports</Localize>
                            </p>
                        )}
                        <p
                            className={cs(styles.actionLink, {
                                [styles.actionLinkDisabled]: disableAdd
                            })}
                            onClick={() => handleAddReportClick(item)}
                        >
                            <Localize>Add report</Localize>
                        </p>
                    </div>
                );
            }
            return <>{item[column.accessor as keyof ExpandedTarget]}</>;
        },
        [handleAddReportClick, disableAdd]
    );

    return (
        <div className={styles.container}>
            <Table
                columns={columnData}
                className={cs(styles.table, className)}
                headerClassName={cs(styles.header, headerClassName)}
                headerItemClassName={styles.headerItem}
                bodyRowClassName={styles.bodyRow}
                dataClassName={styles.tableData}
                renderDataItem={renderDataItem}
                EmptyComponent={
                    <tr>
                        <td className={styles.tableMessage}>
                            <Localize>No targets!</Localize>
                        </td>
                    </tr>
                }
                {...otherProps}
            />
            <TargetReportFormModal
                isVisible={isReportFormVisible}
                onClose={handleCloseFormModal}
                target={activeTarget as ExpandedTarget}
                onComplete={onReportAdded}
            />
            <ViewTargetReportsModal
                isVisible={isReportViewVisible}
                onClose={handleCloseViewModal}
                target={activeTarget as ExpandedTarget}
            />
        </div>
    );
};

export default TargetTable;
