import React from 'react';

import { RectLoader } from 'components/Loader';
import Button, { ButtonProps } from '@ra/components/Button';

import cs from '@ra/cs';

import styles from './styles.scss';

export interface CustomButtonProps extends ButtonProps {
    large?: boolean;
    small?: boolean;
    secondary?: boolean;
    tertiary?: boolean;
    accent?: boolean;
    leftIcon?: string;
    rightIcon?: string;
    iconClassName?: string;
    loading?: boolean;
}

const _Button = React.forwardRef(
    (props: CustomButtonProps, ref: React.ForwardedRef<HTMLButtonElement>) => {
        const {
            large,
            small,
            secondary,
            tertiary,
            accent,
            leftIcon,
            rightIcon,
            loading,
            children,
            className,
            disabled,
            iconClassName,
            ...buttonProps
        } = props;

        return (
            <Button
                ref={ref}
                className={cs(className, styles.button, {
                    [styles.buttonLarge]: large,
                    [styles.buttonSmall]: small,
                    [styles.buttonDisabled]: disabled || loading,
                    [styles.buttonSecondary]: secondary,
                    [styles.buttonSecondaryDisabled]: secondary && (disabled || loading),
                    [styles.buttonTertiary]: tertiary,
                    [styles.buttonTertiaryDisabled]: tertiary && (disabled || loading),
                    [styles.buttonAccent]: accent,
                    [styles.buttonAccentDisabled]: accent && (disabled || loading)
                })}
                disabled={disabled || loading}
                {...buttonProps}
            >
                {!!leftIcon && (
                    <span
                        className={cs('material-symbols-rounded', styles.leftIcon, iconClassName)}
                    >
                        {leftIcon}
                    </span>
                )}
                {loading ? <RectLoader dark={tertiary} primary={secondary} /> : children}
                {!!rightIcon && (
                    <span
                        className={cs('material-symbols-rounded', styles.rightIcon, iconClassName)}
                    >
                        {rightIcon}
                    </span>
                )}
            </Button>
        );
    }
);

export default _Button;
