import React, { useState, useMemo, useCallback } from 'react';
import DatePicker from 'react-datepicker';
import { format } from 'date-fns';

import cs from '@ra/cs';

import styles from './styles.scss';

const noop = () => null;

interface DateRangeInputProps {
    name?: string;
    onChange: (payload: { name?: string; value: string | null }) => void;
    showRequired?: boolean;
    placeholder?: string;
    defaultValue?: string;
}

export const DateRangeDisplay: React.FC<any> = (props) => {
    const { showRequired, onClick, onBlur } = props;

    const [isOpen, setOpen] = useState<boolean>(false);

    const handleClick = useCallback(
        (e: React.MouseEvent) => {
            if (!isOpen) {
                onClick?.(e);
                return setOpen(true);
            }
            onBlur?.(e);
            setOpen(false);
        },
        [onClick, onBlur, isOpen]
    );

    return (
        <>
            <input
                {...props}
                onClick={handleClick}
                className={cs(styles.input, { [styles.inputWarning]: showRequired })}
            />
            {Boolean(showRequired) && <span className={styles.warningText}>Required</span>}
        </>
    );
};

const DateRangeInput: React.FC<DateRangeInputProps> = (props) => {
    const { onChange, name, showRequired, placeholder, defaultValue } = props;

    const initialValue: [Date | null, Date | null] = useMemo(() => {
        if (!defaultValue) {
            return [null, null];
        }
        const [dateFrom, dateTo] = defaultValue.split(',');
        return [new Date(dateFrom), new Date(dateTo)];
    }, [defaultValue]);

    const [dateRange, setDateRange] = useState<[Date | null, Date | null]>(initialValue);
    const [startDate, endDate] = useMemo(() => dateRange, [dateRange]);

    const showRequiredText = useMemo(() => {
        return showRequired && !(startDate && endDate);
    }, [showRequired, startDate, endDate]);

    const handleDateChange = useCallback(
        (date: [Date | null, Date | null]) => {
            setDateRange(date);
            if (date.length < 2) {
                return onChange({ name, value: null });
            }
            if (date[0] && date[1]) {
                return onChange({
                    name,
                    value: format(date[0], 'yyyy-MM-dd').concat(',', format(date[1], 'yyyy-MM-dd'))
                });
            }
            return onChange({ name, value: null });
        },
        [onChange, name]
    );

    return (
        <DatePicker
            selectsRange
            startDate={startDate}
            endDate={endDate}
            onChange={handleDateChange}
            popperPlacement="bottom"
            popperClassName={styles.datePopper}
            placeholderText={placeholder}
            customInput={<DateRangeDisplay showRequired={showRequiredText} />}
            showYearDropdown
            onChangeRaw={noop}
        />
    );
};

export default DateRangeInput;
