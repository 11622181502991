import store from 'store';
import { defaultTranslator as translator } from '@ra/components/I18n/i18nContext';

import { Localize } from '@ra/components/I18n';

import neTranslations from './locales/locale_ne.json';

export type TranslationsModel = {
    [key: string]: {
        [key: string]: string;
    };
};

export type LanguageModel = {
    id: number;
    title: string;
    displayTitle?: string;
    code: string;
};

export const translations: TranslationsModel = {
    ENG: {},
    NEP: neTranslations
};

export const languages: LanguageModel[] = [
    {
        id: 1,
        title: 'English',
        displayTitle: 'ENG',
        code: 'ENG'
    },
    {
        id: 2,
        title: 'नेपाली',
        displayTitle: 'नेपाली',
        code: 'NEP'
    }
];

export const _ = (text: string) => {
    const {
        locale: { currentLanguage, translations }
    } = store.getState();

    return translator(text, currentLanguage, translations);
};

export const objTranslator = (obj: any, key: string, curLng?: string) => {
    if (!curLng) {
        const {
            locale: { currentLanguage }
        } = store.getState();
        curLng = currentLanguage;
    }
    return (
        obj?.[`${key}${curLng.charAt(0) + curLng.slice(1, 2).toLowerCase()}`] || obj?.[key] || ''
    );
};

export const mapPropertyTranslator = (obj: any, curLng: string, key: string) => {
    return obj?.[`${key}_${curLng.slice(0, 2).toLowerCase()}`] || obj?.[key] || '';
};

export { Localize };
