import React, { useCallback } from 'react';
import { BarChart, Bar, XAxis, YAxis, ResponsiveContainer, Cell } from 'recharts';

import { useFilters, useFiltersDispatch } from 'hooks/filters';

import type { Stats } from 'services/types';

interface FilterBarChartProps {
    data: Stats[];
    filterType: string;
    queryKey?: string;
}

const FilterBarChart: React.FC<FilterBarChartProps> = (props) => {
    const { data, filterType, queryKey } = props;

    const dispatch = useFiltersDispatch();
    const filters = useFilters();

    const handleClick = useCallback(
        ({ payload }: { payload: Stats }) => {
            const isActive = filters.some(
                (el) => el.filterType === filterType && el.filterValue === payload.identifier
            );
            const filter: {
                filterType: string;
                filterValue: string | number;
                query?: { [key: string]: string };
            } = {
                filterType,
                filterValue: payload.identifier ? payload.identifier : ''
            };
            if (queryKey) {
                filter.query = {
                    [queryKey]: `${payload.identifier}-${
                        queryKey.includes('from') ? '01-01' : '12-31'
                    }`
                };
            }
            const actionType: 'add' | 'remove' = isActive ? 'remove' : 'add';
            dispatch({ type: actionType, filter });
        },
        [filterType, dispatch, filters, queryKey]
    );

    return (
        <ResponsiveContainer width="100%" height="100%">
            <BarChart
                width={120}
                height={100}
                data={data}
                margin={{ top: 0, bottom: 0, left: 0, right: 0 }}
            >
                <XAxis
                    dataKey="identifier"
                    padding={{ left: 15, right: 15 }}
                    stroke="#D0D5DD"
                    tickLine={{ stroke: '#3B3E4A' }}
                    tick={{ fill: '#3B3E4A' }}
                />
                <YAxis
                    axisLine={false}
                    tickLine={false}
                    width={25}
                    dataKey="count"
                    type="number"
                    allowDecimals={false}
                    padding={{ bottom: 2, top: 10 }}
                    style={{
                        fontSize: '0.625rem',
                        fontWeight: 500,
                        color: '#3b3e4a'
                    }}
                />
                <Bar dataKey="count" onClick={handleClick} barSize={20}>
                    {data.map((entry, index) => {
                        const isActive = filters.some(
                            (el) =>
                                el.filterType === filterType && el.filterValue === entry.identifier
                        );

                        return (
                            <Cell
                                cursor="pointer"
                                fill={isActive ? '#FF8616' : '#EFC7A1'}
                                key={`cell-${index}`}
                            />
                        );
                    })}
                </Bar>
            </BarChart>
        </ResponsiveContainer>
    );
};

export default FilterBarChart;
