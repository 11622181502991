import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import type { ProjectDocumentType } from 'services/types';

interface ProjectDocumentTypeReducerState {
    projectDocumentTypes: ProjectDocumentType[];
}

const initialState: ProjectDocumentTypeReducerState = {
    projectDocumentTypes: []
};

const projectDocumentTypeSlice = createSlice({
    name: 'projectDocumentType',
    initialState,
    reducers: {
        setProjectDocumentTypes: (
            state: ProjectDocumentTypeReducerState,
            { payload }: PayloadAction<ProjectDocumentType[]>
        ) => {
            state.projectDocumentTypes = payload;
        }
    }
});

export const { setProjectDocumentTypes } = projectDocumentTypeSlice.actions;

export default projectDocumentTypeSlice.reducer;
