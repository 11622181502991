import React, { useCallback } from 'react';

import AuthInput from 'components/Input/AuthInput';
import Button from 'components/Button';
import Label from '@ra/components/Form/Label';
import TextareaInput from 'components/Input/TextareaInput';
import DateInput from 'components/Input/DateInput';
import FileInput from 'components/FileInput';
import { CurrencyInput as FormCurrencyInput } from 'components/Input/CurrencyInputGroup';
import Form, { type FormSubmitCallback } from '@ra/components/Form/Advanced';
import Modal, { type ModalProps } from '@ra/components/Modal';
import { OrganizationSelectInput, OptionsType } from 'components/SelectInput';

import Api from 'services/api';
import cs from '@ra/cs';
import { Localize, _ } from 'services/i18n';
import Toast from 'services/toast';
import { currencies } from 'utils/form';
import { getErrorMessage } from '@ra/utils/error';

import { useAppSelector } from 'hooks/store';
import usePromise from '@ra/hooks/usePromise';

import type { Organization } from 'services/types';
import type { SelectInputChangeCallback } from '@ra/components/Form/SelectInput';
import type { FileInputChangeCallback } from '@ra/components/Form/DragDropFileInput';
import type { ExpandedTarget } from 'services/report';

import styles from './styles.scss';

const titleExtractor = (item: OptionsType) => item?.title;
const keyExtractor = (item: OptionsType) => item?.id;
const organizationIdExtractor: SelectInputChangeCallback<Organization> = ({ option }) =>
    option?.id || null;
const fileExtractor: FileInputChangeCallback = ({ files }) => (files.length > 0 ? files[0] : null);

type TargetReportFormModalProps = Omit<ModalProps, 'children'> & {
    target: ExpandedTarget;
    onComplete?: () => void;
};

const TargetReportFormModal: React.FC<TargetReportFormModalProps> = (props) => {
    const { onClose, target, onComplete, ...modalProps } = props;

    const { organizations } = useAppSelector((state) => state.organization);

    const [{ loading }, createTargetReport] = usePromise(Api.createTargetReport);

    const handleClose = useCallback(() => {
        onClose?.();
    }, [onClose]);

    const handleSubmitForm: FormSubmitCallback = useCallback(
        async (formData) => {
            formData.append('exchangeRate', '1');
            try {
                await createTargetReport(target.id, formData, {
                    headers: new Headers()
                });
                Toast.show(_('Target Report has been successfully created!'), Toast.SUCCESS, 10);
                onComplete?.();
            } catch (error) {
                console.log(error);
                Toast.show(getErrorMessage(error), Toast.DANGER, 10);
            }
        },
        [onComplete, createTargetReport, target]
    );

    return (
        <Modal className={styles.modal} {...modalProps}>
            <div className={styles.header}>
                <h2 className={styles.title}>
                    <Localize>Add target report</Localize>
                </h2>
                <span
                    className={cs('material-symbols-rounded', styles.closeIcon)}
                    onClick={handleClose}
                >
                    close
                </span>
            </div>
            <Form onSubmit={handleSubmitForm} className={styles.reportForm}>
                <div className={styles.formContent}>
                    <Form.Input
                        name="title"
                        component={AuthInput}
                        label={_('Title*')}
                        labelClassName={styles.inputLabel}
                        placeholder={_('Enter target title')}
                        required
                    />
                    <Form.Input
                        name="description"
                        component={TextareaInput}
                        label={_('Description')}
                        labelClassName={styles.inputLabel}
                        containerClassName={styles.inputSpacing}
                        placeholder={_('Enter target description')}
                        rows={4}
                    />
                    <Form.Input
                        name="value"
                        type="number"
                        component={AuthInput}
                        label={_('Value*')}
                        labelClassName={styles.inputLabel}
                        placeholder={_('Enter target value')}
                        required
                    />
                    <Form.Input
                        name="date"
                        component={DateInput}
                        label={_('Completion date*')}
                        containerClassName={styles.inputSpacing}
                        labelClassName={styles.inputLabel}
                        inputContainerClassName={styles.dateInputContainer}
                        className={styles.dateInput}
                        placeholder="mm/dd/yyyy"
                        required
                    />
                    <div className={styles.inputSpacing}>
                        <Label className={styles.inputLabel}>{_('Expense*')}</Label>
                        <FormCurrencyInput
                            currencyInputName="expenseCurrency"
                            amountInputName="expense"
                            lockedCurrency={currencies[0]}
                            amountRequired
                        />
                    </div>
                    <Form.Input
                        component={OrganizationSelectInput}
                        label={_('Partner Organization*')}
                        labelClassName={styles.inputLabel}
                        containerClassName={styles.selectInput}
                        name="organization"
                        options={organizations}
                        valueExtractor={titleExtractor}
                        fieldValueExtractor={organizationIdExtractor}
                        keyExtractor={keyExtractor}
                        placeholder={_('Select organization')}
                        required
                        hideFooter
                    />
                    <Form.Input
                        component={FileInput}
                        name="attachment"
                        containerClassName={styles.inputSpacing}
                        label={_('Add attachment*')}
                        labelClassName={styles.inputLabel}
                        info={_('PDF (max. 2MB)')}
                        maxSize={2048}
                        fieldValueExtractor={fileExtractor}
                        accept="application/pdf"
                        required
                    />
                </div>
                <Button large className={styles.submitButton} loading={loading}>
                    <Localize>Add</Localize>
                </Button>
            </Form>
        </Modal>
    );
};

export default TargetReportFormModal;
