import { Link } from 'react-router-dom';

import cs from '@ra/cs';
import { Localize } from 'services/i18n';
import { formatNumber } from 'utils/formatter';

import styles from './styles.scss';

const OverviewStatItem = ({
    item
}: {
    item: { icon: string; value: number; title: string; link: string };
}) => {
    return (
        <Link to={item?.link} className={styles.statCard}>
            <div className={styles.iconWrapper}>
                <span className={cs('material-symbols-rounded', styles.statsIcon)}>
                    {item.icon}
                </span>
            </div>
            <div className={styles.data}>
                <p className={styles.statsValue}>{formatNumber(item.value)}</p>
                <p className={styles.statsTitle}>
                    <Localize>{item.title}</Localize>
                </p>
            </div>
        </Link>
    );
};

export default OverviewStatItem;
