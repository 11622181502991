import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import type { MapboxGeoJSONFeature } from 'react-map-gl';

import Button from 'components/Button';
import Map from 'components/Map';
import Tabs, { Tab, TabChangeCallback } from 'components/Tabs';

import Api from 'services/api';
import { Localize, _, objTranslator } from 'services/i18n';
import { selectPriorityAreas } from 'store/selectors/priorityIndicator';

import useAdministrativeDivisions, { DivisionLevelModel } from 'hooks/useAdministrativeDivisions';
import useSize from '@ra/hooks/useSize';
import usePromise from '@ra/hooks/usePromise';
import { useFiltersDispatch } from 'hooks/filters';
import { useAppSelector } from 'hooks/store';

import type { Region, MPTTStats, PriorityIndicator } from 'services/types';

import 'mapbox-gl/dist/mapbox-gl.css';

import styles from './styles.scss';

const ExploreData: React.FC = () => {
    const navigate = useNavigate();

    const divisionLevels: DivisionLevelModel[] = useAdministrativeDivisions();

    const filtersDispatch = useFiltersDispatch();

    const { regions } = useAppSelector((state) => state.region);
    const { organizations } = useAppSelector((state) => state.organization);
    const priorityAreas = useAppSelector((state) => selectPriorityAreas(state));

    const { width } = useSize();

    const [activeCategory, setActiveCategory] = useState<string>('administrative_divisions');
    const [activeAccessor, setActiveAccessor] = useState<'projects' | 'amount'>('projects');
    const [activeLevel, setActiveLevel] = useState<DivisionLevelModel>(divisionLevels[1]);

    const [{ result, loading: loadingMapStats }, getMapStats] = usePromise(Api.getLocationStats);
    const [{ result: donorResult, loading: loadingDonorStats }, getDonorStats] = usePromise(
        Api.getDonorStats
    );
    const [{ result: themeResult, loading: loadingThemeStats }, getThemeStats] = usePromise(
        Api.getPriorityIndicatorStats
    );

    useEffect(() => {
        getMapStats();
        getDonorStats();
        getThemeStats();
    }, [getMapStats, getDonorStats, getThemeStats]);
    const mapData = useMemo(() => {
        return result?.filter((res: MPTTStats) => res.level === Number(activeLevel.code)) || [];
    }, [result, activeLevel]);

    const handleLevelChange: TabChangeCallback = useCallback(
        ({ activeTab }) => {
            const newActiveLevel = divisionLevels.find((dvl) => dvl.label === activeTab);
            if (newActiveLevel) {
                setActiveLevel(newActiveLevel);
            }
        },
        [divisionLevels]
    );

    const handleCategoryChange: TabChangeCallback = useCallback(({ activeTab }) => {
        setActiveCategory(activeTab);
    }, []);

    const [overviewData, totalProjects, totalBudget] = useMemo(() => {
        let totalProjects = 0;
        let totalBudget = 0;
        const sorted = [...mapData].sort((a, b) => {
            const accessorKey = activeAccessor === 'amount' ? 'budget' : 'count';
            return b[accessorKey] - a[accessorKey];
        });
        const acc = [];
        for (const mapDataItem of sorted) {
            const regionTitle = objTranslator(
                regions.find((reg) => reg.id === mapDataItem.identifier),
                'title'
            );
            totalProjects += mapDataItem.count;
            totalBudget += Number(mapDataItem.budget);
            if (activeCategory === 'administrative_divisions' && regionTitle) {
                acc.push({
                    name: regionTitle,
                    projects: mapDataItem.count,
                    amount: Number(mapDataItem.budget)
                });
            }
        }
        if (activeCategory === 'Donors') {
            const donorData = donorResult || [];
            const sortedDonorData = [...donorData].sort((a, b) => {
                const accessorKey = activeAccessor === 'amount' ? 'budget' : 'count';
                return b[accessorKey] - a[accessorKey];
            });
            for (const donorDataItem of sortedDonorData) {
                const donorTitle = objTranslator(
                    organizations.find((org) => org.id === donorDataItem.identifier),
                    'title'
                );
                if (donorTitle) {
                    acc.push({
                        name: donorTitle,
                        projects: donorDataItem.count,
                        amount: Number(donorDataItem.budget)
                    });
                }
            }
        } else if (activeCategory === 'Themes') {
            const themesData = themeResult || [];
            const sortedThemesData = themesData
                .filter((td: MPTTStats) => td.level === 0)
                .sort((a: MPTTStats, b: MPTTStats) => {
                    const accessorKey = activeAccessor === 'amount' ? 'budget' : 'count';
                    return (b[accessorKey] as number) - (a[accessorKey] as number);
                });
            for (const themeDataItem of sortedThemesData) {
                const themeTitle = objTranslator(
                    priorityAreas.find(
                        (pa: PriorityIndicator) => pa.id === themeDataItem.identifier
                    ),
                    'title'
                );
                if (themeTitle) {
                    acc.push({
                        name: themeTitle,
                        projects: themeDataItem.count,
                        amount: Number(themeDataItem.budget)
                    });
                }
            }
        }
        return [acc, totalProjects, totalBudget];
    }, [activeCategory, activeAccessor, mapData, regions, donorResult, organizations]);

    const handleGoToProjects = useCallback(
        (feature: MapboxGeoJSONFeature | null) => {
            const featureRegion = regions.find(
                (reg: Region) =>
                    feature &&
                    Number(reg.code) === feature.id &&
                    reg.title === feature.properties?.title
            );
            if (!featureRegion) {
                return;
            }
            filtersDispatch({
                type: 'add',
                filter: {
                    filterType: 'Region-Region',
                    filterValue: featureRegion,
                    query: { location: featureRegion.id }
                }
            });
            navigate({ pathname: '/map/details', search: `?region=${featureRegion.id}` });
        },
        [navigate, regions, filtersDispatch]
    );

    return (
        <div className={styles.container}>
            <div className={styles.header}>
                <h2 className={styles.title}>
                    <Localize>Explore the data</Localize>
                </h2>
                <p className={styles.description}>
                    <Localize>
                        Search by administrative divisions, projects, donors, and themes
                    </Localize>
                </p>
            </div>
            <div className={styles.mapTabs}>
                <Tabs
                    primary
                    defaultActiveTab="administrative_divisions"
                    onChange={handleCategoryChange}
                >
                    <Tab label="administrative_divisions" title={_('ADMINISTRATIVE DIVISIONS')} />
                    <Tab label="Donors" title={_('DONORS')} />
                    <Tab label="Themes" title={_('THEMES')} />
                </Tabs>
            </div>
            <div className={styles.mapSection}>
                <div className={styles.mapHeader}>
                    <Tabs
                        secondary
                        defaultActiveTab={divisionLevels[1].label}
                        onChange={handleLevelChange}
                    >
                        {divisionLevels.slice(0, 4).map((lvl) => (
                            <Tab key={lvl.id} label={lvl.label} title={lvl.title} />
                        ))}
                    </Tabs>
                    <div className={styles.buttons}>
                        <p className={styles.message}>
                            <Localize>For advanced filter click below</Localize>
                        </p>
                        <Link to="/map">
                            <Button leftIcon="map">
                                <Localize>Explore detailed map</Localize>
                            </Button>
                        </Link>
                    </div>
                </div>
                <Map
                    className={styles.mapContainer}
                    activeDivisionLevel={activeLevel}
                    showActiveFeaturePopup
                    style={{ width: '100%', height: 650 }}
                    navigationControlStyle={{ marginRight: '2rem', marginTop: '1rem' }}
                    overviewData={{
                        loading:
                            regions.length === 0 ||
                            loadingMapStats ||
                            loadingDonorStats ||
                            loadingThemeStats,
                        topData: overviewData,
                        numDonors: donorResult?.length || '-',
                        totalProjects,
                        totalBudget
                    }}
                    overviewDataCategory={
                        activeCategory !== 'administrative_divisions' ? activeCategory : undefined
                    }
                    overviewDataAccessorState={[activeAccessor, setActiveAccessor]}
                    mapData={mapData}
                    padding={{ left: width ? width / 3 : 0, top: 0, bottom: 0, right: 0 }}
                    onPopupButtonClick={handleGoToProjects}
                />
            </div>
        </div>
    );
};

export default ExploreData;
