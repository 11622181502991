import Api from 'services/api';
import store from 'store';

import { setRegions } from 'store/slices/region';
import { setOrganizations, setOrganizationTypes } from 'store/slices/organization';
import { setPriorityIndicators } from 'store/slices/priorityIndicator';
import { setHazards } from 'store/slices/hazard';
import { setLegalDocuments } from 'store/slices/legalDocument';
import { setProjectDocumentTypes } from 'store/slices/projectDocumentType';

const dispatch = store.dispatch;

const tokenRefresh = async () => {
    const {
        auth: { refreshToken }
    } = store.getState();
    refreshToken && (await Api.refreshToken(refreshToken));
};

export const getRegions = async () => {
    try {
        const {
            region: { regions }
        } = store.getState();
        if (!regions?.length) {
            const regionData = await Api.getRegions({ limit: -1 });
            if (regionData) {
                dispatch(setRegions(regionData.results || []));
            }
        }
    } catch (error) {
        console.log(error);
    }
};

export const getOrganizations = async () => {
    try {
        const organizationData = await Api.getOrganizations({ limit: -1 });
        if (organizationData) {
            dispatch(setOrganizations(organizationData.results || []));
        }
        const organizationTypesData = await Api.getOrganizationTypes({ limit: -1 });
        if (organizationTypesData) {
            dispatch(setOrganizationTypes(organizationTypesData.results || []));
        }
    } catch (error) {
        console.log(error);
    }
};

export const getPriorityIndicators = async () => {
    try {
        const priorityIndicatorsData = await Api.getPriorityIndicators({ limit: -1 });
        if (priorityIndicatorsData) {
            dispatch(setPriorityIndicators(priorityIndicatorsData.results || []));
        }
    } catch (error) {
        console.log(error);
    }
};

export const getHazards = async () => {
    try {
        const hazardsData = await Api.getHazards({ limit: -1 });
        if (hazardsData) {
            dispatch(setHazards(hazardsData.results || []));
        }
    } catch (error) {
        console.log(error);
    }
};

export const getLegalDocuments = async () => {
    try {
        const legalDocumentsData = await Api.getLegalDocuments();
        if (legalDocumentsData) {
            dispatch(setLegalDocuments(legalDocumentsData.results || []));
        }
    } catch (error) {
        console.log(error);
    }
};

export const getProjectDocumentTypes = async () => {
    try {
        const projectDocumentTypesData = await Api.getProjectDocumentTypes();
        if (projectDocumentTypesData) {
            dispatch(setProjectDocumentTypes(projectDocumentTypesData.results || []));
        }
    } catch (error) {
        console.log(error);
    }
};

export const refreshStoreData = () => {
    getLegalDocuments();
    getHazards();
    getOrganizations();
    getPriorityIndicators();
};

export const initializeData = async () => {
    await tokenRefresh();
    setInterval(tokenRefresh, 240 * 1000);
    getLegalDocuments();
    getHazards();
    getOrganizations();
    getPriorityIndicators();
    getRegions();
    getProjectDocumentTypes();
};
