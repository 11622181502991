import React, { useMemo, useEffect } from 'react';

import Container from 'components/Container';
import List from '@ra/components/List';
import OverviewStatItem from 'components/OverviewStatItem';

import Api from 'services/api';
import usePromise from '@ra/hooks/usePromise';
import { statsData } from 'services/data';

import styles from './styles.scss';

const keyExtractor = (item: { id: number }) => item.id;

const StatsSection: React.FC = () => {
    const [{ result }, getStats] = usePromise(Api.getProjectCounts);
    useEffect(() => {
        getStats();
    }, [getStats]);
    const countStats = useMemo(() => {
        return statsData.map((st) => ({
            ...st,
            value: result?.[st.key] || ''
        }));
    }, [result]);

    return (
        <Container>
            <List
                className={styles.statsList}
                keyExtractor={keyExtractor}
                data={countStats}
                renderItem={OverviewStatItem}
            />
        </Container>
    );
};

export default StatsSection;
