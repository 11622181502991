import React, { useEffect, useCallback, useMemo } from 'react';

import cs from '@ra/cs';
import { useAppSelector, useAppDispatch } from 'hooks/store';
import * as uiActions from 'store/slices/ui';

import styles from './styles.scss';

const Toast = () => {
    const dispatch = useAppDispatch();
    const { toast } = useAppSelector((state) => state.ui);

    const handleClose = useCallback(() => {
        dispatch(uiActions.hideToast());
    }, [dispatch]);

    useEffect(() => {
        let timer: any;
        if (toast.duration) {
            timer = setTimeout(handleClose, toast.duration * 1000);
        }
        return () => {
            if (timer) {
                clearTimeout(timer);
            }
        };
    }, [toast.duration]);

    const iconName = useMemo(() => {
        switch (toast.status) {
            case 'success':
                return 'check_circle';
            case 'danger':
                return 'error';
            case 'warning':
                return 'warning';
            default:
                return 'info';
        }
    }, [toast]);

    if (!toast.visible) {
        return null;
    }

    return (
        <div className={styles.container}>
            <div className={styles.content}>
                <span className={cs(toast.status, styles.toastIcon, 'material-symbols-rounded')}>
                    {iconName}
                </span>
                <p className={styles.message}>{toast.message}</p>
                <div className={styles.closeContainer} onClick={handleClose}>
                    <span className={cs(styles.close, 'material-symbols-rounded')}>close</span>
                </div>
            </div>
            {toast.duration && (
                <div
                    style={{ animationDuration: `${toast.duration}s` }}
                    className={styles.timerProgress}
                />
            )}
        </div>
    );
};

export default Toast;
