import { useCallback } from 'react';

import Button from 'components/Button';

import AuthInput from 'components/Input/AuthInput';
import Form, { InputField, type FormSubmitCallback } from '@ra/components/Form';
import Modal from '@ra/components/Modal';
import withVisibleCheck from '@ra/components/WithVisibleCheck';

import cs from '@ra/cs';
import { _, Localize } from 'services/i18n';

import type { AuthModalProps } from '..';

import styles from './styles.scss';

type ConfirmPasswordModalProps = Partial<AuthModalProps> & {
    loading?: boolean;
    onSubmit?: (password: string) => void;
};

const ConfirmPasswordModal: React.FC<ConfirmPasswordModalProps> = (props) => {
    const { onClose, loading, onSubmit } = props;

    const handleSubmit: FormSubmitCallback = useCallback(
        ({ password }) => {
            if (onSubmit) {
                onSubmit(password);
            }
        },
        [onSubmit]
    );

    return (
        <Modal className={styles.modal}>
            <div className={styles.header}>
                <h2 className={styles.title}>
                    <Localize>Confirm your password</Localize>
                </h2>
                <span
                    onClick={onClose}
                    className={cs('material-symbols-rounded', styles.closeIcon)}
                >
                    close
                </span>
            </div>
            <Form onSubmit={handleSubmit} className={styles.content}>
                <InputField
                    name="password"
                    type="password"
                    required
                    component={AuthInput}
                    className={styles.input}
                    label={_('Password')}
                    labelClassName={styles.inputLabel}
                    containerClassName={styles.inputGroup}
                />
                <div className={styles.buttons}>
                    <Button type="button" secondary className={styles.button} onClick={onClose}>
                        <Localize>Cancel</Localize>
                    </Button>
                    <Button loading={loading} className={styles.button}>
                        <Localize>Continue</Localize>
                    </Button>
                </div>
            </Form>
        </Modal>
    );
};

export default withVisibleCheck(ConfirmPasswordModal);
