import React, { useMemo } from 'react';

import AuthInput from 'components/Input/AuthInput';
import { OrganizationSelectInput, OptionsType } from 'components/SelectInput';
import Form, { InputField, type FormSubmitCallback } from '@ra/components/Form';
import Button from 'components/Button';

import cs from '@ra/cs';
import { _, Localize } from 'services/i18n';

import { useAppSelector } from 'hooks/store';

import styles from './styles.scss';

interface EditProfileProps {
    onEditProfile: FormSubmitCallback;
    error?: any;
    loading?: boolean;
}

const keyExtractor = (item: OptionsType) => item?.id;
const titleExtractor = (item: OptionsType) => item?.title;

const EditProfile: React.FC<EditProfileProps> = ({ onEditProfile, error, loading }) => {
    const { organizations } = useAppSelector((state) => state.organization);
    const { user } = useAppSelector((state) => state.auth);

    const defaultOrganization = useMemo(() => {
        return organizations.find((org) => org.id === user.organization);
    }, [organizations, user]);

    const defaultFullName = useMemo(() => {
        return `${user.firstName} ${user.lastName}`;
    }, [user]);

    return (
        <Form onSubmit={onEditProfile} error={error} formErrorClassName={styles.errorMessage}>
            <h1 className={styles.title}>
                <Localize>Edit Profile</Localize>
            </h1>
            <InputField
                name="organization"
                component={OrganizationSelectInput}
                label={_('Organization')}
                labelClassName={cs(styles.inputLabel, styles.inputLabelSelect)}
                className={styles.selectInput}
                options={organizations}
                valueExtractor={titleExtractor}
                keyExtractor={keyExtractor}
                placeholder={_('Select your organization')}
                defaultValue={defaultOrganization}
                disabled
                clearable={false}
                searchable={false}
            />
            <InputField
                component={AuthInput}
                label={_('Name')}
                labelClassName={styles.inputLabel}
                placeholder={_('Eg. Subas Pathak')}
                name="fullName"
                required
                defaultValue={defaultFullName}
            />
            <InputField
                component={AuthInput}
                name="designation"
                label={_('Designation')}
                labelClassName={styles.inputLabel}
                placeholder={_('Eg. Director')}
                defaultValue={user.designation}
            />
            <InputField
                component={AuthInput}
                type="email"
                info={_(
                    'If you change this, we will send you an email at your new address to confirm it. The new address will not become active until confirmed.'
                )}
                labelClassName={styles.inputLabel}
                className={styles.emailInput}
                name="newEmail"
                label={_('Email')}
                placeholder={_('Eg. subas@mail.com')}
                required
                defaultValue={user.email}
            />
            <InputField
                component={AuthInput}
                type="tel"
                className={styles.input}
                containerClassName={styles.contactInput}
                name="phoneNumber"
                labelClassName={styles.inputLabel}
                placeholder={_('+97798XXXXXXXX')}
                label={_('Contact Number (with country code)')}
                required
                defaultValue={user.phoneNumber}
            />
            <Button loading={loading} className={styles.button}>
                <Localize>Save changes</Localize>
            </Button>
        </Form>
    );
};

export default EditProfile;
