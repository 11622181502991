import { combineReducers } from 'redux';

import authReducer from './auth';
import hazardReducer from './hazard';
import legalDocumentReducer from './legalDocument';
import localeReducer from './locale';
import regionReducer from './region';
import organizationReducer from './organization';
import priorityIndicatorReducer from './priorityIndicator';
import projectDocumentTypeReducer from './projectDocumentType';
import uiReducer from './ui';

const rootReducer = combineReducers({
    auth: authReducer,
    hazard: hazardReducer,
    legalDocument: legalDocumentReducer,
    locale: localeReducer,
    region: regionReducer,
    organization: organizationReducer,
    ui: uiReducer,
    priorityIndicator: priorityIndicatorReducer,
    projectDocumentType: projectDocumentTypeReducer
});

export default rootReducer;
