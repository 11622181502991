import React, { useCallback, useState, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import AuthInput from 'components/Input/AuthInput';
import Button from 'components/Button';
import CheckboxInput from '@ra/components/Form/CheckboxInput';
import FileInput, { FilePreviewProps } from 'components/FileInput';
import Form, { InputField, FormSubmitCallback } from '@ra/components/Form';
import Input from 'components/Input';
import { OrganizationSelectInput, OptionsType } from 'components/SelectInput';

import Api from 'services/api';
import { Localize, _ } from 'services/i18n';
import { useAppSelector } from 'hooks/store';
import usePromise from '@ra/hooks/usePromise';
import Toast from 'services/toast';
import { phoneNumberValidationRegex } from 'utils/form';

import type { OrganizationType } from 'services/types';
import type { SelectInputChangeCallback } from '@ra/components/Form/SelectInput';
import type { FileInputChangeCallback } from '@ra/components/Form/DragDropFileInput';

import styles from './styles.scss';

const titleExtractor = (item: OptionsType) => item?.title;
const keyExtractor = (item: OptionsType) => item?.id;

const organizationTypeIdExtractor: SelectInputChangeCallback<OrganizationType> = ({ option }) =>
    option?.id || null;
const fileExtractor: FileInputChangeCallback = ({ files }) => (files.length > 0 ? files[0] : null);

const LogoPreview: React.FC<FilePreviewProps> = ({ file, onRemove }) => {
    const previewLogo = useMemo(() => {
        if (file) {
            return URL.createObjectURL(file as File);
        }
        return '';
    }, [file]);

    if (!file) {
        return null;
    }

    return (
        <div className={styles.logoInfo}>
            <div className={styles.logoContainer}>
                <img className={styles.logo} src={previewLogo} />
            </div>
            <div className={styles.logoAction}>
                <Button className={styles.deleteLogoButton} small onClick={onRemove}>
                    <span className="material-symbols-rounded">delete</span>
                </Button>
            </div>
        </div>
    );
};

const RequestRegister: React.FC = () => {
    const navigate = useNavigate();

    const { organizationTypes } = useAppSelector((state) => state.organization);

    const [error, setError] = useState<any>();
    const [doesAgreeDetails, setDoesAgreeDetails] = useState<boolean>(false);

    const handleAgreeDetailsChange = useCallback((payload: HTMLInputElement) => {
        setDoesAgreeDetails(payload.checked);
    }, []);

    const [{ loading }, organizationRequest] = usePromise(Api.requestNewOrganization);

    const handleSubmit: FormSubmitCallback = useCallback(
        async (formData) => {
            setError(null);
            const submitData = new FormData();
            const name = formData.fullName.split(' ');
            const firstName = name[0];
            const lastName = formData.fullName.substring(name[0].length).trim();

            if (!lastName) {
                setError({ fullName: _('Last name cannot be empty') });
                return;
            }
            for (const key in formData) {
                if (formData[key] && key !== 'fullName') {
                    if (key === 'organizationWebsite' && !formData[key].startsWith('http')) {
                        submitData.append(key, 'https://' + formData[key]);
                    } else {
                        submitData.append(key, formData[key]);
                    }
                }
            }
            submitData.append('firstName', firstName);
            submitData.append('lastName', lastName);
            try {
                if (!phoneNumberValidationRegex.test(submitData.get('phoneNumber') as string)) {
                    throw {
                        errors: { phoneNumber: ['The phone number you entered is not valid.'] }
                    };
                }
                const result = await organizationRequest(submitData, { headers: new Headers() });
                if (result) {
                    navigate('/');
                    // TODO: Success message
                    Toast.show(
                        _(
                            'Your request for a new organization has been successfully sent. The focal person will be notified by our administrators within a few working days.'
                        ),
                        Toast.SUCCESS,
                        null
                    );
                }
                console.log(result);
            } catch (err: any) {
                setError(err?.errors || err);
            }
        },
        [navigate, organizationRequest]
    );

    const handleInvalidSubmit = useCallback((reason: string) => {
        if (reason === 'required') {
            Toast.show(_('Please complete all required fields!'), Toast.DANGER);
        }
    }, []);

    return (
        <div className={styles.authContainer}>
            <div className={styles.authBox}>
                <h1 className={styles.authHeading}>
                    <Localize>Request to register an organization</Localize>
                </h1>
                <p className={styles.authSubHeading}>
                    <Localize>Please enter the organization and focal person details</Localize>
                </p>
                <Form
                    error={error}
                    formErrorClassName={styles.errorMessage}
                    onSubmit={handleSubmit}
                    onInvalidSubmit={handleInvalidSubmit}
                    className={styles.formWrapper}
                >
                    <div>
                        <h3 className={styles.detailsHeading}>
                            <Localize>1. Organization Details</Localize>
                        </h3>
                        <div className={styles.titleInputGroup}>
                            <InputField
                                name="title"
                                containerClassName={styles.nameInputContainer}
                                component={AuthInput}
                                label={_('Organization Name*')}
                                labelClassName={styles.inputLabel}
                                placeholder={_('Eg. Better Society')}
                                required
                            />
                            <InputField
                                name="acronym"
                                containerClassName={styles.acronymInputContainer}
                                component={AuthInput}
                                label={_('Acronym*')}
                                labelClassName={styles.inputLabel}
                                placeholder={_('Eg. BS')}
                                required
                            />
                        </div>
                        <InputField
                            name="organizationWebsite"
                            component={AuthInput}
                            label={_('Organization Website*')}
                            labelClassName={styles.inputLabel}
                            placeholder={_('Eg. www.example.com')}
                            required
                        />
                        <InputField
                            name="organizationEmail"
                            component={AuthInput}
                            label={_('Organization Email*')}
                            labelClassName={styles.inputLabel}
                            placeholder={_('Eg. title@example.com')}
                            required
                        />
                        <InputField
                            name="logo"
                            component={FileInput}
                            label={_('Organization Logo*')}
                            inputContainerClassName={styles.logoInput}
                            labelClassName={styles.inputLabel}
                            info={_('PNG, JPG, JPEG, SVG (max. 2MB)')}
                            maxSize={2048}
                            containerClassName={styles.fileInput}
                            fileInputContainerClassName={styles.logoInputWrapper}
                            fieldValueExtractor={fileExtractor}
                            accept=".jpg, .jpeg, .svg, .png"
                            PreviewComponent={LogoPreview}
                            required
                        />
                        <InputField
                            name="description"
                            component="textarea"
                            label={_('Description')}
                            labelClassName={styles.inputLabel}
                            className={styles.textarea}
                            rows={4}
                            placeholder={_('Eg. Better society is a...')}
                            containerClassName={styles.fileInput}
                        />
                        <InputField
                            name="organizationType"
                            component={OrganizationSelectInput}
                            label={_('Organization type*')}
                            containerClassName={styles.selectInput}
                            labelClassName={styles.inputLabel}
                            options={organizationTypes}
                            valueExtractor={titleExtractor}
                            fieldValueExtractor={organizationTypeIdExtractor}
                            keyExtractor={keyExtractor}
                            placeholder={_('Select Organization type')}
                            hideFooter
                            required
                        />
                        <InputField
                            component={FileInput}
                            containerClassName={styles.fileInput}
                            name="letter"
                            label={_('Organization Letter*')}
                            labelClassName={styles.inputLabel}
                            info={_('PDF (max. 2MB)')}
                            maxSize={2048}
                            fieldValueExtractor={fileExtractor}
                            accept="application/pdf"
                            required
                        />
                        <a
                            className={styles.viewButton}
                            href="https://docs.google.com/document/d/1_eImUtmYhjb86-uRGBAAU2zQV0oP2Ag7"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <span className="material-symbols-rounded">article</span>
                            <Localize>View a sample letter</Localize>
                        </a>
                    </div>
                    <div>
                        <h3 className={styles.detailsHeading}>
                            <Localize>2. Focal Person Details</Localize>
                        </h3>
                        <InputField
                            component={AuthInput}
                            label={_('Focal Person Name*')}
                            labelClassName={styles.inputLabel}
                            placeholder={_('Eg. Subas Pathak')}
                            name="fullName"
                            required
                        />
                        <InputField
                            component={AuthInput}
                            name="designation"
                            label={_('Focal Person Designation*')}
                            labelClassName={styles.inputLabel}
                            placeholder={_('Eg. Director')}
                            required
                        />
                        <InputField
                            component={AuthInput}
                            labelClassName={styles.inputLabel}
                            name="email"
                            label={_('Focal Person Email*')}
                            placeholder={_('Eg. subas@mail.com')}
                            required
                        />
                        <InputField
                            component={Input}
                            className={styles.input}
                            containerClassName={styles.contactInput}
                            name="phoneNumber"
                            labelClassName={styles.inputLabel}
                            placeholder={_('+97798XXXXXXXX')}
                            label={_('Focal Person Contact Number* (with country code)')}
                            required
                        />
                    </div>
                    <div className={styles.msgWrapper}>
                        <div className={styles.checkWrapper}>
                            <CheckboxInput
                                id="agree_details_checkbox"
                                className={styles.checkbox}
                                onChange={handleAgreeDetailsChange}
                            />
                            <label htmlFor="agree_details_checkbox" className={styles.message}>
                                <Localize>I agree all the details entered are correct</Localize>
                            </label>
                        </div>
                    </div>
                    <Button
                        type="submit"
                        disabled={!doesAgreeDetails}
                        loading={loading}
                        className={styles.authButton}
                    >
                        <Localize>Request Registration</Localize>
                    </Button>
                </Form>
            </div>
        </div>
    );
};

export default RequestRegister;
