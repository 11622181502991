import React, { useRef, useCallback, useEffect, useMemo, useState } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import useResizeObserver from '@react-hook/resize-observer';
import DatePicker from 'react-datepicker';
import { format } from 'date-fns';

import Button from 'components/Button';
import DateRangePickerButton from 'components/DateRangePickerButton';
import DropdownSelector from 'components/DropdownSelector';
import Filters from 'components/Filters';
import FilterBudgetSlider from 'components/Filters/FilterBudgetSlider';
import FilterHazards from 'components/Filters/FilterHazards';
import FilterOrganizations from 'components/Filters/FilterOrganizations';
import FilterProjectStatuses from 'components/Filters/FilterProjectStatuses';
import FilterRegionSelector from 'components/Filters/FilterRegionSelector';
import ProjectTable from 'components/ProjectTable';
import Pagination, { PageChangeCallback } from '@ra/components/Pagination';
import SelectInput, {
    SelectInputChangeCallback,
    ValueExtractor,
    KeyExtractor
} from '@ra/components/Form/SelectInput';

import Api from 'services/api';
import cs from '@ra/cs';
import { debounce } from '@ra/utils';
import { _, Localize } from 'services/i18n';
import ReportGenerator, { type ExpandedProject } from 'services/report';
import { formatLongTitle } from 'utils/formatter';
import { selectPriorityActivities } from 'store/selectors/priorityIndicator';

import { useFilters, useFiltersDispatch } from 'hooks/filters';
import { useAppSelector } from 'hooks/store';
import usePromise from '@ra/hooks/usePromise';

import { maxRowsOptions, type MaxRowOption } from 'containers/Projects';
import type { ActivityTableItem } from 'components/ActivitiesTable';
import type { Project } from 'services/types';

import 'react-datepicker/dist/react-datepicker.css';

import styles from './styles.scss';

interface ActivityDetailsLocationState {
    activity?: ActivityTableItem;
}

const keyExtractor: KeyExtractor<MaxRowOption> = (item: MaxRowOption) => item.value;
const labelExtractor: ValueExtractor<MaxRowOption, string> = (item) => item.label;

const ActivityDetails: React.FC = () => {
    const { activityId } = useParams();

    const priorityActivities = useAppSelector((state) => selectPriorityActivities(state));

    const location = useLocation();
    const stateActivity = (location.state as ActivityDetailsLocationState)?.activity;

    const activity = useMemo(() => {
        if (stateActivity) {
            return stateActivity;
        }
        return priorityActivities.find((act) => act.id === Number(activityId));
    }, [stateActivity, priorityActivities]);

    const filtersDispatch = useFiltersDispatch();
    const projectFilters = useFilters('PriorityActivityProject');

    const containerRef = useRef<HTMLDivElement>(null);
    const headerRef = useRef<HTMLDivElement>(null);
    const contentHeaderRef = useRef<HTMLDivElement>(null);
    const filterRef = useRef<HTMLDivElement>(null);
    const footerRef = useRef<HTMLDivElement>(null);

    const [filtersActive, setFiltersActive] = useState<boolean>(Boolean(projectFilters.length));
    const toggleFilters = useCallback(() => {
        setFiltersActive((prev) => !prev);
    }, []);

    const [page, setPage] = useState<number>(1);
    const [maxRows, setMaxRows] = useState<MaxRowOption>(maxRowsOptions[0]);

    const [dateRange, setDateRange] = useState<[Date | null, Date | null]>([null, null]);
    const [startDate, endDate] = useMemo(() => dateRange, [dateRange]);

    const handleDateChange = useCallback(
        (date: [Date | null, Date | null]) => {
            if (date[0]) {
                filtersDispatch({
                    type: 'add',
                    filter: {
                        filterType: 'PriorityActivityProject-From',
                        filterValue: date[0],
                        query: { date_from__gte: format(date[0], 'yyyy-MM-dd') }
                    }
                });
            } else if (dateRange[0]) {
                filtersDispatch({
                    type: 'remove',
                    filter: {
                        filterType: 'PriorityActivityProject-From',
                        filterValue: dateRange[0]
                    }
                });
            }
            if (date[1]) {
                filtersDispatch({
                    type: 'add',
                    filter: {
                        filterType: 'PriorityActivityProject-To',
                        filterValue: date[1],
                        query: { date_to__lte: format(date[1], 'yyyy-MM-dd') }
                    }
                });
            } else if (dateRange[1]) {
                filtersDispatch({
                    type: 'remove',
                    filter: { filterType: 'PriorityActivityProject-To', filterValue: dateRange[1] }
                });
            }
            setDateRange(date);
        },
        [filtersDispatch, dateRange]
    );

    const [projectOrdering, setProjectOrdering] = useState<string>('-budget');
    const handleSortProjects = useCallback(setProjectOrdering, []);

    const [{ loading, result, error }, getProjects] = usePromise(Api.getProjects);
    const loadProjects = useCallback(debounce(getProjects, 1000), [getProjects]);
    useEffect(() => {
        const filterQueries = projectFilters.reduce(
            (acc, cur) => {
                if (cur.query?.hazards__in) {
                    const addStr = acc.hazards__in
                        ? `,${cur.query.hazards__in}`
                        : cur.query.hazards__in;
                    acc.hazards__in += addStr;
                    return acc;
                }
                if (cur.filterType !== 'PriorityActivityProject-Status') {
                    Object.assign(acc, cur.query);
                    return acc;
                }
                if (cur.query?.status__in) {
                    const addStr = acc.status__in
                        ? `,${cur.query.status__in}`
                        : cur.query.status__in;
                    acc.status__in += addStr;
                    return acc;
                }
                return acc;
            },
            {
                limit: maxRows.value,
                outputs__activities__priority_indicators: activityId,
                status__in: '',
                hazards__in: '',
                expand: 'outputs.activities.targets.reports',
                offset: (page - 1) * maxRows.value
            }
        );
        if (projectOrdering) {
            Object.assign(filterQueries, { ordering: projectOrdering });
        }
        loadProjects(filterQueries);
    }, [loadProjects, projectOrdering, page, maxRows, projectFilters]);
    const projectData: Project[] = useMemo(() => result?.results || [], [result]);

    const handlePageChange: PageChangeCallback = useCallback(({ currentPage }) => {
        setPage(currentPage);
    }, []);
    const handleMaxRowsChange: SelectInputChangeCallback<MaxRowOption> = useCallback(
        ({ option }) => {
            if (option) {
                setMaxRows(option);
                setPage(1);
            }
        },
        []
    );

    const handleClearFilters = useCallback(() => {
        setDateRange([null, null]);
        filtersDispatch({ type: 'clear', category: 'PriorityActivityProject' });
    }, [filtersDispatch]);

    const [contentStyle, setContentStyle] = useState<React.CSSProperties>({});

    const handleContainerResize = useCallback(() => {
        if (
            containerRef?.current &&
            headerRef?.current &&
            contentHeaderRef?.current &&
            footerRef?.current
        ) {
            let tableHeight =
                containerRef.current.offsetHeight -
                headerRef.current.offsetHeight -
                contentHeaderRef.current.offsetHeight -
                footerRef.current.offsetHeight;
            let spacingHeight = 2.875;
            if (filterRef?.current) {
                tableHeight -= filterRef.current.offsetHeight;
                spacingHeight += 1;
            }
            setContentStyle({
                maxHeight: `calc(${tableHeight}px - ${spacingHeight}rem)`
            });
        }
    }, []);
    useResizeObserver(containerRef, handleContainerResize);

    const handleExportProjects = useCallback(() => {
        new ReportGenerator(Number(activity?.id))
            .loadProjectData(projectData as ExpandedProject[])
            .downloadXlsxReport('MDSA_ActivityReport');
    }, [projectData, activity]);

    return (
        <div className={styles.container} ref={containerRef}>
            <header className={styles.header} ref={headerRef}>
                <h1 title={activity?.title} className={styles.activityTitle}>
                    {formatLongTitle(activity?.title as string, 140)}
                </h1>
            </header>
            <main className={styles.content}>
                <div ref={contentHeaderRef} className={styles.contentHeader}>
                    <h1 className={styles.title}>
                        <Localize>Project List</Localize>
                    </h1>
                    <div className={styles.buttons}>
                        {projectFilters.length > 0 && (
                            <p className={styles.clearLink} onClick={handleClearFilters}>
                                <Localize>Clear all</Localize>
                            </p>
                        )}
                        <Button
                            small
                            secondary
                            leftIcon="filter_alt"
                            onClick={toggleFilters}
                            className={cs({ [styles.filterButtonActive]: filtersActive })}
                        >
                            <Localize>
                                Filters{projectFilters.length > 0 && ` (${projectFilters.length})`}
                            </Localize>
                        </Button>
                        <Button small leftIcon="ios_share" onClick={handleExportProjects}>
                            <Localize>Export</Localize>
                        </Button>
                    </div>
                </div>
                {filtersActive && (
                    <Filters ref={filterRef} className={styles.filters}>
                        <div className={styles.dateFilterContainer}>
                            <DatePicker
                                selectsRange
                                startDate={startDate}
                                endDate={endDate}
                                onChange={handleDateChange}
                                popperPlacement="bottom"
                                popperClassName={styles.datePopper}
                                customInput={
                                    <DateRangePickerButton
                                        startDate={startDate}
                                        endDate={endDate}
                                    />
                                }
                                isClearable
                                showYearDropdown
                            />
                        </div>
                        <FilterOrganizations filterType="PriorityActivityProject-Organization" />
                        <DropdownSelector
                            title={_('Budget')}
                            filterType="PriorityActivityProject-Budget"
                            icon="payments"
                        >
                            <FilterBudgetSlider
                                showTitle
                                filterType="PriorityActivityProject-Budget"
                                className={styles.filterBudget}
                                activeClassName={styles.filterBudgetActive}
                            />
                        </DropdownSelector>
                        <DropdownSelector
                            title={_('Status')}
                            filterType="PriorityActivityProject-Status"
                            icon="monitor_heart"
                        >
                            <FilterProjectStatuses
                                filterType="PriorityActivityProject-Status"
                                className={styles.filterItemList}
                            />
                        </DropdownSelector>
                        <FilterRegionSelector filterType="PriorityActivityProject-Region" />
                        <DropdownSelector
                            title={_('Hazard(s)')}
                            filterType="PriorityActivityProject-Hazard"
                            icon="warning"
                        >
                            <FilterHazards
                                filterType="PriorityActivityProject-Hazard"
                                className={styles.filterItemList}
                            />
                        </DropdownSelector>
                    </Filters>
                )}
                <div className={styles.tableContainer} style={contentStyle}>
                    <ProjectTable
                        className={styles.table}
                        headerClassName={cs(styles.tableHeader, {
                            [styles.tableHeaderStatic]: filtersActive
                        })}
                        loading={(!result && !error) || loading}
                        data={projectData}
                        onSort={handleSortProjects}
                        ordering={projectOrdering}
                    />
                </div>
            </main>
            <footer ref={footerRef} className={styles.footer}>
                <div className={styles.maxRowsSelect}>
                    <Localize>Show</Localize>
                    <SelectInput
                        className={styles.select}
                        controlClassName={styles.selectControl}
                        options={maxRowsOptions}
                        keyExtractor={keyExtractor}
                        valueExtractor={labelExtractor}
                        onChange={handleMaxRowsChange}
                        value={maxRows}
                        defaultValue={maxRowsOptions[0]}
                        clearable={false}
                        searchable={false}
                        optionsDirection="up"
                    />
                </div>
                <Pagination
                    showControlIcons
                    controlIconClassName={styles.paginationControl}
                    className={styles.pagination}
                    pageItemClassName={styles.paginationItem}
                    activePageItemClassName={styles.paginationItemActive}
                    onChange={handlePageChange}
                    totalRecords={result?.count || projectData.length}
                    pageNeighbours={1}
                    pageLimit={maxRows.value}
                    pageNum={page}
                />
            </footer>
        </div>
    );
};

export default ActivityDetails;
