import React from 'react';

import Footer from 'components/Footer';

import HeroSection from './HeroSection';
import StatsSection from './StatsSection';
import OrganizationSection from './OrganizationSection';
import ExploreData from './ExploreData';
import RolesSection from './RolesSection';
import StepSection from './StepsSection';

const Home: React.FC = () => {
    return (
        <>
            <HeroSection />
            <StatsSection />
            <OrganizationSection />
            <ExploreData />
            <RolesSection />
            <StepSection />
            <Footer />
        </>
    );
};

export default Home;
