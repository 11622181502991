import React, { useCallback, useState, useMemo, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import Button from 'components/Button';
import Ckeditor from 'components/Ckeditor';
import ConfirmModal from 'components/ConfirmModal';
import HTMLParser from 'components/HTMLParser';
import PDFDetails from 'components/PDFDetails';

import Api from 'services/api';
import cs from '@ra/cs';
import { Localize, _ } from 'services/i18n';
import { getOrganizations } from 'services/bootstrap';
import usePromise from '@ra/hooks/usePromise';
import { useAppSelector } from 'hooks/store';
import Toast from 'services/toast';
import { getErrorMessage } from '@ra/utils/error';

import type { Organization, Notification } from 'services/types';

import styles from './styles.scss';

interface ReviewRegistrationLocationState {
    notification?: Notification;
}

const ReviewRegistration: React.FC = () => {
    const location = useLocation();
    const navigate = useNavigate();

    const { organizations, organizationTypes } = useAppSelector((state) => state.organization);

    const { notification } = location.state as ReviewRegistrationLocationState;

    useEffect(() => {
        if (!notification) {
            navigate('/dashboard/');
        }
    }, [notification, navigate]);

    const [requestPromise, approvePromise, declinePromise] = useMemo(() => {
        if (notification?.notificationType === 'new_organization_request') {
            return [
                Api.getNewOrganizationRequest,
                Api.acceptNewOrganizationRequest,
                Api.rejectNewOrganizationRequest
            ];
        } else if (notification?.notificationType === 'claim_organization_request') {
            return [
                Api.getClaimOrganizationRequest,
                Api.acceptClaimOrganizationRequest,
                Api.rejectClaimOrganizationRequest
            ];
        }
        return [];
    }, [notification]);

    const requestId = useMemo(() => notification?.actorObjectId, [notification]);

    const [{ result: requestObject }, getRequest] = usePromise(requestPromise);
    useEffect(() => {
        if (requestId) {
            getRequest(requestId);
        }
    }, [getRequest, requestId]);

    const requestOrganization = useMemo(() => {
        if (!requestObject) {
            return {};
        }
        if (notification?.notificationType === 'new_organization_request') {
            const organizationTypeTitle =
                organizationTypes.find((el) => el.id === requestObject.organizationType)?.title ||
                '-';
            return {
                title: requestObject.title,
                acronym: requestObject.acronym,
                logo: requestObject.logo,
                description: requestObject.description,
                organizationType: organizationTypeTitle,
                organizationWebsite: requestObject.organizationWebsite,
                organizationEmail: requestObject.organizationEmail,
                letter: requestObject.letter
            };
        } else if (notification?.notificationType === 'claim_organization_request') {
            const organization = organizations.find((org) => org.id === requestObject.organization);
            if (organization) {
                const organizationTypeTitle =
                    organizationTypes.find((el) => el.id === organization.organizationType)
                        ?.title || '-';
                return {
                    ...organization,
                    organizationType: organizationTypeTitle,
                    letter: requestObject.letter
                } as unknown as Organization & { letter?: string };
            }
            return {};
        }
        return {};
    }, [organizations, organizationTypes, requestObject]);

    const [isApproveVisible, setApproveVisible] = useState<boolean>(false);
    const [isDeclineVisible, setDeclineVisible] = useState<boolean>(false);

    const [declineMessage, setDeclineMessage] = useState<string>('');

    const toggleApproveModal = useCallback(() => setApproveVisible((prev) => !prev), []);
    const toggleDeclineModal = useCallback(() => {
        setDeclineMessage('');
        setDeclineVisible((prev) => !prev);
    }, []);

    const handleDeclineMessageChange = useCallback((event: any, editor: any) => {
        setDeclineMessage(editor.getData());
    }, []);

    const [{ loading: declining }, declineRequest] = usePromise(declinePromise);
    const [{ loading: approving }, approveRequest] = usePromise(approvePromise);

    const handleDeclineRequest = useCallback(async () => {
        try {
            if (!declineMessage) {
                return Toast.show(
                    _('Please enter reason for declining registration.'),
                    Toast.DANGER
                );
            }
            if (requestId && declinePromise) {
                const result = await declineRequest(requestId, { reason: declineMessage });
                if (result) {
                    await getRequest(requestId);
                    Toast.show(
                        _(
                            'The request has been successfully declined. You may still approve the request if you change your mind.'
                        ),
                        Toast.NEUTRAL,
                        10
                    );
                    return toggleDeclineModal();
                }
            }
            throw new Error(_('An error occured while retrieving request!'));
        } catch (err) {
            Toast.show(getErrorMessage(err), Toast.DANGER, 10);
            console.log(err);
        }
    }, [declinePromise, declineRequest, declineMessage, requestId, toggleDeclineModal, getRequest]);

    const handleApproveRequest = useCallback(async () => {
        try {
            if (approvePromise && requestId) {
                const result = await approveRequest(requestId);
                if (result) {
                    await getRequest(requestId);
                    Toast.show(_('The request has been successfully approved!'), Toast.SUCCESS);
                    toggleApproveModal();
                    return getOrganizations();
                }
            }
            throw new Error(_('An error occured while retrieving request!'));
        } catch (err) {
            Toast.show(getErrorMessage(err), Toast.DANGER, 10);
            console.log(err);
        }
    }, [approvePromise, approveRequest, requestId, toggleApproveModal]);

    const handleEditorReady = useCallback((editor: any) => {
        editor.editing.view.change((writer: any) => {
            writer.setStyle('max-height', '40vh', editor.editing.view.document.getRoot());
            writer.setStyle('min-height', '7.75rem', editor.editing.view.document.getRoot());
            writer.setStyle('font-size', '0.875rem', editor.editing.view.document.getRoot());
            writer.setStyle('line-height', '1.57em', editor.editing.view.document.getRoot());
        });
    }, []);

    const renderApproveDescription = useCallback(() => {
        return (
            <p className={styles.descriptionText}>
                <Localize>
                    A link will be sent to
                    <span className={styles.descriptionEmail}>
                        {` ${requestObject?.email || ' '} `}
                    </span>
                    to create password.
                </Localize>
            </p>
        );
    }, [requestObject]);

    const renderDeclineDescription = useCallback(() => {
        return (
            <div className={styles.declineDescription}>
                <p className={styles.descriptionText}>
                    <Localize>Please enter the reason for declining the registration</Localize>
                </p>
                <Ckeditor
                    data={declineMessage}
                    onReady={handleEditorReady}
                    onChange={handleDeclineMessageChange}
                />
            </div>
        );
    }, []);

    return (
        <>
            <div className={styles.container}>
                <div className={styles.header}>
                    <h1 className={styles.title}>
                        <Localize>Review registration request</Localize>
                    </h1>
                </div>
                <main className={styles.content}>
                    <div className={styles.contentHeader}>
                        <h3 className={styles.contentHeaderTitle}>
                            <Localize>1. Organization Details</Localize>
                        </h3>
                    </div>
                    <div className={styles.contentBody}>
                        <div className={styles.contentBodyItem}>
                            <div className={styles.label}>
                                <Localize>Organization Name</Localize>
                            </div>
                            <div className={styles.value}>{requestOrganization.title}</div>
                        </div>
                        <div className={styles.contentBodyItem}>
                            <div className={styles.label}>
                                <Localize>Acronym</Localize>
                            </div>
                            <div className={styles.value}>{requestOrganization.acronym || '-'}</div>
                        </div>
                        <div className={styles.contentBodyItem}>
                            <div className={styles.label}>
                                <Localize>Organization Website</Localize>
                            </div>
                            <div className={styles.value}>
                                {requestOrganization.organizationWebsite || '-'}
                            </div>
                        </div>
                        <div className={styles.contentBodyItem}>
                            <div className={styles.label}>
                                <Localize>Organization Email</Localize>
                            </div>
                            <div className={styles.value}>
                                {requestOrganization.organizationEmail || '-'}
                            </div>
                        </div>
                        <div className={styles.contentBodyItem}>
                            <div className={styles.label}>
                                <Localize>Organization Logo</Localize>
                            </div>
                            <div className={styles.value}>
                                {requestOrganization.logo ? (
                                    <img
                                        className={styles.logo}
                                        src={requestOrganization.logo}
                                        alt={requestOrganization.title}
                                    />
                                ) : (
                                    '-'
                                )}
                            </div>
                        </div>
                        <div className={styles.contentBodyItem}>
                            <div className={styles.label}>
                                <Localize>Description</Localize>
                            </div>
                            <div className={cs(styles.value, styles.valueDescription)}>
                                <HTMLParser content={requestOrganization.description || '-'} />
                            </div>
                        </div>
                        <div className={styles.contentBodyItem}>
                            <div className={styles.label}>
                                <Localize>Organization Type</Localize>
                            </div>
                            <div className={styles.value}>
                                {requestOrganization.organizationType || '-'}
                            </div>
                        </div>
                        <div className={styles.contentBodyItem}>
                            <div className={styles.label}>
                                <Localize>Organization Letter</Localize>
                            </div>
                            <div className={styles.value}>
                                {requestOrganization.letter ? (
                                    <PDFDetails file={requestOrganization.letter} />
                                ) : (
                                    '-'
                                )}
                            </div>
                        </div>
                    </div>
                    <div className={styles.contentHeader}>
                        <h3 className={styles.contentHeaderTitle}>
                            <Localize>2. Focal Person Details</Localize>
                        </h3>
                    </div>
                    <div className={styles.contentBody}>
                        <div className={styles.contentBodyItem}>
                            <div className={styles.label}>
                                <Localize>Focal Person Name</Localize>
                            </div>
                            <div className={styles.value}>
                                {requestObject?.firstName || '-'} {requestObject?.lastName || ''}
                            </div>
                        </div>
                        <div className={styles.contentBodyItem}>
                            <div className={styles.label}>
                                <Localize>Focal Person Designation</Localize>
                            </div>
                            <div className={styles.value}>{requestObject?.designation || '-'}</div>
                        </div>
                        <div className={styles.contentBodyItem}>
                            <div className={styles.label}>
                                <Localize>Focal Person Email</Localize>
                            </div>
                            <div className={styles.value}>{requestObject?.email || '-'}</div>
                        </div>
                        <div className={styles.contentBodyItem}>
                            <div className={styles.label}>
                                <Localize>Focal Person Contact</Localize>
                            </div>
                            <div className={styles.value}>{requestObject?.phoneNumber || '-'}</div>
                        </div>
                    </div>
                </main>
                {requestObject && (
                    <div className={styles.footer}>
                        {requestObject.status !== 'accepted' && (
                            <Button
                                disabled={requestObject.status === 'rejected'}
                                tertiary
                                large
                                onClick={toggleDeclineModal}
                            >
                                {requestObject.status === 'rejected' ? _('Declined') : _('Decline')}
                            </Button>
                        )}
                        <Button
                            large
                            onClick={toggleApproveModal}
                            disabled={requestObject.status === 'accepted'}
                        >
                            {requestObject.status === 'accepted' ? _('Approved') : _('Approve')}
                        </Button>
                    </div>
                )}
            </div>
            <ConfirmModal
                isVisible={isDeclineVisible}
                decline
                titleText={_('Decline registration approval?')}
                onClose={toggleDeclineModal}
                renderDescription={renderDeclineDescription}
                onConfirm={handleDeclineRequest}
                confirmButtonProps={{ loading: declining }}
            />
            <ConfirmModal
                isVisible={isApproveVisible}
                titleText={_('Confirm registration approval?')}
                onClose={toggleApproveModal}
                renderDescription={renderApproveDescription}
                onConfirm={handleApproveRequest}
                confirmButtonProps={{ loading: approving }}
            />
        </>
    );
};

export default ReviewRegistration;
