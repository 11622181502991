import React, { useState, useMemo, useCallback } from 'react';
import DatePicker from 'react-datepicker';
import { format } from 'date-fns';

import { DateRangeDisplay } from '../DateRangeInput';

const noop = () => null;

interface DateInputProps {
    name?: string;
    onChange: (payload: { name?: string; value: string | null }) => void;
    showRequired?: boolean;
    placeholder?: string;
    defaultValue?: string;
}

const DateInput: React.FC<DateInputProps> = (props) => {
    const { name, defaultValue, placeholder, showRequired, onChange } = props;

    const initialValue = useMemo(() => {
        if (!defaultValue) {
            return null;
        }
        return new Date(defaultValue);
    }, [defaultValue]);

    const [selectedDate, setSelectedDate] = useState<Date | null>(initialValue);

    const showRequiredText = useMemo(() => {
        return showRequired && !selectedDate;
    }, [showRequired, selectedDate]);

    const handleDateChange = useCallback(
        (date: Date | null) => {
            setSelectedDate(date);
            if (date) {
                return onChange({ name, value: format(date, 'yyyy-MM-dd') });
            }
            onChange({ name, value: null });
        },
        [onChange, name]
    );

    return (
        <DatePicker
            selected={selectedDate}
            onChange={handleDateChange}
            popperPlacement="bottom"
            placeholderText={placeholder}
            customInput={<DateRangeDisplay showRequired={showRequiredText} />}
            onChangeRaw={noop}
        />
    );
};

export default DateInput;
