import React, { useMemo, useEffect } from 'react';
import { useLocation, matchPath, Routes, Route } from 'react-router-dom';

import DashboardContainer from 'components/DashboardContainer';
import FiltersProvider from 'components/Filters/FiltersContext';
import Layout from 'components/Layout';
import PrivateRoute from 'components/PrivateRoute';
import Toast from 'components/Toast';

import AccountSettings from 'containers/AccountSettings';
import Activities from 'containers/Activities';
import ActivityDetails from 'containers/ActivityDetails';
import Dashboard from 'containers/Dashboard';
import Error404 from 'containers/Error404';
import Home from 'containers/Home';
import LegalDocument from 'containers/LegalDocument';
import Login from 'containers/Login';
import Map from 'containers/Map';
import Notifications from 'containers/Notifications';
import OrganizationDetails from 'containers/OrganizationDetails';
import Organizations from 'containers/Organizations';
import ProjectDetails from 'containers/ProjectDetails';
import Projects from 'containers/Projects';
import RegionDetails from 'containers/RegionDetails';
import Register from 'containers/Register';
import RequestRegister from 'containers/RequestRegister';
import ReviewRegistration from 'containers/ReviewRegistration';

import { _ } from 'services/i18n';
import { useAppSelector } from 'hooks/store';

const doesMatchOneOfMultiplePaths = (pathname: string, paths: string[] = []) => {
    for (const path of paths) {
        if (matchPath({ path, end: true }, pathname)) {
            return true;
        }
    }
    return false;
};

const AppRoutes: React.FC = () => {
    const { pathname } = useLocation();

    const shouldFitContent = useMemo(() => {
        return doesMatchOneOfMultiplePaths(pathname, [
            '/map',
            '/projects',
            '/notifications',
            '/activities',
            '/activities/:activityId',
            '/account'
        ]);
    }, [pathname]);

    const { currentLanguage } = useAppSelector((state) => state.locale);
    const breadcrumbs = useMemo(
        () =>
            pathname
                .split('/')
                .filter(Boolean)
                .map((pth: string) => {
                    if (!isNaN(Number(pth))) {
                        return { title: _('Details'), path: pathname };
                    }
                    return { title: pth.charAt(0).toUpperCase() + pth.slice(1), path: pth };
                }),
        [pathname]
    );

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, [pathname]);

    return (
        <FiltersProvider>
            <Routes
                // Changing key re-renders entire router on language change.
                key={currentLanguage}
            >
                <Route path="/" element={<Layout />}>
                    <Route path="/" element={<Home />} />
                    <Route path="/login" element={<Login />} />
                    <Route path="/register" element={<Register />} />
                    <Route path="/request-register" element={<RequestRegister />} />
                    <Route path="/legal-document" element={<LegalDocument />} />
                </Route>
                <Route
                    path="/"
                    element={
                        <DashboardContainer
                            breadcrumbs={breadcrumbs}
                            fitHeight={shouldFitContent}
                        />
                    }
                >
                    <Route
                        path="dashboard"
                        element={
                            <PrivateRoute>
                                <Dashboard />
                            </PrivateRoute>
                        }
                    />
                    <Route path="map" element={<Map />} />
                    <Route path="map/details" element={<RegionDetails />} />
                    <Route
                        path="notifications"
                        element={
                            <PrivateRoute>
                                <Notifications />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="notifications/review-registration"
                        element={
                            <PrivateRoute>
                                <ReviewRegistration />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="account"
                        element={
                            <PrivateRoute>
                                <AccountSettings />
                            </PrivateRoute>
                        }
                    />
                    <Route path="organizations" element={<Organizations />} />
                    <Route path="organizations/:organizationId" element={<OrganizationDetails />} />
                    <Route path="projects" element={<Projects />} />
                    <Route path="projects/:projectId" element={<ProjectDetails />} />
                    <Route path="activities" element={<Activities />} />
                    <Route path="activities/:activityId" element={<ActivityDetails />} />
                </Route>
                <Route path="*" element={<Error404 />} />
            </Routes>
            <Toast />
        </FiltersProvider>
    );
};

export default AppRoutes;
