import React, { useRef, useState, useCallback, useMemo } from 'react';

import cs from '@ra/cs';

import styles from './styles.scss';

interface InfoSectionProps {
    children?: React.ReactNode;
    className?: string;
    headerClassName?: string;
    titleClassName?: string;
    title?: string;
    expandable?: boolean;
}

const InfoSection: React.FC<InfoSectionProps> = (props) => {
    const { children, className, headerClassName, titleClassName, title, expandable } = props;

    const content = useRef<HTMLDivElement>(null);
    const [isOpen, setOpen] = useState(true);

    const toggleExpand = useCallback(() => {
        setOpen((prev) => !prev);
    }, []);

    const contentStyle = useMemo(() => {
        if (content?.current && expandable) {
            return isOpen
                ? { maxHeight: `${content.current.scrollHeight}px` }
                : { maxHeight: '0px' };
        }
        return {};
    }, [isOpen]);

    return (
        <div className={className}>
            {expandable ? (
                <div className={headerClassName}>
                    <h3 className={titleClassName}>{title}</h3>
                    <span
                        className={cs(styles.expandIcon, 'material-symbols-rounded')}
                        onClick={toggleExpand}
                    >
                        {isOpen ? 'chevron_right' : 'expand_more'}
                    </span>
                </div>
            ) : (
                <h3 className={headerClassName}>{title}</h3>
            )}
            <div
                className={cs({ [styles.content]: expandable })}
                style={contentStyle}
                ref={content}
            >
                {children}
            </div>
        </div>
    );
};

export default InfoSection;
