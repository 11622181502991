import WHOIcon from 'assets/icons/who.svg';
import WHATIcon from 'assets/icons/what.svg';
import WHEREIcon from 'assets/icons/where.svg';
import WHENIcon from 'assets/icons/when.svg';

export const themesData = [
    { id: 1, title: 'Community based DRR' },
    { id: 2, title: 'Disaster Risk Management' },
    { id: 3, title: 'Early Warning' },
    { id: 4, title: 'Economics of DRR' },
    { id: 5, title: 'Urban DRR' },
    { id: 6, title: 'Livelihood' },
    { id: 7, title: 'Education' }
];

export const statsData = [
    {
        id: 1,
        icon: 'folder',
        title: 'Projects',
        key: 'projectCount',
        link: '/projects'
    },
    {
        id: 2,
        icon: 'workspaces',
        title: 'Organizations',
        key: 'organizationCount',
        link: '/organizations'
    },
    {
        id: 3,
        icon: 'volunteer_activism',
        title: 'Donors',
        key: 'donorCount',
        link: '/organizations'
    },
    {
        id: 4,
        icon: 'handshake',
        title: 'Partners',
        key: 'partnerCount',
        link: '/organizations'
    }
];

export const rolesData = [
    {
        id: 1,
        title: 'WHO',
        icon: WHOIcon,
        list: [
            { id: 1, title: 'Project Owners' },
            { id: 2, title: 'Project Partners' },
            { id: 3, title: 'Project Donors' }
        ]
    },
    {
        id: 2,
        title: 'WHAT',
        icon: WHATIcon,
        list: [
            { id: 1, title: 'Activities in line with Priority' },
            { id: 2, title: 'Actions of NDRRSAP 2018-2030' }
        ]
    },
    {
        id: 3,
        title: 'WHERE',
        icon: WHEREIcon,
        list: [
            { id: 1, title: 'National Level Support' },
            { id: 2, title: 'Province Level Support' },
            { id: 3, title: 'District Level Support' },
            { id: 4, title: 'Local Level Support' }
        ]
    },
    {
        id: 4,
        title: 'WHEN',
        icon: WHENIcon,
        list: [{ id: 1, title: 'Project Duration' }]
    }
];

export const stepsData = [
    {
        id: 1,
        icon: 'description',
        position: '01',
        title: 'Project',
        subTitle: 'Enter Project Detail',
        points: [
            { id: 1, title: 'Project Owners' },
            { id: 2, title: 'Project Partners' },
            { id: 3, title: 'Project Donors' }
        ]
    },
    {
        id: 2,
        icon: 'output',
        position: '02',
        title: 'Output',
        subTitle: 'Outputs under project that deliver to support the Priority Actions',
        points: [
            { id: 1, title: 'Output' },
            { id: 2, title: 'Priority Actions supported' }
        ]
    },
    {
        id: 3,
        icon: 'timeline',
        position: '03',
        title: 'Activity',
        subTitle: 'Activities under outputs',
        points: [
            { id: 1, title: 'Activity done' },
            { id: 2, title: 'Operational Budget for Activity' },
            { id: 3, title: 'Partner Organizations for Activity' },
            { id: 4, title: 'Delivery Location of Activity' }
        ]
    },
    {
        id: 4,
        icon: 'track_changes',
        position: '04',
        title: 'Target',
        subTitle: 'Targets are quantifiable indicators to monitor activities',
        points: [
            { id: 1, title: 'Target title' },
            { id: 2, title: 'Target value' }
        ]
    }
];
