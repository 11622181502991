import React, { useMemo, useState, useCallback } from 'react';
import { BarChart, Bar, XAxis, YAxis, ResponsiveContainer, Tooltip } from 'recharts';

import cs from '@ra/cs';
import { _, Localize } from 'services/i18n';
import { formatCurrency, formatNumber } from 'utils/formatter';

import OverviewDataItem from './OverviewItem';

import styles from './styles.scss';

const tooltipLabelFormatter = () => '';
const tooltipValueFormatter = (val: string, name: string) => {
    return name === 'amount' ? ['', formatCurrency(Number(val))] : (['', formatNumber(val)] as any);
};

interface CustomizedTickProps {
    x?: number | string;
    y?: number | string;
    stroke?: number;
    fontWeight?: number;
    payload?: {
        value: string;
    };
    format?: (value?: number) => void;
}

const CustomizedTick = ({ x, y, payload, fontWeight, format }: CustomizedTickProps) => {
    const value = useMemo(() => {
        if (!format) {
            if (payload?.value?.length && payload.value.length > 30) {
                return payload?.value?.substring(0, 30) + '...';
            }
            return payload?.value || '';
        }
        return format(Number(payload?.value));
    }, [format, payload]);

    return (
        <text x={x} y={y} className={styles.tickLabel} fontWeight={fontWeight} textAnchor="end">
            <>{value}</>
            <title>{payload?.value || ''}</title>
        </text>
    );
};

export type TopDivision = {
    name?: string;
    projects: number;
    amount: number;
};

export type OverviewDataType = {
    loading?: boolean;
    topData: TopDivision[];
    numDonors: string | number;
    totalProjects: number;
    totalBudget: number;
};

interface OverviewProps {
    data: OverviewDataType;
    className?: string;
    topDataCategory?: string;
    topDataKey: 'projects' | 'amount';
}

const Overview: React.FC<OverviewProps> = (props) => {
    const { data, className, topDataCategory, topDataKey } = props;

    const [isCollapsed, setCollapsed] = useState<boolean>(false);

    const maxYWidth = useMemo(() => {
        const maxVal =
            Math.max(...data.topData.map((dt) => dt?.name?.length || 0)) *
            (window.innerWidth < 568 ? 5 : 8);
        return Math.max(100, Math.min(maxVal, 220));
    }, [data, window.innerWidth]);

    const toggleCollapse = useCallback(() => setCollapsed((prev) => !prev), []);

    const totalData = useMemo(() => {
        return { projects: data.totalProjects, amount: data.totalBudget };
    }, [data]);

    return (
        <div
            className={cs(
                styles.overviewBox,
                {
                    [styles.overviewBoxCollapsed]: isCollapsed
                },
                className
            )}
        >
            <div className={styles.header}>
                <h4 className={styles.headerTitle}>
                    <Localize>Overview</Localize>
                </h4>
                <div
                    className={cs(styles.controlIconContainer, {
                        [styles.controlIconContainerCollapsed]: isCollapsed
                    })}
                    onClick={toggleCollapse}
                >
                    <span className={cs('material-symbols-rounded', styles.controlIcon)}>
                        {isCollapsed ? 'chevron_right' : 'chevron_left'}
                    </span>
                </div>
            </div>
            {!isCollapsed && (
                <>
                    <div className={styles.dataItems}>
                        <OverviewDataItem title={_('Projects')} value={totalData.projects} />
                        <OverviewDataItem
                            title={_('Budget')}
                            value={formatCurrency(totalData.amount)}
                        />
                        {/* TODO: Expense value
                        <OverviewDataItem title={_('Expense')} value="-" />
                          */}
                        <OverviewDataItem title={_('Donors')} value={data.numDonors} />
                    </div>
                    <div className={styles.topDataSection}>
                        <p className={styles.topDataSectionTitle}>
                            <Localize>Top</Localize> <Localize>{topDataCategory}</Localize>
                        </p>
                        {data.topData?.length > 0 ? (
                            <ResponsiveContainer
                                width="100%"
                                height={data.topData.slice(0, 5).length * 35 + 50}
                            >
                                <BarChart
                                    data={data.topData.slice(0, 5)}
                                    layout="vertical"
                                    barCategoryGap={5}
                                    margin={{ top: 0, right: 50, left: 0, bottom: 0 }}
                                >
                                    <XAxis
                                        type="number"
                                        orientation="top"
                                        axisLine={false}
                                        tickLine={false}
                                        allowDecimals={false}
                                        tick={
                                            <CustomizedTick
                                                format={
                                                    topDataKey === 'amount'
                                                        ? formatCurrency
                                                        : formatNumber
                                                }
                                                fontWeight={500}
                                            />
                                        }
                                    />
                                    <YAxis
                                        type="category"
                                        dataKey="name"
                                        width={maxYWidth}
                                        tick={<CustomizedTick fontWeight={400} />}
                                        tickLine={false}
                                        tickMargin={10}
                                        axisLine={{ stroke: '#D0D5DD' }}
                                    />
                                    <Tooltip
                                        cursor={{ opacity: 0.15 }}
                                        separator=""
                                        wrapperStyle={{ outline: 'none' }}
                                        contentStyle={{ padding: '0.25rem' }}
                                        itemStyle={{ color: 'black', fontSize: '0.75rem' }}
                                        formatter={tooltipValueFormatter}
                                        labelFormatter={tooltipLabelFormatter}
                                    />
                                    <Bar dataKey={topDataKey} fill="#FF8616" barSize={20} />
                                </BarChart>
                            </ResponsiveContainer>
                        ) : (
                            <p className={styles.emptyText}>
                                {data?.loading ? _('Loading...') : _('No data found')}
                            </p>
                        )}
                    </div>
                </>
            )}
        </div>
    );
};

export default Overview;
