import React from 'react';
import SVG, { Props as SVGProps } from 'react-inlinesvg';

import cs from '@ra/cs';

import ENGAsset from 'assets/icons/ENG.svg';
import NEPAsset from 'assets/icons/NEP.svg';

import styles from './styles.scss';

interface ApprovedIconProps {
    containerClassName?: string;
    className?: string;
}

interface NotificationIconProps extends ApprovedIconProps {
    numActiveNotifications: number;
}

export const ApprovedIcon: React.FC<ApprovedIconProps> = (props: ApprovedIconProps) => {
    const { containerClassName, className } = props;

    return (
        <div className={cs(styles.iconContainer, styles.iconContainerApproved, containerClassName)}>
            <span className={cs('material-symbols-rounded', styles.approvedIcon, className)}>
                check_circle
            </span>
        </div>
    );
};

export const DeclineIcon: React.FC<ApprovedIconProps> = (props) => {
    const { containerClassName, className } = props;

    return (
        <div className={cs(styles.iconContainer, styles.iconContainerDecline, containerClassName)}>
            <span className={cs('material-symbols-rounded', styles.declineIcon, className)}>
                not_interested
            </span>
        </div>
    );
};

export const ENGIcon: React.FC = (props: Omit<SVGProps, 'src'>) => {
    return <SVG src={ENGAsset} {...props} />;
};

export const NEPIcon: React.FC = (props: Omit<SVGProps, 'src'>) => {
    return <SVG src={NEPAsset} {...props} />;
};

export const NotificationIcon: React.FC<NotificationIconProps> = (props: NotificationIconProps) => {
    const { className, containerClassName, numActiveNotifications } = props;

    return (
        <div className={cs(styles.notificationIconContainer, containerClassName)}>
            {numActiveNotifications > 0 && (
                <div className={styles.activeNotifications}>{numActiveNotifications}</div>
            )}
            <span className={cs('material-symbols-rounded', styles.notificationIcon, className)}>
                notifications
            </span>
        </div>
    );
};
