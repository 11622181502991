import React from 'react';
import { Link } from 'react-router-dom';

import Button from 'components/Button';
import Container from 'components/Container';
import NavBar from 'components/NavBar';

import { Localize } from 'services/i18n';

import error404Image from 'assets/images/error404.svg';

import styles from './styles.scss';

const Error404: React.FC = () => {
    return (
        <main className={styles.container}>
            <NavBar />
            <Container className={styles.content}>
                <div className={styles.infoSection}>
                    <h1 className={styles.title}>
                        <Localize>Oops!</Localize>
                    </h1>
                    <h2 className={styles.subTitle}>
                        <Localize>Page Not Found</Localize>
                    </h2>
                    <p className={styles.description}>
                        <Localize>This page doesn't exist or was removed.</Localize>
                    </p>
                    <p className={styles.description}>
                        <Localize>We suggest you go back to the home page.</Localize>
                    </p>
                    <div className={styles.homeLink}>
                        <Link to="/">
                            <Button secondary large>
                                <Localize>Back to Home</Localize>
                            </Button>
                        </Link>
                    </div>
                </div>
                <img className={styles.errorImage} src={error404Image} />
            </Container>
        </main>
    );
};

export default Error404;
