import React, { useCallback, useState } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

import AppRoutes from 'routes';
import store, { persistor } from 'store';

import LocalizeProvider from '@ra/components/I18n';
import SyncLocaleStore from 'components/SyncLocaleStore';
import { languages, objTranslator, translations } from 'services/i18n';
import { initializeData } from 'services/bootstrap';

import 'styles/_base.scss';

function App() {
    const [initialLang, setInitialLang] = useState('ENG');

    const handleInitialize = useCallback(async () => {
        const {
            locale: { currentLanguage }
        } = store.getState();
        if (currentLanguage) {
            setInitialLang(currentLanguage);
        }
        await initializeData();
    }, []);

    return (
        <Provider store={store}>
            <PersistGate persistor={persistor} onBeforeLift={handleInitialize}>
                <LocalizeProvider
                    translations={translations}
                    languages={languages}
                    defaultLanguage={initialLang}
                    keyTranslator={objTranslator}
                >
                    <SyncLocaleStore>
                        <BrowserRouter>
                            <AppRoutes />
                        </BrowserRouter>
                    </SyncLocaleStore>
                </LocalizeProvider>
            </PersistGate>
        </Provider>
    );
}

export default App;
