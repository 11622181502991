import React, { useState, useMemo, useCallback, useEffect, useRef } from 'react';
import { useNavigate, Link, useSearchParams } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import { format } from 'date-fns';

import Button from 'components/Button';
import BubbleChart from 'components/Charts/BubbleChart';
import PieChart from 'components/Charts/PieChart';
import DateRangePickerButton from 'components/DateRangePickerButton';
import DropdownSelector from 'components/DropdownSelector';
import Filters from 'components/Filters';
import FilterBudgetSlider from 'components/Filters/FilterBudgetSlider';
import FilterThemes from 'components/Filters/FilterThemes';
import FilterHazards from 'components/Filters/FilterHazards';
import FilterProjectStatuses from 'components/Filters/FilterProjectStatuses';
import FilterRegionSelector from 'components/Filters/FilterRegionSelector';
import Map from 'components/Map';
import ProjectTable, { getDefaultProjectColumns } from 'components/ProjectTable';

import cs from '@ra/cs';
import Api from 'services/api';
import useAdministrativeDivisions from 'hooks/useAdministrativeDivisions';
import { Localize, _, objTranslator } from 'services/i18n';
import { debounce } from '@ra/utils';
import { formatNumber } from 'utils/formatter';
import { selectPriorityAreas } from 'store/selectors/priorityIndicator';

import { useAppSelector } from 'hooks/store';
import usePromise from '@ra/hooks/usePromise';
import { useFilters, useFiltersDispatch } from 'hooks/filters';

import type { Project, Region, Stats, MPTTStats } from 'services/types';

import styles from './styles.scss';

interface StatItemProps {
    icon: string;
    label: string;
    value: number;
}

const StatItem: React.FC<StatItemProps> = (props) => {
    const { icon, label, value } = props;

    return (
        <div className={styles.statItem}>
            <div className={styles.statItemIconContainer}>
                <span className={cs('material-symbols-rounded', styles.statItemIcon)}>{icon}</span>
            </div>
            <p className={styles.statItemValue}>{formatNumber(value) ?? '-'}</p>
            <p className={styles.statItemLabel}>{label}</p>
        </div>
    );
};

const RegionDetails: React.FC = () => {
    const filterRef = useRef<HTMLDivElement>(null);

    const filtersDispatch = useFiltersDispatch();
    const filters = useFilters();

    const navigate = useNavigate();

    const [searchParams, setSearchParams] = useSearchParams();

    const projectColumns = useMemo(() => {
        const defaultColumns = getDefaultProjectColumns();
        defaultColumns.splice(2, 0, {
            Header: _('BUDGET (NPR)'),
            accessor: 'budget'
        });
        return defaultColumns.filter((col) => col.accessor !== 'id');
    }, []);

    const { organizations } = useAppSelector((state) => state.organization);
    const priorityAreas = useAppSelector((state) => selectPriorityAreas(state));
    const regions = useAppSelector((state) => state.region.regions);

    const regionFilters = useMemo(() => {
        return filters.filter((el) => el.filterType.startsWith('Region-'));
    }, [filters]);

    const regionFiltersNonLocation = useMemo(() => {
        return regionFilters.filter((el) => el.filterType !== 'Region-Region');
    }, [regionFilters]);

    const handleClearFilters = useCallback(() => {
        setDateRange([null, null]);
        filtersDispatch({ type: 'clear', category: 'Region-' });
    }, [filtersDispatch]);

    const focusedRegion = useMemo(() => {
        const regionData = regionFilters.find((fil) => fil.filterType === 'Region-Region')
            ?.filterValue as Region;
        if (regionData) {
            searchParams.set('region', String(regionData.id));
            setSearchParams(searchParams);
            return regionData;
        }
        const regionFromParams = regions.find(
            (reg) => reg.id === Number(searchParams.get('region'))
        ) as Region;
        if (regionFromParams) {
            filtersDispatch({
                type: 'add',
                filter: {
                    filterType: 'Region-Region',
                    filterValue: regionFromParams,
                    query: { location: regionFromParams.id }
                }
            });
        }
        return regionFromParams;
    }, [regions, regionFilters, searchParams, filtersDispatch]);

    useEffect(() => {
        if (!focusedRegion?.id && !searchParams.get('region')) {
            navigate('/dashboard');
        }
    }, [focusedRegion, navigate, searchParams]);

    const divisionLevels = useAdministrativeDivisions();

    const activeDivisionLevel = useMemo(() => {
        if (focusedRegion) {
            return divisionLevels[Math.min(focusedRegion.level + 1, 4)];
        }
        return divisionLevels[1];
    }, [divisionLevels, focusedRegion]);

    const filterQueries = useMemo(() => {
        const queries = regionFilters.reduce(
            (acc, cur) => {
                if (cur.query?.hazards__in) {
                    const addStr = acc.hazards__in
                        ? `,${cur.query.hazards__in}`
                        : cur.query.hazards__in;
                    acc.hazards__in += addStr;
                    return acc;
                }
                if (!cur.filterType.includes('-Status')) {
                    Object.assign(acc, cur.query);
                    return acc;
                }
                if (cur.query?.status__in) {
                    const addStr = acc.status__in
                        ? `,${cur.query.status__in}`
                        : cur.query.status__in;
                    acc.status__in += addStr;
                    return acc;
                }
                return acc;
            },
            { status__in: '', hazards__in: '' }
        );
        return queries;
    }, [regionFilters]);

    const [{ result: countStats }, getCountStats] = usePromise(Api.getProjectCounts);
    const loadCountStats = useCallback(debounce(getCountStats, 1000), [getCountStats]);
    useEffect(() => {
        if (focusedRegion?.id) {
            loadCountStats(filterQueries);
        }
    }, [getCountStats, filterQueries, focusedRegion]);

    const [projectOrdering, setProjectOrdering] = useState<string>('-budget');
    const handleSortProjects = useCallback(setProjectOrdering, []);

    const [{ loading, result, error }, getProjects] = usePromise(Api.getProjects);
    const loadProjects = useCallback(debounce(getProjects, 1000), [getProjects]);
    useEffect(() => {
        if (focusedRegion?.id) {
            const queryObj = { ...filterQueries, limit: 5 };
            if (projectOrdering) {
                Object.assign(queryObj, { ordering: projectOrdering });
            }
            loadProjects(queryObj);
        }
    }, [loadProjects, focusedRegion, projectOrdering, filterQueries]);
    const projectData: Project[] = useMemo(() => result?.results || [], [result]);

    const [{ result: statResult }, getMapStats] = usePromise(Api.getLocationStats);
    const loadMapStats = useCallback(debounce(getMapStats, 1000), [getMapStats]);
    useEffect(() => {
        if (focusedRegion?.id) {
            loadMapStats(filterQueries);
        }
    }, [loadMapStats, filterQueries, focusedRegion]);
    const mapData = useMemo(() => {
        return statResult || [];
    }, [statResult]);

    const [{ result: organizationStatResult }, getOrganizationStats] = usePromise(
        Api.getOrganizationStats
    );
    const loadOrganizationStats = useCallback(debounce(getOrganizationStats, 1000), [
        getOrganizationStats
    ]);
    useEffect(() => {
        if (focusedRegion?.id) {
            loadOrganizationStats(filterQueries);
        }
    }, [loadOrganizationStats, filterQueries, focusedRegion]);
    const organizationStatData = useMemo(() => {
        return (
            organizationStatResult
                ?.map((st: Stats, idx: number) => {
                    const organizationTitle =
                        organizations.find((org) => org.id === Number(st.identifier))?.title || '-';
                    return {
                        id: idx + 1,
                        organization: organizationTitle,
                        value: Number(st.budget)
                    };
                })
                ?.sort((a: { value: number }, b: { value: number }) => b.value - a.value)
                ?.slice(0, 12) || []
        );
    }, [organizationStatResult, organizations]);

    const [{ result: categoryStatResult }, getCategoryStats] = usePromise(
        Api.getPriorityIndicatorStats
    );
    const loadCategoryStats = useCallback(debounce(getCategoryStats, 1000), [getCategoryStats]);
    useEffect(() => {
        if (focusedRegion?.id) {
            loadCategoryStats(filterQueries);
        }
    }, [loadCategoryStats, filterQueries, focusedRegion]);
    const categoryStatsData: { name: string; value: number }[] = useMemo(() => {
        const categoryStats = categoryStatResult?.filter((st: MPTTStats) => st.level === 0) || [];
        const totalBudget = categoryStats.reduce(
            (acc: number, cur: MPTTStats) => ((acc += Number(cur.budget)), acc),
            0
        );
        return categoryStats
            .map((st: MPTTStats) => {
                const categoryTitle =
                    priorityAreas.find((pa) => pa.id === Number(st.identifier))?.title || '-';
                return {
                    name: categoryTitle,
                    value: (Number(st.budget) / totalBudget) * 100
                };
            })
            .slice(0, 4);
    }, [categoryStatResult, priorityAreas]);

    const spendingDataSorted = useMemo(() => {
        return categoryStatsData.sort((a, b) => b.value - a.value);
    }, [categoryStatsData]);

    const [dateRange, setDateRange] = useState<[Date | null, Date | null]>([null, null]);
    const [startDate, endDate] = useMemo(() => dateRange, [dateRange]);

    const handleDateChange = useCallback(
        (date: [Date | null, Date | null]) => {
            if (date[0]) {
                filtersDispatch({
                    type: 'add',
                    filter: {
                        filterType: 'Region-From',
                        filterValue: date[0],
                        query: { date_from__gte: format(date[0], 'yyyy-MM-dd') }
                    }
                });
            } else if (dateRange[0]) {
                filtersDispatch({
                    type: 'remove',
                    filter: { filterType: 'Region-From', filterValue: dateRange[0] }
                });
            }
            if (date[1]) {
                filtersDispatch({
                    type: 'add',
                    filter: {
                        filterType: 'Region-To',
                        filterValue: date[1],
                        query: { date_to__lte: format(date[1], 'yyyy-MM-dd') }
                    }
                });
            } else if (dateRange[1]) {
                filtersDispatch({
                    type: 'remove',
                    filter: { filterType: 'Region-To', filterValue: dateRange[1] }
                });
            }
            setDateRange(date);
        },
        [filtersDispatch, dateRange]
    );

    const handleAllProjects = useCallback(() => {
        localStorage.setItem('activeProjectsTab', 'all');
        filtersDispatch({
            type: 'set',
            filters: [
                {
                    filterType: 'Project-Region',
                    filterValue: focusedRegion,
                    query: { location: focusedRegion.id }
                }
            ],
            category: 'Project'
        });
    }, [filtersDispatch, focusedRegion]);

    return (
        <div className={styles.container}>
            <div className={styles.filterHeader}>
                <h3 className={styles.filterTitle}>
                    <Localize>Filters</Localize>
                </h3>
                {regionFiltersNonLocation.length > 0 && (
                    <p className={styles.clearLink} onClick={handleClearFilters}>
                        <Localize>Clear all</Localize>
                        {` (${formatNumber(regionFiltersNonLocation.length)})`}
                    </p>
                )}
            </div>
            <Filters className={styles.filters} ref={filterRef}>
                <FilterRegionSelector filterType="Region-Region" isClearable={false} disableWard />
                <div className={styles.dateFilterContainer}>
                    <DatePicker
                        selectsRange
                        startDate={startDate}
                        endDate={endDate}
                        onChange={handleDateChange}
                        popperPlacement="bottom-start"
                        popperClassName={styles.datePopper}
                        customInput={
                            <DateRangePickerButton startDate={startDate} endDate={endDate} />
                        }
                        isClearable
                        showYearDropdown
                    />
                </div>
                <DropdownSelector title={_('Budget')} filterType="Region-Budget" icon="payments">
                    <FilterBudgetSlider
                        showTitle
                        filterType="Region-Budget"
                        className={styles.filterBudget}
                        activeClassName={styles.filterBudgetActive}
                    />
                </DropdownSelector>
                <DropdownSelector
                    title={_('Status')}
                    filterType="Region-Status"
                    icon="monitor_heart"
                >
                    <FilterProjectStatuses
                        filterType="Region-Status"
                        className={styles.filterItemList}
                    />
                </DropdownSelector>
                <FilterThemes filterType="Project-Theme" />
                <DropdownSelector title={_('Hazard(s)')} filterType="Region-Hazard" icon="warning">
                    <FilterHazards filterType="Region-Hazard" className={styles.filterItemList} />
                </DropdownSelector>
            </Filters>
            <div className={styles.content}>
                <div className={styles.contentGroup}>
                    <div className={cs(styles.contentItem, styles.contentItemMap)}>
                        <Map
                            activeDivisionLevel={activeDivisionLevel}
                            mapData={mapData}
                            style={{ width: '100%', height: 592 }}
                            focusedRegion={focusedRegion}
                        />
                    </div>
                    <div className={cs(styles.contentItem, styles.contentItemStat)}>
                        <h3
                            className={cs(styles.contentItemTitle, styles.contentItemTitleLocation)}
                        >
                            {objTranslator(focusedRegion, 'title') || '-'}
                        </h3>
                        <div className={styles.stats}>
                            <StatItem
                                icon="folder"
                                label={_('PROJECTS')}
                                value={countStats?.projectCount}
                            />
                            <StatItem
                                icon="workspaces"
                                label={_('ORGANIZATIONS')}
                                value={countStats?.organizationCount}
                            />
                            <StatItem
                                icon="volunteer_activism"
                                label={_('DONORS')}
                                value={countStats?.donorCount}
                            />
                            <StatItem
                                icon="handshake"
                                label={_('PARTNERS')}
                                value={countStats?.partnerCount}
                            />
                        </div>
                    </div>
                </div>
                <div className={styles.contentGroup}>
                    <div className={styles.contentItem}>
                        <h4 className={styles.contentItemTitle}>
                            <Localize>Organization budget</Localize>
                        </h4>
                        {organizationStatData.length > 0 ? (
                            <div className={styles.chartContainer}>
                                <BubbleChart data={organizationStatData} label="organization" />
                            </div>
                        ) : (
                            <p className={styles.infoText}>
                                <Localize>No data found!</Localize>
                            </p>
                        )}
                    </div>
                    <div className={styles.contentItem}>
                        <h4 className={styles.contentItemTitle}>
                            <Localize>Spending by category</Localize>
                        </h4>
                        {spendingDataSorted.length > 0 ? (
                            <div className={styles.chartContainer}>
                                <PieChart data={spendingDataSorted} />
                            </div>
                        ) : (
                            <p className={styles.infoText}>
                                <Localize>No data found!</Localize>
                            </p>
                        )}
                    </div>
                </div>
                <div className={styles.contentItemTable}>
                    <h4 className={styles.tableTitle}>
                        <Localize>Projects with largest budget</Localize>
                    </h4>
                    <div className={styles.tableContainer}>
                        <ProjectTable
                            className={styles.table}
                            headerClassName={styles.tableHeader}
                            loading={(!result && !error) || loading}
                            data={projectData}
                            columns={projectColumns}
                            onSort={handleSortProjects}
                            ordering={projectOrdering}
                        />
                    </div>
                    <div className={styles.buttonContainer}>
                        <Link to="/projects" onClick={handleAllProjects}>
                            <Button large>
                                <Localize>View all projects</Localize>
                            </Button>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default RegionDetails;
