import React, { useCallback, useMemo } from 'react';

import SliderInput from '@ra/components/Form/SliderInput';

import cs from '@ra/cs';
import { Localize } from 'services/i18n';
import { formatCurrency } from 'utils/formatter';

import { useFilters, useFiltersDispatch, FilterItemType } from 'hooks/filters';

import styles from './styles.scss';

const budgetValueExtractor = (value: number) => formatCurrency(value);

const budgetRangeValueExtractor = (payload: FilterItemType) => {
    if (!payload.filterValue) {
        return '';
    }
    const budgetRange = payload.filterValue as number[];

    return `Budget: ${budgetValueExtractor(budgetRange[0])} - ${budgetValueExtractor(
        budgetRange[1]
    )}`;
};

interface FilterBudgetSliderProps {
    className?: string;
    activeClassName?: string;
    showTitle?: boolean;
    filterType: string;
}

const FilterBudgetSlider: React.FC<FilterBudgetSliderProps> = ({
    className,
    activeClassName,
    showTitle,
    filterType
}) => {
    const dispatch = useFiltersDispatch();
    const filters = useFilters();

    const budgetFilterValue = useMemo(() => {
        const filterItem = filters.find((el) => el.filterType === filterType);
        if (!filterItem) {
            return null;
        }
        return filterItem.filterValue as unknown as number[];
    }, [filters, filterType]);

    const handleBudgetValueChange = useCallback(
        ({ value }: { value: number[] }) => {
            const filterValue = value.map((val) => Number(val.toFixed(2)));
            dispatch({
                type: 'add',
                filter: {
                    filterType,
                    filterValue,
                    valueExtractor: budgetRangeValueExtractor,
                    query: { budget__gte: filterValue[0], budget__lte: filterValue[1] }
                }
            });
        },
        [dispatch, filterType]
    );

    const handleClearFilter = useCallback(() => {
        dispatch({
            type: 'remove',
            filter: { filterType, filterValue: budgetFilterValue as number[] }
        });
    }, [dispatch, filterType, budgetFilterValue]);

    const renderSliderMark = useCallback(
        ({ item }: { item: string }) => <span className={styles.budgetSliderMark}>{item}</span>,
        []
    );

    return (
        <div
            className={cs(
                className,
                activeClassName && { [activeClassName]: Boolean(budgetFilterValue) }
            )}
        >
            {showTitle && (
                <h5 className={styles.title}>
                    <Localize>Budget</Localize>
                </h5>
            )}
            <SliderInput
                containerClassName={cs(styles.budgetSlider, {
                    [styles.budgetSliderActive]: Boolean(budgetFilterValue)
                })}
                inputRange={[0, 1000000000]}
                step={10000000}
                marks={['Rs.0', 'Rs.1ar.']}
                renderMark={renderSliderMark}
                thumbStyle={{
                    backgroundColor: 'white',
                    borderColor: '#4a54d3',
                    borderWidth: 2,
                    width: '1.25rem',
                    height: '1.25rem'
                }}
                value={budgetFilterValue || [0, 0]}
                onChange={handleBudgetValueChange}
                showTooltip
                tooltipClassName={styles.budgetTooltip}
                tooltipValueExtractor={budgetValueExtractor}
                isRangeInput
            />
            {Boolean(budgetFilterValue) && (
                <p
                    className={cs(styles.clearLink, {
                        [styles.clearLinkNoSpacing]: !activeClassName
                    })}
                    onClick={handleClearFilter}
                >
                    Clear
                </p>
            )}
        </div>
    );
};

export default FilterBudgetSlider;
