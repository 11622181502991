import React, { useRef, useCallback, useState, useMemo, useEffect } from 'react';
import useResizeObserver from '@react-hook/resize-observer';
import DatePicker from 'react-datepicker';
import { format } from 'date-fns';

import ActivitiesTable from 'components/ActivitiesTable';
import Button from 'components/Button';
import DateRangePickerButton from 'components/DateRangePickerButton';
import Filters from 'components/Filters';
import FilterOrganizations from 'components/Filters/FilterOrganizations';
import FilterPriorityIndicators from 'components/Filters/FilterPriorityIndicators';
import FilterRegionSelector from 'components/Filters/FilterRegionSelector';
import Pagination, { PageChangeCallback } from '@ra/components/Pagination';
import SelectInput, {
    SelectInputChangeCallback,
    ValueExtractor,
    KeyExtractor
} from '@ra/components/Form/SelectInput';

import Api from 'services/api';
import cs from '@ra/cs';
import { Localize } from 'services/i18n';
import { selectPriorityActivities } from 'store/selectors/priorityIndicator';
import { debounce } from '@ra/utils';

import { useFilters, useFiltersDispatch } from 'hooks/filters';
import { useAppSelector } from 'hooks/store';
import usePromise from '@ra/hooks/usePromise';

import { maxRowsOptions, type MaxRowOption } from 'containers/Projects';

import type { MPTTStats } from 'services/types';

import 'react-datepicker/dist/react-datepicker.css';

import styles from './styles.scss';

const keyExtractor: KeyExtractor<MaxRowOption> = (item: MaxRowOption) => item.value;
const labelExtractor: ValueExtractor<MaxRowOption, string> = (item) => item.label;

const Activities: React.FC = () => {
    const priorityActivities = useAppSelector((state) => selectPriorityActivities(state));

    const filtersDispatch = useFiltersDispatch();
    const activityFilters = useFilters('Activity');

    const containerRef = useRef<HTMLDivElement>(null);
    const headerRef = useRef<HTMLDivElement>(null);
    const filterRef = useRef<HTMLDivElement>(null);
    const footerRef = useRef<HTMLDivElement>(null);

    const [filtersActive, setFiltersActive] = useState<boolean>(Boolean(activityFilters.length));
    const toggleFilters = useCallback(() => {
        setFiltersActive((prev) => !prev);
    }, []);

    const [page, setPage] = useState<number>(1);
    const [maxRows, setMaxRows] = useState<MaxRowOption>(maxRowsOptions[0]);

    const handleClearFilters = useCallback(() => {
        setDateRange([null, null]);
        filtersDispatch({ type: 'clear', category: 'Activity' });
    }, [filtersDispatch]);

    const [activityOrdering, setActivityOrdering] = useState<string>('');
    const handleSortActivities = useCallback(setActivityOrdering, []);

    const [dateRange, setDateRange] = useState<[Date | null, Date | null]>([null, null]);
    const [startDate, endDate] = useMemo(() => dateRange, [dateRange]);

    const [contentStyle, setContentStyle] = useState<React.CSSProperties>({});

    const [{ loading, result, error }, getPriorityIndicatorStats] = usePromise(
        Api.getPriorityIndicatorStats
    );
    const loadPriorityActivities = useCallback(debounce(getPriorityIndicatorStats, 1000), [
        getPriorityIndicatorStats
    ]);

    useEffect(() => {
        const filterQueries = activityFilters.reduce((acc, cur) => {
            Object.assign(acc, cur.query);
            return acc;
        }, {});
        if (activityOrdering) {
            Object.assign(filterQueries, { ordering: activityOrdering });
        }
        loadPriorityActivities(filterQueries);
    }, [loadPriorityActivities, activityFilters, activityOrdering, maxRows, page]);

    const [dataLoading, setDataLoading] = useState<boolean>(true);

    const totalDataRef = useRef<number>();
    const tableData = useMemo(() => {
        const activitiesData =
            result
                ?.filter((stat: MPTTStats) => stat.level === 2)
                .map((statItem: MPTTStats) => {
                    const priorityActivity = priorityActivities.find(
                        (pa) => pa.id === statItem.identifier
                    );
                    return {
                        id: priorityActivity?.id || '',
                        title: priorityActivity?.title || '',
                        budget: statItem.budget,
                        projectCount: statItem.count
                    };
                }) || [];
        if ((!result && !error) || loading) {
            setDataLoading(true);
        } else {
            setDataLoading(false);
        }
        if (activityFilters.length > 0 || activitiesData.length > 0) {
            totalDataRef.current = activitiesData.length;
        }
        return activitiesData;
    }, [result, priorityActivities, loading, activityFilters]);

    const handleDateChange = useCallback(
        (date: [Date | null, Date | null]) => {
            if (date[0]) {
                filtersDispatch({
                    type: 'add',
                    filter: {
                        filterType: 'Activity-From',
                        filterValue: date[0],
                        query: { date_from__gte: format(date[0], 'yyyy-MM-dd') }
                    }
                });
            } else if (dateRange[0]) {
                filtersDispatch({
                    type: 'remove',
                    filter: { filterType: 'Activity-From', filterValue: dateRange[0] }
                });
            }
            if (date[1]) {
                filtersDispatch({
                    type: 'add',
                    filter: {
                        filterType: 'Activity-To',
                        filterValue: date[1],
                        query: { date_to__lte: format(date[1], 'yyyy-MM-dd') }
                    }
                });
            } else if (dateRange[1]) {
                filtersDispatch({
                    type: 'remove',
                    filter: { filterType: 'Activity-To', filterValue: dateRange[1] }
                });
            }
            setDateRange(date);
        },
        [filtersDispatch, dateRange]
    );

    const handlePageChange: PageChangeCallback = useCallback(({ currentPage }) => {
        setDataLoading(true);
        setPage(currentPage);
    }, []);
    const handleMaxRowsChange: SelectInputChangeCallback<MaxRowOption> = useCallback(
        ({ option }) => {
            if (option && option.value !== maxRows.value) {
                setDataLoading(true);
                setPage(1);
                setMaxRows(option);
            }
        },
        [maxRows.value]
    );

    const handleContainerResize = useCallback(() => {
        if (containerRef?.current && headerRef?.current && footerRef?.current) {
            let tableHeight =
                containerRef.current.offsetHeight -
                headerRef.current.offsetHeight -
                footerRef.current.offsetHeight;
            let spacingHeight = 2.875;
            if (filterRef?.current) {
                tableHeight -= filterRef.current.offsetHeight;
                spacingHeight += 1;
            }
            setContentStyle({
                maxHeight: `calc(${tableHeight}px - ${spacingHeight}rem)`
            });
        }
    }, []);
    useResizeObserver(containerRef, handleContainerResize);

    return (
        <div className={styles.container} ref={containerRef}>
            <header className={styles.header} ref={headerRef}>
                <h1 className={styles.title}>
                    <Localize>NDRRSAP Activity List</Localize>
                </h1>
                <div className={styles.buttons}>
                    {activityFilters.length > 0 && (
                        <p className={styles.clearLink} onClick={handleClearFilters}>
                            <Localize>Clear all</Localize>
                        </p>
                    )}
                    <Button
                        small
                        secondary
                        leftIcon="filter_alt"
                        onClick={toggleFilters}
                        className={cs({ [styles.filterButtonActive]: filtersActive })}
                    >
                        <Localize>
                            Filters{activityFilters.length > 0 && ` (${activityFilters.length})`}
                        </Localize>
                    </Button>
                </div>
            </header>
            {filtersActive && (
                <Filters ref={filterRef} className={styles.filters}>
                    <div className={styles.dateFilterContainer}>
                        <DatePicker
                            selectsRange
                            startDate={startDate}
                            endDate={endDate}
                            onChange={handleDateChange}
                            popperPlacement="bottom"
                            popperClassName={styles.datePopper}
                            customInput={
                                <DateRangePickerButton startDate={startDate} endDate={endDate} />
                            }
                            isClearable
                            showYearDropdown
                        />
                    </div>
                    <FilterOrganizations filterType="Activity-Organization" />
                    <FilterRegionSelector filterType="Activity-Region" />
                    <FilterPriorityIndicators filterType="Activity-PriorityIndicator" />
                </Filters>
            )}
            <main className={styles.tableContainer} style={contentStyle}>
                <ActivitiesTable
                    data={tableData.slice((page - 1) * maxRows.value, page * maxRows.value)}
                    loading={dataLoading}
                    onSort={handleSortActivities}
                    ordering={activityOrdering}
                    headerClassName={cs(styles.tableHeader, {
                        [styles.tableHeaderStatic]: filtersActive
                    })}
                    maxRows={maxRows.value}
                />
            </main>
            <footer ref={footerRef} className={styles.footer}>
                <div className={styles.maxRowsSelect}>
                    <Localize>Show</Localize>
                    <SelectInput
                        className={styles.select}
                        controlClassName={styles.selectControl}
                        options={maxRowsOptions}
                        keyExtractor={keyExtractor}
                        valueExtractor={labelExtractor}
                        onChange={handleMaxRowsChange}
                        value={maxRows}
                        defaultValue={maxRowsOptions[0]}
                        clearable={false}
                        searchable={false}
                        optionsDirection="up"
                    />
                </div>
                <Pagination
                    showControlIcons
                    controlIconClassName={styles.paginationControl}
                    className={styles.pagination}
                    pageItemClassName={styles.paginationItem}
                    activePageItemClassName={styles.paginationItemActive}
                    onChange={handlePageChange}
                    totalRecords={totalDataRef.current}
                    pageNeighbours={1}
                    pageLimit={maxRows.value}
                    pageNum={page}
                />
            </footer>
        </div>
    );
};

export default Activities;
