import { useState, useCallback } from 'react';

import AuthInput from 'components/Input/AuthInput';
import Button from 'components/Button';

import cs from '@ra/cs';
import Modal from '@ra/components/Modal';
import Form, { FormSubmitCallback, InputField } from '@ra/components/Form';
import withVisibleCheck from '@ra/components/WithVisibleCheck';
import usePromise from '@ra/hooks/usePromise';

import { Localize, _ } from 'services/i18n';
import Api from 'services/api';
import Toast from 'services/toast';

import { useAppSelector } from 'hooks/store';

import { AuthModalProps } from '..';

import styles from './styles.scss';

type SendEmailProps = AuthModalProps & {
    onShowVerifyEmail: () => void;
    isNewPassword?: boolean;
    defaultEmail?: string;
};

const SendEmail: React.FC<SendEmailProps> = (props) => {
    const { defaultEmail, setEmail, onClose, onShowVerifyEmail, isNewPassword } = props;

    const { isAuthenticated, user } = useAppSelector((state) => state.auth);

    const [error, setError] = useState<any>(null);

    const [{ loading }, forgotPassword] = usePromise(Api.forgotPassword);

    const handleSendEmail: FormSubmitCallback = useCallback(
        async (formData) => {
            try {
                const result = await forgotPassword({
                    ...formData,
                    mailFormat: isNewPassword ? 'new' : 'reset'
                });
                if (result) {
                    Toast.show(
                        isNewPassword
                            ? _('OTP sent to your registered email')
                            : `OTP sent to your mail: ${formData.username}`,
                        Toast.SUCCESS
                    );
                    onShowVerifyEmail();
                    setEmail(formData.username);
                }
            } catch (err) {
                console.log(err);
                setError(err);
            }
        },
        [forgotPassword, onShowVerifyEmail, setEmail, isNewPassword]
    );

    return (
        <Modal className={styles.modal}>
            <div className={styles.header}>
                <div className={styles.lockWrapper}>
                    <span className={cs('material-symbols-rounded', styles.lockIcon)}>lock</span>
                </div>
                <span
                    onClick={onClose}
                    className={cs('material-symbols-rounded', styles.closeIcon)}
                >
                    close
                </span>
            </div>
            <h2 className={styles.title}>
                {isNewPassword ? _('Set new password') : _('Forgot password?')}
            </h2>
            <p className={styles.description}>
                {isNewPassword ? (
                    <Localize>
                        We will send you a code to your registered email to set your password.
                    </Localize>
                ) : (
                    <Localize>
                        Enter the email address you used to create your MDSA account. We’ll send you
                        a code to reset password.
                    </Localize>
                )}
            </p>
            <Form
                formErrorClassName={styles.errorMessage}
                error={error}
                onSubmit={handleSendEmail}
                className={styles.formWrapper}
            >
                <InputField
                    component={AuthInput}
                    name="username"
                    label={isNewPassword ? '' : _('Email')}
                    labelClassName={styles.inputLabel}
                    placeholder={_('Enter your email')}
                    required
                    defaultValue={user?.email || defaultEmail || ''}
                    disabled={isAuthenticated || isNewPassword}
                />
                <Button
                    loading={loading}
                    className={cs(styles.button, { [styles.buttonSingle]: isNewPassword })}
                >
                    {isNewPassword ? _('Send code') : _('Send reset instructions')}
                </Button>
            </Form>
            {!(isAuthenticated || isNewPassword) && (
                <div onClick={onClose} className={styles.backIcon}>
                    <span className={cs('material-symbols-rounded', styles.backIcon)}>
                        arrow_back
                    </span>
                    <p className={styles.backTitle}>Back to log in</p>
                </div>
            )}
        </Modal>
    );
};

export default withVisibleCheck(SendEmail);
