import React, { useCallback, useRef, useState } from 'react';

import Popup from '@ra/components/Popup';

import cs from '@ra/cs';
import { Localize, _ } from 'services/i18n';

import type { Project } from 'services/types';

import styles from './styles.scss';

export const StatusItem = (props: { text: string }) => {
    const { text } = props;

    return (
        <div className={styles.statusItemValue}>
            <div
                className={cs(styles.statusIndicator, {
                    [styles.statusIndicatorActive]: text === _('Active'),
                    [styles.statusIndicatorCompleted]: text === _('Completed'),
                    [styles.statusIndicatorDropped]: text === _('Dropped')
                })}
            />
            <Localize>{text || '-'}</Localize>
        </div>
    );
};

interface StatusItemProps {
    activeProject?: Project;
    statusData: { [key: string]: string };
    onChange?: (statusValue: string, callback?: () => void) => void;
    disabled?: boolean;
}

const StatusItemSelect = (props: StatusItemProps) => {
    const { activeProject, statusData, onChange, disabled } = props;

    const [activeStatusValue, setActiveStatusValue] = useState<string | null>(null);

    const anchorRef: React.RefObject<HTMLDivElement> = useRef(null);

    const [isDropdownVisible, setDropdownVisible] = useState<boolean>(false);
    const togglePopup = useCallback(() => setDropdownVisible((prev) => !prev), []);

    const handleStatusChange = useCallback(
        (statusValue: string) => {
            togglePopup();
            onChange?.(statusValue, () => setActiveStatusValue(statusValue));
        },
        [onChange, togglePopup]
    );

    return (
        <div ref={anchorRef} className={styles.statusValue}>
            <StatusItem text={statusData[activeStatusValue || (activeProject?.status as string)]} />
            {!disabled && (
                <span
                    className={cs(styles.dropdownIcon, 'material-symbols-rounded', {
                        [styles.dropdownIconActive]: isDropdownVisible
                    })}
                    onClick={togglePopup}
                >
                    chevron_left
                </span>
            )}
            <Popup
                isVisible={isDropdownVisible}
                anchor={anchorRef}
                anchorOrigin="bottom right"
                transformOrigin="top right"
                onClose={togglePopup}
                closeOnOutsideClick
                className={styles.dropdown}
            >
                {Object.entries(statusData).map(([statusValue, statusText]) => (
                    <div
                        className={styles.dropdownItem}
                        onClick={() => handleStatusChange(statusValue)}
                    >
                        <StatusItem text={statusText} />
                    </div>
                ))}
            </Popup>
        </div>
    );
};

export default StatusItemSelect;
