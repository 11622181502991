import { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';

import Button from 'components/Button';
import FileInput, { FilePreview } from 'components/FileInput';
import TextareaInput from 'components/Input/TextareaInput';
import Form from '@ra/components/Form/Advanced';
import Label from '@ra/components/Form/Label';
import Modal, { ModalProps } from '@ra/components/Modal';

import cs from '@ra/cs';
import Api from 'services/api';
import { _ } from 'services/i18n';
import usePromise from '@ra/hooks/usePromise';
import Toast from 'services/toast';
import { getErrorMessage } from '@ra/utils/error';

import type { FormSubmitCallback } from '@ra/components/Form';
import type { FileInputChangeCallback } from '@ra/components/Form/DragDropFileInput';
import type { ProjectDocument, ProjectDocumentType } from 'services/types';

import styles from './styles.scss';

const fileExtractor: FileInputChangeCallback = ({ files }) => (files.length > 0 ? files[0] : null);

type ProjectReportFormModalProps = Omit<ModalProps, 'children'> & {
    documentType: ProjectDocumentType;
    onComplete?: () => void;
    editMode?: boolean;
    document?: ProjectDocument;
};

const ProjectReportFormModal: React.FC<ProjectReportFormModalProps> = (props) => {
    const { onClose, documentType, onComplete, editMode, document, ...modalProps } = props;

    const { projectId } = useParams();

    const handleClose = useCallback(() => onClose?.(), [onClose]);

    const [{ loading }, createProjectReport] = usePromise(Api.createProjectDocument);
    const [{ loading: updating }, editProjectReport] = usePromise(Api.patchProjectDocument);
    const handleSubmitForm: FormSubmitCallback = useCallback(
        async (formData) => {
            try {
                if (editMode) {
                    if (formData.get('document') === 'null') {
                        formData.delete('document');
                    }
                    await editProjectReport(document?.id, formData, { headers: new Headers() });
                    Toast.show(
                        _('Project Report has been successfully edited!'),
                        Toast.SUCCESS,
                        10
                    );
                    onComplete?.();
                    return;
                }
                formData.append('documentType', documentType.id);
                formData.append('title', documentType.title);
                await createProjectReport(projectId, formData, {
                    headers: new Headers()
                });
                Toast.show(_('Project Report has been successfully added!'), Toast.SUCCESS, 10);
                onComplete?.();
            } catch (error) {
                console.log(error);
                Toast.show(getErrorMessage(error), Toast.DANGER, 10);
            }
        },
        [createProjectReport, onComplete, projectId, documentType]
    );

    const [hasNewFile, setHasNewFile] = useState<boolean>(false);
    const handleFileChange: FileInputChangeCallback = useCallback((payload) => {
        const { files } = payload;
        if (files.length > 0) {
            setHasNewFile(true);
        } else {
            setHasNewFile(false);
        }
    }, []);

    return (
        <Modal className={styles.modal} {...modalProps}>
            <div className={styles.header}>
                <h2 className={styles.title}>{editMode ? _('Edit Report') : _('Add Report')}</h2>
                <span
                    className={cs('material-symbols-rounded', styles.closeIcon)}
                    onClick={handleClose}
                >
                    close
                </span>
            </div>
            <Form onSubmit={handleSubmitForm} className={styles.reportForm}>
                <div className={styles.formContent}>
                    <Label className={styles.inputLabel}>{documentType.title + '*'}</Label>
                    {!hasNewFile && editMode && document && <FilePreview file={document} />}
                    <Form.Input
                        component={FileInput}
                        name="document"
                        containerClassName={styles.inputSpacing}
                        info={_('PDF (max. 2MB)')}
                        maxSize={2048}
                        fieldValueExtractor={fileExtractor}
                        accept="application/pdf"
                        required={!editMode}
                        onChange={handleFileChange}
                    />
                    <Form.Input
                        component={TextareaInput}
                        name="description"
                        label={_('Remarks')}
                        labelClassName={styles.inputLabel}
                        containerClassName={styles.inputSpacing}
                        placeholder={_('Enter remarks')}
                        rows={4}
                        defaultValue={document?.description}
                    />
                </div>
                <Button large className={styles.submitButton} loading={loading || updating}>
                    {editMode ? _('Save') : _('Add')}
                </Button>
            </Form>
        </Modal>
    );
};

export default ProjectReportFormModal;
