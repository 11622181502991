import React, { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import Table, {
    TableProps,
    Column,
    TableRenderDataItem,
    TableRenderHeaderItem
} from '@ra/components/Table';

import cs from '@ra/cs';
import { Localize, _ } from 'services/i18n';
import { formatCurrency, formatNumber } from 'utils/formatter';

import styles from './styles.scss';

export type ActivityTableItem = {
    id: string | number;
    title: string;
    count: number | string;
    budget: number | string;
};

export const getDefaultActivityColumns = () => [
    {
        Header: _('ACTIVITY'),
        accessor: 'title'
    },
    {
        Header: _('NO. OF PROJECTS'),
        accessor: 'projectCount'
    },
    {
        Header: _('BUDGET (NPR)'),
        accessor: 'budget'
    }
];

type HeaderItemProps = { onSort?: (ordering: string) => void; ordering?: string };
const HeaderItem: React.FC<{ column: Column } & HeaderItemProps> = ({ column }) => {
    return <div className={styles.headerItem}>{column.Header}</div>;
};

type ActivityTableProps = Omit<TableProps<ActivityTableItem>, 'columns'> &
    HeaderItemProps & { columns?: Column[] };

const ActivityTable: React.FC<ActivityTableProps> = (props) => {
    const { className, headerClassName, onSort, ordering, columns, ...otherProps } = props;

    const navigate = useNavigate();

    const columnData = useMemo(() => {
        if (columns) {
            return columns;
        }
        return getDefaultActivityColumns();
    }, [columns]);

    const renderHeaderItem: TableRenderHeaderItem = useCallback(
        (tableProps) => {
            return <HeaderItem {...tableProps} onSort={onSort} ordering={ordering} />;
        },
        [onSort, ordering]
    );

    const renderDataItem: TableRenderDataItem<ActivityTableItem> = useCallback(
        ({ item, column }) => {
            if (column.accessor === 'title') {
                return (
                    <div className={styles.titleItem}>
                        <>{item[column.accessor as keyof ActivityTableItem]}</>
                    </div>
                );
            }
            if (column.accessor === 'projectCount') {
                return <>{formatNumber(item[column.accessor as keyof ActivityTableItem])}</>;
            }
            if (column.accessor === 'budget') {
                return (
                    <>
                        {formatCurrency(item[column.accessor as keyof ActivityTableItem] as number)
                            ?.replace('Rs.', '')
                            ?.trim() || '-'}
                    </>
                );
            }
            return <>{item[column.accessor as keyof ActivityTableItem]}</>;
        },
        []
    );

    const handleRowClick = useCallback(
        (item: ActivityTableItem) => {
            if (item.id) {
                navigate('../activities/' + item.id.toString(), { state: { activity: item } });
            }
        },
        [navigate]
    );

    return (
        <Table
            columns={columnData}
            className={cs(styles.table, className)}
            headerClassName={cs(styles.header, headerClassName)}
            bodyRowClassName={styles.bodyRow}
            dataClassName={styles.tableData}
            renderHeaderItem={renderHeaderItem}
            renderDataItem={renderDataItem}
            onRowClick={handleRowClick}
            EmptyComponent={
                <tr>
                    <td className={styles.tableMessage}>
                        <Localize>No activities found!</Localize>
                    </td>
                </tr>
            }
            LoadingComponent={
                <tr>
                    <td className={styles.tableMessage}>
                        <Localize>Loading NDRRSAP activities...</Localize>
                    </td>
                </tr>
            }
            {...otherProps}
        />
    );
};

export default ActivityTable;
