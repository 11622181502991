import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import type { PriorityIndicator } from 'services/types';

interface PriorityIndicatorReducerState {
    priorityIndicators: PriorityIndicator[];
}

const initialState: PriorityIndicatorReducerState = {
    priorityIndicators: []
};

const priorityIndicatorSlice = createSlice({
    name: 'priorityIndicator',
    initialState,
    reducers: {
        setPriorityIndicators: (
            state: PriorityIndicatorReducerState,
            { payload }: PayloadAction<PriorityIndicator[]>
        ) => {
            state.priorityIndicators = payload;
        }
    }
});

export const { setPriorityIndicators } = priorityIndicatorSlice.actions;

export default priorityIndicatorSlice.reducer;
