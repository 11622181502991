import React, { useCallback, useMemo, useEffect } from 'react';

import Button from 'components/Button';
import MultiSelectInput, {
    KeyExtractor,
    ValueExtractor,
    MultiSelectInputChangeCallback,
    RenderControlCallback
} from '@ra/components/Form/MultiSelectInput';

import cs from '@ra/cs';
import { Localize, _ } from 'services/i18n';
import { getOrganizations } from 'services/bootstrap';

import { useAppSelector } from 'hooks/store';
import { useFilters, useFiltersDispatch } from 'hooks/filters';

import type { Organization } from 'services/types';

import styles from './styles.scss';

const keyExtractor: KeyExtractor<Organization> = (item) => item.id;
const valueExtractor: ValueExtractor<Organization, string> = (item) =>
    item.title.length > 50 ? item.title.slice(0, 50) + '...' : item.title;

interface FilterOrganizationsProps {
    filterType: string;
    isClearable?: boolean;
}

const FilterOrganizations: React.FC<FilterOrganizationsProps> = (props) => {
    const { filterType } = props;

    const dispatch = useFiltersDispatch();
    const filters = useFilters();

    const { organizations } = useAppSelector((state) => state.organization);

    const organizationFilter = useMemo(() => {
        return filters.find((el) => el.filterType === filterType);
    }, [filters, filterType]);

    useEffect(() => {
        getOrganizations();
    }, []);

    const handleOrganizationChange: MultiSelectInputChangeCallback<Organization> = useCallback(
        ({ value: option }) => {
            if (option?.length) {
                return dispatch({
                    type: 'add',
                    filter: {
                        filterType,
                        filterValue: option,
                        query: {
                            organization__in: option.map((opt) => opt.id).toString()
                        }
                    }
                });
            }
            dispatch({
                type: 'remove',
                filter: { filterType }
            });
        },
        [dispatch, filterType]
    );

    const renderControl: RenderControlCallback<Organization, string> = useCallback(
        ({ selectedItems, handleCaretClick }) => {
            return (
                <Button
                    small
                    tertiary
                    leftIcon="workspaces"
                    rightIcon="expand_more"
                    onClick={handleCaretClick}
                    className={styles.controlButton}
                    iconClassName={styles.controlButtonIcon}
                >
                    {selectedItems.length ? (
                        <>
                            {selectedItems.length === 1
                                ? selectedItems[0].title.length > 50
                                    ? selectedItems[0].title.slice(0, 50) + '...'
                                    : selectedItems[0].title
                                : `${selectedItems.length} ${_('organizations')}`}
                        </>
                    ) : (
                        <Localize>Organization</Localize>
                    )}
                </Button>
            );
        },
        []
    );

    return (
        <div className={styles.container}>
            <MultiSelectInput
                className={styles.select}
                optionsWrapperClassName={styles.optionsWrapper}
                selectOptionClassName={styles.selectOption}
                controlClassName={cs(styles.selectControl, {
                    [styles.selectControlFilled]: !!organizationFilter
                })}
                defaultValue={organizationFilter?.filterValue || []}
                searchable
                clearable
                renderControl={renderControl}
                options={organizations}
                keyExtractor={keyExtractor}
                valueExtractor={valueExtractor}
                onChange={handleOrganizationChange}
                placeholder={_('Organization')}
                anchorOrigin="bottom right"
                transformOrigin="top right"
            />
        </div>
    );
};

export default FilterOrganizations;
