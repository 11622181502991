import React, { useMemo } from 'react';

import cs from '@ra/cs';

import styles from './styles.scss';

interface BreadcrumbsProps {
    children: React.ReactNode;
    className?: string;
    separator?: React.ReactNode;
    separatorClassName?: string;
}

const insertSeparators = (
    items: React.ReactNode[],
    separator: React.ReactNode,
    className?: string
) => {
    return items.reduce((acc: React.ReactNode[], cur: React.ReactNode, idx: number) => {
        if (idx < items.length - 1 || items.length === 1) {
            acc = acc.concat(
                cur,
                <li className={cs(className, styles.separator)} key={`separator-${idx}`}>
                    {separator}
                </li>
            );
        } else {
            acc.push(cur);
        }
        return acc;
    }, []);
};

const Breadcrumbs: React.FC<BreadcrumbsProps> = (props) => {
    const { children, className, separatorClassName, separator = '/' } = props;

    const allItems = useMemo(
        () =>
            React.Children.toArray(children)
                .filter((child) => {
                    return React.isValidElement(child);
                })
                .map((child, index) => <li key={`child-${index}`}>{child}</li>),
        [children]
    );

    return (
        <ol className={cs(styles.container, className)}>
            {insertSeparators(allItems, separator, separatorClassName)}
        </ol>
    );
};

export default Breadcrumbs;
