import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import type { LegalDocument } from 'services/types';

interface LegalDocumentReducerState {
    legalDocuments: LegalDocument[];
    isLoading: boolean;
}

const initialState: LegalDocumentReducerState = {
    legalDocuments: [],
    isLoading: true
};

const legalDocumentSlice = createSlice({
    name: 'legalDocument',
    initialState,
    reducers: {
        setLegalDocuments: (
            state: LegalDocumentReducerState,
            { payload }: PayloadAction<LegalDocument[]>
        ) => {
            state.legalDocuments = payload;
            state.isLoading = false;
        }
    }
});

export const { setLegalDocuments } = legalDocumentSlice.actions;

export default legalDocumentSlice.reducer;
