import { createSelector } from '@reduxjs/toolkit';

import type { RootState } from 'store';

const selectPriorityIndicators = (state: RootState) => state.priorityIndicator.priorityIndicators;

export const selectPriorityAreas = createSelector(
    selectPriorityIndicators,
    (priorityIndicators) => {
        return priorityIndicators.filter((pi) => pi.level === 0);
    }
);

export const selectPriorityActions = createSelector(
    [selectPriorityIndicators, (state: RootState, priorityArea?: number) => priorityArea],
    (priorityIndicators, priorityArea) => {
        return priorityIndicators.filter((pi) => {
            if (priorityArea) {
                return pi.level === 1 && pi.parent === priorityArea;
            }
            return pi.level === 1;
        });
    }
);

export const selectPriorityActivities = createSelector(
    [selectPriorityIndicators, (state: RootState, priorityAction?: number) => priorityAction],
    (priorityIndicators, priorityAction) => {
        return priorityIndicators.filter((pi) => {
            if (priorityAction) {
                return pi.level === 2 && pi.parent === priorityAction;
            }
            return pi.level === 2;
        });
    }
);
