import { ProjectTypeEnum, ExpenseCurrencyEnum } from 'services/types';

export type Currency = {
    id: number;
    code: string;
};

export type FundingType = {
    id: number;
    title: string;
    code: string;
};

export const agreementDocumentTypeTitles: string[] = [
    'Proposal Agreement',
    'MOW Agreement',
    'SWC Agreement',
    'Agreement Feedback'
];
export const reportDocumentTypeTitles: string[] = [
    'CPAC Progress Report',
    'Mid Term Report',
    'Completion Report',
    'Evaluation Report'
];

export const currencies: Currency[] = [
    {
        id: 1,
        code: ExpenseCurrencyEnum.NPR
    },
    {
        id: 2,
        code: ExpenseCurrencyEnum.CHF
    },
    {
        id: 3,
        code: ExpenseCurrencyEnum.CNY
    },
    {
        id: 4,
        code: ExpenseCurrencyEnum.EUR
    },
    {
        id: 5,
        code: ExpenseCurrencyEnum.ILS
    },
    {
        id: 6,
        code: ExpenseCurrencyEnum.INR
    },
    {
        id: 7,
        code: ExpenseCurrencyEnum.JPY
    },
    {
        id: 8,
        code: ExpenseCurrencyEnum.KRW
    },
    {
        id: 9,
        code: ExpenseCurrencyEnum.QAR
    },
    {
        id: 10,
        code: ExpenseCurrencyEnum.RUB
    },
    {
        id: 11,
        code: ExpenseCurrencyEnum.SAR
    },
    {
        id: 12,
        code: ExpenseCurrencyEnum.USD
    }
];

export const fundingTypes: FundingType[] = [
    {
        id: 1,
        title: 'On budget',
        code: ProjectTypeEnum.OnBudget
    },
    {
        id: 2,
        title: 'Off budget',
        code: ProjectTypeEnum.OffBudget
    }
];

export const phoneNumberValidationRegex = /^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s./0-9]*$/;
