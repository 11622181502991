import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import type { Organization, OrganizationType } from 'services/types';

interface OrganizationReducerState {
    organizations: Organization[];
    organizationTypes: OrganizationType[];
}

const initialState: OrganizationReducerState = {
    organizations: [],
    organizationTypes: []
};

const organizationSlice = createSlice({
    name: 'organization',
    initialState,
    reducers: {
        setOrganizations: (
            state: OrganizationReducerState,
            { payload }: PayloadAction<Organization[]>
        ) => {
            state.organizations = payload;
        },
        setOrganizationTypes: (
            state: OrganizationReducerState,
            { payload }: PayloadAction<OrganizationType[]>
        ) => {
            state.organizationTypes = payload;
        }
    }
});

export const { setOrganizations, setOrganizationTypes } = organizationSlice.actions;

export default organizationSlice.reducer;
