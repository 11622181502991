import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import type { Hazard } from 'services/types';

interface HazardReducerState {
    hazards: Hazard[];
}

const initialState: HazardReducerState = {
    hazards: []
};

const hazardSlice = createSlice({
    name: 'hazard',
    initialState,
    reducers: {
        setHazards: (state: HazardReducerState, { payload }: PayloadAction<Hazard[]>) => {
            state.hazards = payload;
        }
    }
});

export const { setHazards } = hazardSlice.actions;

export default hazardSlice.reducer;
