import React, { useCallback, useState, useEffect } from 'react';
import { ResponsiveContainer, PieChart, Pie, Cell, Sector, Legend } from 'recharts';

import useSize from '@ra/hooks/useSize';

import { COLORS, darkColorIndices } from '../utils';

import styles from './styles.scss';

const RADIAN = Math.PI / 180;

type EntryType = {
    value: number;
    [key: string]: any;
};

type CustomizedShapeProps = {
    cx: number;
    cy: number;
    midAngle: number;
    percent: number;
    index: number;
    innerRadius: number;
    outerRadius: number;
};

interface PieChartProps {
    data: EntryType[];
}

const _PieChart: React.FC<PieChartProps> = (props) => {
    const { data } = props;

    const [chartWidth, setChartWidth] = useState('100%');
    const updateWidth = useCallback(() => {
        setChartWidth('99%');
    }, []);

    useSize();

    useEffect(() => {
        window.addEventListener('resize', updateWidth);
        return () => window.addEventListener('resize', updateWidth);
    }, [updateWidth]);

    const renderActiveShape = useCallback((cellProps: CustomizedShapeProps) => {
        return <Sector {...cellProps} outerRadius={124} />;
    }, []);

    const renderCustomizedLabel = useCallback((labelProps: CustomizedShapeProps) => {
        const { cx, cy, midAngle, percent, index, innerRadius, outerRadius } = labelProps;

        const radius = innerRadius + (outerRadius - innerRadius) * (index === 0 ? 0.25 : 0.2);
        const x = cx + radius * Math.cos(-midAngle * RADIAN);
        const y = cy + radius * Math.sin(-midAngle * RADIAN);

        return (
            <text
                x={x}
                y={y}
                fill={darkColorIndices.includes(index) ? '#32343D' : '#FFFFFF'}
                textAnchor={x > cx ? 'start' : 'end'}
                dominantBaseline="central"
                className={styles.labelText}
            >
                {`${(percent * 100).toFixed(0)}%`}
            </text>
        );
    }, []);

    return (
        <ResponsiveContainer width={chartWidth} height={374}>
            <PieChart>
                <Pie
                    activeIndex={0}
                    activeShape={renderActiveShape}
                    data={data}
                    cx={window.innerWidth > 767 ? '33%' : '50%'}
                    cy="50%"
                    innerRadius={70}
                    outerRadius={112}
                    dataKey="value"
                    startAngle={90}
                    paddingAngle={0}
                    endAngle={-270}
                    labelLine={false}
                    label={renderCustomizedLabel}
                >
                    {data.map((entry, index) => (
                        <Cell
                            fill={COLORS[index % COLORS.length]}
                            stroke={COLORS[index % COLORS.length]}
                        />
                    ))}
                </Pie>
                <Legend
                    align={window.innerWidth > 767 ? 'right' : 'center'}
                    layout={window.innerWidth > 767 ? 'vertical' : 'horizontal'}
                    verticalAlign={window.innerWidth > 767 ? 'middle' : 'bottom'}
                    iconType="circle"
                    formatter={(value: string) => {
                        return (
                            <p className={styles.legendText} title={value}>
                                {value.length > 45 ? value.slice(0, 45) + '...' : value}
                            </p>
                        );
                    }}
                />
            </PieChart>
        </ResponsiveContainer>
    );
};

export default _PieChart;
